import { Fragment, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Divider } from '@ui-kitten/components';

import { getCartTerm } from '../../../../Helper/RemoteConfig';
import useOrderAgain from '../../../../Hooks/useOrderAgain';
import ThemeStyle from '../../../../Theme/styles';

import Button from '../../../Button';

import StoreCartItem from '../../../StoreCart/CartItem';

import ModalPrompt from './ModalPrompt';
import useModalPrompt from './hooks/useModalPrompt';

const OrderAgain = ({ modalId }) => {
  const { submitting, onSubmit } = useOrderAgain();
  const { hideModalPrompt } = useModalPrompt();
  const [data, setData] = useState<any>();

  const _onClosed = () => hideModalPrompt(modalId, {});

  return (
    <ModalPrompt
      id={modalId}
      title={`This will be added to your ${getCartTerm()}`}
      hasHeader={true}
      hasHeaderLine={true}
      onCloseButtonClick={!submitting && _onClosed}
      closeOnDocumentClick={false}
      onBeforeShow={setData}
    >
      <View style={styles.contentContainerNoHPadding}>
        <View style={styles.modalContentContainer}>
          <ScrollView>
            {data?.items?.map((item, index) => (
              <Fragment key={index}>
                <StoreCartItem
                  index={index}
                  sectionIndex={0}
                  {...item}
                  wrapperStyle={{
                    paddingHorizontal: 0,
                    marginLeft: -10,
                  }}
                />

                {index !== data?.items.length - 1 && (
                  <Divider style={ThemeStyle.divider} />
                )}
              </Fragment>
            ))}
          </ScrollView>
        </View>

        <View>
          <Button
            status="success"
            onPress={() => onSubmit(data, _onClosed)}
            loading={submitting}
          >
            OK
          </Button>
        </View>
      </View>
    </ModalPrompt>
  );
};

const styles = StyleSheet.create({
  contentContainerNoHPadding: { ...ThemeStyle.spacingBottomMedium },
  modalContentContainer: {
    maxHeight: 400,
  },
});

export default OrderAgain;
