import { Alert, Linking, PermissionsAndroid } from 'react-native';
import { CANCEL_ERROR } from 'apisauce';
import geolocation from '@react-native-community/geolocation';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../../Config/constants';
import messages from '../../../Config/messages';
import Sentry from '../../../Helper/Sentry';
import useCancellableRequest from '../../../Hooks/useCancellableRequest';
import Service from '../../../Service';
import otherApi from '../../../Service/api/other';

function useCommonHooks() {
  const { createRequest } = useCancellableRequest();

  const _getPrefixLabel = (label, defaultIcon) => {
    // find the label prefix
    const prefixLabel = lodashFind(
      constants.LABEL_TYPE,
      (l) => l.label?.toLowerCase() === label?.toLowerCase()
    );
    if (!lodashIsEmpty(prefixLabel)) {
      // if found, return the prefix label
      return prefixLabel;
    } else if (defaultIcon) {
      // if not found and defaultIcon is true, return default icon
      return { icon: 'pin-outline' };
    } else {
      // if not found, return the other label
      return lodashFind(constants.LABEL_TYPE, { showInput: true });
    }
  };

  const _getUserLocation = async (onSuccess, onError) => {
    const Geolocation = constants.isWeb ? navigator.geolocation : geolocation;
    // request permission and get user location
    if (constants.isIOS) {
      await Geolocation.requestAuthorization();
    } else if (constants.isAndroid) {
      await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
      );
    }
    // get location
    Geolocation.getCurrentPosition(
      async ({ coords: { latitude, longitude } }) => {
        const { ok, data, problem } = await createRequest(
          otherApi.googleMapReverseGeocode,
          {
            latitude,
            longitude,
          }
        );
        // stop the code from going if request is cancelled
        if (problem === CANCEL_ERROR) {
          return;
        }
        if (ok && data?.status === 'OK') {
          const { place_id, formatted_address: address } = data.results[0];

          onSuccess?.({
            userLocation: { place_id, address, latitude, longitude },
          });
        } else {
          Sentry.reportError('Error getting google map reverse geocode', {
            payload: {
              latitude,
              longitude,
            },
            data,
          });
          Alert.alert(
            'Please try again',
            'Failed to get user location details.'
          );
        }
      },
      (error) => {
        if (constants.isWeb || error.code !== 2) {
          Alert.alert(
            messages.COMMON_ERROR_TITLE,
            error?.message ||
              'An error has occurred while trying to get your location'
          );
        } else {
          // if error code === 2 (location is off)
          Alert.alert(
            'Location is turned off',
            "Please turn on the location by pressing 'ok' to continue.",
            [
              {
                text: 'ok',
                onPress: () => {
                  if (constants.isAndroid) {
                    Linking.sendIntent(
                      'android.settings.LOCATION_SOURCE_SETTINGS'
                    );
                  } else if (constants.isIOS) {
                    Linking.openURL('app-settings:');
                  }
                },
              },
              {
                text: 'cancel',
                onPress: () => {
                  Alert.alert(
                    'Location Unavailable',
                    "You can't use this feature without turning on the location in settings."
                  );
                },
              },
            ]
          );
        }
        onError?.();
      },
      {
        enableHighAccuracy: false,
        maximumAge: 10000, // 10s
        timeout: 20000, // 20s
      }
    );
  };

  return {
    getPrefixLabel: _getPrefixLabel,
    getUserLocation: _getUserLocation,
  };
}

export default useCommonHooks;
