import React, { useEffect, useRef, useCallback, memo } from 'react';
import { Animated, StyleSheet } from 'react-native';

import Button from '../../Button';

import { RAZZLE_APP_URL } from '@env';

const ScaleButtonAnimation = memo(
  ({
    dataSet,
    onPress,
    buttonStyle,
    contentStyle,
    onScaling,
    scale = 1.01,
    disabled = false,
    children,
    onLayout,
    originalScale = 1,
    href,
  }) => {
    const scaleValue = useRef(new Animated.Value(originalScale)).current;

    useEffect(() => {
      scaleValue.setValue(originalScale);
    }, [scaleValue, originalScale]);

    const handleScaleAnimation = useCallback(() => {
      Animated.timing(scaleValue, {
        toValue: scale,
        duration: 100,
        useNativeDriver: true,
      }).start();
      onScaling?.(true);
    }, [scale, onScaling, scaleValue]);

    const handleReset = useCallback(() => {
      Animated.timing(scaleValue, {
        toValue: originalScale,
        duration: 100,
        useNativeDriver: true,
      }).start();
      onScaling?.(false);
    }, [originalScale, onScaling, scaleValue]);

    const handleLinkClick = (event) => {
      event.preventDefault();
      onPress();
    };

    return (
      <Button
        dataSet={dataSet}
        style={buttonStyle}
        onPress={handleLinkClick}
        onHoverIn={handleScaleAnimation}
        onHoverOut={handleReset}
        isHoverable
        disabled={disabled}
        onLayout={onLayout}
        accessibilityRole="link"
        href={href || RAZZLE_APP_URL}
        target="_blank"
      >
        <Animated.View
          style={[
            StyleSheet.flatten(contentStyle),
            { transform: [{ scale: scaleValue }] },
          ]}
        >
          {children}
        </Animated.View>
      </Button>
    );
  }
);

export default ScaleButtonAnimation;
