import './ignoreWarnings';
import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Sentry from './Helper/Sentry';
import store from './RTK/store';

import {
  FontAwesome,
  FontAwesome5,
  MaterialCommunity,
  Ionicons,
  Entypo,
  Feather,
} from './IconPack';

const persistor = persistStore(store);
const queryClient = new QueryClient();

Sentry.init();

function Main({ children }) {
  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <IconRegistry
            icons={[
              EvaIconsPack,
              FontAwesome,
              FontAwesome5,
              MaterialCommunity,
              Ionicons,
              Entypo,
              Feather,
            ]}
          />
          <QueryClientProvider client={queryClient}>
            <GestureHandlerRootView style={{ flex: 1 }}>
              {children}
            </GestureHandlerRootView>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </SafeAreaProvider>
  );
}
export default Main;
