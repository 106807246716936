import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '@ui-kitten/components';
import StarRatings from 'react-star-ratings';

import ThemeStyles from '../../../Theme/styles';
import ThemeColor from '../../../Theme/colors';

const Review = ({ item, index, onPress }) => {
  // console.log(item)
  const { user, comment } = item.item;
  const [starValue, setStarValue] = useState(0);

  const onStarChange = () => {};
  const onFeedbackChange = () => {};

  const myOwnReview = () => (
    <View style={styles.sub_wrapper}>
      <View style={styles.my_own_review_blank_row}>
        <Text
          style={[ThemeStyles.bold, { color: ThemeColor.black, fontSize: 14 }]}
        >{`Walter M.`}</Text>
      </View>

      <View style={styles.my_own_review_blank_row_star_container}>
        <StarRatings
          rating={starValue}
          starRatedColor={ThemeColor.gold}
          starHoverColor={ThemeColor.gold}
          starEmptyColor={ThemeColor.gray}
          starDimension="20px"
          changeRating={onPress}
          numberOfStars={5}
        />
      </View>
      <View style={styles.my_own_review_blank_row}>
        <Text
          style={[
            ThemeStyles.bold,
            { color: ThemeColor.darkGray, fontSize: 14 },
          ]}
        >{`Start your review`}</Text>
      </View>
    </View>
  );
  const otherReview = () => (
    <View
      style={[
        styles.sub_wrapper,
        {
          backgroundColor: ThemeColor.white,
          borderWidth: 1,
          borderColor: ThemeColor.gray,
        },
      ]}
    >
      <View style={{ height: '15%', justifyContent: 'flex-start' }}>
        <Text
          style={[ThemeStyles.bold, { color: ThemeColor.black, fontSize: 14 }]}
        >
          {user}
        </Text>
      </View>

      <View
        style={{
          height: '20%',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <StarRatings
          rating={starValue}
          starRatedColor={ThemeColor.gold}
          starHoverColor={ThemeColor.gold}
          starEmptyColor={ThemeColor.gray}
          starDimension="15px"
          changeRating={onStarChange}
          numberOfStars={5}
        />
      </View>
      <View style={{ height: '25%', justifyContent: 'flex-start' }}>
        <Text
          numberOfLines={3}
          style={[ThemeStyles.bold, { color: ThemeColor.black, fontSize: 12 }]}
        >
          {comment}
        </Text>
      </View>
    </View>
  );
  return (
    <View style={[styles.main_wrapper, {}]}>
      {user == 'me' ? myOwnReview() : otherReview()}
    </View>
  );
};

const styles = StyleSheet.create({
  main_wrapper: {
    flex: 1,
    justifyContent: 'center',
  },
  sub_wrapper: {
    justifyContent: 'center',
    backgroundColor: ThemeColor.lightGray,
    borderRadius: 5,
    width: 280,
    height: 150,
    padding: 15,
  },
  my_own_review_blank_row: { height: '25%', justifyContent: 'flex-start' },
  my_own_review_blank_row_star_container: {
    height: '30%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
});

export default Review;
