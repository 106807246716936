import React from 'react';
import { View } from 'react-native';
import { Layout, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import EmptyCartIcon from '../../Assets/EmptyCartIcon.svg';
import EmptyFavorites from '../../Assets/EmptyFavorites.svg';

import NoResultFound from '../../Assets/NoResultFound.svg';

import ThemeStyles from '../../Theme/styles';

import { capitalize } from '../../Helper';
import { getCartTerm } from '../../Helper/RemoteConfig';

const EmptyMessage = {
  cart: "But it doesn't have to be.",
  favorites: "Let's help you look for the perfect restaurant the you'll love.",
  filter: "We couldn't find what you're looking for",
};
const EmptyData = ({
  title = '',
  subtitle = '',
  type = '',
  svgWidth = 180,
  svgHeight = 180,
}) => {
  const EmptyTitle = {
    cart: `${capitalize(getCartTerm())} is empty.`,
    favorites: 'No Favorites to show.',
    filter: 'No results found.',
  };

  const SVG = () => {
    if (type === 'cart') {
      return <EmptyCartIcon width={svgWidth} height={svgHeight} />;
    } else if (type === 'favorites') {
      return <EmptyFavorites width={svgWidth} height={svgHeight} />;
    } else if (type === 'filter') {
      return <NoResultFound width={svgWidth} height={svgHeight} />;
    }
    return null;
  };

  return (
    <Layout
      style={[
        ThemeStyles.flex1,
        ThemeStyles.alignItemsCenter,
        ThemeStyles.justifyContentCenter,
        ThemeStyles.alignSelfCenter,
        { backgroundColor: 'transparent' },
      ]}
    >
      {SVG()}

      <View style={[ThemeStyles.spacingTop, ThemeStyles.alignItemsCenter]}>
        <Text category="s1">
          {lodashIsEmpty(title) ? EmptyTitle[type] : title}
        </Text>
        <Text category="p2" status="primary">
          {lodashIsEmpty(subtitle) ? EmptyMessage[type] : subtitle}
        </Text>
      </View>
    </Layout>
  );
};

export default EmptyData;
