import React from 'react';
import { FlatList, View, StyleSheet } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';

import constants from '../../../Config/constants';
import useStoreCartServiceController from '../../../Screens/Cart/controller/useStoreCartService';
import ThemeColors from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';

import Button from '../../Button';
import { RectangleLoader } from '../../Loader';
import MinimumOrderNote from '../../StoreCart/MinimumOrderNote';
import StoreCartSummary from '../../StoreCart/CartSummary';
import { RAZZLE_BUILD_MODE } from '@env';

import CartItem from './CartItem';
import {
  CartDetails,
  StoreCart,
  StoreCartUpdate,
  CartHeader,
} from '../../../Types';

type Props = {
  isCartLoading: boolean;
  isUpdatingItem: string;
  store_cart: StoreCart;
  storeHeader: (store: CartHeader) => React.ReactElement;
  onUpdateQuantity: (item: StoreCartUpdate) => () => void;
  goToCheckout: any;
  toggleConfirmDeleteModal: any;
  onItemPress: (item: CartDetails) => void;
  isPaymentLinkLoading: boolean;
};
function CartListService({
  isCartLoading,
  isUpdatingItem,
  store_cart,
  storeHeader,
  onUpdateQuantity,
  goToCheckout,
  toggleConfirmDeleteModal,
  onItemPress,
  isPaymentLinkLoading = false,
}: Props) {
  const {
    buttonTxt,
    isAvailabilityLoading,
    isLessThanMinimum,
    isPaymentPending,
    promotion,
    promotionMinimum,
    storePromo,
    total,
    storeInformation,
    unavailableItemIds,
    onPromoRemove,
    proceedToOrder,
  } = useStoreCartServiceController({
    storeData: store_cart,
    onProceedToOrderPress: goToCheckout,
  });
  const store_theme = useTheme();

  return (
    <FlatList
      data={store_cart.items}
      ListHeaderComponent={() => storeHeader(store_cart as CartHeader)}
      renderItem={({ item, index }) => (
        <CartItem
          item={item}
          disabled={isPaymentPending}
          isLoading={isCartLoading || isAvailabilityLoading}
          isUpdatingItem={isUpdatingItem === item.cart_details_id}
          isUnavailable={unavailableItemIds.includes(item.item_id)}
          onItemPress={() => onItemPress(item)}
          decrementPress={
            item.quantity > 1
              ? onUpdateQuantity({
                  store_id: store_cart.store_id,
                  store_name: store_cart.store_name,
                  cart_details_id: item.cart_details_id,
                  product_name: item.name,
                  item_id: item.item_id,
                  instructions: item.instructions,
                  extras: item.extras,
                  order_type: constants.ORDER_TYPES.DELIVERY,
                  ...item,
                  quantity: item.quantity - 1,
                  is_accepting_in_advanced_orders:
                    storeInformation?.is_accepting_in_advanced_orders,
                })
              : toggleConfirmDeleteModal(
                  {
                    store_id: store_cart.store_id,
                    store_name: store_cart.store_name,
                    cart_details_id: item.cart_details_id,
                    product_name: item.name,
                    item_id: item.item_id,
                    quantity: item.quantity,
                    instructions: item.instructions,
                    extras: item.extras,
                    order_type: constants.ORDER_TYPES.DELIVERY,
                  },
                  'single'
                )
          }
          incrementPress={onUpdateQuantity({
            store_id: store_cart.store_id,
            store_name: store_cart.store_name,
            cart_details_id: item.cart_details_id,
            product_name: item.name,
            item_id: item.item_id,
            instructions: item.instructions,
            extras: item.extras,
            order_type: constants.ORDER_TYPES.DELIVERY,
            ...item,
            quantity: item.quantity + 1,
            is_accepting_in_advanced_orders:
              storeInformation?.is_accepting_in_advanced_orders,
          })}
        />
      )}
      ItemSeparatorComponent={() => <View style={style.itemSeparator} />}
      ListFooterComponent={() =>
        !isCartLoading ? (
          <View>
            <StoreCartSummary
              cart={store_cart.items}
              isDelivery={false} // always false on cart page, since we only need to display the promo details here
              promotion={promotion}
              noHspacing
            />
            {
              //@ts-ignore: Error redline due to JS File
              <MinimumOrderNote
                current={total}
                minimum={promotionMinimum}
                show={isLessThanMinimum}
                voucher={storePromo}
                onRemove={onPromoRemove}
              />
            }

            {RAZZLE_BUILD_MODE === 'branded' && total < 100 && (
              <Text
                category="c2"
                status="danger"
              >{`Minimum order is Php 100.00`}</Text>
            )}

            <Button
              testID="checkout_button"
              disabled={
                (RAZZLE_BUILD_MODE === 'branded' && total < 100) ||
                isCartLoading ||
                isAvailabilityLoading ||
                isPaymentPending ||
                Boolean(isUpdatingItem)
              }
              loading={isPaymentLinkLoading}
              onPress={() => proceedToOrder(store_cart)}
              style={{
                ...style.checkoutButtonContainer,
                backgroundColor:
                  store_theme['button_color'] || ThemeColors.green,
                borderColor: store_theme['button_color'] || ThemeColors.green,
              }}
              status="control"
            >
              {() => (
                <Text
                  category="c1"
                  status="control"
                  style={{
                    color: store_theme['button_text_color'],
                  }}
                >
                  {buttonTxt as string}
                </Text>
              )}
            </Button>
          </View>
        ) : (
          <RectangleLoader height={40} />
        )
      }
    />
  );
}

const style = StyleSheet.create({
  itemSeparator: {
    borderBottomWidth: 0.8,
    borderBottomColor: ThemeColors.gray,
    alignSelf: 'stretch',
  },
  checkoutButtonContainer: {
    ...ThemeStyle.spacingTopMedium,
  },
  contentRow: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  checkoutTxt: { ...ThemeStyle.medium, color: ThemeColors.white },
});

export default CartListService;
