import React from 'react';
import { Layout, Text } from '@ui-kitten/components';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';
import ScreenSize from '../../../Helper/ScreenSize';
import { View } from 'react-native';

const ListGroupSection = ({
  sections,
  renderSectionHeader,
  renderItem,
  onLayout,
}) => {
  const { xs } = ScreenSize();
  return (
    <View
      style={
        !xs && {
          backgroundColor: ThemeColor.white,
          paddingLeft: 20,
          marginHorizontal: 50,
        }
      }
      onLayout={onLayout}
    >
      {typeof renderSectionHeader === 'function' && (
        <Layout>{renderSectionHeader({ section: sections })}</Layout>
      )}
      <Layout>{renderItem({ section: sections })}</Layout>
    </View>
  );
};

export default ListGroupSection;
