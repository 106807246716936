import React, { Fragment } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import { TabView } from 'react-native-tab-view';
import { Text } from '@ui-kitten/components';

import Button from '../../Components/Button';
import Footer from '../../Components/Footer';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import Orders from '../Orders';
import OrderHistory from '../OrderHistory/view';

import useBasketController, { routes } from './index.controller';

const switchTabData = [
  { value: 'orders', label: 'Orders' },
  { value: 'order-history', label: 'History' },
];

function Basket() {
  const { activeTabindex, setActiveTabIndex } = useBasketController();

  const _renderScene = ({ route }) => {
    switch (route.key) {
      case routes[0].key:
        return (
          <View
            style={[
              ThemeStyle.flex1,
              activeTabindex !== 0 && { display: 'none' },
            ]}
          >
            <Orders />
          </View>
        );
      case routes[1].key:
        return (
          <View
            style={[
              ThemeStyle.flex1,
              activeTabindex !== 1 && { display: 'none' },
            ]}
          >
            <OrderHistory />
          </View>
        );
      default:
        return null;
    }
  };

  // NOTE - must be flatlist, if it changed to scrollview, the infinite scroll on order history is not gonna work as expected
  return (
    <FlatList
      data={[0]}
      keyExtractor={(e) => `parent${e}`}
      contentContainerStyle={ThemeStyle.scrollContainer}
      CellRendererComponent={({ children, style }) => (
        <View style={[style, ThemeStyle.flex1]} children={children} />
      )}
      renderItem={() => (
        <View style={styles.wrapper}>
          <View style={ThemeStyle.webPageContainer}>
            <View style={styles.tabContainer}>
              {switchTabData.map((tab, i) => (
                <Fragment key={i}>
                  <Button
                    onPress={() => setActiveTabIndex(i)}
                    style={[
                      styles.tabButton,
                      activeTabindex === i && styles.tabButtonActive,
                    ]}
                    plain
                  >
                    <Text
                      style={ThemeStyle.medium}
                      category="h4"
                      numberOfLines={1}
                    >
                      {tab.label}
                    </Text>
                  </Button>
                  {switchTabData.length - 1 !== i && (
                    <Text
                      category="h5"
                      style={ThemeStyle.pageHorizontalSpacingSmall}
                    >
                      /
                    </Text>
                  )}
                </Fragment>
              ))}
            </View>
            <TabView
              animationEnabled={false}
              navigationState={{ index: activeTabindex, routes }}
              renderTabBar={() => null}
              swipeEnabled={false}
              onIndexChange={setActiveTabIndex}
              renderScene={_renderScene}
            />
          </View>
        </View>
      )}
      ListFooterComponent={
        <View
          style={[
            ThemeStyle.flex1,
            ThemeStyle.fitWidthContainer,
            ThemeStyle.pageHorizontalSpacing,
          ]}
        >
          <Footer />
        </View>
      }
    />
  );
}

const styles = StyleSheet.create({
  wrapper: [
    ThemeStyle.webPageWrapper,
    ThemeStyle.pageBackground,
    {
      alignItems: 'center',
      flexDirection: 'column',
      paddingTop: 50,
      paddingBottom: 30,
    },
  ],
  tabContainer: [
    ThemeStyle.flexDirectionRowCenter,
    ThemeStyle.pageHorizontalSpacing,
    ThemeStyle.spacingBottom,
  ],
  tabButton: {
    backgroundColor: ThemeColor.white,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderBottomWidth: 3,
    borderBottomColor: 'transparent',
  },
  tabButtonActive: {
    borderBottomColor: ThemeColor.green,
  },
});

export default Basket;
