import { Dimensions, StyleSheet } from 'react-native';

import constants from '../../Config/constants';
import colors from '../colors';

import { fontWeights } from '../mapping';

type ThemeStyle = {
  [key: string]: {
    flex?: number;
    zIndex?: number;
  };
};

const deviceSize = Dimensions.get('screen');
export const deviceHeight = deviceSize.height;
export const deviceWidth = deviceSize.width;
export const globalSpacing = 20;

let flex = {};
let zIndex = {};
for (let i = 0; i <= 5; i++) {
  flex[`flex${i}`] = {
    flex: i,
  };
  zIndex[`zIndex${i}`] = {
    zIndex: i,
  };
}

export default StyleSheet.create({
  // Alignments
  ...flex,
  ...zIndex,
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsEnd: {
    alignItems: 'flex-end',
  },
  alignSelfStart: {
    alignSelf: 'flex-start',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  alignSelfEnd: {
    alignSelf: 'flex-end',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  flexDirectionRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexDirectionRowEnd: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  flexDirectionRowEndEnd: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  flexDirectionColEnd: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  flexDirectionRowCenterCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexDirectionRowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexDirectionRowCenterSpaceBetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexDirectionColumnSpaceBetween: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  // spacings
  pageContainer: {
    flex: 1,
    paddingVertical: globalSpacing / 2,
    paddingHorizontal: globalSpacing,
  },
  pageTopSpacing: {
    paddingTop: globalSpacing * 2.5,
  },
  pageVerticalSpacing: {
    paddingVertical: globalSpacing,
  },
  pageVerticalSpacingSmall: {
    paddingVertical: globalSpacing / 4,
  },
  pageVerticalSpacingMedium: {
    paddingVertical: globalSpacing / 2,
  },
  pageHorizontalSpacing: {
    paddingHorizontal: globalSpacing,
  },
  pageHorizontalSpacingSmall: {
    paddingHorizontal: globalSpacing / 2,
  },
  contentTopSpacing: {
    paddingTop: globalSpacing,
  },
  spacingTop: {
    marginTop: globalSpacing,
  },
  spacingTopSmall: {
    marginTop: globalSpacing / 4,
  },
  spacingTopXSmall: {
    marginTop: globalSpacing / 8,
  },
  spacingTopMedium: {
    marginTop: globalSpacing / 2,
  },
  spacingTopLarge: {
    marginTop: globalSpacing * 2.65,
  },
  scrollContainer: {
    flexGrow: 1,
    paddingBottom: constants.isAndroid ? globalSpacing : 0,
  },
  sectionSeparator: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    height: 8,
    borderColor: colors.gray,
    backgroundColor: colors.lightGray,
  },
  rowSeparator: {
    width: globalSpacing / 2,
  },
  rowSeparatorSmall: {
    width: globalSpacing / 4,
  },

  spacingBottom: {
    marginBottom: globalSpacing,
  },
  spacingBottomSmall: {
    marginBottom: globalSpacing / 4,
  },
  spacingBottomXSmall: {
    marginBottom: globalSpacing / 8,
  },
  spacingBottomMedium: {
    marginBottom: globalSpacing / 2,
  },

  spacingVertical: {
    marginVertical: globalSpacing,
  },
  spacingVerticalSmall: {
    marginVertical: globalSpacing / 4,
  },
  spacingVerticalMedium: {
    marginVertical: globalSpacing / 2,
  },

  spacingLeft: {
    marginLeft: globalSpacing,
  },
  paddingLeft: {
    paddingLeft: globalSpacing,
  },
  spacingLeftSmall: {
    marginLeft: globalSpacing / 4,
  },
  spacingLeftMedium: {
    marginLeft: globalSpacing / 2,
  },

  spacingRight: {
    marginRight: globalSpacing,
  },
  spacingRightSmall: {
    marginRight: globalSpacing / 4,
  },
  spacingRightMedium: {
    marginRight: globalSpacing / 2,
  },

  // Buttons
  buttonContentWhite: {
    paddingVertical: globalSpacing / 2,
    paddingHorizontal: globalSpacing,
    backgroundColor: colors.white,
    borderRadius: 50,
  },
  buttonContentGray: {
    padding: globalSpacing / 2,
    backgroundColor: colors.gray,
    borderRadius: 50,
  },
  buttonContentGraySmall: {
    paddingHorizontal: globalSpacing / 2,
    paddingVertical: globalSpacing / 4,
    backgroundColor: colors.gray,
    borderRadius: 50,
  },
  buttonContentGraySmallCircle: {
    padding: globalSpacing / 3,
    backgroundColor: colors.gray,
    borderRadius: 50,
  },
  buttonContentGoogle: {
    padding: globalSpacing / 2,
    backgroundColor: colors.google,
    borderRadius: 50,
  },
  buttonContentFacebook: {
    padding: globalSpacing / 2,
    backgroundColor: colors.facebook,
    borderRadius: 50,
  },

  buttonContentApple: {
    padding: globalSpacing / 2,
    backgroundColor: colors.black,
    borderRadius: 50,
    marginTop: 10,
  },

  // Fonts
  dimColor: {
    opacity: 0.8,
  },
  bold: {
    fontWeight: fontWeights.BOLD,
  },
  strikethrough: {
    textDecorationLine: 'line-through',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  textCenter: {
    textAlign: 'center',
  },
  textLink: {
    color: colors.green,
  },
  noticeText: {
    color: colors.red,
  },
  noticeTextLight: {
    color: colors.lightRed,
  },

  // Utility
  pageBackground: {
    backgroundColor: colors.white,
  },
  fitWidthContainer: {
    width: '100%',
  },
  fullRoundCorder: {
    borderRadius: 150,
  },
  divider: {
    backgroundColor: colors.gray,
  },
  indentedDivider: {
    marginLeft: globalSpacing * 2,
    backgroundColor: colors.gray,
  },
  pageBottomSpacer: {
    paddingBottom: globalSpacing * 5,
  },
  flipHorizontal: {
    transform: [{ scaleX: -1 }],
  },
  shadow: {
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  shadowThin: {
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2,
    elevation: 3,
  },
  appVersionContainer: {
    position: 'absolute',
    bottom: 0,
    right: 5,
  },
  /*
    // use both for web example
    <View style={webPageWrapper}>
      <View style={webPageContainer}>
        ...content
      </View>
    </View>
  */
  webPageWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  webPageContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 940,
  },
}) as ThemeStyle;
