import lodashFind from 'lodash/find';

import Badge from '../Components/Badge';

const fontAwesomeIcon = {
  bulk: 'truck-loading',
};

const ICONS = {
  lollipop: '🍭',
  birthdayCake: '🎂',
  shortcake: '🍰',
  candy: '🍬',
  chocolateBar: '🍫',
  cookie: '🍪',
  custard: '🍮',
  doughnut: '🍩',
  honeyPot: '🍯',
  iceCream: '🍨',
  shavedIce: '🍧',
  softIceCream: '🍦',
  pie: '🥧',
  cupcake: '🧁',
  greenApple: '🍏',
  banana: '🍌',
  cherries: '🍒',
  strawberry: '🍓',
  pear: '🍐',
  lemon: '🍋',
  peach: '🍑',
  redApple: '🍎',
  tangerine: '🍊',
  tomato: '🍅',
  watermelon: '🍉',
  pineapple: '🍍',
  coconut: '🥥',
  mango: '🥭',
  avocado: '🥑',
  chestnut: '🌰',
  carrot: '🥕',
  cucumber: '🥒',
  earOfCorn: '🌽',
  eggplant: '🍆',
  hotPepper: '🌶',
  mushroom: '🍄',
  peanuts: '🥜',
  potato: '🥔',
  broccoli: '🥦',
  leafyGreen: '🥬',
  garlic: '🧄',
  onion: '🧅',
  vegan: '🥗',
  dairyFree: '✔️',
  spicy: '🌶️',
  heartEyes: '😍',
  oneHundred: '💯',
  poo: '💩',
  rainbow: '🌈',
  fire: '🔥',
  star: '⭐',
  leaf: '🍀',
  cheese: '🧀',
  meatBone: '🍖',
  cutMeat: '🥩',
  pizza: '🍕',
  plate: '🍽️',
  spaghetti: '🍝',
  hamburger: '🍔',
  shrimp: '🍤',
  fries: '🍟',
  coffee: '☕',
  wineGlass: '🍷',
  bentoBox: '🍱',
  chickenLeg: '🍗',
  chopSticks: '🥢',
  cupWithStraw: '🥤',
  bomb: '💣',
  explodingHead: '🤯',
  coupleWithHeart: '💑',
  heavyExclamation: '❗',
  heart: '❤️',
  heartPulse: '💗',
  blueHeart: '💙',
  greenHeart: '💚',
  yellowHeart: '💛',
  orangeHeart: '🧡',
  purpleHeart: '💜',
  blackHeart: '🖤',
  giftHeart: '💝',
  arm: '💪',
  lifting: '🏋️‍♀️',
  halal: 'حلال',
};

const TAGS = [
  {
    id: 'popular',
    name: 'Popular',
    type: Badge.TYPES.SOLID,
    category: Badge.CATEGORIES.PRIMARY,
    icon: ICONS.fire,
  },
  {
    id: 'vegan',
    name: 'Vegan',
    type: Badge.TYPES.SEMI_OUTLINE,
    category: Badge.CATEGORIES.SUCCESS,
    icon: ICONS.vegan,
  },
  {
    id: 'dairyFree',
    name: 'Dairy Free',
    type: Badge.TYPES.SEMI_OUTLINE,
    category: Badge.CATEGORIES.PRIMARY,
    icon: ICONS.cheese,
  },
  {
    id: 'spicy',
    name: 'Spicy',
    type: Badge.TYPES.SEMI_OUTLINE,
    category: Badge.CATEGORIES.DANGER,
    icon: ICONS.spicy,
  },
  {
    id: 'vegetarian',
    name: 'Vegetarian',
    type: Badge.TYPES.SEMI_OUTLINE,
    category: Badge.CATEGORIES.SUCCESS,
    icon: ICONS.carrot,
  },
  {
    id: 'glutenFree',
    name: 'Gluten Free',
    type: Badge.TYPES.OUTLINE,
    category: Badge.CATEGORIES.WARNING,
    icon: ICONS.dairyFree,
  },
  {
    id: 'newItem',
    name: 'New',
    type: Badge.TYPES.SEMI_OUTLINE,
    category: Badge.CATEGORIES.SUCCESS,
    icon: ICONS.star,
  },
  {
    id: 'halal',
    name: 'Halal',
    type: Badge.TYPES.SOLID,
    category: Badge.CATEGORIES.SUCCESS,
    icon: ICONS.halal,
  },
  {
    id: 'keto',
    name: 'Keto',
    type: Badge.TYPES.SOLID,
    category: Badge.CATEGORIES.SUCCESS,
    icon: ICONS.avocado,
  },
  {
    id: 'lowCholesterol',
    name: 'Low Cholesterol',
    type: Badge.TYPES.SOLID,
    category: Badge.CATEGORIES.DANGER,
    icon: ICONS.heartPulse,
  },

  {
    id: 'lowCarbs',
    name: 'Low Carbs',
    type: Badge.TYPES.SOLID,
    category: Badge.CATEGORIES.SUCCESS,
    icon: ICONS.leafyGreen,
  },

  {
    id: 'lowCalorie',
    name: 'Low Calorie',
    type: Badge.TYPES.SOLID,
    category: Badge.CATEGORIES.WARNING,
    icon: ICONS.lifting,
  },

  {
    id: 'highProtein',
    name: 'High Protein',
    type: Badge.TYPES.SEMI_OUTLINE,
    category: Badge.CATEGORIES.WARNING,
    icon: ICONS.arm,
  },
];

function getTagIcon(tag) {
  const result = lodashFind(TAGS, { id: tag });
  return result || { name: tag };
}

export default {
  fontAwesomeIcon,
  getTagIcon,
};
