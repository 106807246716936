import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Animated, View } from 'react-native';

import appsjon from '../../app.json';
import constants from '../Config/constants';
import routeList from '../Routes/list';
import withNavigation from '../Hooks/withNavigation';
// import Share from '../Service/Share'
import ThemeColor from '../Theme/colors';
import ThemeStyle, { globalSpacing } from '../Theme/styles';

const backTypes = ['back', 'close'];
const defaultNavigationMinHeight =
  (constants.isIOS ? 53.67 : 57.45) + constants.statusBarHeight;
const iconSize = 25;

// navigationMinHeight, navigationMaxHeight and maxOutputRange will be set on _adjustTitleAnimationPosition method
// adjust navigationMinHeight if aligning the top position of page title
let navigationMinHeight = defaultNavigationMinHeight;
// adjust navigationMaxHeight if aligning the bottom position of page title
let navigationMaxHeight = 0;
// adjust to until what scroll offset the animation would last
let maxOutputRange = 0;

const getListStyle = () => ({
  flexGrow: 1,
  paddingTop: navigationMinHeight,
  ...ThemeStyle.pageBackground,
});

const _renderHeaderTitle = (
  text = 'Title',
  style = styles.defaultTitleStyle
) => {
  return (
    <Animated.Text style={[styles.commonTitleStyle, style]} numberOfLines={1}>
      {text}
    </Animated.Text>
  );
};

// currently the header title is supported only for 1 line
class HeaderWithTitleAnimation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSharing: false,
    };
    this._adjustTitleAnimationPosition(props);
  }

  _adjustTitleAnimationPosition = (props) => {
    // adjust header title animation base on if the caller has banner or not
    if (props.withBanner) {
      navigationMinHeight = defaultNavigationMinHeight;
      navigationMaxHeight = constants.isIOS ? 274 : 277;
      maxOutputRange = constants.isIOS ? 165 : 185;
    } else {
      navigationMinHeight = defaultNavigationMinHeight;
      navigationMaxHeight =
        (constants.isIOS ? 98 : 105) + constants.statusBarHeight;
      maxOutputRange = constants.isIOS ? 45 : 50;

      if (constants.isAndroid) {
        // if props.statusBar is equal to false it means we should add the constants.statusBarHeight in
        // navigationMinHeight and navigationMaxHeight of animation, it just means the page is from product or store page
        // and it doesnt have status bar to safely show the content below status bar so as fallback we are not going to deduct
        // the constants.statusBarHeight on navigationMinHeight and navigationMaxHeight
        // to backtrack: you can the method _onCheckoutPressed on App/Screens/Cart/SectionList.js with_statusbar is pass on navigation state
        // that is were the props.statusBar condition come from
        if (props.statusBar !== false) {
          navigationMinHeight = navigationMinHeight - constants.statusBarHeight;
          navigationMaxHeight = navigationMaxHeight - constants.statusBarHeight;
        }
      }
    }
  };

  _onShareStorePagePressed = () => {
    // this._toggleShareModal()
    setTimeout(() => {
      const intro = constants.isAndroid ? 'Checkout ' : '';
      const title = this.props.title;
      const appName = appsjon.displayName;
      // Share.shareLink({
      //   title: `${title} on ${appName}`,
      //   message: `${intro}${title} on ${appName}`,
      //   linkToShare: 'https://ga-multi.pickup.kloud.ai/',
      // })
    }, 500);
  };

  _onBackPressed = () => {
    const { onBackPress, navigation } = this.props;
    if (onBackPress) {
      onBackPress();
    } else if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  _onSavePressed = () => {
    this.props.onSavePress?.();
  };

  _toggleShareModal = () => {
    this.setState({ isSharing: !this.state.isSharing });
  };

  _createInterpolation = (range1, range2, applyOnEnd) => {
    if (applyOnEnd) {
      return this.props.scroll.interpolate({
        inputRange: [0, maxOutputRange - 1, maxOutputRange],
        outputRange: [range1, range1, range2],
        extrapolate: 'clamp',
      });
    }

    return this.props.scroll.interpolate({
      inputRange: [0, maxOutputRange],
      outputRange: [range1, range2],
      extrapolate: 'clamp',
    });
  };

  render() {
    const {
      title,
      backType,
      statusBar,
      withBanner,
      withBorder,
      accessoryRight,
      favourite,
      disableFavourite,
      storePage,
      onSavePress,
    } = this.props;
    const { isSharing } = this.state;
    const heightStyle = this._createInterpolation(
      navigationMaxHeight,
      navigationMinHeight
    );
    const positionLeftStyle = this._createInterpolation(10, 52);
    const maxPaddingForStorePage = constants.isAndroid ? 110 : 105;
    const paddingRightStyle = this._createInterpolation(
      0,
      storePage ? maxPaddingForStorePage : 40
    );
    const positionBottomStyle = this._createInterpolation(
      10,
      constants.isIOS ? 15 : 18
    );
    const fontSizeStyle = this._createInterpolation(28, 18);
    const bgStyle = this._createInterpolation(
      'rgba(255,255,255, 0)',
      'rgba(255,255,255, 1)'
    );
    const isBack = backType === backTypes[0];
    const addSpaceToTopForStatusBar =
      statusBar === false || withBanner || constants.isIOS;
    let borderStyle = null;

    if (withBorder) {
      borderStyle = this._createInterpolation(0, 1, true);
    }

    return (
      <>
        <Animated.View
          style={[
            styles.wrapper,
            {
              paddingTop: addSpaceToTopForStatusBar
                ? constants.statusBarHeight
                : 0,
              backgroundColor: bgStyle,
              height: heightStyle,
              borderBottomWidth: borderStyle,
            },
          ]}
        >
          <Animated.View style={styles.container}>
            {_renderHeaderTitle(title, {
              position: 'absolute',
              opacity: 1,
              zIndex: -1, // in ios, still on top of heart & share button but after adding this, it solve the issue
              left: positionLeftStyle,
              paddingRight: paddingRightStyle,
              bottom: positionBottomStyle,
              fontSize: fontSizeStyle,
            })}
          </Animated.View>
        </Animated.View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    width: '100%',
    top: 0,
    zIndex: 1,
    borderColor: ThemeColor.gray,
    ...ThemeStyle.pageHorizontalSpacingSmall,
  },
  container: [ThemeStyle.flex1, ThemeStyle.pageVerticalSpacingMedium],
  clickableContentContainer: {
    backgroundColor: ThemeColor.white,
    padding: 5,
    borderRadius: iconSize,
  },
  spacingRight: {
    marginRight: globalSpacing / 2,
  },
  icon: {
    width: iconSize,
    height: iconSize,
  },
  defaultTitleStyle: {
    opacity: 0,
    fontSize: 28,
  },
  commonTitleStyle: {
    fontWeight: 'bold',
    width: '95%',
    color: ThemeColor.black,
  },
  shareClickableContainer: {
    paddingVertical: globalSpacing / 1.5,
    paddingHorizontal: globalSpacing,
    ...ThemeStyle.flexDirectionRowCenterSpaceBetween,
  },
});

HeaderWithTitleAnimation.defaultProps = {
  backType: backTypes[0],
};

HeaderWithTitleAnimation.propsTypes = {
  title: PropTypes.string.isRequired,
  scroll: PropTypes.instanceOf(Animated.Value).isRequired,
  backType: PropTypes.oneOf(backTypes),
  statusBar: PropTypes.bool,
  withBanner: PropTypes.bool,
  withBorder: PropTypes.bool,
  accessoryRight: PropTypes.node,
  favourite: PropTypes.bool,
  disableFavourite: PropTypes.bool,
  storePage: PropTypes.bool,
  onBackPress: PropTypes.func,
  onSavePress: PropTypes.func,
};

export { getListStyle };
export const navigationHeight = navigationMinHeight;
export const renderTitlePlaceholder = _renderHeaderTitle;
export default withNavigation(HeaderWithTitleAnimation);
