import React from 'react';
import { useNavigation } from '@react-navigation/native';

export default function (Component) {
  return (props) => {
    const navigation = useNavigation();

    return <Component navigation={navigation} {...props} />;
  };
}
