import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';

import lodashIsEmpty from 'lodash/isEmpty';
import lodashFind from 'lodash/find';

import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

import { filter } from '../../RTK/defaultValues';
import List from '../List';
import Button from '../Button';
import constants from '../../Config/constants';

const { useCase } = filter;

const iconSize = 15;

const ListTag = ({
  data,
  filterTags,
  onFilterSelect,
  filterHomeTags,
  filterSearch,
  useIn,
}) => {
  const _renderIcon = (item, key, isWhite) => {
    if (!item[key]) return null;
    return (
      <Icon
        name={item[key].name}
        pack={item[key].pack}
        fill={isWhite ? ThemeColor.white : ThemeColor.green}
        style={{ width: iconSize, height: iconSize }}
      />
    );
  };
  const _renderItem = ({ index, isFirstOfList, isLastOfList, item }) => {
    const filter = useIn === useCase.HOME ? filterHomeTags : filterSearch;
    const result = lodashFind(filter, { id: item.id });
    const isActive = !lodashIsEmpty(result);
    const firstAndLastMargin = constants.isWeb ? 0 : globalSpacing;

    return (
      <Button
        testID={`tagButton${index}`}
        onPress={onFilterSelect(item)}
        style={{
          marginLeft: isFirstOfList ? firstAndLastMargin : globalSpacing / 4,
          marginRight: isLastOfList ? firstAndLastMargin : globalSpacing / 4,
        }}
        plain
      >
        <View
          style={[
            ThemeStyle.flexDirectionRowCenterCenter,
            styles.listItemContainer,
            {
              borderRadius: 50,
              backgroundColor: isActive ? ThemeColor.green : ThemeColor.white,
              borderWidth: 1,
              borderColor: isActive ? ThemeColor.green : ThemeColor.gray400,
            },
          ]}
        >
          {_renderIcon(item, 'leftIcon', isActive)}
          <Text
            category="c1"
            style={[
              isActive && { color: ThemeColor.white },
              {
                marginHorizontal: 5,
              },
            ]}
          >
            {isActive ? result.label : item.label}
          </Text>
          {_renderIcon(item, 'rightIcon', isActive)}
        </View>
      </Button>
    );
  };
  return (
    <List
      data={data}
      extraData={filterTags}
      renderItem={_renderItem}
      horizontal
      plain
    />
  );
};

const styles = StyleSheet.create({
  listItemContainer: {
    paddingHorizontal: globalSpacing / 2,
    paddingVertical: globalSpacing / 2,
    marginVertical: globalSpacing / 2,
  },
});

export default memo(ListTag);
