import constants from '../../Config/constants';

let firebase = null; // this need to be initialize on load of file of mobile and web
const CUSTOM_EVENTS = {
  sectionSeeAll: 'section_see_all',
  selectStore: 'store_selection',
  selectItem: 'item_selection',
  addToCart: 'add_to_cart',
  checkingOut: 'continue_to_checkout',
  checkoutSuccess: 'checkout_success',
  error: 'custom_error',
};

function addToCart(from, itemObject) {
  return customEvent(CUSTOM_EVENTS.addToCart, {
    cart_item_id: itemObject.item_id || itemObject.id,
    cart_item_name: itemObject.item_name || itemObject.name,
    from,
  });
}

function customEvent(eventName, data) {
  if (constants.isWeb) {
    return firebase?.logEvent(firebase.analytics, eventName, data);
  } else {
    return firebase?.().logEvent?.(eventName, data);
  }
}

function error(err) {
  return customEvent(CUSTOM_EVENTS.error, { error: err });
}

function itemSelection(itemObject) {
  return customEvent(CUSTOM_EVENTS.selectItem, {
    item_id: itemObject.item_id || itemObject.id,
    item_name: itemObject.item_name || itemObject.name,
    from: itemObject.fromSection,
  });
}

function setLibrary(libraryToUse) {
  firebase = libraryToUse;
}

function setUser(userId) {
  if (constants.isWeb) {
    return firebase?.setUserId(firebase.analytics, userId);
  } else {
    return firebase?.()?.setUserId?.(userId);
  }
}

function storeSelection(storeObject) {
  return customEvent(CUSTOM_EVENTS.selectStore, {
    store_id: storeObject.store_id || storeObject.id,
    store_name: storeObject.store_name || storeObject.name,
    from: storeObject.fromSection,
  });
}

export { CUSTOM_EVENTS };

export default {
  addToCart,
  customEvent,
  error,
  itemSelection,
  setLibrary,
  setUser,
  storeSelection,
};
