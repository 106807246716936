import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import lodashGet from 'lodash/get';

import { setScrollCartToStoreId } from '../../RTK/utility';

export const routes = [
  { key: 'first', title: 'First' },
  { key: 'second', title: 'Second' },
];

function useBasketController() {
  const navigation = useNavigation();
  const route = useRoute();
  const dispatch = useDispatch();

  const activeTabParams = lodashGet(route, 'params.activeTab');
  const scrollToTopParams = lodashGet(route, 'params.scrollToTop');
  const storeIdParams = lodashGet(route, 'params.storeId');

  const [activeTabindex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    const hasActiveTab = activeTabParams >= 0 && activeTabParams <= 1;
    const hasStoreId = typeof storeIdParams === 'string' && storeIdParams;
    const hasScrollToTop = scrollToTopParams == true; // not strict, on web parameter it's "true" (string) on mobile it's true (boolean)
    if (hasActiveTab && activeTabindex !== activeTabParams) {
      // if receive activeTab in url parameter AND selected tab is not match on the url parameter
      setActiveTabIndex(Number(activeTabParams));
    }
    if (hasStoreId || hasScrollToTop) {
      // if receive storeId or scrollToTop in url parameter
      dispatch(setScrollCartToStoreId(storeIdParams || 'storeId')); // 'storeId' string is just a dummy text to trigger scroll to top if no cart with store id found
    }
    if (activeTabParams || hasStoreId || hasScrollToTop) {
      // remove activeTab, storeId and scrollToTop in url parameter
      setTimeout(() => {
        navigation.setParams({
          activeTab: undefined,
          storeId: undefined,
          scrollToTop: undefined,
        });
      }, 500);
    }
  }, [activeTabParams, scrollToTopParams, storeIdParams]);

  return {
    activeTabindex: activeTabParams ? Number(activeTabParams) : activeTabindex,
    animationEnabled: typeof activeTabParams === 'undefined',
    setActiveTabIndex,
  };
}

export default useBasketController;
