import { useEffect, useRef, useState } from 'react';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';

import useCancellableRequest from '../../../Hooks/useCancellableRequest';
import routeList from '../../../Routes/list';
import userApi from '../../../Service/api/user';
import { setOrderDetails } from '../../../RTK/utility';
import constants from '../../../Config/constants';
import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';

const defaultFilter = 'completed';

const useBackButton = () => {
  const [isBack, setIsBack] = useState(false);
  const handleEvent = () => setIsBack(true);
  useFocusEffect(() => {
    window.addEventListener('popstate', handleEvent);
    return () => window.removeEventListener('popstate', handleEvent);
  });
  return isBack;
};

const useViewModel = (listRef) => {
  const prevFilterStatus = useRef(defaultFilter);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();
  const { createRequest } = useCancellableRequest();

  if (constants.isWeb) {
    // on web, after checkout redirection
    // should not be able to go back to checkout upon pressing the browser back button
    const isBack = useBackButton();
    if (isBack) {
      // no need for checking if the active tab is history, because this hooks is used from order history itself, should not load if the active tab is 0 or orders page
      navigation.navigate(routeList.HOME_TAB);
    }
  }

  const [filterStatus, setFilterStatus] = useState(defaultFilter);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const { showModalPrompt } = useModalPrompt();
  const routeParams = lodashGet(route, 'params');
  const refetchParam = lodashGet(route, 'params.refetch');
  const refetchOrderParam = lodashGet(route, 'params.refreshOrders');
  const shouldRefetch =
    refetchParam === 'true' ||
    refetchParam === true ||
    refetchOrderParam === 'true' ||
    refetchOrderParam === true;
  const orderId = lodashGet(route, 'params.order_id');
  const storeAvatar = lodashGet(route, 'params.store_avatar');
  const storeName = lodashGet(route, 'params.store_name');
  const dispute = lodashGet(route, 'params.dispute');

  const reviewModalData = { orderId, storeAvatar, storeName, dispute };
  const reviewModalShow =
    !lodashIsEmpty(
      Object.keys(reviewModalData).filter((e) => reviewModalData[e])
    ) && isDataFetched;

  useEffect(() => {
    if (shouldRefetch && listRef.current) {
      listRef.current._onRefresh(); // refetch order history, because it was requested
      navigation.setParams({
        ...routeParams,
        refetch: false,
        refreshOrders: false,
      }); // remove params
    } else if (prevFilterStatus.current !== filterStatus && listRef.current) {
      listRef.current._onRefresh(); // refetch order history because filterStatus is chaned
      prevFilterStatus.current = filterStatus;
    }
  }, [shouldRefetch, filterStatus]);

  const _fetchOrderHistory = (page) =>
    createRequest(userApi.getOrders, page, filterStatus);

  const _onFetchedOrderHistory = () => setIsDataFetched(true);

  const _onItemPressed = (data) => async () => {
    dispatch(setOrderDetails(data)); // store data to redux before navigating to order details page
    navigation.navigate(routeList.ORDER_DETAILS, {
      id: data.transaction_number,
    });
  };

  const _closeMobileModal = () => {
    navigation.setParams({
      ...routeParams,
      order_id: undefined,
      store_avatar: undefined,
      store_name: undefined,
      dispute: undefined,
    });
  };

  const _onReviewPress = (star) => {
    const listRefState = listRef.current._getState();
    const params = { id: reviewModalData.orderId };
    const orderData = lodashFind(listRefState.data, { id: params.id });
    if (typeof star === 'number') {
      params.defaultStoreStar = star;
    }
    dispatch(setOrderDetails(orderData)); // store data to redux before navigating to order rating page
    _closeMobileModal();
    navigation.navigate(routeList.ORDER_RATING, params); // redirect
  };

  const _onStatusChange = (status) => setFilterStatus(status);

  const _toggleWebOrderAgain = (data) =>
    showModalPrompt(MODALPROMPT.orderAgain, data);

  return {
    state: {
      filterStatus,
      reviewModalData,
      reviewModalShow,
    },
    fetchOrderHistory: _fetchOrderHistory,
    onFetchedOrderHistory: _onFetchedOrderHistory,
    onItemPressed: _onItemPressed,
    onReviewNotNow: _closeMobileModal,
    onReviewPress: _onReviewPress,
    onStatusChange: _onStatusChange,
    toggleWebOrderAgain: _toggleWebOrderAgain,
  };
};

export default useViewModel;
