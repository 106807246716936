import { useEffect, useRef } from 'react';
import { CancelToken } from 'apisauce';

// IMPORTANT NOTE - Every api request must be wrap on _createRequest
// the purpose of this hooks is to cancel api call on unmounted page or component

function useCancellableRequest() {
  // source token can be use on multiple _createRequest call but when cancel, all of the request use the same token will be
  // cancel as well (only if they are still pending request) as for the use case as of now I think it's ok
  const sources = useRef([]); // push here the created cancel token so we can cancell all of it on unmount

  useEffect(() => {
    return _cancelPendingRequest;
  }, []);

  const _createRequest = async (request, ...args) => {
    const source = CancelToken.source();
    sources.current.push(source);
    return request(...args, source.token);
  };

  const _cancelPendingRequest = () => {
    sources.current.map((src) => src.cancel()); // cancel all request
    sources.current = []; // reinitialize the sources
  };

  return {
    cancelPendingRequest: _cancelPendingRequest,
    createRequest: _createRequest,
  };
}

export default useCancellableRequest;
