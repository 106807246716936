import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  SafeAreaView,
  StatusBar,
  StyleSheet,
  ScrollView,
  View,
} from 'react-native';

import constants from '../Config/constants';
import ThemeStyle from '../Theme/styles';

const barStyles = ['dark-content', 'light-content'];

// this component handle the status bar appearance and place the start of ui to below of status bar for ios
// also handle the spacing to the bottom part of the screen in ios by placing safeareview
// it also contain scrolling of the page but can be disable by passing 'scroll' props as false
class ScreenWrapper extends React.Component {
  render() {
    const {
      backgroundColor,
      barStyle,
      children,
      footer,
      header,
      modal,
      outsideSafeAreaBackgroundColor,
      scroll,
      statusBar,
      onRef,
      fullWidth = false,
      ...rest
    } = this.props;
    const Container = scroll ? ScrollView : View;
    const conditionalProps = scroll ? rest : {};
    if (scroll) {
      conditionalProps.contentContainerStyle = styles.container;
      conditionalProps.showsVerticalScrollIndicator = false;
      if (onRef) {
        conditionalProps.ref = onRef;
      }
    } else {
      conditionalProps.style = styles.container;
    }

    return (
      <Fragment>
        {statusBar && (
          <StatusBar backgroundColor={backgroundColor} barStyle={barStyle} />
        )}
        {constants.isIOS ? (
          <View
            style={[
              styles.wrapper,
              {
                backgroundColor,
                paddingTop: modal ? 0 : constants.statusBarHeight,
              },
            ]}
          >
            {header}
            <Container {...conditionalProps}>{children}</Container>
            {footer}
            <SafeAreaView
              style={
                outsideSafeAreaBackgroundColor && {
                  backgroundColor: outsideSafeAreaBackgroundColor,
                }
              }
            />
          </View>
        ) : (
          <SafeAreaView
            style={[
              ThemeStyle.pageBackground,
              styles.wrapper,
              { paddingTop: !statusBar ? constants.statusBarHeight : 0 },
              constants.isWeb && !fullWidth
                ? { paddingHorizontal: '5%' }
                : null,
            ]}
          >
            {header}
            {constants.isWeb ? (
              <Container {...conditionalProps}>
                {children}
                {footer}
              </Container>
            ) : (
              <Fragment>
                <Container {...conditionalProps}>{children}</Container>
                {footer}
              </Fragment>
            )}
            {/* <Container {...conditionalProps}>{children}</Container>
            {footer} */}
          </SafeAreaView>
        )}
      </Fragment>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  container: {
    flexGrow: 1,
    ...ThemeStyle.pageBackground,
  },
});

ScreenWrapper.defaultProps = {
  backgroundColor: '#fff',
  barStyle: barStyles[0],
  modal: false,
  scroll: true,
  statusBar: true,
};

ScreenWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  header: PropTypes.node,
  backgroundColor: PropTypes.string,
  barStyle: PropTypes.oneOf(barStyles),
  modal: PropTypes.bool,
  outsideSafeAreaBackgroundColor: PropTypes.string,
  scroll: PropTypes.bool,
  statusBar: PropTypes.bool,
};

export default ScreenWrapper;
