import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

class Form extends React.PureComponent {
  render() {
    const { children, initialValues, onSubmit, validationSchema, ...rest } =
      this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        {...rest}
      >
        {typeof children === 'function' ? children : () => <>{children}</>}
      </Formik>
    );
  }
}

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.object,
};

export default Form;
