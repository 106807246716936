import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  isEmpty as lodashIsEmpty,
  filter as lodashFilter,
  isNil as lodashIsNull,
  isUndefined as lodashUndefined,
} from 'lodash';

import constants from '../../../../Config/constants';

import StoreHelper from '../../../../Helper/Store';

import storeAPI from '../../../../Service/api/store';

import { RAZZLE_BUILD_MODE } from '@env';

const { ORDER_TYPE_API_KEYS, ORDER_TYPE_DATA } = constants;

const useStoreDescriptionController = (storeId) => {
  const { data } = useSelector((state) => state.shop);
  const [ratings, setTotalRatings] = useState({});
  const checkoutData = useSelector((state) => state.checkout.checkoutData);

  useMemo(() => {
    if (storeId != '' || storeId != undefined) {
      fetchStoreTotalRatings();
    }
  }, [storeId]);

  async function fetchStoreTotalRatings() {
    if (RAZZLE_BUILD_MODE === 'branded') {
      return;
    } else {
      const { ok, data } = await storeAPI.getTotalRatings(storeId);
      if (ok && data) {
        setTotalRatings(data);
      }
    }
  }

  const order_types =
    lodashIsNull(data) || lodashUndefined(data)
      ? {
          is_curbside_active: true,
          is_delivery_active: true,
          is_pickup_active: true,
        }
      : ORDER_TYPE_API_KEYS.reduce((acc, key) => {
          acc[key] = data[key];
          return acc;
        }, {});

  const availableOrderTypes = lodashIsEmpty(order_types)
    ? ORDER_TYPE_DATA
    : lodashFilter(ORDER_TYPE_DATA, (d) => order_types[d.apiDataKey]);

  return {
    storeCheckoutData: StoreHelper.getStoreCheckoutData(storeId, checkoutData),
    ratings,
    availableOrderTypes,
  };
};

export default useStoreDescriptionController;
