import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

const PromoFreeGift = ({
  fill1,
  fill2,
  fill1_opacity = '100%',
  fill2_opacity = '100%',
  ...props
}) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="17.06 10.85 1044.92 396.88"
    preserveAspectRatio="none"
    {...props}
  >
    <Path
      fillOpacity={fill1_opacity}
      fill={fill1 || '#cbefda'}
      strokeWidth={0}
      d="m1004.85,209.29v.02c0,4.7.59,9.25,1.68,13.6,5.93,23.54,26.87,40.94,51.78,40.94,1.13,0,2.26-.04,3.38-.12.1,0,.19,0,.29-.01v101.03c.24,23.49-18.62,42.74-42.11,42.98H59.18c-23.49-.24-42.34-19.47-42.12-42.98v-100.9c29.52,0,53.46-24.43,53.46-54.56s-23.94-54.56-53.46-54.56V53.83c-.22-23.49,18.63-42.74,42.12-42.98h960.69c23.49.24,42.35,19.49,42.11,42.98v101.03c-1.22-.1-2.44-.13-3.67-.13-8.2,0-15.97,1.88-22.91,5.25-18.06,8.76-30.55,27.55-30.55,49.31Z"
    />
    <G id="gift" className="cls-1" opacity={0.37}>
      <Path
        id="Path_660"
        data-name="Path 660"
        className="cls-3"
        fill={fill2 || '#8ac1b1'}
        fillOpacity={fill2_opacity}
        strokeWidth={0}
        d="m716.4,159.98v49.33h160.32v-49.33h-160.32Zm209.64,0v49.33h78.81v-.02c0-21.76,12.49-40.55,30.55-49.31h-109.36Zm-184.97,62.93v124.96c0,18.83,16.56,34.08,36.99,34.08h98.66v-159.04h-135.65Zm317.24,40.94c-24.91,0-45.85-17.4-51.78-40.94h-80.49v159.04h98.66c20.43,0,36.99-15.25,36.99-34.08v-84.14c-1.12.08-2.25.12-3.38.12Zm-32.63-177.86c0-27.24-22.07-49.33-49.31-49.34-.34,0-.67.01-1,.01-34.61-1.09-65.35,21.96-73.99,55.5-8.64-33.54-39.38-56.59-73.99-55.5-26.41-.83-48.5,19.91-49.33,46.32-.03,1.01-.03,2.01,0,3.01-3.84,23.25,11.89,45.21,35.14,49.05,3.96.66,7.99.75,11.97.28h151.19c23.55,3.69,45.63-12.41,49.32-35.96.7-4.43.7-8.94,0-13.37Zm-198.29,24.66c-12.48,1.15-23.52-8.05-24.67-20.52-.12-1.38-.12-2.76,0-4.14-1.14-12.48,8.05-23.52,20.53-24.66,1.38-.13,2.76-.13,4.14,0,27.24,0,49.33,22.08,49.33,49.32h-49.33Zm152.12,0c-1.38.13-2.76.13-4.14,0h-49.33c0-27.24,22.09-49.32,49.33-49.32,6.81,0,12.97,2.76,17.44,7.22,4.46,4.46,7.22,10.63,7.22,17.44.07.71.1,1.41.1,2.11,0,11.59-8.86,21.47-20.62,22.55Z"
      />
    </G>
  </Svg>
);

export default PromoFreeGift;
