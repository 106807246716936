import { useState } from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';
import lodashIsNull from 'lodash/isNull';

import lodashIsEmpty from 'lodash/isEmpty';

import ThemeStyle from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import Button from '../../../Button';

import StepSlider from '../../../StepSlider';
import useModalPrompt from './hooks/useModalPrompt';
import ModalPrompt from './ModalPrompt';

const RatingSelector = ({ modalId }) => {
  const [data, setData] = useState<any>();
  const [rating, setRating] = useState(0);

  const { hideModalPrompt } = useModalPrompt();

  const { value } = data || {};

  const _onClose = () => {
    hideModalPrompt(modalId, {});
  };

  const _onSelectOnList = (data) => {
    hideModalPrompt(modalId, lodashIsNull(data) ? { value: null } : data);
  };

  return (
    <ModalPrompt
      id={modalId}
      title={'Ratings'}
      dataSet={{ media: ids.ratingModal }}
      mainContentWrapperStyle={styles.ratingModal}
      hasHeader
      hasHeaderLine
      onCloseButtonClick={_onClose}
      closeOnDocumentClick={false}
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <View>
        <Text category="p1" style={{ opacity: 0.5 }}>
          {rating !== 5 && 'Over '}
          {rating == 0 ? value : rating}
        </Text>

        <StepSlider
          defaultValue={rating == 0 ? value : rating}
          min={3}
          max={5}
          onChange={(text) => setRating(text)}
        />

        <Button
          status="success"
          style={ThemeStyle.spacingBottomMedium}
          onPress={() => _onSelectOnList({ value: rating })}
        >
          View Results
        </Button>

        <Button
          status="control"
          onPress={() => {
            _onSelectOnList(null);
            // setRating(4);
          }}
          disabled={rating === 0 && !lodashIsEmpty(value)}
        >
          Reset
        </Button>
      </View>
    </ModalPrompt>
  );
};
const { ids, styles } = StyleSheet.create({
  ratingModal: {
    [`@media ${BreakPoints.xs}`]: {
      width: 380,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 420,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 440,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 460,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 480,
    },
  },
});

export default RatingSelector;
