import { StyleSheet as RNStyleSheet } from 'react-native';
import StyleSheet from 'react-native-media-query';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import breakpoints from '../../Theme/styles/breakpoints';
import { TITLE } from '../../Theme/typographyProps';

const titleProps = {
  category: 'p2',
  numberOfLines: 1,
  style: !constants.isWeb && TITLE.style,
};

const subTitleProps = {
  category: 'c1',
  numberOfLines: 1,
  style: ThemeStyle.dimColor,
};

const { ids, styles } = StyleSheet.create({
  // base card style
  baseImageContainer: {
    borderRadius: 5,
    overflow: 'hidden',
  },
  baseImage: {
    width: '100%',
    aspectRatio: 2 / 1.1,
    borderRadius: 5,
  },
  baseImageOverlay: {
    ...RNStyleSheet.absoluteFill,
    zIndex: 1,
    overflow: 'hidden',
    borderRadius: 5,
  },
  // store card style
  storeContainer: {
    width: 255,
    maxWidth: '100%',
    overflow: 'hidden',
  },
  storeResponsiveContainer: {
    [`@media ${breakpoints.sm}`]: {
      width: 300,
    },
  },
  storeResponsiveMobileContainer: {
    [`@media ${breakpoints.xs}`]: {
      width: '100%',
    },
    [`@media ${breakpoints.sm}`]: {
      width: 'auto',
    },
  },
  storeIcon: {
    width: 20,
    height: 20,
  },
  // item card small style
  itemSmallContainer: {
    ...ThemeStyle.flexDirectionRowCenter,
    ...ThemeStyle.pageVerticalSpacingSmall,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    width: 210,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: ThemeColor.gray500,
    borderRadius: 5,
  },
  itemSmallImage: {
    ...ThemeStyle.spacingLeftSmall,
    width: 60,
    height: 60,
    borderRadius: 5,
  },
  // item card medium style
  itemMediumContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '42%',
    maxWidth: 180,
    [`@media (max-width: 269px)`]: {
      maxWidth: '100%',
    },
  },
  // item card large style
  itemLargeContainer: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.pageVerticalSpacingMedium,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    borderWidth: 1,
    borderColor: ThemeColor.gray500,
    borderRadius: 5,
  },
  itemLargeImage: {
    width: 110,
    height: 110,
    borderRadius: 5,
    [`@media ${breakpoints.xs}`]: {
      width: 95,
      height: 95,
    },
  },
  itemLargeTooltip: {
    ...ThemeStyle.shadow,
    borderRadius: 5,
    padding: 5,
    backgroundColor: ThemeColor.green,
  },
  itemLargeTooltipIcon: {
    width: 15,
    height: 15,
  },
});

export { ids, titleProps, subTitleProps };
export default styles;
