import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';

import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

import Button from './Button';

//        Title
// |Icon| Subtitle                   |Icon|

// this component is use for list item. preview is like above
// bottom divider is not included in this component should be put on List ItemSeparatorComponent
// |Icon| is center vertically in title and subtitle
// |Icon| is optional
const iconSize = 20;

class ListItem extends React.PureComponent {
  render() {
    const {
      leftIcon,
      leftIconColor,
      leftIconPack,
      accessoryLeft,
      renderContent,
      title,
      subTitle,
      subTitleStyle,
      rightIcon,
      accessoryRight,
      disabled,
      onPress,
      ...rest
    } = this.props;

    const Wrapper = onPress ? Button : View;
    const conditionalProps = onPress
      ? { testID: 'liButton', onPress, disabled, plain: true }
      : {};

    return (
      <Wrapper {...conditionalProps}>
        <View
          style={[
            ThemeStyle.flexDirectionRowCenter,
            ThemeStyle.pageVerticalSpacingMedium,
          ]}
        >
          {accessoryLeft}
          {typeof leftIcon === 'string' ? (
            <Icon
              name={leftIcon}
              fill={leftIconColor || ThemeColor.black}
              pack={leftIconPack}
              style={[styles.leftIcon, ThemeStyle.spacingRight]}
            />
          ) : (
            leftIcon
          )}

          {typeof renderContent !== 'function' ? (
            <View style={[ThemeStyle.flex1]}>
              {!!title && (
                <Text testID="title" numberOfLines={1}>
                  {title}
                </Text>
              )}

              {!!subTitle && (
                <Text
                  testID="subTitle"
                  category="p2"
                  style={ThemeStyle.dimColor}
                  numberOfLines={1}
                >
                  {subTitle}
                </Text>
              )}
            </View>
          ) : (
            renderContent()
          )}

          {typeof rightIcon === 'string' ? (
            <Icon
              name={rightIcon}
              fill={ThemeColor.green}
              style={[styles.rightIcon, ThemeStyle.spacingLeft]}
            />
          ) : (
            rightIcon
          )}
          {accessoryRight}
        </View>
      </Wrapper>
    );
  }
}

const styles = StyleSheet.create({
  leftIcon: {
    width: iconSize,
    height: iconSize,
  },
  rightIcon: {
    width: iconSize - 5,
    height: iconSize - 5,
  },
});

ListItem.defaultProps = {
  leftIconPack: 'eva',
  disabled: false,
};

ListItem.propTypes = {
  leftIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  leftIconPack: PropTypes.string,
  accessoryLeft: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleStyle: PropTypes.object,
  rightIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  accessoryRight: PropTypes.node,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
};

export default ListItem;
