import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Icon, Divider, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFind from 'lodash/find';

import constants from '../../Config/constants';
import CartHelper from '../../Helper/Cart';
import PromoHelper, { promoTypes } from '../../Helper/Promo';
import Service from '../../Service';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import Button from '../Button';
import modals from '../Sheets/modals';

const { DELIVERY_PAYMENT_OPTION, ORDER_TYPE_DATA, ORDER_TYPES } = constants;
const pickupOrderType = lodashFind(ORDER_TYPE_DATA, {
  value: ORDER_TYPES.PICKUP,
});
const summaryTextCategory = constants.isWeb ? 'c1' : 'p2';

const BreakdownRow = ({
  discountText,
  error,
  label,
  labelIcon,
  labelIconColor,
  labelStyle,
  loading,
  negative,
  value,
  value2,
  valueIcon,
  valueIconColor,
  valueStyle,
  valueSuffix,
  onHint,
}) => {
  const hasHint = typeof onHint === 'function';
  const _renderValue = () => {
    if (error) {
      return (
        <Text
          category={summaryTextCategory}
          style={[
            ThemeStyle.noticeText,
            ThemeStyle.spacingLeftMedium,
            { textAlign: 'right' },
          ]}
          numberOfLines={2}
        >
          {error}
        </Text>
      );
    } else if (loading) {
      return <ActivityIndicator size={18} color={ThemeColor.green} />;
    }
    // Label value & Value Icon
    return (
      <View style={ThemeStyle.flexDirectionRowCenter}>
        {/* Value icon */}
        {Boolean(valueIcon) && (
          <Icon
            name="bolt"
            pack="fa"
            fill={valueIconColor}
            style={[styles.icon, ThemeStyle.spacingRightSmall]}
          />
        )}
        {Boolean(value2) && (
          <Text
            category={summaryTextCategory}
            style={[ThemeStyle.strikethrough, ThemeStyle.spacingRightSmall]}
          >
            {Service.commafyNumber(value2, true)}
          </Text>
        )}
        {/* Label value */}
        <Text
          category={summaryTextCategory}
          style={[
            (Boolean(value2) || negative) && ThemeStyle.noticeText,
            valueStyle,
          ]}
        >
          {negative ? '- ' : ''}
          {Service.commafyNumber(value, true)}
          {valueSuffix}
        </Text>
      </View>
    );
  };
  return (
    <View
      style={[
        ThemeStyle.flexDirectionRowCenterSpaceBetween,
        ThemeStyle.spacingBottomMedium,
      ]}
    >
      <View style={ThemeStyle.flexDirectionRowCenter}>
        {/* Label icon */}
        {Boolean(labelIcon) && (
          <Icon
            name={labelIcon}
            fill={labelIconColor}
            style={[styles.icon, ThemeStyle.spacingRightSmall]}
          />
        )}
        {/* Label & Discount Text */}
        <Text
          category={summaryTextCategory}
          style={[hasHint && ThemeStyle.spacingRightMedium, labelStyle]}
        >
          {/* Label */}
          {label}
          {/* Discount text */}
          {Boolean(discountText) && (
            <Text category={summaryTextCategory} style={ThemeStyle.noticeText}>
              {' '}
              ({discountText})
            </Text>
          )}
        </Text>
        {/* Hint button */}
        {hasHint && (
          <Button onPress={onHint} plain>
            <Icon
              name="info-outline"
              fill={ThemeColor.green}
              style={styles.icon}
            />
          </Button>
        )}
      </View>
      {/* Label value */}
      {_renderValue()}
    </View>
  );
};

class CartSummary extends React.PureComponent {
  _onDeliveryFee = () => {
    modals.show(modals.PROMPT, {
      title: 'Delivery Fee',
      message:
        'The delivery fee varies for each restaurant based on your location, courier price surges, and other factors.',
      buttonStatus: 'success',
      buttonText: 'Continue',
    });
  };

  _onPriorityFee = async () => {
    const { hasPickupOrderType, onOrderTypeChange } = this.props;
    let message =
      'This priority fee will assist in hailing riders for your order.';
    if (hasPickupOrderType) {
      message += ` If you prefer to opt out of the priority fee, please change this order to "${pickupOrderType.label}", please note that with ${pickupOrderType.label} orders you will need to hail your own rider and self-manage the delivery.`;
    }
    const result = await modals.show(modals.PROMPT, {
      title: 'Priority Fee',
      message,
      highlight: [
        { word: `"${pickupOrderType.label}"`, color: ThemeColor.green },
      ],
      buttonStatus: 'success',
      buttonText: hasPickupOrderType
        ? `Change the order type to "${pickupOrderType.label}"`
        : 'Continue',
    });
    if (hasPickupOrderType && result?.buttonClicked) {
      onOrderTypeChange?.(pickupOrderType);
    }
  };

  _getTotalDeducted = () => {
    const { delivery, promotion } = this.props;
    const amtOrPctOff = [promoTypes.AMOUNT_OFF, promoTypes.PERCENT_OFF];
    if (promotion?.type === promoTypes.FREE_DELIVERY) {
      const promoDelFee = promotion?.delivery_discount || 0;
      const hasDelFee = Boolean(delivery?.fee);
      return hasDelFee && promoDelFee > delivery?.fee
        ? delivery?.fee
        : promoDelFee;
    } else if (amtOrPctOff.includes(promotion?.type)) {
      return Math.round(promotion?.promo?.applied || 0);
    }
    return 0;
  };

  _renderDeliveryRightContent = (fee = 0) => {
    const { delivery, hasMealPlanDeliveryDiscount } = this.props;
    if (delivery?.loading) {
      return <ActivityIndicator color={ThemeColor.green} />;
    } else if (delivery?.error) {
      return (
        <Text
          category={summaryTextCategory}
          style={[
            ThemeStyle.noticeText,
            ThemeStyle.spacingLeftMedium,
            { textAlign: 'right' },
          ]}
          numberOfLines={2}
        >
          {delivery.error}
        </Text>
      );
    }
    return (
      <Text
        category={summaryTextCategory}
        style={hasMealPlanDeliveryDiscount && ThemeStyle.noticeText}
      >
        {Service.commafyNumber(fee, true)}
      </Text>
    );
  };

  render() {
    const {
      additionalCharge,
      cart,
      computed,
      delivery,
      hasMealPlanDeliveryDiscount,
      isDelivery,
      noHspacing,
      promotion,
      refund,
      subTotalPromoPercent,
      onDeliveryFeePress,
      onPriorityFeePress,
    } = this.props;

    const { subTotal, subTotalOriginal, total } = CartHelper.getBreakdown({
      cart,
      delivery,
      isDelivery,
      promotion,
      refund,
      subTotalPromoPercent,
      totalAdditionalCharge: additionalCharge?.total,
    });
    const formattedPromoValue = PromoHelper.getFormatedPromoValue(
      promotion,
      delivery?.fee
    );
    // for showing delivery row on summary
    const hasDeliveryFee = typeof delivery?.fee === 'number';
    const hasError = !!delivery?.error;
    const isCODfreeDel = delivery?.codFreeDel;
    const isNC = delivery?.mode === DELIVERY_PAYMENT_OPTION.NON_CASH;
    const showDeliveryFee =
      isDelivery && isNC && (hasDeliveryFee || delivery?.loading || hasError);
    const doNotDisplayPromoValue =
      !isDelivery && promotion?.type === promoTypes.FREE_DELIVERY;

    return (
      <View
        style={[
          !noHspacing && ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.pageVerticalSpacingMedium,
        ]}
      >
        {/* if has promotion and type is free item */}
        {promotion?.type === promoTypes.FREE_ITEM && (
          <View
            style={[
              ThemeStyle.flexDirectionRowCenter,
              ThemeStyle.spacingBottomMedium,
            ]}
          >
            {/* Gift icon */}
            <View style={ThemeStyle.spacingRightSmall}>
              <Icon
                name="gift-outline"
                fill={ThemeColor.green}
                style={{ width: 20, height: 20 }}
              />
            </View>
            {/* Free item description */}
            <Text category={summaryTextCategory}>{formattedPromoValue}</Text>
          </View>
        )}
        {/* Subtotal */}
        <BreakdownRow
          discountText={
            Boolean(subTotalPromoPercent)
              ? `${subTotalPromoPercent}% OFF`
              : undefined
          }
          label="Subtotal"
          value={computed?.subTotal || subTotal}
          value2={Boolean(subTotalPromoPercent) ? subTotalOriginal : undefined}
        />
        {/* Additional Charges */}
        {additionalCharge?.enabled &&
          (additionalCharge?.loading || Boolean(additionalCharge?.error) ? (
            <BreakdownRow
              error={additionalCharge?.error}
              label="Additional Charges"
              loading={additionalCharge?.loading}
            />
          ) : (
            additionalCharge?.data?.charges_breakdown?.map((breakdown, i) => (
              <BreakdownRow
                key={i}
                label={`${breakdown.name.replace('_', ' ')}${
                  breakdown.type === 'percent_charge' &&
                  !lodashIsEmpty(additionalCharge.breakdownFactor)
                    ? ` (${additionalCharge.breakdownFactor.factor}%)`
                    : ''
                }`}
                labelStyle={ThemeStyle.capitalize}
                value={breakdown.amount}
              />
            ))
          ))}
        {/* Delivery */}
        {showDeliveryFee && (
          <BreakdownRow
            discountText={
              hasMealPlanDeliveryDiscount
                ? delivery?.fee === 0
                  ? 'Free Delivery'
                  : 'Discounted'
                : ''
            }
            error={delivery?.error}
            label="Delivery Fee"
            loading={delivery?.loading}
            value={delivery?.fee}
            onHint={constants.isWeb ? onDeliveryFeePress : this._onDeliveryFee}
          />
        )}
        {/* Promo */}
        {(hasDeliveryFee || !isDelivery) && // mush have delivery fee or not delivery
          !lodashIsEmpty(promotion) && // must have promotion object
          promotion.type !== promoTypes.FREE_ITEM && ( // promo type should not free item
            <BreakdownRow
              discountText={formattedPromoValue}
              label="Promo"
              labelStyle={ThemeStyle.noticeText}
              value={doNotDisplayPromoValue ? null : this._getTotalDeducted()}
              negative={!doNotDisplayPromoValue}
            />
          )}
        {/* Priority fee */}
        {showDeliveryFee && delivery.priority_fee > 0 && (
          <BreakdownRow
            label="Priority Fee"
            value={delivery.priority_fee}
            valueIcon="bolt"
            valueIconColor={ThemeColor.red}
            onHint={constants.isWeb ? onPriorityFeePress : this._onPriorityFee}
          />
        )}
        <Divider style={[ThemeStyle.spacingBottomMedium, ThemeStyle.divider]} />
        {/* Refund */}
        {Boolean(refund) && (
          <BreakdownRow
            value={refund}
            valueStyle={ThemeStyle.bold}
            valueSuffix={' *'}
            negative
          />
        )}
        {/* Total */}
        <BreakdownRow label="Total" value={computed?.total || total} />
        {/* COD note */}
        {isDelivery && !isNC && !isCODfreeDel && (
          <BreakdownRow
            label={
              hasError ? delivery?.error : 'DELIVERY FEE - PAY IN CASH TO RIDER'
            }
            labelIcon="alert-triangle"
            labelIconColor={ThemeColor.yellow}
            labelStyle={{ ...ThemeStyle.noticeText, ...ThemeStyle.bold }}
            value={delivery?.fee}
            valueStyle={ThemeStyle.noticeText}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  icon: {
    width: 15,
    height: 15,
  },
});

CartSummary.defaultProps = {
  isDelivery: true,
  delivery: {
    fee: undefined,
    priority_fee: 0,
    mode: DELIVERY_PAYMENT_OPTION.NON_CASH,
  },
  promotion: {},
};

CartSummary.propTypes = {
  additionalCharge: PropTypes.shape({
    breakdownFactor: PropTypes.PropTypes.shape({
      factor: PropTypes.number.isRequired,
    }),
    data: PropTypes.shape({
      charges_breakdown: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          type: PropTypes.oneOf(['amount_charge', 'percent_charge']).isRequired,
        })
      ),
      total_additional_charges: PropTypes.number,
    }).isRequired,
    enabled: PropTypes.bool.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool,
    total: PropTypes.number,
  }),
  cart: PropTypes.array.isRequired,
  delivery: PropTypes.shape({
    fee: PropTypes.number,
    priority_fee: PropTypes.number,
    loading: PropTypes.bool,
    error: PropTypes.string,
    mode: PropTypes.oneOf(
      Object.keys(DELIVERY_PAYMENT_OPTION).map(
        (e) => DELIVERY_PAYMENT_OPTION[e]
      )
    ),
  }),
  hasMealPlanDeliveryDiscount: PropTypes.bool, // usually passed on meal plan checkout
  hasPickupOrderType: PropTypes.bool,
  isDelivery: PropTypes.bool,
  noHspacing: PropTypes.bool, // no horizontal spacing
  promotion: PropTypes.object,
  refund: PropTypes.number,
  subTotalPromoPercent: PropTypes.number, // usually passed on meal plan checkout
  onDeliveryFeePress: PropTypes.func,
  onOrderTypeChange: PropTypes.func,
  onPriorityFeePress: PropTypes.func,
};

export default CartSummary;
