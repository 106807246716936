import React from 'react';
import { View } from 'react-native';
import { Divider } from '@ui-kitten/components';

import { CircleLoader, RectangleLoader } from '../../Components/Loader';
import ScreenWrapper from '../../Components/ScreenWrapper';
import ThemeStyle from '../../Theme/styles';

import { styles as orderRatingStyle } from './index';

type PageLoaderProps = {
  header?: React.ReactElement;
  leftStar?: boolean;
};
const PageLoader = ({ header, leftStar }: PageLoaderProps) => {
  const renderFooter = () => (
    <View
      style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.spacingBottomMedium]}
    >
      <View style={{ borderRadius: 50, overflow: 'hidden' }}>
        <RectangleLoader width="100%" height={40} />
      </View>
    </View>
  );

  const _renderRating = () => (
    <View style={ThemeStyle.spacingBottom}>
      {/* rate your delivery title */}
      <View style={ThemeStyle.spacingBottomSmall}>
        <RectangleLoader width={200} height={20} />
      </View>
      {/* rate your delivery description */}
      <View style={ThemeStyle.spacingBottom}>
        <RectangleLoader width="100%" height={20} />
      </View>
      {/* 5 Stars */}
      <View
        style={[
          leftStar
            ? ThemeStyle.flexDirectionRow
            : ThemeStyle.flexDirectionRowCenterCenter,
          ThemeStyle.spacingBottom,
        ]}
      >
        <View style={ThemeStyle.pageHorizontalSpacingSmall}>
          <RectangleLoader width={25} height={25} />
        </View>
        <View style={ThemeStyle.pageHorizontalSpacingSmall}>
          <RectangleLoader width={25} height={25} />
        </View>
        <View style={ThemeStyle.pageHorizontalSpacingSmall}>
          <RectangleLoader width={25} height={25} />
        </View>
        <View style={ThemeStyle.pageHorizontalSpacingSmall}>
          <RectangleLoader width={25} height={25} />
        </View>
        <View style={ThemeStyle.pageHorizontalSpacingSmall}>
          <RectangleLoader width={25} height={25} />
        </View>
      </View>
      {/* Delivery rating text area */}
      <View>
        <RectangleLoader width="100%" height={135} />
      </View>
    </View>
  );

  const _renderOrder = () => (
    <View
      style={[
        ThemeStyle.flexDirectionRowCenterSpaceBetween,
        ThemeStyle.spacingBottom,
      ]}
    >
      <View style={ThemeStyle.flexDirectionRowCenter}>
        <RectangleLoader width={50} height={40} />
        <View style={ThemeStyle.spacingLeftMedium}>
          <View style={ThemeStyle.spacingBottomSmall}>
            <RectangleLoader width={150} height={20} />
          </View>
          <RectangleLoader width={50} height={20} />
        </View>
      </View>

      <View style={ThemeStyle.flexDirectionRow}>
        <CircleLoader size={30} />
        <View style={ThemeStyle.rowSeparator} />
        <CircleLoader size={30} />
      </View>
    </View>
  );

  return (
    <ScreenWrapper header={header} footer={renderFooter()}>
      <View style={ThemeStyle.pageHorizontalSpacing}>
        {/* Store name and date section */}
        <View
          style={[
            ThemeStyle.pageVerticalSpacingMedium,
            ThemeStyle.flexDirectionRowCenterSpaceBetween,
          ]}
        >
          <View>
            <View style={ThemeStyle.spacingBottomSmall}>
              {/* Store name */}
              <RectangleLoader width={200} height={20} />
            </View>
            {/* date */}
            <RectangleLoader width={200} height={20} />
          </View>

          {/* Store image */}
          <RectangleLoader
            width={orderRatingStyle.storeImage.width}
            height={orderRatingStyle.storeImage.height}
          />
        </View>

        <View style={[ThemeStyle.spacingTopMedium, ThemeStyle.spacingBottom]}>
          <Divider style={ThemeStyle.divider} />
        </View>

        {/* Rate your delivery section */}
        {_renderRating()}

        {/* Rate this store section */}
        {_renderRating()}

        <View style={ThemeStyle.spacingBottom}>
          <Divider style={ThemeStyle.divider} />
        </View>

        <View
          style={[
            ThemeStyle.flexDirectionRowCenterSpaceBetween,
            ThemeStyle.spacingBottom,
          ]}
        >
          <RectangleLoader width={100} height={25} />
          <View style={{ borderRadius: 50, overflow: 'hidden' }}>
            <RectangleLoader width={100} height={35} />
          </View>
        </View>

        {/* order item 1 */}
        {_renderOrder()}

        {/* order item 2 */}
        {_renderOrder()}
      </View>
    </ScreenWrapper>
  );
};

export default PageLoader;
