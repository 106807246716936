import React from 'react';
import { connect } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { SheetProvider } from 'react-native-actions-sheet';

import Toast from '../Components/Toast';
import '../Components/Sheets';

import RootNavigator from './RootNavigator.branded';
import { Linking } from 'react-native';
import routeList from './list';

const { RAZZLE_APP_URL } = process.env;

class Routes extends React.Component {
  render() {
    return (
      <NavigationContainer
        linking={{
          prefixes: [RAZZLE_APP_URL || 'https://marketplace.stg.pickup.ph'],
          config: {
            screens: {
              order: {
                screens: {
                  order: 'order',
                },
              },
              checkout: 'checkout',
            },
          },
          getInitialURL: () => {
            return Linking.getInitialURL();
          },
        }}
        documentTitle={{
          formatter: () => `Pickup | Menu`,
        }}
      >
        <SheetProvider>
          <Toast />
          <RootNavigator />
        </SheetProvider>
      </NavigationContainer>
    );
  }
}
const mapStateToProps = (state) => ({
  shopData: state.shop.data,
  orderNumber: state.checkout.trackOrder,
});

export default connect(mapStateToProps)(Routes);
