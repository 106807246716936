import lodashIsEmpty from 'lodash/isEmpty';
import constants from '../Config/constants';
const { PICKUP_DEFAULT_TRANSPARENT_STORE, PICKUP_DEFAULT_GREEN_STORE } =
  constants;

const getResizedImage = (uri = '', size = constants.ImageSize.MEDIUM) => {
  const whiteList = ['.amazonaws.com/items/'];

  const status = whiteList.find((item) => uri.includes(item));

  if (!lodashIsEmpty(status)) {
    const indexPosition = uri.lastIndexOf('.') || 0;
    const newString =
      uri.substring(0, indexPosition) +
      '_' +
      size +
      uri.substring(indexPosition);

    return newString;
  }

  return uri;
};

const getStoreBannerImage = ({ partner_banner, banner }, isGreenBG) => {
  const placeHolder = isGreenBG
    ? PICKUP_DEFAULT_GREEN_STORE
    : PICKUP_DEFAULT_TRANSPARENT_STORE;

  return partner_banner || banner || placeHolder;
};

const getOrderImage = ({ partner_logo, store_logo, banner }) =>
  partner_logo || store_logo || banner || PICKUP_DEFAULT_GREEN_STORE;

const getStoreLogoImage = ({ partner_logo }) => partner_logo;

const isPickupDefaultBanner = (banner) => banner?.includes('pickup-default');

export default {
  getResizedImage,
  getStoreBannerImage,
  getStoreLogoImage,
  getOrderImage,
  isPickupDefaultBanner,
};
