import React, { useRef, useState } from 'react';
import { Marker as WebMarker } from '@react-google-maps/api';

import styles from './styles';

const Marker = ({ coordinate, draggable = true, icon, coordinateChanged }) => {
  const marker = useRef(null);
  const [position, setPostiion] = useState({ ...coordinate });
  const webMarkerConditionalProps = {};
  if (icon) {
    webMarkerConditionalProps.icon = {
      scaledSize: styles.markerStyle,
      url: icon,
    };
  }

  const onPositionChanged = () => {
    if (marker.current != null) {
      const lat = marker.current.marker.getPosition().lat();
      const lng = marker.current.marker.getPosition().lng();
      setPostiion({ lat: lat, lng: lng });
    }
  };
  return (
    <WebMarker
      ref={marker}
      position={coordinate}
      draggable={draggable}
      onDragEnd={() =>
        coordinateChanged(
          { latitude: position.lat, longitude: position.lng },
          { isGesture: true }
        )
      }
      onPositionChanged={onPositionChanged}
      {...webMarkerConditionalProps}
    />
  );
};

export default Marker;
