import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';

import Promotion from '../../Components/Promotion';
import constants from '../../Config/constants';
import Service from '../../Service';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import { SECTION_SUB_TITLE } from '../../Theme/typographyProps';
import Button from '../Button';

function MinimumOrderNote({
  current,
  minimum,
  show,
  style,
  voucher,
  onRemove,
}) {
  if (!show) {
    return null;
  }
  const textProps = constants.isWeb ? { category: 'c1' } : SECTION_SUB_TITLE;
  const remaining = minimum - current;
  const remainingPercent = (current / minimum) * 100;

  return (
    <View style={[styles.wrapper, style]}>
      <Promotion
        item={voucher}
        fill1={ThemeColor.lightGray}
        fill2={ThemeColor.gray}
        height={94}
        preserveAspectRatio="xMidYMid slice"
        width="100%"
      >
        <View style={styles.container}>
          {/* Remove voucher button */}
          <View style={[ThemeStyle.flex1, ThemeStyle.alignItemsEnd]}>
            <View
              style={[
                ThemeStyle.pageVerticalSpacingSmall,
                ThemeStyle.pageHorizontalSpacingSmall,
              ]}
            >
              <Button onPress={onRemove} plain>
                <Text {...textProps} status="danger">
                  Remove
                </Text>
              </Button>
            </View>
          </View>
          {/* Voucher value and remaining to get it */}
          <View style={styles.textContainer}>
            <Text category="h6" status="success">
              - {voucher.discount}
            </Text>
            <Text {...textProps}>
              Add{' '}
              <Text {...textProps} style={ThemeStyle.bold}>
                {Service.commafyNumber(remaining, true)}
              </Text>{' '}
              more to use this voucher.
            </Text>
          </View>
          {/* Progress */}
          <View
            style={[
              styles.progress,
              { width: `${Math.abs(remainingPercent)}%` },
            ]}
          />
        </View>
      </Promotion>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    borderRadius: 15,
    overflow: 'hidden',
  },
  container: {
    borderTopWidth: 0,
    zIndex: 1,
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  textContainer: {
    position: 'absolute',
    height: '100%',
    left: 30,
    justifyContent: 'center',
    opacity: 0.5,
  },
  progress: {
    height: 4,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    backgroundColor: ThemeColor.green,
  },
});

MinimumOrderNote.propTypes = {
  current: PropTypes.number,
  minimum: PropTypes.number,
  show: PropTypes.bool,
  voucher: PropTypes.object,
};

export default MinimumOrderNote;
