import { createSlice } from '@reduxjs/toolkit';

import constants from '../../Config/constants';

const { ORDER_TYPE_DATA } = constants;

export const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    loading: true,
    data: null,
    rawData: null,
  },
  reducers: {
    setShopData: (state, action) => {
      state.loading = false;
      state.data = {
        ...action.payload,
        order_type: ORDER_TYPE_DATA[0],
      };
    },
    setShopRawData: (state, action) => {
      state.rawData = action.payload;
    },
    removeShopData: (state) => {
      state.loading = true;
      state.data = null;
      state.rawData = null;
    },
    updateShopOrderType: (state, action) => {
      state.data.order_type = action.payload;
    },
    setShopOrderForLater: (state, action) => {
      state.rawData = { ...state.rawData, orderForLater: action.payload };
    },
  },
});

export const {
  setShopData,
  setShopRawData,
  removeShopData,
  updateShopOrderType,
  setShopOrderForLater,
} = shopSlice.actions;
export default shopSlice.reducer;
