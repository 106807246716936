import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';

const Countdown = ({ initialSeconds, onEnd, textStyle }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else {
      if (onEnd && typeof onEnd === 'function') {
        onEnd();
      }
    }
  }, [seconds, onEnd]);

  return <Text {...textStyle}>{seconds}</Text>;
};

export default Countdown;
