import { useEffect, useRef, useState } from 'react';
import { Alert, ScrollView } from 'react-native';
import { StackActions, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Tooltip from 'rn-tooltip';

import { CANCEL_ERROR } from 'apisauce';
import { useDispatch, useSelector } from 'react-redux';
import PushNotification from 'react-native-push-notification';
import dayjs from 'dayjs';
import lodashFilter from 'lodash/filter';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';

import Toast from '../Components/Toast';
import constants from '../Config/constants';
import OrderHelper from '../Helper/Order';
import Sentry from '../Helper/Sentry';

import useCancellableRequest from '../Hooks/useCancellableRequest';
import useGoBack from '../Hooks/useGoBack';
import useToast from '../Hooks/useToast';
import useModalPrompt from '../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';

import { userSelector } from '../RTK/user/selectors';
import { setOrderDetails } from '../RTK/utility';
import { orderDetailsSelector } from '../RTK/utility/selectors';
import routeList from '../Routes/list';
import { RootStackParamList } from '../Routes/types';
import Service from '../Service';
import userApi from '../Service/api/user';
import storeApi from '../Service/api/store';

import { MODALPROMPT } from '../Components/Web/Modal/ModalPrompt/config';
import messages from '../Config/messages';
const { ERROR_ORDER_DETAILS } = messages;
const { ORDER_TYPES } = constants;
const ADDED_REVIEW = 'The review was added successfully.';
const UPDATE_REVIEW = 'The review was updated successfully.';
type Props = {
  defaultDeliveryStar?: number;
  defaultStoreStar?: number;
  transaction_number: string;
};

type ItemReaction = {
  id: string;
  liked: boolean;
  item: {
    name: string;
    description: string;
    image: string;
    active_discount: string;
    discount_type: string;
    discounted_price: string;
    discount_value: string;
    regular_price: string;
    price: string;
  };
};
type ReviewData = {
  delivery: number;
  delivery_feedbacks: Array<string>;
  store: number;
  store_feedbacks: Array<string>;
  item_reactions: Array<ItemReaction>;
  customer: string;
  timestamp: string;
};
const useOrderRating = ({
  defaultDeliveryStar,
  defaultStoreStar,
  transaction_number,
}: Props) => {
  const scrollList = useRef<ScrollView>();
  const publicReviewRef = useRef<Tooltip>(null);

  const { createRequest } = useCancellableRequest();
  const dispatch = useDispatch();
  const goBack = useGoBack();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const toast = useToast();
  const { hideModalPrompt } = useModalPrompt();
  const user = useSelector(userSelector);
  const orderDetails = useSelector(orderDetailsSelector);

  const [isLoading, setIsLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [isFailedToRetrieveReview, setFailedToRetrieveReview] = useState(false);
  const [deliveryRating, setDeliveryRating] = useState(
    OrderHelper.getDefaultStar(defaultDeliveryStar)
  );
  const [storeRating, setStoreRating] = useState(
    OrderHelper.getDefaultStar(defaultStoreStar)
  );
  const [errorRateDelivery, setErrorRateDelivery] = useState(false);
  const [errorRateStore, setErrorRateStore] = useState(false);
  const [errorEnchanceExperience, setErrorEnchanceExperience] = useState(false);
  const [errorFetchingOrderDetails, setErrorFetchingOrderDetails] = useState({
    title: '',
    message: '',
  });
  const [deliveryFeedback, setDeliveryFeedback] = useState([]);
  const [storeFeedback, setStoreFeedback] = useState([]);
  const [itemsReaction, setItemReaction] = useState([]);
  const [reviewData, setReviewData] = useState<ReviewData>();
  const [customFeedbackEnchancement, setCustomFeedbackEnchancement] =
    useState('');
  const transactionNumberParams = transaction_number;

  const orderDateAndTime = dayjs(
    `${orderDetails?.order_date} ${orderDetails?.completed_time}`
  ).format(constants.DATE_DISPLAY_FORMAT);
  const userName = `${user?.first_name} ${user?.last_name?.[0]}.`;
  const isUpdate = Boolean(orderDetails?.rating_id);

  const lowestStarRatings = storeRating < 3 && storeRating !== 0;

  useEffect(() => {
    if (defaultDeliveryStar) {
      if (deliveryRating != defaultDeliveryStar) {
        setDeliveryRating(OrderHelper.getDefaultStar(defaultDeliveryStar));
      }
    }
    if (defaultStoreStar) {
      if (storeRating != defaultStoreStar) {
        setStoreRating(OrderHelper.getDefaultStar(defaultStoreStar));
      }
    }
  }, [defaultDeliveryStar, defaultStoreStar]);

  useEffect(() => {
    if (!lodashIsEmpty(orderDetails)) {
      // has data on redux
      if (orderDetails.rating_id) {
        // has rating_id this must be edit rating
        _getRatingDetails(); // fetch rating details
      } else {
        // no rating id, this must be add rating
        setIsLoading(false); // removed loader flag
      }
    } else if (transactionNumberParams) {
      // user is in rating page but no order details on redux, fetch it using transaction number on url params
      _getOrderHistoryData();
    } else {
      // user is in rating page and has no id on url parameter
      goBack();
    }
  }, []);

  const _getOrderHistoryData = async () => {
    const { ok, data, problem } = await createRequest(
      userApi.getOrder,
      transactionNumberParams
    );
    if (problem === CANCEL_ERROR) {
      // stop the code from going if request is cancelled
      return;
    }
    if (ok) {
      dispatch(setOrderDetails(data));
      if (data.rating_id) {
        _getRatingDetails(data.store_details.id, data.rating_id);
      } else {
        setIsLoading(false);
      }
    } else {
      Sentry.reportError('Error getting order details', data);
      const ErroMessage = Service.handleErrorMessage(data?.message);
      if (ErroMessage === ERROR_ORDER_DETAILS.title) {
        setErrorFetchingOrderDetails({
          title: ErroMessage,
          message: ' ',
        });
      } else {
        setErrorFetchingOrderDetails({
          title: ErroMessage,
          message: 'Unable to load order details, Please try again.',
        });
      }

      setIsLoading(false);
    }
  };

  const _getRatingDetails = async (storeId?: string, ratingId?: string) => {
    const { ok, data, problem } = await createRequest(
      storeApi.getRatingDetails,
      storeId || orderDetails.store_details.id,
      ratingId || orderDetails.rating_id
    );

    if (problem === CANCEL_ERROR) {
      // stop the code from going if request is cancelled
      return;
    }
    if (ok) {
      const {
        delivery,
        delivery_feedbacks,
        store,
        store_feedbacks,
        customer_suggestion,
        item_reactions,
      } = data;
      setDeliveryRating(delivery);
      setStoreRating(store);
      setDeliveryFeedback(delivery_feedbacks);
      setStoreFeedback(store_feedbacks);
      setCustomFeedbackEnchancement(customer_suggestion);
      setItemReaction(
        item_reactions.map((ir) => ({
          ...ir,
          extraIds: ir.item.extras?.map((e) => e.id) || [],
        }))
      );
      setReviewData(data);
    } else {
      // it means, adding the review
      setFailedToRetrieveReview(true);
      Sentry.reportError('Error getting review details', data);
      Toast.show('Unable to load review, Please try again.', Toast.TYPE_ERROR);
    }
    setIsLoading(false);
  };

  const _onGoBack = (isFromEmail: boolean) => {
    if (isFromEmail) {
      navigation.replace(routeList.BASKET);
    } else {
      goBack();
    }
  };

  const _onHeaderActionRightPressed = () => {
    // this is the button Get help button on upper right on header
    navigation.navigate(routeList.GET_HELP);
  };

  const _onItemReaction = (item, isLiked) => () => {
    const extraIds = item.extras?.map((e) => e.id) || [];
    const findItem = (i) =>
      i.id === item.id && lodashIsEqual(i.extraIds, extraIds);
    const existing = lodashFind(itemsReaction, findItem);
    const isExist = !lodashIsEmpty(existing);
    if (isExist) {
      // only do something if reaction is different than the one saved on the state
      if (existing.liked !== isLiked) {
        const updated = itemsReaction.map((r) => {
          const isThisItem = findItem(r);
          if (isThisItem) {
            r.liked = isLiked;
          }
          return r;
        });
        setItemReaction(updated);
      }
    } else {
      const newReaction = [
        ...itemsReaction,
        { id: item.id, extraIds, liked: isLiked },
      ];
      setItemReaction(newReaction);
    }
  };

  const _onSubmitPressed = async () => {
    if (constants.isWeb) {
      toast.hide();
    }
    const isReviewValid = _validateReview();
    if (isReviewValid) {
      // if has valid review
      const payload: any = {
        order_id: orderDetails.id,
        store: storeRating,
        store_feedbacks: storeFeedback,
        item_reactions: itemsReaction,
        customer_suggestion: lowestStarRatings
          ? customFeedbackEnchancement
          : '',
      };
      if (orderDetails?.order_type === ORDER_TYPES.DELIVERY) {
        payload.delivery = deliveryRating;
        payload.delivery_feedbacks = deliveryFeedback;
      }
      setSubmitting(true); // loader flag
      const apiMethod = isUpdate ? storeApi.updateRating : storeApi.addRating;
      const { ok, data, problem } = isUpdate
        ? await createRequest(
            apiMethod,
            orderDetails.store_details.id,
            orderDetails.rating_id,
            payload
          )
        : await createRequest(
            apiMethod,
            orderDetails.store_details.id,
            payload
          );
      // stop the code from going if request is cancelled
      if (problem === CANCEL_ERROR) {
        return;
      }
      // proceed the logic
      if (ok && data.success) {
        // removal of scheduled notification
        PushNotification.getScheduledLocalNotifications((notifs) => {
          const notifications = lodashFilter(
            notifs,
            (notif) => notif.data.order_id === orderDetails.id
          );
          // if has notification(s)
          if (!lodashIsEmpty(notifications)) {
            // loop through it
            notifications.forEach((notif) => {
              // it just means it still has schedule notif but user already added notif for this schedule
              PushNotification.cancelLocalNotification({ id: notif.id }); // so cancel it on scheduled on notif
            });
          }
        });
        if (!isUpdate) {
          // if add, after going back need to navigate to order history
          // just to pass the refetch flag so it refetch again for updating the history with rating id on it
          toast.show(ADDED_REVIEW, true);
          if (constants.isWeb) {
            navigation.dispatch(
              StackActions.replace(routeList.HOME, {
                screen: 'MainMenu',
                params: {
                  screen: routeList.BASKET_TAB,
                  params: {
                    title: 'Orders',
                    activeTab: 1,
                    refetch: true,
                  },
                },
              })
            );
            hideModalPrompt(MODALPROMPT.storeRatingsModal, {});
          } else {
            navigation.goBack(); // go back first
            navigation.navigate(routeList.ORDER_HISTORY, { refetch: true }); // then navigate to history
          }
        } else {
          toast.show(UPDATE_REVIEW, true);
          setSubmitting(false);
        }
      } else {
        // if api failed during submitting of review
        const action = isUpdate ? 'update' : 'post';
        const errorMessage = Service.handleFormError(data?.message, true);
        Alert.alert(`Unable to ${action} review right now`, errorMessage);
        Sentry.reportError(`Error ${action} review`, { payload, data });
        setSubmitting(false); // remove loader flag
      }
    }
  };

  const _onRatingChange = (isDelivery) => (star) => {
    const toUpdateState = isDelivery ? setDeliveryRating : setStoreRating;
    if (isUpdate) {
      const { delivery, store } = reviewData;
      const toCompare = isDelivery ? delivery : store;
      const title = `${isDelivery ? 'Delivery' : 'Store'} Rating`;
      const message = 'The rating must be higher than your previous review.';
      if (star < toCompare) {
        toast.show(message, false);
      } else {
        toUpdateState(star);
      }
    } else {
      toUpdateState(star);
    }
  };

  const _validateReview = () => {
    if (
      orderDetails?.order_type === ORDER_TYPES.DELIVERY &&
      deliveryRating === 0
    ) {
      setErrorRateDelivery(true);
      return false;
    } else if (storeRating === 0) {
      setErrorRateStore(true);

      return false;
    } else if (lowestStarRatings && customFeedbackEnchancement === '') {
      setErrorEnchanceExperience(true);
      return false;
    }
    return true;
  };

  return {
    refs: {
      scrollList,
      publicReviewRef,
    },
    lowestStarRatings,
    orderDateAndTime,
    userName,
    state: {
      deliveryFeedback,
      deliveryRating,
      customFeedbackEnchancement,
      details: orderDetails,
      isFailedToRetrieveReview,
      isLoading,
      isUpdate,
      itemsReaction,
      storeFeedback,
      storeRating,
      submitting,
      errorRateDelivery,
      errorRateStore,
      errorEnchanceExperience,
      errorFetchingOrderDetails,
    },
    onDeliveryFeedbackChange: setDeliveryFeedback,
    onGoBack: _onGoBack,
    onHeaderActionRightPressed: _onHeaderActionRightPressed,
    onItemReaction: _onItemReaction,
    onRatingChange: _onRatingChange,
    onStoreFeedbackChange: setStoreFeedback,
    onSubmitPressed: _onSubmitPressed,
    setCustomFeedbackEnchancement,
    setErrorRateDelivery,
    setErrorRateStore,
    setErrorEnchanceExperience,
  };
};

export default useOrderRating;
