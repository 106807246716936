import { createSlice } from '@reduxjs/toolkit';
import indexOf from 'lodash/indexOf';

const initialState = {
  resultSearched: [],
  recentlySearched: [],
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    populatesResultSearched: (state, action) => {
      state.resultSearched = [...action.payload];
    },
    pushRecentlySearch: (state, action) => {
      const searchValue = action.payload.toLowerCase();
      const resultIndex = indexOf(state.recentlySearched, searchValue);
      const isExist = resultIndex !== -1;
      if (isExist) {
        // remove it from the array and below code will push the searched to the first item
        state.recentlySearched.splice(resultIndex, 1);
      }
      state.recentlySearched = [searchValue, ...state.recentlySearched];
      if (state.recentlySearched.length >= 4) {
        // after adding the searched to redux and it has more than 4
        state.recentlySearched.length = 4; // limit the search length to 4 only
      }
    },
    removeRecentlySearch: (state, action) => {
      if (action.payload) {
        // if has payload, remove individual
        const searchIndex = indexOf(state.recentlySearched, action.payload);
        state.recentlySearched.splice(searchIndex, 1);
      } else {
        // if no payload, remove all
        state.recentlySearched = initialState.recentlySearched;
      }
    },
    clearResultSearched: (state) => {
      state.resultSearched = [];
    },
  },
});

// prettier-ignore
export const { populatesResultSearched, pushRecentlySearch, removeRecentlySearch } = searchSlice.actions
export default searchSlice.reducer;
