import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import Button from '../Button';

import styles from './styles';

function FeedbackItem({ index, selected, onFeedbackPress, value }) {
  return (
    <Button
      testID={`feedbackButton${index}`}
      onPress={onFeedbackPress(value)}
      key={index}
      plain
    >
      <View
        style={[styles.feedbackContainer, selected && styles.feedbackActive]}
      >
        <Text category="c1" appearance={selected ? 'alternative' : 'default'}>
          {value}
        </Text>
      </View>
    </Button>
  );
}

FeedbackItem.propTypes = {
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  onFeedbackPress: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default FeedbackItem;
