import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import useOrderProgress from './index.controller';

function OrderProgressSimple(props) {
  const { checkpoints, progress, getProgress } = useOrderProgress(props);
  return (
    <View
      style={[ThemeStyle.flex1, ThemeStyle.flexDirectionRowCenterSpaceBetween]}
    >
      {checkpoints.map((data, index) => {
        const cpPercent = getProgress(data);
        const isActive = progress === cpPercent;
        const isNotLast = checkpoints.length - 1 !== index;
        return (
          <Fragment key={index}>
            <View
              style={[
                styles.progress,
                progress >= cpPercent && styles.progressReached,
                isActive && styles.progressActive,
              ]}
            />
            {isNotLast && (
              <View
                style={
                  props.small
                    ? ThemeStyle.rowSeparatorSmall
                    : ThemeStyle.rowSeparator
                }
              />
            )}
          </Fragment>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  progress: {
    backgroundColor: ThemeColor.gray,
    borderRadius: 10,
    flex: 1,
    height: 7,
  },
  progressReached: {
    flex: 1,
    backgroundColor: ThemeColor.green,
  },
  progressActive: {
    flex: 2,
    backgroundColor: ThemeColor.green,
  },
});

OrderProgressSimple.propTypes = {
  small: PropTypes.bool,
  value: PropTypes.string,
  orderType: PropTypes.string,
};

export default OrderProgressSimple;
