import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

const PromoFreeDelivery = ({
  fill1,
  fill2,
  fill1_opacity = '100%',
  fill2_opacity = '100%',
  ...props
}) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="17.55 10.85 1044.92 396.88"
    preserveAspectRatio="none"
    {...props}
  >
    <Path
      className="cls-2"
      fill={fill1 || '#cbefda'}
      fillOpacity={fill1_opacity}
      strokeWidth={0}
      d="m1062.46,53.83v101.03c-1.22-.09-2.43-.13-3.67-.13-29.52,0-53.46,24.43-53.46,54.56,0,6.32,1.05,12.38,3.01,18.03,1.46,4.3,3.44,8.38,5.87,12.1,9.58,14.72,25.96,24.43,44.57,24.43.47,0,.94,0,1.38-.02.06,0,.13-.02.21-.02.69-.02,1.38-.06,2.08-.09v101.03c.24,23.49-18.61,42.74-42.1,42.98H59.67c-23.49-.24-42.35-19.47-42.12-42.98v-100.89c29.52,0,53.46-24.43,53.46-54.56s-23.94-54.56-53.46-54.56V53.83c-.22-23.49,18.63-42.74,42.12-42.98h960.69c23.49.24,42.35,19.49,42.1,42.98Z"
    />
    <G id="baseline-delivery-dining" className="cls-1" opacity={0.37}>
      <Path
        id="Path_653"
        data-name="Path 653"
        className="cls-3"
        fill={fill2 || '#8ac1b1'}
        fillOpacity={fill2_opacity}
        strokeWidth={0}
        d="m1017.7,39.97h-67.11v44.74h67.11v59.29l-77.85,97.34h-78.77v-111.87h-89.5c-49.44,0-89.5,40.07-89.5,89.5v67.11h44.74c0,37.07,30.06,67.13,67.13,67.13s67.13-30.06,67.13-67.13h100.24l47.02-58.77c-1.96-5.65-3.01-11.71-3.01-18.03,0-30.13,23.94-54.56,53.46-54.56,1.23,0,2.45.04,3.67.13v-70.14c-.07-24.67-20.07-44.67-44.76-44.74Zm-223.75,268.51c-12.35-.04-22.33-10.04-22.37-22.39h44.74c-.04,12.35-10.03,22.35-22.37,22.39Z"
      />
      <Path
        id="Path_654"
        data-name="Path 654"
        className="cls-3"
        fill={fill2 || '#8ac1b1'}
        fillOpacity={fill2_opacity}
        strokeWidth={0}
        d="m749.21,62.34v44.76h111.87v-44.76h-111.87Zm290.88,223.75c0-11.58,8.79-21.12,20.09-22.26-.45.02-.92.02-1.38.02-18.61,0-35-9.71-44.57-24.43-11.69,12.06-18.89,28.53-18.89,46.67,0,37.07,30.06,67.13,67.13,67.13v-44.74c-12.36,0-22.37-10.03-22.37-22.39Z"
      />
    </G>
  </Svg>
);

export default PromoFreeDelivery;
