import React, { useState } from 'react';
import { TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { useDispatch } from 'react-redux';
import { Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import { saveUserAddress, saveUserAsGuest } from '../../../../RTK/user';

import useCancellableRequest from '../../../../Hooks/useCancellableRequest';

import userApi from '../../../../Service/api/user';

import addEditAddress from '../../../../Screens/Address/controller/addEditAddress';

import ThemeStyle from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import useModalPrompt from './hooks/useModalPrompt';
import { MODALPROMPT } from './config';

import ModalPrompt from './ModalPrompt';

import GooglePlaces_Autocomplete from '../../../Map/GooglePlacesAutocomplete';
import Button from '../../../Button';
//@ts-ignore
import PinOnMap from '../../../../Assets/PinOnMap.svg';

import { testProps, TestAppId } from '../../../../../test-ids';

const DeliveryAddress = () => {
  const dispatch = useDispatch();
  const { showModalPrompt, hideModalPrompt } = useModalPrompt();
  const { createRequest } = useCancellableRequest();
  const { state, autoCompleteRef, onSearchItemPressed } = addEditAddress({
    selectedAddress: '',
  });

  const [data, setData] = useState<{ navigation: any }>();

  const { selectedAddress, submitting } = state;

  const _onSaveAddress = async () => {
    const { ok, problem } = await createRequest(userApi.signInAsGuest, {
      place_id: selectedAddress.place_id,
      address: selectedAddress.address,
    });
    if (ok) {
      const payload = {
        id: selectedAddress.place_id,
        label: 'Home',
        place_id: selectedAddress.place_id,
        formatted_address: selectedAddress.address,
        geo_json_point: {
          type: 'Point',
          coordinates: [selectedAddress.longitude, selectedAddress.latitude],
        },
      };
      dispatch(saveUserAsGuest());
      dispatch(saveUserAddress(payload));
      hideModalPrompt(MODALPROMPT.deliveryAddress, {});
      window.location.reload();
    } else {
      alert(problem);
    }
  };

  const _showAuthenticationModal = () => {
    const { navigation } = data || {};
    hideModalPrompt(MODALPROMPT.deliveryAddress, {});
    showModalPrompt(MODALPROMPT.authentication, {
      isLogin: true,
      navigation: navigation,
    });
  };
  return (
    <ModalPrompt
      id={MODALPROMPT.deliveryAddress}
      title={''}
      dataSet={{ media: ids.mainContentWrapperStyle }}
      mainContentWrapperStyle={styles.mainContentWrapperStyle}
      childContentContainerStyle={{ marginBottom: 0 }}
      hasHeader={false}
      hasHeaderLine={false}
      closeOnDocumentClick={false}
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <View
        dataSet={{ media: ids.pinImageBackgrounContainer }}
        style={styles.pinImageBackgrounContainer as ViewStyle}
      >
        <PinOnMap width={300} height={300} />
      </View>
      <View
        dataSet={{ media: ids.singleContentContainer }}
        style={styles.singleContentContainer as ViewStyle}
      >
        <View style={{ flex: 1, rowGap: 10 } as ViewStyle}>
          <Text
            category="h5"
            dataSet={{ media: ids.titleTxt }}
            style={styles.titleTxt as TextStyle}
          >
            We’re cooking up some culinary wonders, but first, we need to find
            you!
          </Text>

          <Text category="p2" status="primary">
            Search for your address or type in your coordinates below
          </Text>

          <GooglePlaces_Autocomplete
            //@ts-ignore
            testID="gpautocomplete"
            ref={!submitting ? autoCompleteRef : null}
            placeholder="Type your address here..."
            initialValue={selectedAddress?.address}
            onSelected={onSearchItemPressed}
            style={styles.googleplace_autocomplete}
          />
        </View>

        <View
          dataSet={{ media: ids.doYouHaveAnAccountIfSmaller }}
          style={styles.doYouHaveAnAccountIfSmaller}
        >
          <TouchableOpacity
            {...testProps(
              TestAppId.LOCATION_MODAL_BASIC_INFO.loginWithYourPickupAccount
            )}
            onPress={_showAuthenticationModal}
          >
            <Text category="p2" status="success">
              Login with your pickup account
            </Text>
          </TouchableOpacity>
        </View>
        <View
          dataSet={{ media: ids.autoCompleteContainer }}
          style={styles.autoCompleteContainer as ViewStyle}
        >
          <Button
            disabled={lodashIsEmpty(selectedAddress)}
            plain
            style={styles.proceedBtn}
            onPress={_onSaveAddress}
          >
            <Text category="c1" status="control">
              Let’s Eat!
            </Text>
          </Button>
          <View
            dataSet={{ media: ids.pinImageContainer }}
            style={styles.pinImageContainer as ViewStyle}
          >
            <PinOnMap width={131} height={138} />
          </View>
        </View>
      </View>
      <View
        dataSet={{ media: ids.doYouHaveAnAccount }}
        style={styles.doYouHaveAnAccount}
      >
        <TouchableOpacity
          {...testProps(
            TestAppId.LOCATION_MODAL_BASIC_INFO.loginWithYourPickupAccount
          )}
          onPress={_showAuthenticationModal}
        >
          <Text category="p2" status="success">
            Login with your pickup account
          </Text>
        </TouchableOpacity>
      </View>
    </ModalPrompt>
  );
};

const { ids, styles } = StyleSheet.create({
  mainContentWrapperStyle: {
    borderRadius: 0,
    paddingVertical: 20,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EAF8F5',
    minHeight: 230,
    [`@media ${BreakPoints.xs}`]: {
      width: 350,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 600,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 700,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 800,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 850,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 900,
    },
  },
  singleContentContainer: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.justifyContentCenter,
    marginVertical: 20,
    //@ts-ignore: columngap definition not available in react native media query
    columnGap: 15,
    [`@media ${BreakPoints.xs}`]: {
      ...ThemeStyle.flexDirectionColumnSpaceBetween,
    },
    [`@media ${BreakPoints.sm}`]: {
      ...ThemeStyle.flexDirectionRowSpaceBetween,
    },
    [`@media ${BreakPoints.md}`]: {
      ...ThemeStyle.flexDirectionRowSpaceBetween,
    },
    [`@media ${BreakPoints.lg}`]: {
      ...ThemeStyle.flexDirectionRowSpaceBetween,
    },
    [`@media ${BreakPoints.xl}`]: {
      ...ThemeStyle.flexDirectionRowSpaceBetween,
    },
    [`@media ${BreakPoints.xxl}`]: {
      ...ThemeStyle.flexDirectionRowSpaceBetween,
    },
  },
  //@ts-ignore
  autoCompleteContainer: {
    columnGap: 15,
    [`@media ${BreakPoints.xs}`]: {
      ...ThemeStyle.spacingTop,
      ...ThemeStyle.flexDirectionColumnSpaceBetween,
    },
    [`@media ${BreakPoints.sm}`]: {
      ...ThemeStyle.spacingTop,
      ...ThemeStyle.flexDirectionRow,
    },
    [`@media ${BreakPoints.md}`]: {
      ...ThemeStyle.spacingTop,
      ...ThemeStyle.flexDirectionRow,
    },
    [`@media ${BreakPoints.lg}`]: {
      marginTop: 0,
      ...ThemeStyle.flexDirectionRow,
    },
    [`@media ${BreakPoints.xl}`]: {
      marginTop: 0,
      ...ThemeStyle.flexDirectionRow,
    },
    [`@media ${BreakPoints.xxl}`]: {
      marginTop: 0,
      ...ThemeStyle.flexDirectionRow,
    },
  },

  pinImageBackgrounContainer: {
    position: 'absolute',
    opacity: 0.1,
    [`@media ${BreakPoints.xs}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'none',
    },
  },
  pinImageContainer: {
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },

  titleTxt: {
    color: '#007257',
    [`@media ${BreakPoints.xs}`]: {
      fontSize: 16,
    },
    [`@media ${BreakPoints.sm}`]: {
      fontSize: 16,
    },
    [`@media ${BreakPoints.md}`]: {
      fontSize: 18,
    },
    [`@media ${BreakPoints.lg}`]: {
      fontSize: 20,
    },
    [`@media ${BreakPoints.xl}`]: {
      fontSize: 20,
    },
    [`@media ${BreakPoints.xxl}`]: {
      fontSize: 22,
    },
  },
  googleplace_autocomplete: {
    ...ThemeStyle.regular,
    ...ThemeStyle.spacingTopMedium,
    fontSize: 12,
    backgroundColor: 'transparent',
  },
  proceedBtn: {
    width: 130,
    height: 44,
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#008B6A',
    borderWidth: 1,
    padding: 10,
    borderRadius: 5,
    borderColor: '#008B6A',
  },
  doYouHaveAnAccount: {
    position: 'absolute',
    bottom: -7,
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },
  doYouHaveAnAccountIfSmaller: {
    ...ThemeStyle.spacingTopMedium,
    [`@media ${BreakPoints.xs}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'none',
    },
  },
});

export default DeliveryAddress;
