import { API_URL, CHECKOUT_PAYMENT_URL, RAZZLE_APP_URL } from '@env';

const webAppURL = RAZZLE_APP_URL || 'https://marketplace.stg.pickup.ph';
const apiURL = API_URL || 'https://marketplace-api.stg.pickup.ph';
const paymentUrl = CHECKOUT_PAYMENT_URL || 'https://payments.stg.pickup.ph';
const sentryEnv = API_URL ? 'production' : 'staging';

const API = {
  STOPLIGHT: 'https://stoplight.io/mocks/pickup/customer-app-api/399135',
  BUCKET: 'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com',
};

export default {
  BUCKET: API.BUCKET,
  PAYMENT: paymentUrl,
  ROOT_API: apiURL,
  SENTRY_ENV: sentryEnv,
  STATIC_API: API.STOPLIGHT,
  WEB: webAppURL,
};
