import React from 'react';
import { connect } from 'react-redux';
import { View } from 'react-native';
import { Divider } from '@ui-kitten/components';
import lodashIsUndefined from 'lodash/isUndefined';

import ListItem from '../../Components/ListItem';
import ScreenWrapper from '../../Components/ScreenWrapperWithTitleAnimation';
import Switch from '../../Components/Switch';
import { userSelector } from '../../RTK/user/selectors';
import { updateNotificationSetting } from '../../RTK/utility';
import ThemeStyle from '../../Theme/styles';

const defaultNotif = [{ id: 'all', value: true }];

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          id: defaultNotif[0].id,
          title: 'Push Notifications',
          subTitle: 'Sent to this device',
        },
      ],
    };
  }

  componentDidMount() {
    const { notification, dispatchUpdateNotificationSetting } = this.props;
    // add default notification if the value is still undefined
    defaultNotif.forEach((dn) => {
      if (lodashIsUndefined(notification[dn.id])) {
        dispatchUpdateNotificationSetting({ key: dn.id, value: dn.value });
      }
    });
  }

  _onRowPress = (key) => () => {
    const { notification, dispatchUpdateNotificationSetting } = this.props;
    dispatchUpdateNotificationSetting({ key, value: !notification[key] });
  };

  render() {
    const { notification } = this.props;
    const { data } = this.state;

    return (
      <ScreenWrapper title="Notifications">
        <View style={ThemeStyle.pageHorizontalSpacing}>
          {data.map((notif, i) => (
            <React.Fragment key={i}>
              <ListItem
                testID={`$${notif.id}NotifButton`}
                title={notif.title}
                subTitle={notif.subTitle}
                accessoryRight={
                  <Switch
                    testID={`${notif.id}NotifSwitch`}
                    value={notification[notif.id] || false}
                    onChange={this._onRowPress(notif.id)}
                    disabled={notif.disabled}
                  />
                }
                onPress={this._onRowPress(notif.id)}
              />

              <Divider style={ThemeStyle.divider} />
            </React.Fragment>
          ))}
        </View>
      </ScreenWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  user: userSelector(state),
  notification: state.utility.settings.notification,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateNotificationSetting: (data) =>
    dispatch(updateNotificationSetting(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
