import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View } from 'react-native';
import { Icon, Divider, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import Button from '../../Components/Button';
import List from '../../Components/List';
import ListItem from '../../Components/ListItem';
import { removeRecentlySearch } from '../../RTK/search';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import constants from '../../Config/constants';

class RecentlyViewedSection extends React.PureComponent {
  _onSearchRemove = (search) => () =>
    this.props.dispatchRemoveRecentlySearch(search);

  _renderItem = ({ index, item }) => (
    <ListItem
      testID={`recentlyButton${index}`}
      leftIcon="clock-time-five-outline"
      leftIconColor={ThemeColor.green}
      leftIconPack="material"
      title={item}
      accessoryRight={
        <Button
          hitSlop={10}
          style={ThemeStyle.spacingLeft}
          onPress={this._onSearchRemove(item)}
          plain
        >
          <Icon
            name="close"
            fill={ThemeColor.darkGray}
            style={{ width: 20, height: 20 }}
          />
        </Button>
      }
      onPress={() => this.props.onPress(item)}
    />
  );

  render() {
    const { recentlySearched } = this.props;

    if (lodashIsEmpty(recentlySearched)) {
      return null;
    }

    return (
      <View
        testID="recentlyViewed"
        style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.spacingTopMedium]}
      >
        <View
          style={[
            ThemeStyle.flexDirectionRowSpaceBetween,
            ThemeStyle.alignItemsCenter,
          ]}
        >
          <Text category={constants.isWeb ? 'p1' : 's1'}>Recent Searches</Text>

          <Button onPress={this._onSearchRemove()} plain>
            <Text>Clear All</Text>
          </Button>
        </View>

        <List
          data={recentlySearched}
          renderItem={this._renderItem}
          ItemSeparatorComponent={() =>
            constants.isWeb && (
              <View>
                <View style={ThemeStyle.webPageWrapper}>
                  <Divider
                    style={[ThemeStyle.divider, ThemeStyle.webPageContainer]}
                  />
                </View>
              </View>
            )
          }
          withSeparator={!constants.isWeb}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  recentlySearched: state.search.recentlySearched,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchRemoveRecentlySearch: (data) => dispatch(removeRecentlySearch(data)),
});

RecentlyViewedSection.propTypes = {
  onPress: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentlyViewedSection);
