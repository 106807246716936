import { Alert } from 'react-native';

import { MODALPROMPT } from '../Components/Web/Modal/ModalPrompt/config';
import useModalPrompt from '../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import constants from '../Config/constants';

function useAlert() {
  const { showModalPrompt } = useModalPrompt();
  return (title, message) => {
    if (constants.isWeb) {
      // if wan't to change the alert on web, update this.
      // BUT make sure to not change how it was use
      showModalPrompt(MODALPROMPT.prompt, {
        buttonStatus: 'success',
        buttonText: 'Ok',
        message,
        title,
      });
    } else {
      Alert.alert(title, message);
    }
  };
}

export default useAlert;
