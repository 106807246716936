import React, { Fragment, useRef } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { SafeAreaView } from 'react-native-safe-area-context';
import Modal from 'react-native-modal';

import { OtpInput } from 'react-native-otp-entry';
import PickupWordLogo from '../../Assets/PickupWordLogo.svg';
import Button from '../../Components/Button';
import constants from '../../Config/constants';
import { anonymize } from '../../Helper';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { deviceHeight, globalSpacing } from '../../Theme/styles';

import OTPTimer from './OTPTimer';

import { testProps, TestAppId } from '../../../test-ids';

const otplength = 6;

function Otp({
  email,
  isResending,
  isSubmitting,
  onBack,
  timer,
  onCountdownEnd,
  onResend,
  onSubmit,
}) {
  const otpInputRef = useRef();

  const _onResend = () => {
    otpInputRef.current.clear();
    onResend();
  };

  return (
    <Fragment>
      {/* Info */}
      <View
        {...testProps(TestAppId.OTP_FORM_BASIC_INFO.otpForm)}
        style={styles.contentContainer}
      >
        <View>
          {/* Icon, number and Code input */}
          <View
            style={[
              ThemeStyle.flex1,
              ThemeStyle.alignItemsCenter,
              ThemeStyle.spacingBottom,
            ]}
          >
            {/* app logo */}
            <PickupWordLogo width={200} height={100} />
            {/* sent to */}
            <Text category="p1">OTP has been sent to</Text>
            {/* number */}
            <View style={styles.numberContainer}>
              <Text appearance="alternative" category="p2">
                {anonymize(email, 3, email.length - email.indexOf('@'))}
              </Text>
            </View>
            {/* Inputs */}
            <View style={[ThemeStyle.spacingBottom]}>
              <OtpInput
                ref={otpInputRef}
                numberOfDigits={otplength}
                focusColor={ThemeColor.green}
                focusStickBlinkingDuration={500}
                onFilled={onSubmit}
                textInputProps={{
                  ...testProps(TestAppId.OTP_FORM_BASIC_INFO.otpInput),
                }}
                blurOnFilled
                theme={{
                  containerStyle: styles.containerStyle,
                  pinCodeContainerStyle: styles.pinCodeContainerStyle,
                  pinCodeTextStyle: styles.pinCodeTextStyle,
                  focusedPinCodeContainerStyle: styles.pinCodeContainerStyle,
                }}
                autoFocus={false}
                disabled={isResending || isSubmitting}
              />
            </View>
            {/* Resend / Countdown */}
            {timer <= 0 ? (
              <View style={ThemeStyle.flexDirectionRowCenter}>
                <Text appearance={!constants.isWeb && 'alternative'}>
                  Did not receive a code?
                </Text>
                <Button
                  onPress={_onResend}
                  style={ThemeStyle.spacingLeftSmall}
                  disabled={isResending}
                  plain
                >
                  <Text status="success">
                    {isResending ? 'Resending' : 'Resend'}
                  </Text>
                </Button>
              </View>
            ) : (
              <OTPTimer
                timer={timer}
                text="Resend code in"
                onCountdownEnd={onCountdownEnd}
              />
            )}
            <Text
              category="label"
              appearance={!constants.isWeb && 'alternative'}
              style={[ThemeStyle.dimColor, ThemeStyle.spacingTop]}
            >
              Never share your OTP with anyone.
            </Text>
          </View>
        </View>
      </View>

      {/* Loader */}
      <Modal
        {...testProps(TestAppId.OTP_FORM_BASIC_INFO.otpModalLoading)}
        isVisible={isSubmitting}
        animationIn="fadeIn"
        animationOut="fadeOut"
        deviceHeight={deviceHeight}
        useNativeDriver
        statusBarTranslucent
      >
        <SafeAreaView>
          <View style={ThemeStyle.flex1}>
            <ActivityIndicator color={ThemeColor.white} size="large" />
          </View>
        </SafeAreaView>
      </Modal>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  contentContainer: [
    ThemeStyle.flex1,
    ThemeStyle.alignItemsCenter,
    ThemeStyle.justifyContentCenter,
  ],
  logo: {
    width: 200,
    height: 50,
    marginTop: globalSpacing,
    marginBottom: globalSpacing * 2,
  },
  numberContainer: {
    ...ThemeStyle.pageHorizontalSpacingSmall,
    ...ThemeStyle.pageVerticalSpacingMedium,
    ...ThemeStyle.spacingBottom,
    ...ThemeStyle.spacingTopSmall,
    backgroundColor: ThemeColor.green,
    borderRadius: 20,
  },
  containerStyle: { columnGap: 10 },
  pinCodeContainerStyle: { width: 50, height: 50 },
  pinCodeTextStyle: {
    ...ThemeStyle.regular,
    fontSize: 14,
  },
});

export default Otp;
