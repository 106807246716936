import { StyleSheet } from 'react-native';

import { globalSpacing } from '../../Theme/styles';

export const iconSize = 20;

export default StyleSheet.create({
  wrapper: {
    padding: globalSpacing / 2,
  },
  headerWrapperLeft: {
    paddingVertical: globalSpacing / 2,
  },
  headerWrapperRight: {
    paddingVertical: globalSpacing / 2,
  },
  icon: {
    width: iconSize,
    height: iconSize,
  },
  leftIcon: {
    marginRight: globalSpacing / 4,
  },
  rightIcon: {
    marginLeft: globalSpacing / 4,
  },
});
