import { createSelector } from '@reduxjs/toolkit';
import lodashFind from 'lodash/find';

const shopPersist = (state) => state.shopPersist?.shops;

const shopPersistSelector = createSelector(
  [
    shopPersist,
    (_, storeId) => storeId, // for 2nd param
  ],
  (shopPersistData, storeId) => lodashFind(shopPersistData, { id: storeId })
);

export { shopPersist, shopPersistSelector };
