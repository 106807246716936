import { createSlice } from '@reduxjs/toolkit';
import lodashFindIndex from 'lodash/findIndex';
import lodashIsEmpty from 'lodash/isEmpty';

export const shopPersistSlice = createSlice({
  name: 'shopPersist',
  initialState: {
    shops: [],
  },
  reducers: {
    saveShop: (state, action) => {
      const storeId = action.payload.id;
      if (storeId) {
        const storeIndex = lodashFindIndex(state.shops, { id: storeId });
        if (storeIndex === -1) {
          // if -1 it means its not yet exist on the shop state, just push it
          state.shops.push(action.payload);
        } else {
          // if storeIndex is more than -1 it means store is already on the state, update it using the storeIndex
          const storeData = action.payload;
          const stateData = state.shops[storeIndex];
          const hasCategories =
            lodashIsEmpty(storeData.categories) &&
            !lodashIsEmpty(stateData.categories); // if HAS NO payload categories and HAS existing categories
          const hasDistance = !storeData.distance && !!stateData.distance; // if HAS NO payload distance and HAS existing distance
          const hasMenu =
            lodashIsEmpty(storeData.menu) && !lodashIsEmpty(stateData.menu); // if HAS NO payload menu and HAS existing menu
          state.shops[storeIndex] = {
            ...stateData, // save existing store state
            ...storeData, // save new store data
            // if no data, use existing data from redux
            categories: hasCategories
              ? stateData.categories
              : storeData.categories,
            distance: hasDistance ? stateData.distance : storeData.distance,
            menu: hasMenu ? stateData.menu : storeData.menu,
          };
        }
      }
    },
    updateShop: (state, action) => {
      const { storeId, data } = action.payload;
      const storeIndex = lodashFindIndex(state.shops, { id: storeId });
      if (storeIndex !== -1) {
        // storeIndex must not be -1 to make the store cache update
        state.shops[storeIndex] = {
          ...state.shops[storeIndex],
          ...data,
        };
      }
    },
  },
});

export const { saveShop, updateShop } = shopPersistSlice.actions;
export default shopPersistSlice.reducer;
