import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Layout, Text } from '@ui-kitten/components';

import { MODALPROMPT } from './config';

import ModalPrompt from './ModalPrompt';

import Button from '../../../Button';

import ThemeColors from '../../../../Theme/colors';
import ThemeStyles from '../../../../Theme/styles';
import useModalPrompt from './hooks/useModalPrompt';
import routeList from '../../../../Routes/list';

const CompleteInformation = () => {
  const [data, setData] = useState<{ navigation: any }>();
  const { hideModalPrompt } = useModalPrompt();
  const { navigation } = data || {};

  const onConfirm = () => {
    navigation.navigate(routeList.HOME, {
      screen: 'MainMenu',
      params: {
        screen: routeList.PROFILE,
      },
    });
    onCancel();
  };

  const onCancel = () => hideModalPrompt(MODALPROMPT.completeInformation, {});

  return (
    <ModalPrompt
      id={MODALPROMPT.completeInformation}
      title={'Complete your profile'}
      mainContentWrapperStyle={{ width: 500 }}
      hasHeader={true}
      hasHeaderLine={true}
      closeOnDocumentClick={false}
      onBeforeShow={setData}
    >
      <Layout style={styles.singleContentContainer}>
        <Text style={styles.singleContentRegular}>
          Would you like to complete your profile now?
        </Text>
        <Layout style={styles.controlContainer}>
          <Button
            testID="checkout_button"
            onPress={onConfirm}
            plain
            style={styles.removeButtonContainer}
          >
            <Text style={styles.checkoutTxt}>{`Yes`}</Text>
          </Button>
          <Layout style={styles.controlButtonSeparator} />
          <Button
            testID="checkout_button"
            onPress={onCancel}
            plain
            style={styles.cancelButtonContainer}
          >
            <Text style={styles.cancelTxt}>{`Not Now`}</Text>
          </Button>
        </Layout>
      </Layout>
    </ModalPrompt>
  );
};

const styles = StyleSheet.create({
  singleContentContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  singleContentBold: { fontSize: 20, textAlign: 'center', fontWeight: 'bold' },
  singleContentRegular: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 10,
  },
  controlContainer: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingHorizontal: 20,
    marginTop: 20,
  },
  removeButtonContainer: {
    backgroundColor: ThemeColors.green,
    width: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    ...ThemeStyles.fullRoundCorder,
  },
  controlButtonSeparator: {
    width: 30,
  },
  cancelButtonContainer: {
    backgroundColor: ThemeColors.white,
    width: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    ...ThemeStyles.fullRoundCorder,
  },
  checkoutTxt: { ...ThemeStyles.medium, color: ThemeColors.white },
  cancelTxt: { ...ThemeStyles.medium, color: ThemeColors.black },
});

export default CompleteInformation;
