import { StackActions, useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import constants from '../Config/constants';
import { isLoggedInSelector } from '../RTK/user/selectors';
import routeList from '../Routes/list';

function useGoBack(backRoute, backRouteParams) {
  const navigation = useNavigation();
  const isLoggedIn = useSelector(isLoggedInSelector);

  return () => {
    if (navigation.canGoBack()) {
      // if can go back, just go back
      navigation.goBack();
    } else if (backRoute) {
      // if has back route, navigate to it
      navigation.replace(backRoute, backRouteParams);
    } else if (constants.isWeb) {
      // if can't go back and no backRoute specified and if web platform, back to homepage
      navigation.dispatch(
        StackActions.replace(routeList.HOME, {
          screen: 'MainMenu',
          params: { screen: routeList.ROOT_TAB },
        })
      );
    } else if (isLoggedIn) {
      // if logged in, back to home tab
      navigation.replace(routeList.ROOT_TAB);
    } else {
      // if not logged in, back to onboarding page
      navigation.replace(routeList.ONBOARDING);
    }
  };
}

export default useGoBack;
