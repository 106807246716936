import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import lodashFindIndex from 'lodash/findIndex';
import { authenticationApi } from '../api/auth';
import constants from '../../Config/constants';
import { getLastUsedAddressCount } from '../../Helper/RemoteConfig';

import routeList from '../../Routes/list';

export const initialState = {
  user: null,
  isLoggedIn: false,
  requiringCompleteInformation: false,
  isLogoutLoading: false,
};

export const guestState = {
  addresses: [],
  guest: true,
  isLoggedIn: false,
  requiringCompleteInformation: false,
};
export const logout = createAsyncThunk(
  'user/logout',
  async (_, thunkApi) =>
    await thunkApi
      .dispatch(authenticationApi.endpoints.logout.initiate(null))
      .unwrap()
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleRequiringCompletingInformation: (state) => {
      state.requiringCompleteInformation = !state.requiringCompleteInformation;
    },
    saveUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
        addresses: [],
        guest: false,
      };
    },
    saveUserAsGuest: (state) => {
      state.user = guestState;
    },
    setUserAddresses: (state, action) => {
      state.user.addresses = action.payload;
      // sort addresses so active address should be first index
      state.user.addresses.sort((e) => (e.active ? -1 : 1));
    },
    saveUserAddress: (state, action) => {
      state.user.addresses = [
        ...state.user.addresses,
        {
          ...action.payload,
          active: state.user.addresses.length === 0,
        },
      ];
    },
    setAsLoggedIn: (state) => {
      state.isLoggedIn = true;
    },
    setForceLogout: (state) => {
      state.user = initialState.user;
      state.isLoggedIn = false;
      if (constants.isWeb) {
        window.location.href = routeList.HOME_TAB;
      }
    },
    updateUserAddress: (state, action) => {
      const { index, ...data } = action.payload;
      if (constants.isWeb) {
        const userAddressCopy = state.user.addresses.map((address) =>
          address.id === index ? { ...state.user.addresses, ...data } : address
        );
        state.user.addresses = userAddressCopy;
      } else {
        state.user.addresses[index] = data;
      }
    },
    setUserAddressAsFirst: (state, action) => {
      // action.payload is address id
      // remove all active aside from the selected address id
      // if (constants.isWeb) {
      //   const id = action.payload

      //   const updatedArrayOfObjects = state.user.addresses.map((obj) => {
      //     if (obj.id === id) {
      //       // Update the 'active' field of the object with the provided id
      //       return { ...obj, active: true }
      //     }
      //     // For other objects, return them unchanged
      //     return obj
      //   })
      //   state.user.addresses = updatedArrayOfObjects
      // } else {
      state.user.addresses.map((e) => {
        if (e.id === action.payload) {
          e.active = true;
        } else {
          e.active = false;
        }
        return e;
      });
      //}
      // sort addresses so active address should be first index
      state.user.addresses.sort((e) => (e.active ? -1 : 1));
    },
    clearGuestData: (state) => {
      state.user = {
        ...state.user,
        addresses: [],
        guest: false,
      };
    },
    removeUserAddress: (state, action) => {
      const addressIndex = lodashFindIndex(state.user.addresses, (address) => {
        return address.id === action.payload;
      });
      state.user.addresses.splice(addressIndex, 1);
    },
    updateInformation: (state, action) => {
      const { firstName, lastName, phone } = action.payload;
      state.user = {
        ...state.user,
        first_name: firstName,
        last_name: lastName,
        phone,
      };
    },
    updateLastUsedAddress: (state, action) => {
      const { active_address, addresses } = action.payload;
      state.user.last_used_addresses = addresses
        .filter((e) => e != active_address)
        .slice(0, getLastUsedAddressCount());
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state, action) => {
      state.user = initialState.user;
      state.isLoggedIn = false;
      if (constants.isWeb) {
        window.location.href = routeList.HOME_TAB;
      }
    });
    builder.addCase(logout.pending, (state, action) => {
      state.isLogoutLoading = true;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.isLogoutLoading = false;
    });
  },
});

export const {
  clearGuestData,
  toggleRequiringCompletingInformation,
  setUserAddresses,
  saveUserAsGuest,
  saveUserAddress,
  saveUser,
  setAsLoggedIn,
  setForceLogout,
  updateUserAddress,
  updateInformation,
  updateLastUsedAddress,
  setUserAddressAsFirst,
  removeUserAddress,
} = userSlice.actions;
export default userSlice.reducer;
