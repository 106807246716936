import React, { useEffect } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Divider, Icon, Layout, Text } from '@ui-kitten/components';
import { SafeAreaView } from 'react-native-safe-area-context';
import lodashIsEmpty from 'lodash/isEmpty';

import otherApi from '../../../Service/api/other';

import Form from '../../FormFields/Form';
import GooglePlaces_Autocomplete from '../../Map/GooglePlacesAutocomplete';
import MapView, { Marker } from '../../Map';
import SubmitButton from '../../FormFields/SubmitButton';
import ScreenWrapper from '../../ScreenWrapper';

import validation from '../../../Config/validationRules';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../../Theme/styles';

import useController from '../../../Screens/Address/controller/addEditAddress';

import AddLabelForm from '../../../Screens/Address/AddLabelForm';

let thisFormInitialValues = {};
const validationSchema = validation.createSchema({
  label: validation.fieldRequired('Address label'),
});
const formDefaultValues = { label: null };

const AddEditAddress = ({ ...props }) => {
  const { onCancel, onDone } = props;
  const {
    state,
    isUpdate,
    autoCompleteRef,
    onSearchItemPressed,
    onSubmit,
    formRef,
    formInitialValues,
    askForUserLocation,
    onRegionChangeComplete,
  } = useController(props);

  const { selectedAddress, submitting } = state;

  const location = {
    lat: selectedAddress?.latitude,
    lng: selectedAddress?.longitude,
  };

  if (formInitialValues) {
    thisFormInitialValues = formInitialValues;
  } else {
    thisFormInitialValues = formDefaultValues;
  }

  useEffect(() => {
    askForUserLocation();
  }, []);

  const onSelectedAddress = async ({ place_id }) => {
    const { ok, data } = await otherApi.getAddressDetails(place_id);
    if (ok) {
      let convertToString = JSON.stringify(data); // need to convert first to string so we can get the raw value for lat and lng. if not it will read as constructor
      let result = JSON.parse(convertToString); // revert it to JSON again...
      onSearchItemPressed({ place_id: place_id }, result);
    }
  };

  return (
    <ScreenWrapper scroll={true}>
      <Layout style={[ThemeStyle.flex1]}>
        <Form
          innerRef={formRef}
          initialValues={thisFormInitialValues}
          validationSchema={validationSchema}
          onSubmit={async (...args) => {
            const center = {
              latitude: location.lat,
              longitude: location.lng,
            };
            onSubmit(center, ...args).finally(() => onDone());
          }}
        >
          <AddLabelForm onBackPress={onCancel} />

          <Divider style={ThemeStyle.divider} />

          <View style={ThemeStyle.flex2}>
            {/* Map Container */}

            <Layout style={ThemeStyle.spacingTopMedium}>
              <Text category="p1">Search address to show map</Text>
              {/* Search suggestion container */}
              <Layout style={styles.searchContainer}>
                <GooglePlaces_Autocomplete
                  testID="gpautocomplete"
                  ref={autoCompleteRef}
                  initialValue={selectedAddress?.address}
                  onSelected={onSelectedAddress}
                  style={styles.googleplace_autocomplete}
                />

                <Divider style={ThemeStyle.divider} />
              </Layout>
            </Layout>
            {!lodashIsEmpty(selectedAddress) ? (
              <View style={ThemeStyle.flex1}>
                <View style={styles.mapContainer}>
                  <MapView testID="mapView" region={location}>
                    <Marker
                      coordinate={location}
                      coordinateChanged={onRegionChangeComplete}
                    />
                  </MapView>
                </View>
                <Layout style={styles.submitButtonContainer}>
                  <SafeAreaView edges={['right', 'bottom']}>
                    <SubmitButton
                      size="giant"
                      style={[styles.saveIcon, ThemeStyle.shadow]}
                      customLoader
                    >
                      <View>
                        {submitting ? (
                          <ActivityIndicator
                            size="large"
                            color={ThemeColor.white}
                          />
                        ) : (
                          <Icon
                            name="save-outline"
                            fill={ThemeColor.white}
                            style={styles.saveIcon}
                          />
                        )}
                      </View>
                    </SubmitButton>
                  </SafeAreaView>
                </Layout>
              </View>
            ) : null}
          </View>
        </Form>
      </Layout>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  screenWrapper: {
    width: 400,
    padding: 10,
    alignSelf: 'center',
    height: 'auto',
  },
  searchContainer: {
    flex: 1,
    zIndex: 1,
    left: 0,
    right: 0,
  },
  saveIcon: {
    width: 30,
    height: 30,
  },
  modal_wrapper: {
    height: 600,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 5,
  },
  mapContainer: {
    marginTop: 20,
    borderRadius: 10,
    alignSelf: 'center',
    width: '100%',
    height: 400,
  },
  googleplace_autocomplete: {
    ...ThemeStyle.regular,
    ...ThemeStyle.spacingTopSmall,
    fontSize: 12,
  },
  submitButtonContainer: {
    position: 'absolute',
    right: globalSpacing,
    bottom: globalSpacing,
    backgroundColor: 'transparent',
  },
});

export { formDefaultValues };
export default AddEditAddress;
