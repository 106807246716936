import { useEffect, useRef, useState } from 'react';
import { Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { CANCEL_ERROR } from 'apisauce';
import {
  filter as lodashFilter,
  find as lodashFind,
  findIndex as lodashFindIndex,
  get as lodashGet,
  indexOf as lodashIndexOf,
  isEmpty as lodashIsEmpty,
  pick as lodashPick,
} from 'lodash';

import modals from '../../../Components/Sheets/modals';
import constants from '../../../Config/constants';
import messages from '../../../Config/messages';
import CartHelper from '../../../Helper/Cart';
import MenuItemHelper from '../../../Helper/MenuItem';
import Sentry from '../../../Helper/Sentry';
import useCancellableRequest from '../../../Hooks/useCancellableRequest';
import useStorePersist from '../../../Hooks/useStorePersist';
import useCart from '../../../Hooks/useCart';
import useMealPlan from '../../../Hooks/useMealPlan';
import { checkoutOrderTypeSelector } from '../../../RTK/checkout/selectors';
import { setProductData, removeProductData } from '../../../RTK/product';
import {
  productLoadingSelector,
  productSelector,
} from '../../../RTK/product/selectors';
import {
  shopLoadingSelector,
  shopRawSelector,
} from '../../../RTK/shop/selectors';
import { whenFilterSelector } from '../../../RTK/filter/selectors';
import Service from '../../../Service';
import productApi from '../../../Service/api/product';
import { specialInstructionDefaultValues } from '../SpecialInstruction';

const stocksInitial = {
  isLoading: true,
  categoryName: '',
  isCategoryStock: false,
  remainingStock: 0,
  stock: 0,
};

function useController(props) {
  /* hooks & redux */
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const isWhenFilterChanged = useRef(false);
  const { createRequest } = useCancellableRequest();
  const { getCartValidationInfo } = useStorePersist();
  const { cartData, addOrUpdateCart, getThisProductOnCart } = useCart();
  const { isMealPlan } = useMealPlan();

  const isProductLoading = useSelector(productLoadingSelector);
  const isStoreLoading = useSelector(shopLoadingSelector);
  const productData = useSelector(productSelector);
  const shopRawData = useSelector(shopRawSelector);
  const whenFilter = useSelector(whenFilterSelector);
  const selectedOrderType = useSelector((state) =>
    checkoutOrderTypeSelector(state, productData?.store?.id)
  );

  const params = lodashGet(props, 'params');

  /* states */
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [thisProductOnCart, setThisProductOnCart] = useState([]);
  const [stocks, setStocks] = useState(stocksInitial);
  const [extrasCoords] = useState([]);

  // mobile & web hooks for calling update stocks after when filter changed
  useEffect(() => {
    // added timeout, this will act as debounce since when unmount will remove the timeout
    const timeoutId = setTimeout(() => {
      // execute only when
      const baseCondition =
        constants.isWeb || // on web, just make the baseCondition to true because isMounted and isWhenFilterChanged variable logic is not applicable on web
        (isMounted.current && isWhenFilterChanged.current); // on mobile, if already mounted & when filter changed
      const mainCondition = isUpdate // if update
        ? !isProductLoading // we only need to check if product is not loading anymore and let the _getCartValidationInfo of useStorePersist hooks do the checking of when filter because shopRawData probably not available on other screen
        : !isStoreLoading && // store is not loading
          !isProductLoading && // product is not loading
          !lodashIsEmpty(shopRawData?.menu); // shop raw data is not empty
      // console.log(isStoreLoading, isProductLoading, isUpdate, shopRawData);
      if (baseCondition && mainCondition) {
        setStocks(stocksInitial);
        _updateStocks();
      }
    }, 500);
    return () => clearTimeout(timeoutId); // clear the timeout when hooks get unmount
  }, [isProductLoading, isStoreLoading, isUpdate, shopRawData]);

  if (!constants.isWeb) {
    // mobile hooks for handling the auto scroll to bottom when quantity changed and stocks is not loading
    useEffect(() => {
      const itemQuantity = productData?.quantity || 1;
      if (
        !stocks.isLoading &&
        (itemQuantity === stocks.remainingStock || stocks.remainingStock === 0)
      ) {
        // if itemQuantity is equal to remaining stock or remainingStock is 0, scroll to bottom
        // because the UI will get updated and change order schedule button will show
        // and might hide the quantity selector behind add to/update cart button
        setTimeout(() => {
          props.scrollHandlers.ref.current?.scrollToEnd?.(); // so we scroll it to bottom
          // added delay to scroll just to let the list initialize the reference for scrollHandlers
          // can't add props.scrollHandlers.ref.current to useEffect hooks dependency because it will be called multiple times
          // it should be called only when onload of add or update cart (IF condition above met) and clicked the edit button
        }, 500);
      }
    }, [productData?.quantity, stocks.isLoading]);

    // mobile hooks for when params changed
    useEffect(() => {
      if (isMounted.current) {
        // execute only when initial load is done
        _handleEditCart();
      }
    }, [params]);

    // mobile hooks for when whenFilter changed
    useEffect(() => {
      if (isMounted.current) {
        // execute only when initial load is done
        isWhenFilterChanged.current = true; // flag for when filter is changed
        _getProductData();
      }
    }, [whenFilter]);

    // mobile hooks for handling load the product data and when unmount clear the product data (must be at the end of all hooks)
    useEffect(() => {
      isMounted.current = true; // set flag that the initial load is done
      _getProductData();
      return () => {
        _clearProductData();
      };
    }, []);
  }

  /* methods */
  // reset state to its original state
  const _resetState = () => {
    setIsUpdate(false);
    setSubmitting(false);
    setSelectedExtras([]);
    setThisProductOnCart([]);
    setStocks(stocksInitial);
  };

  // this is called when user select or unselect extra (used for radio and checkbox)
  const _addOrRemoveExtras = (isMultiple, data) => {
    let savedExtras = JSON.parse(JSON.stringify(selectedExtras));
    let saveToState = true;

    const indexId = lodashIndexOf(savedExtras, data.id);
    if (isMultiple) {
      if (indexId === -1) {
        // not found, just push it
        savedExtras.push(data.id);
      } else {
        // found, remove it
        savedExtras.splice(indexId, 1);
      }
    } else {
      if (indexId === -1) {
        // not found
        // remove all check on this group
        const group = lodashFind(productData.extra_group, (eg) => {
          const groupIndex = lodashFindIndex(eg.data, { id: data.id });
          return groupIndex !== -1;
        });
        const egIds = group.data.map((eg) => eg.id);
        savedExtras = lodashFilter(savedExtras, (se) => !egIds.includes(se));
        // then push it
        savedExtras.push(data.id);
      } else {
        saveToState = false;
      }
    }

    if (saveToState) {
      setSelectedExtras(savedExtras);
    }
  };

  // for clearing product data (supposedly return as promise)
  const _clearProductData = () => dispatch(removeProductData());

  // for discounted item, to show compare price
  const _getItemOriginalPrice = () => {
    if (MenuItemHelper.hasDiscount(productData)) {
      const discountedTotal = _getItemTotalPrice();
      const quantity = productData.quantity || 1;
      const activeDiscountWithQuantity =
        Number(productData.active_discount) * quantity;
      return discountedTotal + activeDiscountWithQuantity;
    }
    return 0;
  };

  // for showing the total price of product, extras and quantity
  const _getItemTotalPrice = () => {
    const quantity = productData?.quantity || 1;
    const extras = JSON.parse(JSON.stringify(selectedExtras));
    const extrasTotalPrice = extras.reduce((acc, id) => {
      const group = lodashFind(productData?.extra_group, (eg) =>
        lodashFind(eg.data, { id })
      );
      const extraInfo = lodashFind(group?.data, (gd) => gd.id === id);
      return acc + (extraInfo?.price ? Number(extraInfo?.price) : 0);
    }, 0);

    return (Number(productData?.price) + extrasTotalPrice) * quantity;
  };

  // this should be called when component mount or component update if when filter change
  const _getProductData = async () => {
    _resetState();
    await _clearProductData();
    if (params.id) {
      const { ok, data, problem } = await createRequest(
        productApi.getDetails,
        params.id,
        whenFilter?.date,
        whenFilter?.time
      );
      // stop the code from going if request is cancelled
      if (problem === CANCEL_ERROR) {
        return;
      }
      if (ok) {
        const extraGroupRaw = JSON.parse(JSON.stringify(data.extra_group)); // to prevent mutation on the data object
        data.extra_group_raw = data.extra_group; // will use when adding to cart for proper sorting of selected extra ids
        data.extra_group = extraGroupRaw.sort(
          (a, b) => Number(b.is_required) - Number(a.is_required)
        ); // sort extra group to show first the required
        const otherData = lodashPick(params, ['is_exclusive']);
        const toBeSave = { ...data, ...otherData };
        if (constants.isWeb && isMealPlan) {
          // to disable the loading of quantity component
          await dispatch(setProductData(toBeSave));
          return setStocks({ ...stocksInitial, isLoading: false });
        }
        // need to pass toBeSave, because productData is not yet registered on redux when called _handleEditCart (in class base it work as expected because of await)
        _handleEditCart(toBeSave);
      } else {
        modals.hide(modals.PRODUCT);
        const msg = data?.message || problem || messages.COMMON_ERROR_MESSAGE;
        Sentry.reportError('Error getting product details', data);
        Alert.alert(
          messages.COMMON_ERROR_TITLE,
          Service.handleErrorMessage(msg)
        );
      }
    } else {
      modals.hide(modals.PRODUCT);
      Alert.alert('Please try again', 'Product ID is invalid.');
    }
  };

  // will be called after successfully fetched/update of product information or store data gets updated
  const _updateStocks = async (data) => {
    const {
      id: productId,
      store: { id: storeId },
    } = data || productData;
    const { error, itemInfo, storeInfo } = await getCartValidationInfo(
      storeId,
      productId,
      shopRawData
    );
    if (error) {
      // if error encounter during getCartValidationInfo
      return Alert.alert(
        messages.COMMON_ERROR_TITLE,
        Service.handleErrorMessage(error?.message)
      );
    }
    const result = MenuItemHelper.getItemStockConsideringCart(
      itemInfo,
      storeInfo,
      cartData
    );
    setStocks({ isLoading: false, ...result });
  };

  // for setting the default extra when edit product from cart
  const _getSelectedExtra = (item) => {
    const lodashFunction = item.maximum_number > 1 ? lodashFilter : lodashFind;

    return lodashFunction(productData?.extras, (e) => {
      return lodashFind(item.data, { id: e.id });
    });
  };

  // this should be called when component update and prev and new params is not equal
  const _handleEditCart = async (data) => {
    const pdata = data || productData;
    const storeId = pdata.store.id;
    const qty = params.quantity || pdata.quantity; // quantity is prio on params, because it means edit
    const thisProductOnCart = getThisProductOnCart(storeId, params.id);
    const toBeSave = { ...params, ...pdata, quantity: qty };
    // update state
    setIsUpdate(params.isUpdate || false);
    setSelectedExtras(params.extras?.map((e) => e.id) || []);
    setThisProductOnCart(thisProductOnCart);
    // clear and update product data on redux
    await _clearProductData();
    await dispatch(setProductData(toBeSave));
    if (!isWhenFilterChanged.current) {
      _updateStocks(toBeSave);
    }
  };

  // used for getting the extra of payload extra ids
  const _getPayloadExtras = (extrasId) => {
    const { extra_group_raw } = productData;
    const extras = [];
    extra_group_raw.forEach((eg) => {
      eg.data.forEach((e) => {
        if (extrasId.includes(e.id)) {
          extras.push(e);
        }
      });
    });
    return extras;
  };

  // when add to cart button press, must pass the scroll reference for validation purpose
  // it's ok to if no scrollReference pass
  const _onAddToCartPressed = async (scrollReference, onDone) => {
    const isOk = _validateExtraGroupSelection(scrollReference);

    if (isOk) {
      const { store } = productData;
      const extras = _getPayloadExtras(selectedExtras);
      const existingCartItem = CartHelper.getCartItem(
        store.id,
        productData.id,
        extras,
        productData.instructions || specialInstructionDefaultValues,
        cartData
      );
      const toBeAddedQty = isUpdate
        ? productData.quantity // if update, use only the quantity in productData
        : productData.quantity + (existingCartItem?.quantity || 0); // if not update, merged the quantity of existing item
      setSubmitting(true);
      addOrUpdateCart({
        from: 'Product Page',
        item: { ...productData, quantity: toBeAddedQty, extras },
        orderType: selectedOrderType,
        store,
        onCancel: () => setSubmitting(false),
        onError: (error) => {
          onDone?.({
            type: 'Error',
            title: error?.title,
            message: error?.message,
          });
          setSubmitting(false);
        },
        onSuccess: () => {
          onDone?.({ type: 'Success', title: 'Success', message: '' });
          setSubmitting(false);
          modals.hide(modals.PRODUCT);
        },
      });
    }
  };

  // for validating the extras (if extra is required or not) return boolean
  const _validateExtraGroupSelection = (reference) => {
    const requiredList = lodashFilter(productData.extra_group, {
      is_required: true,
    });
    let isEverythingGood = true;
    setErrorMessage('');
    for (let i = 0; i < requiredList.length; i++) {
      const group = requiredList[i];

      // check if selectedExtras is fulfilled with group id
      const filteredExtras = lodashFilter(selectedExtras, (e) => {
        const groupExtrasIds = group.data.map((ee) => ee.id);
        return groupExtrasIds.includes(e);
      });

      const isMultiple = group.maximum_number > 1;
      const isRange = group.minimum_number !== group.maximum_number; // !isRange is fixed extras should be the maximum
      let isNotYetResolve = filteredExtras.length === 0;
      let selectionRequired = 1;

      if (isMultiple) {
        if (isRange) {
          const val = filteredExtras.length;
          const min = group.minimum_number;
          const max = group.maximum_number;
          // extras must be 1 - n
          selectionRequired = `${group.minimum_number} - ${group.maximum_number}`;
          isNotYetResolve = !(val >= min && val <= max);
        } else {
          // extras must be 1, 2 or more
          selectionRequired = group.maximum_number;
          isNotYetResolve = filteredExtras.length !== group.maximum_number;
        }
      }

      // show alert if required extra group is not yet resolved
      if (isNotYetResolve) {
        isEverythingGood = false;
        const message = `Please Select ${selectionRequired} on ${group.name} category section`;

        ///scroll to the coordinates of required extras
        reference?.scrollTo?.({
          y: extrasCoords[i],
          animated: true,
        });

        setErrorMessage(`More Selections Required! ${message}`);
        Alert.alert('More Selections Required!', message);
        break;
      }
    }

    return isEverythingGood;
  };

  return {
    errorMessage,
    state: {
      extrasCoords,
      isUpdate,
      selectedExtras,
      stocks,
      submitting,
      thisProductOnCart,
    },
    addOrRemoveExtras: _addOrRemoveExtras,
    clearProductData: _clearProductData,
    getItemOriginalPrice: _getItemOriginalPrice,
    getItemTotalPrice: _getItemTotalPrice,
    getProductData: _getProductData,
    getSelectedExtra: _getSelectedExtra,
    handleEditCart: _handleEditCart,
    onAddToCartPressed: _onAddToCartPressed,
    setErrorMessage,
  };
}

export default useController;
