import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import { RectangleLoader } from '../../Components/Loader';
import ItemCardSmallLoader from '../../Components/Card/ItemSmallLoader';
import { numberToArray } from '../../Helper';
import ThemeStyle from '../../Theme/styles';

function ReviewItemLoader() {
  return (
    <View
      style={[ThemeStyle.pageVerticalSpacing, ThemeStyle.pageHorizontalSpacing]}
    >
      {/* Author Name */}
      <View style={ThemeStyle.spacingBottomMedium}>
        <RectangleLoader width={150} height={25} />
      </View>

      {/* RatingStar, Date rated, is verified order ? */}
      <View
        style={[
          ThemeStyle.flexDirectionRowCenter,
          ThemeStyle.flexWrap,
          ThemeStyle.spacingBottomSmall,
        ]}
      >
        {/* 5 Stars */}
        {numberToArray(5).map((_, i) => (
          <View key={i} style={ThemeStyle.spacingRightSmall}>
            <RectangleLoader width={15} height={15} />
          </View>
        ))}
        {/* Dot Separator */}
        <View style={ThemeStyle.spacingRightSmall}>
          <Text> · </Text>
        </View>
        {/* Date */}
        <View style={ThemeStyle.spacingRightSmall}>
          <RectangleLoader width={100} height={18} />
        </View>
        {/* Dot Separator */}
        <View style={ThemeStyle.spacingRightSmall}>
          <Text> · </Text>
        </View>
        {/* Verified Order */}
        <RectangleLoader width={100} height={18} />
      </View>

      {/* Rated Feedbacks */}
      <View
        style={[
          ThemeStyle.flexDirectionRowCenter,
          ThemeStyle.flexWrap,
          ThemeStyle.spacingBottomMedium,
        ]}
      >
        {/* 3 Generic feedbacks */}
        {numberToArray(3).map((_, i) => (
          <View key={i} style={ThemeStyle.spacingRightSmall}>
            <RectangleLoader width={100} height={27} />
          </View>
        ))}
      </View>

      <View style={ThemeStyle.spacingBottomMedium}>
        <RectangleLoader width={100} height={17} />
      </View>

      {/* Product Horizontal List */}
      <View style={ThemeStyle.flexDirectionRow}>
        {numberToArray(2).map((_, i) => (
          <View key={i} style={ThemeStyle.spacingRightMedium}>
            <ItemCardSmallLoader />
          </View>
        ))}
      </View>
    </View>
  );
}

export default ReviewItemLoader;
