import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import lodashIsEmpty from 'lodash/isEmpty';

import Toast from '../Components/Toast';
import constants from '../Config/constants';
import StoreHelper from '../Helper/Store';
import { checkout } from '../RTK/defaultValues';
import { setStoreId, updateCheckoutDetails } from '../RTK/checkout';
import { changeWhenFilter } from '../RTK/filter';
import { toggleDatePicker } from '../RTK/utility';
import messages from '../Config/messages';

function useHomeDatePicker() {
  const checkoutData = useSelector((state) => state.checkout.checkoutData);
  const checkoutFlagStoreId = useSelector(
    (state) => state.checkout.checkoutFlag.storeId
  );
  const whenFilter = useSelector((state) => state.filter.home.when);
  const showDatePicker = useSelector((state) => state.utility.showDatePicker);
  const dispatch = useDispatch();

  /**
   * Toggle date picker on homepage.
   * @param {string | object} evt - Button event object or String for specific condition
   * @param {String} storeId - (Optional) Store id for dispatching the selected time to checkout pre order date & time
   * @returns {Promise<void>} - A promise that resolves after dispatching the toggle action.
   */
  const _toggleDateTimePicker = async (evt, storeId) => {
    if (Boolean(storeId)) {
      await dispatch(setStoreId(storeId));
    }
    await dispatch(toggleDatePicker());
    if (typeof evt === 'string' && evt === 'cancel') {
      _onConfirm();
    }
  };

  const _onConfirm = async (date) => {
    let updatedWhenFilter;
    if (date) {
      await dispatch(toggleDatePicker());
      const dayjsObject = dayjs(date);
      updatedWhenFilter = {
        label: dayjsObject.format(constants.DATE_DISPLAY_FORMAT),
        value: dayjsObject.toISOString(),
        date: dayjsObject.format(constants.DBDATE_FORMAT),
        time: dayjsObject.format(constants.DBTIME_FORMAT),
      };
    }
    if (Boolean(checkoutFlagStoreId)) {
      // if has flag
      await dispatch(setStoreId()); // clear checkout store id flag it by passing nothing
      // get checkout data using the flag store id
      const storeIdCheckoutData = StoreHelper.getStoreCheckoutData(
        checkoutFlagStoreId,
        checkoutData
      );
      if (!lodashIsEmpty(storeIdCheckoutData)) {
        // if the store id has checkout data, update the order schedule using the selected date & time
        await dispatch(
          updateCheckoutDetails({
            store_id: checkoutFlagStoreId,
            keyToUpdate: checkout.keys.DELIVERY_SCHEDULE,
            keyValue: updatedWhenFilter,
          })
        );
      }
      if (whenFilter?.label !== updatedWhenFilter?.label) {
        // show toast message to notify user of the changes only if the filter is changed
        Toast.show(
          `${messages.CHANGE_SCHEDULE} ${updatedWhenFilter?.label || 'ASAP'}.`
        );
      }
    }
    await dispatch(changeWhenFilter(updatedWhenFilter));
  };

  return {
    showDatePicker,
    whenFilter,
    onConfirm: _onConfirm,
    toggleDateTimePicker: _toggleDateTimePicker,
  };
}

export default useHomeDatePicker;
