import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Icon } from '@ui-kitten/components';
import LottieView from 'lottie-react-native';

import lottieLiveJson from '../../Assets/lottie/live.json';
import { STATUS } from '../../Helper/Order';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import useOrderProgress from './index.controller';

const OrderProgress = (props) => {
  const { checkpoints, progress, getProgress } = useOrderProgress(props);

  return (
    <View style={styles.wrapper}>
      {/* Progress */}
      <View style={styles.progressContainer}>
        <View style={[styles.progress, { width: `${progress}%` }]} />
      </View>

      {/* Checkpoints */}
      <View style={styles.checkpointContainer}>
        {checkpoints.map((data, index) => {
          const isStatusCompleted = data.value === STATUS.COMPLETED;
          const cpPercent = getProgress(data);
          const isActive = progress === cpPercent && !isStatusCompleted;
          return (
            <View
              key={index}
              style={[
                styles.iconContainer,
                progress >= cpPercent && styles.cpReached,
              ]}
            >
              {isActive && (
                // if checkpoint is active, add lottie pulsate effect
                <View style={styles.itemOnGoingLottieContainer}>
                  <LottieView
                    source={lottieLiveJson}
                    style={styles.itemOnGoingLottie}
                    webStyle={styles.itemOnGoingLottie}
                    autoPlay
                    loop
                  />
                </View>
              )}
              <Icon
                name={data.icon}
                fill={ThemeColor.white}
                pack={data.iconPack}
                style={styles.icon}
              />
            </View>
          );
        })}
      </View>
    </View>
  );
};

const iconSize = 30;
const styles = StyleSheet.create({
  wrapper: {
    ...ThemeStyle.justifyContentCenter,
    height: iconSize,
  },
  progressContainer: {
    ...ThemeStyle.fitWidthContainer,
    backgroundColor: ThemeColor.gray,
  },
  progress: {
    height: 3,
    backgroundColor: ThemeColor.green,
    borderRadius: 10,
  },
  checkpointContainer: {
    ...ThemeStyle.flexDirectionRowSpaceBetween,
    position: 'absolute',
    width: '100%',
  },
  iconContainer: {
    ...ThemeStyle.alignItemsCenter,
    ...ThemeStyle.justifyContentCenter,
    width: iconSize,
    height: iconSize,
    borderRadius: iconSize / 2,
    backgroundColor: ThemeColor.gray,
  },
  cpReached: {
    backgroundColor: ThemeColor.green,
  },
  itemOnGoingLottieContainer: {
    position: 'absolute',
    top: -10,
  },
  itemOnGoingLottie: {
    width: iconSize + 20,
    height: iconSize + 20,
  },
  icon: {
    width: iconSize / 2,
    height: iconSize / 2,
  },
});

OrderProgress.propTypes = {
  value: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
};

export default OrderProgress;
