import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CANCEL_ERROR } from 'apisauce';
import lodashIndexOf from 'lodash/indexOf';

import Toast from '../Components/Toast';
import { toggleFavourite as reduxToggleFavourite } from '../RTK/favourite';
import userApi from '../Service/api/user';

import useCancellableRequest from './useCancellableRequest';
import useShouldCallApi from './useShouldCallApi';
import { constant } from 'lodash';
import constants from '../Config/constants';
import { useToast } from 'react-native-toast-notifications';

let shouldSetState = false; // flag for checking if component still mounted
function useFavorite() {
  const dispatch = useDispatch();
  const toast = useToast();
  const favourites = useSelector((state) => state.favourite.favouriteData);
  const shouldCallApi = useShouldCallApi();
  const { createRequest } = useCancellableRequest();
  const [disableFavourite, setDisableFavourite] = useState(false);

  useEffect(() => {
    shouldSetState = true;
    return () => {
      shouldSetState = false;
    };
  }, []);

  const isSavedToFavourite = (storeId) => {
    const result = lodashIndexOf(favourites, storeId);
    return result !== -1;
  };

  // prettier-ignore
  const toggleFavourite = ({ id }) => async () => {
    if (shouldSetState) {
      setDisableFavourite(true)
    }
    const isSaved = isSavedToFavourite(id)
    await showToastAndToggleFavoriteOnRedux(isSaved, id) // toggle it before api call so it show as saved store even still calling the api
    if (shouldCallApi) {
      const apiMethod = isSaved ? userApi.removeFromFavorite : userApi.addToFavorite
      const { ok, data, problem } = await createRequest(apiMethod, id)
      // stop the code from going if request is cancelled
      if (problem === CANCEL_ERROR) {
        return
      }
      if (!ok) {
        console.log(`error ${isSaved ? 'saving': 'removing'} favorite ==>`, data)
        await showToastAndToggleFavoriteOnRedux(isSaved, id, 'Failed to update your saved store please try again later.') // re-toggle it to go back to previous state because failed api call
      }
    } else {
      console.log('Guest/Social Account', 'save favourite to device')
    }
    if (shouldSetState) {
      setDisableFavourite(false)
    }
  }

  const showToastAndToggleFavoriteOnRedux = async (
    isSaved,
    storeId,
    errorMessage
  ) => {
    if (constants.isWeb) {
      toast.hideAll();
    }
    const savedMessage = 'Added to “My Favorites."';
    const removedMessage = 'Removed from "My Favorites."';
    if (errorMessage) {
      if (constants.isWeb) {
        toast.show(errorMessage, constants.ERROR_CONFIG);
      } else {
        Toast.show(errorMessage, Toast.TYPE_ERROR);
      }
    } else {
      if (constants.isWeb) {
        toast.show(
          !isSaved ? savedMessage : removedMessage,
          constants.SUCCESS_CONFIG
        );
      } else {
        Toast.show(
          !isSaved ? savedMessage : removedMessage,
          Toast.TYPE_SUCCESS
        );
      }
    }
    return dispatch(reduxToggleFavourite(storeId));
  };

  return {
    disableFavourite,
    isSavedToFavourite,
    toggleFavourite,
  };
}

export default useFavorite;
