import React from 'react';
import { Polyline as WebPolyline } from '@react-google-maps/api';

import Service from '../../Service';

const Polyline = ({ coordinates, ...props }) => {
  return <WebPolyline path={Service.toWebLatLng(coordinates)} {...props} />;
};

export default Polyline;
