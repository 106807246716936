import { useDispatch, useSelector } from 'react-redux';
import { CANCEL_ERROR } from 'apisauce';

import { updateInformation } from '../../../../RTK/user';

import Sentry from '../../../../Helper/Sentry';
import useCancellableRequest from '../../../../Hooks/useCancellableRequest';
import useToast from '../../../../Hooks/useToast';

import Service from '../../../../Service';
import userApi from '../../../../Service/api/user';
import { useNavigation } from '@react-navigation/native';

const useProfileController = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const toast = useToast();
  const { createRequest } = useCancellableRequest();
  const { user } = useSelector((state: any) => state.user);

  const _onSubmit = async (formData, { setSubmitting, setErrors }) => {
    if (!user.id) {
      toast.show(
        `Cannot update info\nUser ID is missing on profile data`,
        false
      );
      return;
    }
    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      phone_number: formData.phone,
    };
    const { ok, data, status, problem } = await createRequest(
      userApi.update,
      payload
    );
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    if (ok && data.success) {
      dispatch(updateInformation(formData));
      toast.show('Your profile has been successfully updated.');
      navigation.goBack();
    } else {
      Sentry.reportError('Error updating profile', data);
      if (status === 400) {
        setErrors(Service.handleFormError(data?.message));
      } else {
        setErrors({
          api: {
            message: Service.handleErrorMessage(data?.message),
          },
        });
      }
    }
    setSubmitting(false);
  };

  return { onSubmit: _onSubmit };
};

export default useProfileController;
