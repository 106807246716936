import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import { CircleLoader, RectangleLoader } from '../../Components/Loader';
import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';
import { ids, styles } from './styles';

const ItemOngoingLoader = () => {
  return (
    <View
      dataSet={{ media: ids.itemOnGoingContainer }}
      style={[styles.itemOnGoingContainer]}
    >
      <View style={[ThemeStyle.flex1, ThemeStyle.flexDirectionRow]}>
        {/* store image */}
        <RectangleLoader width={70} height={70} />
        <View style={[ThemeStyle.flex1, ThemeStyle.spacingLeft]}>
          {/* store name */}
          <View style={ThemeStyle.spacingBottomXSmall}>
            <RectangleLoader width="100%" height={20} />
          </View>
          {/* order status text */}
          <View style={ThemeStyle.spacingBottomXSmall}>
            <RectangleLoader width="100%" height={20} />
          </View>
          {/* order status text */}
          <View style={ThemeStyle.flexDirectionRowCenter}>
            {/* Order date */}
            <View style={ThemeStyle.flex1}>
              <RectangleLoader width="100%" height={20} />
            </View>
            <View style={ThemeStyle.rowSeparator} />
            {/* Order Number */}
            <View style={ThemeStyle.flex1}>
              <RectangleLoader width="100%" height={20} />
            </View>
          </View>
        </View>
      </View>
      <View
        dataSet={{ media: ids.itemOnGoing2ndContainer }}
        style={styles.itemOnGoing2ndContainer}
      >
        <View
          dataSet={{ media: ids.itemOnGoingProgressContainer }}
          style={styles.itemOnGoingProgressContainer}
        >
          {/* Progress */}
          <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
            <View style={{ position: 'absolute', width: '100%', zIndex: -1 }}>
              <RectangleLoader width="100%" height={3} />
            </View>
            <CircleLoader size={30} />
            <CircleLoader size={30} />
            <CircleLoader size={30} />
            <CircleLoader size={30} />
            <CircleLoader size={30} />
          </View>
        </View>
      </View>
    </View>
  );
};

export default ItemOngoingLoader;
