import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { Text } from '@ui-kitten/components';

import constants from '../../Config/constants';
import CartHelper from '../../Helper/Cart';
import MenuItemHelper from '../../Helper/MenuItem';
import Service from '../../Service';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

import Button from '../Button';
import Image, { resizeMode } from '../Image';
import QuantitySelectorSmall, { QtySizes } from '../QuantitySelector/Small';
import QuickCartQtyBadge from '../QuickCart/Badge';

const imageSize = 75;
const ellipsisSize = 50;

function CartItem(props) {
  const {
    active_discount,
    canReschedule,
    changingQty,
    extras,
    image,
    index,
    instructions,
    loading,
    name,
    price,
    quantity,
    sectionIndex,
    showQtyBadge,
    unavailable,
    wrapperStyle,
    onItemPress,
    onQtyChange,
    onRemovePress,
  } = props;
  const uniqueId = sectionIndex ? `${sectionIndex}-${index}` : '';
  const instructionsValue = instructions?.instructions;
  const extrasValue = extras?.map((e) => e.name).join(', ') || '';
  const totalPrice = CartHelper.getTotal({ price, quantity, extras });
  const hasDiscount = MenuItemHelper.hasDiscount(props);
  const Container = onItemPress ? Button : View;

  return (
    <View>
      {/* Unavailable Overlay */}
      {unavailable && (
        <View
          style={[
            ThemeStyle.flex1,
            ThemeStyle.zIndex2,
            StyleSheet.absoluteFillObject,
          ]}
        >
          <View
            style={[
              ThemeStyle.pageBackground,
              { opacity: 0.5, ...StyleSheet.absoluteFillObject },
            ]}
          />
        </View>
      )}

      {/* Content */}
      <Container
        testID={uniqueId ? `cartItemButton${uniqueId}` : ''}
        onPress={onItemPress}
        disabled={loading}
        plain
      >
        <View style={[styles.wrapper, wrapperStyle]}>
          <View
            style={[
              styles.itemImageAndQuantityContainer,
              !showQtyBadge && { paddingLeft: 0 },
            ]}
          >
            {/* Quantity Badge Above Image */}
            {showQtyBadge && (
              <View style={styles.itemQuantityContainer}>
                <QuickCartQtyBadge value={quantity} />
              </View>
            )}

            {/* Image */}
            <Image
              source={{ uri: image }}
              style={styles.itemImage}
              resizeMode={constants.isWeb ? 'cover' : resizeMode.cover}
            />
          </View>

          <View style={ThemeStyle.flex1}>
            <View
              style={[
                ThemeStyle.flex1,
                ThemeStyle.flexDirectionColumnSpaceBetween,
              ]}
            >
              {/* Name */}
              <Text
                category="p2"
                numberOfLines={2}
                style={ThemeStyle.spacingBottomSmall}
              >
                {name}
              </Text>

              {/* Extras Value (Comman separated) */}
              {!!extrasValue && (
                <Text
                  category="c1"
                  numberOfLines={2}
                  style={ThemeStyle.spacingBottomSmall}
                  testID={uniqueId ? `extrasValue${uniqueId}` : ''}
                >
                  {extrasValue}
                </Text>
              )}

              {/* Instructions */}
              {!!instructionsValue && (
                <Text
                  category="c1"
                  numberOfLines={2}
                  style={ThemeStyle.spacingBottomSmall}
                  testID={uniqueId ? `instructionsValue${uniqueId}` : ''}
                >
                  {instructionsValue}
                </Text>
              )}

              {unavailable && (
                <Text
                  status="danger"
                  category="c1"
                  style={ThemeStyle.spacingBottomSmall}
                >
                  Unavailable
                </Text>
              )}

              {!!onQtyChange && (
                <QuantitySelectorSmall
                  disabled={loading} // cart item is loading
                  disableIncrement={!canReschedule}
                  loading={changingQty}
                  value={quantity}
                  onRemove={onRemovePress}
                  onQtyChange={onQtyChange}
                />
              )}
            </View>
          </View>

          <View style={ThemeStyle.spacingLeftMedium}>
            <View style={styles.itemPriceAndEllipsisContainer}>
              <View>
                {hasDiscount && (
                  <Text category="p2" style={ThemeStyle.strikethrough}>
                    {!isNaN(totalPrice)
                      ? Service.commafyNumber(
                          totalPrice + Number(active_discount) * quantity,
                          true
                        )
                      : ''}
                  </Text>
                )}
                <Text
                  category="p2"
                  style={hasDiscount && ThemeStyle.noticeText}
                >
                  {!isNaN(totalPrice)
                    ? Service.commafyNumber(totalPrice, true)
                    : ''}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Container>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: [
    ThemeStyle.pageHorizontalSpacing,
    ThemeStyle.pageVerticalSpacingMedium,
    ThemeStyle.flexDirectionRow,
    { alignItems: 'flex-start' },
  ],
  itemImageAndQuantityContainer: {
    ...ThemeStyle.justifyContentCenter,
    ...ThemeStyle.spacingRightMedium,
    paddingLeft: globalSpacing / 1.5,
  },
  itemQuantityContainer: {
    position: 'absolute',
    zIndex: 1,
  },
  itemImage: {
    width: imageSize,
    height: imageSize,
    borderRadius: 5,
  },
  itemPriceAndEllipsisContainer: [
    ThemeStyle.flex1,
    ThemeStyle.flexDirectionColumnSpaceBetween,
    ThemeStyle.alignItemsEnd,
  ],
  itemEllipsisButton: {
    borderWidth: 1,
    borderRadius: 10,
  },
  itemRemoveButton: {
    padding: QtySizes.padding,
    backgroundColor: ThemeColor.lightRed,
    borderRadius: QtySizes.radius,
  },
  itemEllipsisIcon: {
    width: ellipsisSize,
    height: ellipsisSize / 2,
  },
});

CartItem.defaultProps = {
  showQtyBadge: true,
};

CartItem.propTypes = {
  canReschedule: PropTypes.bool,
  changingQty: PropTypes.bool,
  extras: PropTypes.array,
  image: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  instructions: PropTypes.object,
  name: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quantity: PropTypes.number.isRequired,
  sectionIndex: PropTypes.number,
  unavailable: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  onItemPress: PropTypes.func,
  onQtyChange: PropTypes.func,
  onRemovePress: PropTypes.func,
};

export { styles };
export default CartItem;
