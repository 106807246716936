import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isError: false,
  errorType: '',
  errorTitle: 'Network Error',
  errorMessage: 'Unable to Process Your Request',
};

export const error = createSlice({
  name: 'error',
  initialState,
  reducers: {
    errorState: (state, action) => {
      state.isError = action.payload.isError;
    },
    setErrorMessage: (state, action) => {
      state.errorType = action.payload.errorType;
      state.errorTitle = action.payload.errorTitle;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const { errorState, setErrorMessage } = error.actions;
export default error.reducer;
