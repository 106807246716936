import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Icon, Text, Spinner, useTheme } from '@ui-kitten/components';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import Button from '../Button';

const iconSize = constants.isWeb ? 20 : 30;

function Selector({ disabled, isMaxReached, loading, value, onChange }) {
  const disableMinus = disabled || value <= 1;
  const disablePlus = disabled || isMaxReached;
  const store_theme = useTheme();

  return (
    <View style={!constants.isWeb && ThemeStyle.pageVerticalSpacing}>
      <View style={ThemeStyle.flexDirectionRowCenter}>
        <Button
          testID="deductButton"
          onPress={onChange(-1)}
          style={styles.button}
          disabled={disableMinus}
          plain
        >
          <Icon
            name="minus"
            fill={store_theme['icon_color'] || ThemeColor.green}
            style={styles.buttonIcon}
          />
        </Button>

        <View
          style={[
            ThemeStyle.pageHorizontalSpacing,
            ThemeStyle.alignItemsCenter,
          ]}
        >
          {/* Loader */}
          {loading && (
            <View style={styles.loaderContainer}>
              <Spinner size="giant" />
            </View>
          )}

          {/* Quantity Value */}
          <Text category={constants.isWeb ? 'p2' : 'h6'}>{value}</Text>
        </View>

        <Button
          testID="addButton"
          onPress={onChange(1)}
          style={styles.button}
          disabled={disablePlus}
          plain
        >
          <Icon
            name="plus"
            fill={store_theme['icon_color'] || ThemeColor.green}
            style={styles.buttonIcon}
          />
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    ...ThemeStyle.pageVerticalSpacingMedium,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    backgroundColor: ThemeColor.lightGray,
    borderRadius: 50,
    zIndex: 1,
  },
  buttonIcon: {
    width: iconSize,
    height: iconSize,
  },
  loaderContainer: {
    position: 'absolute',
    top: constants.isAndroid ? -3 : -5,
  },
});

Selector.propTypes = {
  disabled: PropTypes.bool,
  isMaxReached: PropTypes.bool,
  loading: PropTypes.bool,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Selector;
