import React from 'react';
import { Linking, View } from 'react-native';
import { Divider } from '@ui-kitten/components';

import ListItem from '../../Components/ListItem';
import ScreenWrapper from '../../Components/ScreenWrapperWithTitleAnimation';
import externalLinks from '../../Config/externalLinks';
import routeList from '../../Routes/list';
import ThemeStyle from '../../Theme/styles';

class Legal extends React.Component {
  render() {
    const { navigation } = this.props;

    return (
      <ScreenWrapper title="Legal">
        <View style={ThemeStyle.pageHorizontalSpacing}>
          <ListItem
            testID="ppButton"
            title="Privacy Policy"
            onPress={() => {
              // navigation.navigate(routeList.LGEAL_BINDING_CONTRACT, {
              //   title: 'Privacy Policy',
              //   key: 'privacyPolicy',
              // })
              Linking.openURL(externalLinks.PRIVACY_POLICY);
            }}
          />

          <Divider style={ThemeStyle.divider} />

          <ListItem
            testID="tncButton"
            title="Terms And Conditions"
            onPress={() =>
              navigation.navigate(routeList.LGEAL_BINDING_CONTRACT, {
                title: 'Terms And Conditions',
                key: 'termsAndConditions',
              })
            }
          />
        </View>
      </ScreenWrapper>
    );
  }
}

export default Legal;
