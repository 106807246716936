import React from 'react';
import { View } from 'react-native';
import ActionSheet from 'react-native-actions-sheet';
import { useNavigation } from '@react-navigation/native';

import Button from '../../Components/Button';
import PublicReviewNotice from '../../Screens/StoreReviews/PublicReviewNotice';
import routeList from '../../Routes/list';
import ThemeStyle from '../../Theme/styles';

import commonProps from './commonProps';
import modals from './modals';
import styles from './styles';

function PublicReviewSheet({ sheetId }) {
  const navigation = useNavigation();

  const _onGotIt = () => modals.hide(modals.PUBLIC_REVIEW);

  const _onReviewGuidelines = async () => {
    await _onGotIt();
    navigation.navigate(routeList.LEGAL_BINDING_CONTRACT, {
      title: 'Review Guidelines',
      key: 'reviewGuidelines',
    });
  };

  return (
    <ActionSheet id={sheetId} {...commonProps}>
      <View style={styles.contentContainer}>
        <PublicReviewNotice />

        <Button
          status="success"
          style={ThemeStyle.spacingBottomMedium}
          onPress={_onGotIt}
        >
          Got it
        </Button>

        <Button status="control" onPress={_onReviewGuidelines}>
          View Review Guidelines
        </Button>
      </View>
    </ActionSheet>
  );
}

export default PublicReviewSheet;
