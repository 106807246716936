import React, { Fragment } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ToastProvider } from 'react-native-toast-notifications';
import { Icon } from '@ui-kitten/components';

import { errorState } from '../RTK/error';

import ThemeStyles from '../Theme/styles';
import ThemeColors from '../Theme/colors';

import ErrorHandler from '../Components/ErrorHandler';
import { ModalProvider } from '../Components/Web/Modal/hooks/provider';

import '../Components/Web/Modal/ModalPrompt';
import './index.css';

const BaseWebNavigator = ({ children }) => {
  const { isError } = useSelector((state: any) => state.error);
  const dispatch = useDispatch();
  return (
    <Fragment>
      <ToastProvider
        placement="top"
        dangerIcon={
          <Icon
            name={'close'}
            fill={ThemeColors.white}
            pack="fa"
            style={styles.icon_size}
          />
        }
        successIcon={
          <Icon
            name={'check-circle'}
            fill={ThemeColors.white}
            pack="fa"
            style={styles.icon_size}
          />
        }
        offset={10}
        dangerColor={ThemeColors.red}
        successColor={ThemeColors.green}
        // Custom type example
        renderType={{
          custom_toast: (toast) => (
            <View style={styles.custom_toast}>
              <Text style={[ThemeStyles.bold]}>{toast.data.title}</Text>
              <Text style={[ThemeStyles.bold]}>{toast.message}</Text>
            </View>
          ),
          with_close_button: (toast) => (
            <View style={styles.with_close_button_container}>
              <Text style={styles.close_button_message}>{toast.message}</Text>
              <TouchableOpacity
                onPress={() => toast.onHide()}
                style={styles.close_button}
              >
                <Text style={styles.close_button_icon}>x</Text>
              </TouchableOpacity>
            </View>
          ),
        }}
      >
        <ModalProvider>{children}</ModalProvider>
        <ErrorHandler
          isOpen={isError}
          onClosed={() => dispatch(errorState({ isError: false }))}
        />
      </ToastProvider>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  icon_size: {
    width: 20,
    height: 20,
  },
  custom_toast: {
    maxWidth: '85%',
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: '#fff',
    marginVertical: 4,
    borderRadius: 8,
    borderLeftColor: '#00C851',
    borderLeftWidth: 6,
    justifyContent: 'center',
    paddingLeft: 16,
    zIndex: 999999,
  },
  with_close_button_container: {
    maxWidth: '85%',
    paddingVertical: 10,
    backgroundColor: '#fff',
    marginVertical: 4,
    borderRadius: 8,
    borderLeftColor: '#00C851',
    borderLeftWidth: 6,
    justifyContent: 'center',
    paddingHorizontal: 16,
    flexDirection: 'row',
  },
  close_button: {
    marginLeft: 'auto',
    width: 25,
    height: 25,
    borderRadius: 5,
    backgroundColor: '#333',
    justifyContent: 'center',
    alignItems: 'center',
  },
  close_button_message: { color: '#a3a3a3', marginRight: 16 },
  close_button_icon: { color: '#fff', fontWeight: '500', marginBottom: 2.5 },
});

export default BaseWebNavigator;
