import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  DrawerContentScrollView,
  useDrawerProgress,
} from '@react-navigation/drawer';
import Animated from 'react-native-reanimated';
import PropTypes from 'prop-types';

import ThemeStyle from '../Theme/styles';

import CloseButton from './Web/CloseButton';

const RightDrawerContent = ({
  title,
  navigation,
  contentContainerStyle,
  children,
}) => {
  const progress = useDrawerProgress();
  const toggleDrawer = () => navigation.getParent('RightDrawer').closeDrawer();

  const translateX = Animated.interpolateNode(progress, {
    inputRange: [0, 1],
    outputRange: [0, 0],
  });

  return (
    <Animated.View style={[{ transform: [{ translateX }] }, styles.container]}>
      <View style={styles.closeButtonContainer}>
        <CloseButton onPress={toggleDrawer} />
      </View>

      <DrawerContentScrollView
        style={styles.drawer_content_scrollview}
        contentContainerStyle={contentContainerStyle}
      >
        {children}
      </DrawerContentScrollView>
    </Animated.View>
  );
};
RightDrawerContent.propTypes = {
  //string to be displayed at the top of drawer.
  title: PropTypes.string,

  //data to be rendered in drawer component.
  data: PropTypes.array,

  //props for navigation provided by react-navigation.
  navigation: PropTypes.any,

  //it allow to render element body inside the drawer component.
  children: PropTypes.element,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  closeButtonContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    ...ThemeStyle.spacingTopMedium,
    ...ThemeStyle.pageHorizontalSpacing,
  },
  drawer_content_scrollview: {
    paddingLeft: '5%',
  },
});

export default RightDrawerContent;
