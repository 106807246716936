import { StyleSheet } from 'react-native';

import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';

export const iconSize = constants.isWeb ? 20 : 25;
export const buttonSize = iconSize * 1.5;
export default StyleSheet.create({
  wrapper: {
    position: 'absolute',
    zIndex: 1,
    bottom: 10,
    right: 10,
  },
  container: {
    ...ThemeStyle.shadow,
    borderRadius: buttonSize,
  },
  button: {
    ...ThemeStyle.pageBackground,
    ...ThemeStyle.flexDirectionRowCenterCenter,
    width: buttonSize,
    height: buttonSize,
    borderRadius: buttonSize / 2,
  },
  icon: {
    width: iconSize,
    height: iconSize,
  },
});
