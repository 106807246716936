import React, { useEffect, useRef, useState } from 'react';
import lodashDebounce from 'lodash/debounce';
import lodashIndexOf from 'lodash/indexOf';

import FeedbackItem from './FeedbackItem';
import constants from '../../Config/constants';

const ratingToShowFeedback = 3.5;
function useController(props) {
  const { disabled, feedbacks, starValue, defaultFeedbacks, onFeedbackChange } =
    props;
  const [custom, ...generic] = defaultFeedbacks || [];

  const [customFeedback, setCustomFeedback] = useState(custom);
  const [customFeedbackEnchancement, setCustomFeedbackEnchancement] =
    useState(custom);
  const [selectedFeedback, setSelectedFeedback] = useState(generic || []);

  useEffect(() => {
    if (constants.isWeb && defaultFeedbacks?.length > 0) {
      setCustomFeedback(custom);
      setSelectedFeedback(generic);
    }
  }, [defaultFeedbacks]);
  // added sideeffect here because this should be have same on both web and mobile
  // NOTE - don't add side effect same functionality as this when using this controller
  useEffect(() => {
    // this is for resetting the selectedFeedback when starValue is reduce to below ratingToShowFeedback
    // and if user has selectedFeedback
    if (starValue < ratingToShowFeedback) {
      if (selectedFeedback.length !== 0) {
        _updateSelectedFeedback([]);
      }
    }
  }, [starValue]);

  const _updateSelectedFeedback = (value) => {
    setSelectedFeedback(value); // update local state
    onFeedbackChange([customFeedback ? customFeedback : '', ...value]); // call callback
  };

  const _debouncedCallback = useRef(
    lodashDebounce((val) => onFeedbackChange(val), 1000)
  ).current;
  const _onCustomFeedbackChanged = (value) => {
    setCustomFeedback(value);
    _debouncedCallback([value, ...selectedFeedback]);
  };
  const _onCustomFeedBackForEnhancement = (value) => {
    setCustomFeedbackEnchancement(value);
    _debouncedCallback([value, ...selectedFeedback]);
  };

  const _onFeedbackPressed = (feedback) => () => {
    const index = lodashIndexOf(selectedFeedback, feedback);
    if (!disabled && index === -1) {
      // if not yet selected, save the feedback to state
      _updateSelectedFeedback([...selectedFeedback, feedback]);
    } else if (!disabled) {
      // if already selected, filter out the feedback user selected
      _updateSelectedFeedback(selectedFeedback.filter((e) => e != feedback));
    }
  };

  // added it here because web and mobile is same code, remove it here when mobile and web become different
  const _renderFeedbacks = () => {
    if (starValue < ratingToShowFeedback) {
      return null;
    }

    return feedbacks?.map((feedback, i) => (
      <FeedbackItem
        index={i}
        key={i}
        selected={selectedFeedback.includes(feedback)}
        onFeedbackPress={_onFeedbackPressed}
        value={feedback}
      />
    ));
  };

  return {
    state: {
      customFeedback,
      customFeedbackEnchancement,
      selectedFeedback,
    },
    onCustomFeedbackChanged: _onCustomFeedbackChanged,
    onCustomFeedBackForEnhancement: _onCustomFeedBackForEnhancement,
    onFeedbackPressed: _onFeedbackPressed,
    renderFeedbacks: _renderFeedbacks,
  };
}

export default useController;
