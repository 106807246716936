import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import Button from '../Button';

class ListHeading extends React.PureComponent {
  render() {
    const { title, seeAllText, onPress, noHPadding } = this.props;

    return (
      <View
        style={[
          !noHPadding && ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.spacingBottomSmall,
          ThemeStyle.flexDirectionRowCenterSpaceBetween,
        ]}
      >
        <Text
          style={ThemeStyle.flex1}
          {...(constants.isWeb ? { category: 's1' } : SECTION_TITLE)}
        >
          {title}
        </Text>

        {onPress && (
          <Button
            testID="headingButton"
            onPress={onPress}
            style={[
              styles.buttonContainer,
              constants.isWeb && ThemeStyle.spacingBottomSmall,
            ]}
            plain
          >
            <View style={styles.forwardContainer}>
              {seeAllText ? (
                <Text
                  category="c1"
                  style={{
                    paddingVertical: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                  }}
                >
                  {seeAllText}
                </Text>
              ) : (
                <Icon
                  name="arrow-forward"
                  fill={ThemeColor.green}
                  style={styles.forwardIcon}
                />
              )}
            </View>
          </Button>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  forwardContainer: {
    backgroundColor: ThemeColor.lightGray,
    padding: 5,
    borderRadius: 20,
  },
  buttonContainer: {
    marginLeft: globalSpacing,
  },
  forwardIcon: {
    width: 20,
    height: 20,
  },
});

ListHeading.propTypes = {
  title: PropTypes.string,
  seeAllText: PropTypes.string,
  noHPadding: PropTypes.bool,
  onPress: PropTypes.func,
};

export default ListHeading;
