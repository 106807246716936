import StyleSheet from 'react-native-media-query';

import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';
import breakpoints from '../../Theme/styles/breakpoints';

const { ids, styles } = StyleSheet.create({
  // wrapper of each section
  wrapper: constants.isWeb
    ? {
        marginHorizontal: 50,
        [`@media ${breakpoints.xs}`]: {
          marginHorizontal: 0,
        },
      }
    : {},
  dividerContainer: {
    marginHorizontal: constants.isWeb ? -50 : 0,
    [`@media ${breakpoints.xs}`]: {
      marginHorizontal: 0,
    },
  },
  menuContainer: constants.isWeb
    ? {
        ...ThemeStyle.flex1,
        ...ThemeStyle.flexDirectionRow,
        ...ThemeStyle.flexWrap,
        marginRight: -20,
        [`@media ${breakpoints.xs}`]: {
          marginRight: 0,
        },
      }
    : { flexDirection: 'column' },
  menuItemContainer: {
    ...ThemeStyle.spacingRight,
    flexGrow: 1,
    flexBasis: '47%',
  },
});

export { ids, styles };
