import React from 'react';
import { View } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';

import ThemeColor from '../../Theme/colors';

import styles from './styles';

type Props = {
  size?: number;
  textSize?: string;
  value: number;
};
function QuickCartQtyBadge({ size = 30, textSize = 'p2', value }: Props) {
  const store_theme = useTheme();
  return (
    <View
      style={[
        styles.button,
        { backgroundColor: store_theme['icon_color'] || ThemeColor.green },
        { width: size, height: size, borderRadius: size / 2 },
      ]}
    >
      <Text category={textSize} appearance="alternative" adjustsFontSizeToFit>
        {value}&times;
      </Text>
    </View>
  );
}

export default QuickCartQtyBadge;
