import lodashFind from 'lodash/find';

import ThemeColor from '../../Theme/colors';

import { CATEGORIES } from './constants';

const colorOpacity = '40'; // 25%, hex value

const colors = [
  {
    category: CATEGORIES.BASIC,
    solid: ThemeColor.lightGray,
    semioutline: `${ThemeColor.gray}${colorOpacity}`,
  },
  {
    category: CATEGORIES.PRIMARY,
    solid: ThemeColor.violet,
    semioutline: `${ThemeColor.violet}${colorOpacity}`,
  },
  {
    category: CATEGORIES.SUCCESS,
    solid: ThemeColor.green,
    semioutline: `${ThemeColor.green}${colorOpacity}`,
  },
  {
    category: CATEGORIES.INFO,
    solid: ThemeColor.blue,
    semioutline: `${ThemeColor.blue}${colorOpacity}`,
  },
  {
    category: CATEGORIES.WARNING,
    solid: ThemeColor.yellow,
    semioutline: `${ThemeColor.yellow}${colorOpacity}`,
  },
  {
    category: CATEGORIES.DANGER,
    solid: ThemeColor.red,
    semioutline: `${ThemeColor.red}${colorOpacity}`,
  },
];

export default function getBackgroundColor(category, type) {
  const selectedColor = lodashFind(colors, { category });
  return selectedColor[type] || 'transparent';
}
