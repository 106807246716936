import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import modals from '../Components/Sheets/modals';
import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

import Button from './Button';

const iconSize = 20;

class Dropdown extends React.PureComponent {
  _getValueObject = () =>
    lodashFind(this.props.options, { value: this.props.value });

  _toggleDropdownState = async () => {
    const { optionLabel, options, value, onChange } = this.props;
    const result = await modals.show(modals.DROPDOWN, {
      title: optionLabel,
      value: this._getValueObject(),
      options,
    });
    if (!lodashIsEmpty(result)) {
      onChange(result);
    }
  };

  render() {
    const { label, children, value } = this.props;
    const displayValue = this._getValueObject();

    return (
      <Fragment>
        {!!label && (
          <Text category="s1" style={ThemeStyle.spacingBottomSmall}>
            {label}
          </Text>
        )}

        {children ? (
          <Button
            testID="dropdownButton"
            onPress={this._toggleDropdownState}
            plain
          >
            {children}
          </Button>
        ) : (
          <Button
            testID="dropdownButton"
            style={styles.button}
            onPress={this._toggleDropdownState}
            plain
          >
            <Text>{displayValue?.label || value}</Text>
            <Icon
              name="arrow-ios-downward-outline"
              fill={ThemeColor.black}
              style={styles.buttonIcon}
            />
          </Button>
        )}
      </Fragment>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    ...ThemeStyle.pageVerticalSpacingMedium,
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.flexDirectionRowCenterSpaceBetween,
    borderRadius: 5,
    backgroundColor: ThemeColor.lightGray,
    borderWidth: 1,
  },
  buttonIcon: {
    width: iconSize,
    height: iconSize,
  },
});

const valuePropTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]).isRequired;

Dropdown.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  value: valuePropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: valuePropTypes,
    }).isRequired
  ).isRequired,
  optionLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Dropdown;
