import React from 'react';
import { View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import constants from '../../Config/constants';

function ErrorPromptBelowInput({ absolute, text }) {
  if (!text) {
    return null;
  }

  return (
    <View style={absolute && { position: 'absolute', top: '102.5%' }}>
      <View
        style={[
          ThemeStyle.flexDirectionRowCenter,
          !absolute && ThemeStyle.spacingTopMedium,
        ]}
      >
        <View style={ThemeStyle.spacingRightSmall}>
          <Icon
            name="alert-circle"
            fill={ThemeColor.red}
            style={{ width: 15, height: 15 }}
          />
        </View>

        <Text
          category="c1"
          style={[!absolute && ThemeStyle.flex1, ThemeStyle.noticeText]}
        >
          {text}
        </Text>
      </View>
    </View>
  );
}

export default ErrorPromptBelowInput;
