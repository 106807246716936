import React, { Fragment } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';

import BreakPoints from '../../../../Theme/styles/breakpoints';

import ModalPrompt from './ModalPrompt';

import Button from '../../../../Components/Button';
import ThemeStyle from '../../../../Theme/styles';
import pkg from '../../../../../app.json';

import PublicReviewContent from '../../../../Screens/StoreReviews/PublicReviewNotice';

import useModalPrompt from './hooks/useModalPrompt';

const PublicReviewsNotice = ({ modalId }) => {
  const { hideModalPrompt } = useModalPrompt();
  const _onClose = () => {
    hideModalPrompt(modalId, {});
  };
  return (
    <ModalPrompt
      id={modalId}
      title={`Public reviews on ${pkg.displayName}`}
      dataSet={{ media: ids.reviewPopupModal }}
      mainContentWrapperStyle={styles.reviewPopupModal}
      childContentContainerStyle={{
        paddingVertical: 10,
      }}
      headerStyle={{ marginBottom: 0 }}
      hasHeader
      hasHeaderLine
      closeOnDocumentClick={false}
      scrollEnabled={false}
    >
      <Fragment>
        <PublicReviewContent />
        <View style={ThemeStyle.flexDirectionRowEnd}>
          <Button status="success" onPress={_onClose}>
            Got it
          </Button>
        </View>
      </Fragment>
    </ModalPrompt>
  );
};

const { ids, styles } = StyleSheet.create({
  reviewPopupModal: {
    [`@media ${BreakPoints.xs}`]: {
      width: 330,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 360,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 390,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 410,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 430,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 450,
    },
  },
});

export default PublicReviewsNotice;
