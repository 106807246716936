import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashPick from 'lodash/pick';

import ThemeStyle from '../../Theme/styles';

import { RectangleLoader } from '../Loader';

import styles from './styles';

function ItemSmallLoader() {
  return (
    <View style={styles.itemSmallContainer}>
      <View style={ThemeStyle.flex1}>
        {/* Name */}
        <View style={ThemeStyle.spacingBottomXSmall}>
          <RectangleLoader width="100%" height={19} />
        </View>
        {/* Description */}
        <View style={ThemeStyle.spacingBottomXSmall}>
          <RectangleLoader width="100%" height={19} />
        </View>
        {/* Price */}
        <RectangleLoader width="100%" height={19} />
      </View>
      {/* Image */}
      <View style={ThemeStyle.spacingLeftSmall}>
        <RectangleLoader
          width={styles.itemSmallImage.width}
          height={styles.itemSmallImage.height}
        />
      </View>
    </View>
  );
}

export default ItemSmallLoader;
