import React from 'react';

import useStoreController from './index';

// this is for hooks can be use on class base component
export default function (Component) {
  return (props) => {
    const controller = useStoreController(props);
    return <Component {...props} controller={controller} />;
  };
}
