import { baseApi } from '../baseApi';

export const authenticationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (body) => ({
        url: `user/login`,
        method: 'POST',
        body: body,
      }),
    }),
    logout: builder.mutation<any, null>({
      query: () => ({
        url: `user/logout`,
        method: 'POST',
        headers: { 'x-api-version': '1' },
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authenticationApi;
