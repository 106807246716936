import { useEffect, useRef, useState } from 'react';
import { Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useNavigation } from '@react-navigation/native';
import lodashFilter from 'lodash/filter';
import lodashFindIndex from 'lodash/findIndex';
import lodashIsEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { RAZZLE_BUILD_MODE } from '@env';

import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';
import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import constants from '../../../Config/constants';
import messages from '../../../Config/messages';
import { pluralize } from '../../../Helper';
import CartHelper from '../../../Helper/Cart';
import DateHelper from '../../../Helper/Date';
import { getCartTerm } from '../../../Helper/RemoteConfig';
import Sentry from '../../../Helper/Sentry';
import StoreHelper from '../../../Helper/Store';
import useCart from '../../../Hooks/useCart';
import usePromoCart from '../../../Hooks/usePromoCart';
import useSetCheckoutStoreInfo from '../../../Hooks/useSetCheckoutStoreInfo';
import { updateCheckoutDetails } from '../../../RTK/checkout';
import {
  checkoutOrderTypeSelector,
  storeCheckoutSelector,
} from '../../../RTK/checkout/selectors';
import { checkout } from '../../../RTK/defaultValues';
import { whenFilterSelector } from '../../../RTK/filter/selectors';
import { successfulOrderSelector } from '../../../RTK/utility/selectors';
import routeList from '../../../Routes/list';
import storeApi from '../../../Service/api/store';
import userApi from '../../../Service/api/user';
import { updateLastValidateTime } from '../../../RTK/cart';

const defaultFn = () => {};

function useStoreCartService({
  storeData = {}, // store cart data
  onProceedToOrderPress = defaultFn,
  onReschedule = defaultFn,
}) {
  const { date_updated, items, status, store_id, store_name } = storeData;

  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const navigation = useNavigation();
  const swipeRowRef = useRef();
  const { addOrUpdateCart, isCanReschedule, removeThisProductOnCart } =
    useCart();
  const { showModalPrompt } = useModalPrompt();
  const {
    promotion,
    promotionMinimum,
    storePromo,
    onPromoRemove,
    refetchPromo,
  } = usePromoCart({
    id: store_id,
    fetchPromo: true,
  });
  const setCheckoutStoreInfo = useSetCheckoutStoreInfo();
  const selectedOrderType = useSelector((state) =>
    checkoutOrderTypeSelector(state, store_id)
  );
  const storeCheckoutData = useSelector((state) =>
    storeCheckoutSelector(state, store_id)
  );
  const whenFilter = useSelector(whenFilterSelector);
  const successfulOrder = useSelector(successfulOrderSelector);

  const [changingQtyIds, setChangingQtyIds] = useState([]);
  const [removingItemIds, setRemovingItemIds] = useState([]);

  const preOrderDate =
    storeCheckoutData?.[checkout.keys.DELIVERY_SCHEDULE] || whenFilter; // checkout pre order date prio
  const isDelivery = selectedOrderType === 'delivery';
  const preOrderDateD = preOrderDate?.date ? preOrderDate.date : undefined;
  const preOrderDateT = preOrderDate?.time ? preOrderDate.time : undefined;
  const continueToPaymentData =
    storeCheckoutData[checkout.keys.CONTINUE_TO_PAYMENT];
  const canContinueToPayment = !lodashIsEmpty(continueToPaymentData);

  const { total, subTotal } = CartHelper.getBreakdown({
    isDelivery,
    promotion,
    cart: items,
  });
  const isLessThanMinimum = subTotal < promotionMinimum;

  // for cart availability
  const shouldValidate = DateHelper.isOlderThanNow({
    amount: 5,
    time: date_updated,
    unit: 'minutes',
  });
  const {
    data: availabilityData,
    error: availabilityError,
    isFetching: isAvailabilityLoading,
    refetch: _refetchAvailability,
  } = useQuery({
    queryKey: [store_id, preOrderDateD, preOrderDateT],
    queryFn: () => {
      if (RAZZLE_BUILD_MODE === 'branded') {
        return Promise.resolve({
          valid: true,
          unavailable_items: [],
        });
      } else {
        return userApi.validateCart({
          store_id,
          order_date: preOrderDateD,
          order_time: preOrderDateT,
        });
      }
    },
    enabled: !!store_id && shouldValidate,
    refetchOnWindowFocus: false,
    onSettled: () => dispatch(updateLastValidateTime(store_id)),
  });
  const availResult = availabilityData?.ok ? availabilityData?.data : {};
  const unavailableItemIds = availResult?.unavailable_items || [];
  const isStoreCartValid = unavailableItemIds.length === 0;
  const unavailableItem = lodashFilter(items, ({ item_id }) =>
    unavailableItemIds.includes(item_id)
  );

  const isPaymentPending = StoreHelper.isPaymentPending({
    status,
    storeId: store_id,
    successfulOrder,
  });

  // for store info
  const {
    data: storeInfoData,
    error: storeInfoError,
    isFetching: isStoreInfoLoading,
    refetch: _refetchStoreInfo,
  } = useQuery({
    queryKey: [store_id],
    queryFn: () => storeApi.getDetails(store_id),
    enabled: !!store_id,
    refetchOnWindowFocus: false,
  });
  const storeInformation = storeInfoData?.data;

  // variable rely to above data
  const otherElementLoading = isAvailabilityLoading || isStoreInfoLoading; // if availability & store info useQuery is loading
  const buttonLoading =
    otherElementLoading ||
    !lodashIsEmpty(removingItemIds) || // if user is removing item from cart
    !lodashIsEmpty(changingQtyIds); // if user is changing quantity of some item

  // this hooks is for handling refetch of cart availability when order date changed (not onload)
  useEffect(() => {
    if (isMounted.current) {
      _refetchAvailability();
    }
  }, [preOrderDateD, preOrderDateT]);

  // this hooks is only for changing the isMounted.current to true for upper hooks condition, needs to be at the last of useEffect
  useEffect(() => {
    isMounted.current = true;
  }, []);

  // _pushId & _removeId used for pushing and removing item ids
  const _pushId = (id, updateState) => updateState((prev) => [...prev, id]);
  const _removeId = (id, updateState) => {
    updateState((prev) => {
      const copiedPrev = JSON.parse(JSON.stringify(prev)); // need to do this in order to reflect the changes to state when doing splice
      const idIndex = lodashFindIndex(copiedPrev, id);
      copiedPrev.splice(idIndex, 1);
      return copiedPrev;
    });
  };

  const _getItemLoadingStatus = (id) => {
    const isThisItemUpdating = changingQtyIds.includes(id);
    const isThisItemRemoving = removingItemIds.includes(id);
    return {
      updating: isThisItemUpdating,
      removing: isThisItemRemoving,
      either: isThisItemUpdating || isThisItemRemoving,
    };
  };

  const _isCanReschedule = (...args) => isCanReschedule(store_id, ...args);

  const _getButtonTxt = () => {
    if (availabilityError) {
      // if has availability error, show it as button text
      return availabilityError;
    } else if (!lodashIsEmpty(removingItemIds)) {
      // if store cart or store cart item is removing
      return 'Removing...';
    } else if (!lodashIsEmpty(changingQtyIds)) {
      return 'Updating...';
    } else if (isPaymentPending) {
      return `${messages.PAYMENT_PENDING.title}...`;
    } else if (canContinueToPayment) {
      return 'Continue to payment';
    }
    // default button text
    return 'Proceed to order';
  };

  const _proceedToOrder = async (...params) => {
    const storeOrderType = constants.ORDER_TYPE_DATA.filter(
      (e) => e.value !== constants.ORDER_TYPES.MEAL_PLAN
    ).map((orderType) => storeInformation[orderType.apiDataKey]);
    // as long as storeOrderType array contains true it can be checkout. If all value is false it means no order types available
    const canCheckout = storeOrderType.some((type) => type);
    if (canContinueToPayment) {
      // if can directly continue to payment
      // this usually happen when user stop checking out when redirected to 3ds or
      // after the checkout screen but decided to not continue
      const fiveMinutesAgo = dayjs().subtract(5, 'minutes'); // 5 min. checkout link validity
      const isDateOlderThan5min = dayjs(
        continueToPaymentData.checkout_date
      ).isBefore(fiveMinutesAgo);
      if (isDateOlderThan5min) {
        dispatch(
          updateCheckoutDetails({
            store_id,
            keyToUpdate: checkout.keys.CONTINUE_TO_PAYMENT,
            keyValue: undefined,
          })
        );
      } else {
        navigation.navigate(routeList.CHECKOUT_WEBVIEW, continueToPaymentData);
        return;
      }
    }
    if (availabilityError) {
      // if has availability error, do nothing when user click the button
      return;
    }
    // mobile validation
    if (!constants.isWeb) {
      if (!isStoreCartValid) {
        const pluralizedItem = pluralize(unavailableItem.length, 'item');
        return Alert.alert(
          'Select another time slot',
          `Ooops! Looks like some of the ${pluralizedItem} from your ${getCartTerm()} went out of stock while you were checking out.`,
          [
            {
              text: 'Reschedule',
              onPress: () => onReschedule(storeInformation, storeCheckoutData),
            },
            { text: 'Not Now' },
          ]
        );
      }
      // if storeInfo encounter error
      if (storeInfoError) {
        Sentry.reportError('Error proceeding order', data);
        _refetchStoreInfo();
        return Alert.alert(
          messages.COMMON_ERROR_TITLE,
          storeInformation?.message
            ? `${storeInformation.message} Please try again.`
            : messages.COMMON_ERROR_MESSAGE
        );
      }
    }
    if (!canCheckout) {
      const title = 'Unavailable';
      const message =
        'This store isn’t accepting orders right now. Please try again later.';
      if (constants.isWeb) {
        showModalPrompt(MODALPROMPT.prompt, {
          title,
          message,
          buttonText: 'Ok',
          buttonStatus: 'control',
        });
      } else {
        Alert.alert(title, message);
      }
      return;
    }
    // else, dispatch store information data before proceeding to checkout
    setCheckoutStoreInfo(storeInformation);
    return onProceedToOrderPress(storeInformation, isStoreCartValid, ...params);
  };

  const _onQtyChange = (cartItem, newQty) => {
    _pushId(cartItem.item_id, setChangingQtyIds);
    const callBack = () => _removeId(cartItem.item_id, setChangingQtyIds);
    addOrUpdateCart({
      from: 'Update Cart Quantity',
      item: {
        ...cartItem,
        id: cartItem.item_id,
        quantity: newQty,
        extras: cartItem?.extras,
      },
      store: { id: store_id, name: store_name },
      onCancel: callBack,
      onSuccess: () => {
        refetchPromo();
        callBack();
      },
      onError: callBack,
    });
  };

  const _onItemRemove = (item, isZeroQty) => {
    const push = () => {
      _pushId(item.item_id, setRemovingItemIds);
      if (isZeroQty) {
        // if removing on 0 qty, also mark as editting for qty change loader
        _pushId(item.item_id, setChangingQtyIds);
      }
    };
    const remove = () => {
      _removeId(item.item_id, setRemovingItemIds);
      if (isZeroQty) {
        // if removing on 0 qty, remove editting flag for qty change loader
        _removeId(item.item_id, setChangingQtyIds);
      }
    };

    push();
    removeThisProductOnCart({
      item,
      storeId: store_id,
      onError: remove,
      onSuccess: () => {
        remove();
        swipeRowRef?.current?.closeAllOpenRows?.();
      },
    });
  };

  return {
    availabilityError,
    buttonLoading,
    buttonTxt: _getButtonTxt(),
    canContinueToPayment,
    changingQtyIds,
    isAvailabilityLoading,
    isDelivery,
    isLessThanMinimum,
    isPaymentPending,
    isStoreCartValid,
    items,
    otherElementLoading,
    promotion,
    promotionMinimum,
    removingItemIds,
    store_id,
    store_name,
    storeData,
    storeInformation,
    storePromo,
    swipeRowRef,
    total,
    unavailableItemIds,
    // local functions
    getItemLoadingStatus: _getItemLoadingStatus,
    isCanReschedule: _isCanReschedule,
    onItemRemove: _onItemRemove,
    onPromoRemove,
    onQtyChange: _onQtyChange,
    proceedToOrder: _proceedToOrder,
    refetchAvailability: _refetchAvailability,
  };
}

export default useStoreCartService;
