import React, { useEffect, useState } from 'react';
import ActionSheet from 'react-native-actions-sheet';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashIsUndefined from 'lodash/isUndefined';

import ThemeStyle from '../../Theme/styles';

import Button from '../Button';
import StepSlider from '../StepSlider';

import commonProps from './commonProps';
import modals from './modals';
import styles from './styles';

function RatingSelectorSheet({ sheetId }) {
  const [data, setData] = useState();
  const [rating, setRating] = useState(4);

  useEffect(() => {
    if (data?.value?.value) {
      setRating(data.value.value);
    }
  }, [data?.value]);

  const _onViewResult = () =>
    modals.hide(modals.RATING_SELECTOR, { value: rating });
  const _onReset = () => modals.hide(modals.RATING_SELECTOR);

  return (
    <ActionSheet id={sheetId} onBeforeShow={setData} {...commonProps}>
      <View style={styles.contentContainer}>
        <Text category="h6" style={ThemeStyle.spacingBottomMedium}>
          Ratings
        </Text>

        <Text appearance="hint">
          {rating !== 5 && 'Over '}
          {rating}
        </Text>

        <StepSlider
          defaultValue={rating}
          min={3}
          max={5}
          onChange={setRating}
        />

        <Button
          status="success"
          style={ThemeStyle.spacingBottomMedium}
          onPress={_onViewResult}
        >
          {({ style: { fontWeight, ...rest } }) => (
            <Text style={rest}>View Results</Text>
          )}
        </Button>

        <Button
          status="control"
          onPress={_onReset}
          disabled={lodashIsUndefined(data?.value)}
        >
          {({ style: { fontWeight, ...rest } }) => (
            <Text style={rest}>Reset</Text>
          )}
        </Button>
      </View>
    </ActionSheet>
  );
}

export default RatingSelectorSheet;
