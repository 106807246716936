import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import { Icon, Input } from '@ui-kitten/components';
import Button from '../Button';
// const width = 500
const CarouselSlide = forwardRef((props, ref) => {
  const carousel = useRef(null);

  useImperativeHandle(ref, () => ({
    prev(item) {
      carousel.current.prev({ ...item });
    },
    next(item) {
      carousel.current.next({ ...item });
    },
    getCurrentIndex() {
      return carousel.current.getCurrentIndex();
    },
    scrollTo(item) {
      carousel.current.scrollTo({ ...item });
    },
  }));
  /*
{
      loop,
      width,
      height,
      autoPlay,
      data,
      scrollAnimationDuration = 1000,
      onSnapToItem,
      renderItem,
      children,
      ...props
    },*/
  return (
    <View style={{ flex: 1, alignSelf: 'center', alignItems: 'center' }}>
      <Carousel
        ref={carousel}
        loop={props.loop}
        width={props.width}
        height={props.height}
        autoPlay={props.autoPlay}
        defaultIndex={0}
        data={props.data} //[...new Array(6).keys()]}
        scrollAnimationDuration={props.scrollAnimationDuration}
        onSnapToItem={props.onSnapToItem}
        renderItem={props.renderItem}
        {...props}
      />

      {props.children}
    </View>
  );
});

export default CarouselSlide;
