import React, { Fragment, useRef, useState } from 'react';
import { View, FlatList, useWindowDimensions, StyleSheet } from 'react-native';
import ScreenWrapper from '../../../Components/ScreenWrapper';
import { Icon, Text } from '@ui-kitten/components';
import Image from '../../../Components/Image';
import Button from '../../../Components/Button';
import ThemeColor from '../../../Theme/colors';
import constants from '../../../Config/constants';
import ScreenSize from '../../../Helper/ScreenSize';
import Footer from '../../../Components/Footer';

const Description = ({ route, navigation }) => {
  const { id, banner, type, title } = route?.params.item;
  const { isWeb, isTabletOrMobile, isSmallScreen } = ScreenSize();

  return (
    <ScreenWrapper modal={true} footer={constants.isWeb ? <Footer /> : null}>
      {constants.isWeb ? (
        <View
          style={[
            styles.main_wrapper,
            { flexDirection: isWeb ? 'row' : 'column' },
          ]}
        >
          <View
            style={[
              styles.wrapper_first_column,
              { width: isWeb ? '75%' : '100%' },
            ]}
          >
            <Image
              source={{ uri: banner }}
              resizeMode="cover"
              style={styles.image_size}
            />
            <View style={{ paddingTop: '2%' }}>
              <Text category="h6">{title}</Text>
              <View style={styles.middle_container}>
                <Button style={styles.button_container_web} plain>
                  <View style={styles.col_button_container}>
                    <Icon
                      name={'clock-time-nine-outline'}
                      fill={ThemeColor.green}
                      pack="material"
                      style={styles.icon_size}
                    />
                    <Text>Today - 10th Nov</Text>
                  </View>
                </Button>

                <Button style={styles.button_container_web} plain>
                  <View style={styles.col_button_container}>
                    <Icon
                      name={'ios-location-outline'}
                      fill={ThemeColor.green}
                      pack="ion"
                      style={styles.icon_size}
                    />
                    <Text>
                      Bonds Level 1 take escalator down to level 1 located next
                      to Platypus
                    </Text>
                  </View>

                  <Icon
                    name={'ios-chevron-forward'}
                    fill={ThemeColor.black}
                    pack="ion"
                    style={styles.icon_size}
                  />
                </Button>
              </View>

              <View style={[styles.bottom_container_web]}>
                <Text>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </Text>
              </View>
            </View>
          </View>

          {
            //second column
          }
          {isWeb ? <View style={styles.wrapper_second_column} /> : null}
        </View>
      ) : (
        <Fragment>
          <Image
            source={{ uri: banner }}
            resizeMode="cover"
            style={styles.image_size}
          />
          <View style={styles.wrapper}>
            <Text category="h6">{title}</Text>
            <View style={styles.middle_container}>
              <Button style={styles.button_container} plain>
                <View style={styles.col_button_container}>
                  <Icon
                    name={'clock-time-nine-outline'}
                    fill={ThemeColor.green}
                    pack="material"
                    style={styles.icon_size}
                  />
                  <Text>Today - 10th Nov</Text>
                </View>
              </Button>

              <Button style={styles.button_container} plain>
                <View style={styles.col_button_container}>
                  <Icon
                    name={'ios-location-outline'}
                    fill={ThemeColor.green}
                    pack="ion"
                    style={styles.icon_size}
                  />
                  <Text>
                    Bonds Level 1 take escalator down to level 1 located next to
                    Platypus
                  </Text>
                </View>

                <Icon
                  name={'ios-chevron-forward'}
                  fill={ThemeColor.black}
                  pack="ion"
                  style={styles.icon_size}
                />
              </Button>
            </View>

            <View style={styles.bottom_container}>
              <Text>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </Text>
            </View>
          </View>
        </Fragment>
      )}
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  main_wrapper: {
    flexGrow: 1,
    justifyContent: 'space-between',
    height: '100vh',
  },
  wrapper_first_column: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingRight: '5%',
    paddingTop: '2%',
  },
  wrapper_second_column: {
    width: '25%',
    flex: 1,
    borderLeftColor: ThemeColor.gray,
    borderLeftWidth: 1,
    borderRightColor: ThemeColor.gray,
    borderRightWidth: 1,
  },

  image_size: { height: '40%' },
  icon_size: { width: 20, height: 20, marginRight: 5 },
  wrapper: { padding: '5%' },
  middle_container: {
    height: '20%',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  bottom_container: { marginTop: '5%', padding: '2%' },
  button_container: {
    alignSelf: 'stretch',
    height: 50,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
  },
  col_button_container: {
    alignSelf: 'stretch',
    width: '70%',
    height: 50,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button_container_web: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    flexGrow: 1,
  },
  bottom_container_web: { marginTop: '10%' },
});
export default Description;
