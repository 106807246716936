import React from 'react';
import { connect } from 'react-redux';
import { View, Linking, Alert, ActivityIndicator } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashIsEqual from 'lodash/isEqual';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFilter from 'lodash/filter';

import List from '../../Components/List';
import ListItem from '../../Components/ListItem';
import ScreenWrapper from '../../Components/ScreenWrapper';
import VersionText from '../../Components/VersionText';
import externalLinks from '../../Config/externalLinks';
import { capitalize, isUserDetailsIncomplete } from '../../Helper';
import { logout } from '../../RTK/user';
import routeList from '../../Routes/list';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import { myFavorites } from '../Home/sections';

import AddPayment from './AddPayment';
import ChoosePlan from './ChoosePlan';
import GetHelp from './GetHelp/GetHelp';
import GiftCard from './GiftCard/GiftCard';
import Legal from './Legal';
import LegalBindingContract from './LegalBindingContract';
import Notification from './Notification';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import Privacy from './Privacy';
import Profile from './Profile/Profile';
import ReferAndEarn from './ReferAndEarn';
import styles from './styles';

class Account extends React.Component {
  state = {
    loggedOut: false,
    list: [],
  };

  componentDidMount() {
    this._updateAccountListing();
  }

  componentDidUpdate(prevProps) {
    const prevUser = prevProps.user;
    const currentUser = this.props.user;
    const isUserUpdated = !lodashIsEqual(prevUser, currentUser);

    const prevNotification = prevProps.notification;
    const currentNotification = this.props.notification;
    const isNotifUpdated = !lodashIsEqual(
      prevNotification,
      currentNotification
    );

    const prevFavorites = prevProps.userFavorites;
    const currentFavorites = this.props.userFavorites;
    const isFavoriteUpdated = !lodashIsEqual(prevFavorites, currentFavorites);

    if (isUserUpdated || isNotifUpdated || isFavoriteUpdated) {
      this._updateAccountListing();
    }
  }

  _updateAccountListing = () => {
    const { user, userFavorites, notification } = this.props;
    let stateToUpdate = {};

    if (!user) {
      stateToUpdate.loggedOut = true;
    } else {
      const profileSubTitle = isUserDetailsIncomplete(user)
        ? 'Complete your profile'
        : `${user?.first_name} ${user?.last_name}`;
      stateToUpdate.list = [
        {
          testID: 'profileButton',
          route: routeList.PROFILE,
          title: 'Name',
          subTitle: profileSubTitle,
        },
        // {
        //   testID: 'paymentMethodButton',
        //   route: routeList.PAYMENT_METHOD,
        //   title: 'Payment Methods',
        //   subTitle: 'Manage payment methods and credits',
        // },
        {
          testID: 'addressButton',
          route: routeList.ADDRESS_SELECTOR,
          title: 'Addresses',
          subTitle: 'Add or remove a delivery address',
          params: { dontClose: true },
        },
        !lodashIsEmpty(userFavorites) && {
          testID: 'savedStoreButton',
          route: routeList.SEE_ALL,
          title: 'My Favorites',
          params: {
            title: 'My Favorites',
            ...myFavorites.tag,
          },
        },
        {
          testID: 'notificationButton',
          route: routeList.NOTIFICATION,
          title: 'Notifications',
          subTitle: Object.keys(notification) // get all keys
            .filter((e) => notification[e]) // filter that is true value
            .map((e) => capitalize(e)) // capitalize each
            .sort() // sort a-z
            .join(', '), // make single string and separate each by comma
        },
        // {
        //   testID: 'giftCardButton',
        //   route: routeList.GIFT_CARD,
        //   title: 'Gift Cards',
        // },
        // {
        //   testID: 'referButton',
        //   route: routeList.REFER_AND_EARN,
        //   title: `Refer Friends and get ${constants.currencySign}1,000`,
        // },
        // {
        //   testID: 'getFreeDeliveryFeeButton',
        //   route: routeList.CHOOSE_PLAN,
        //   title: `Get ${constants.currencySign}0 delivery fees`,
        // },
        // {
        //   testID: 'helpButton',
        //   route: routeList.GET_HELP,
        //   title: 'Get Help',
        // },
        {
          testID: 'ppButton',
          route: routeList.PRIVACY,
          title: 'Privacy',
          subTitle: 'Choose what data to share with businesses',
        },
        {
          testID: 'partnerButton',
          title: 'Be a Partner Restaurant',
          extLink: externalLinks.PARTNER_RESTAURANT,
        },
        {
          testID: 'faqButton',
          title: 'Frequently Asked Questions',
          extLink: externalLinks.FAQ,
        },
        // {
        //   testID: 'betaButton',
        //   title: 'Join the Beta',
        //   extLink: externalLinks.JOIN_BETA,
        // },
        {
          testID: 'legalButton',
          route: routeList.LEGAL,
          title: 'Legal',
        },
        {
          testID: 'logoutButton',
          title: 'Log Out',
          logout: true,
        },
      ];
    }

    stateToUpdate.list = lodashFilter(
      stateToUpdate.list,
      (d) => typeof d === 'object'
    );
    this.setState(stateToUpdate);
  };

  _onItemPressed = (data) => () => {
    if (data.logout) {
      Alert.alert('Confirm Logout', 'Are you sure you want to log out?', [
        { text: 'Log Out', onPress: () => this.props.dispatchLogout() },
        { text: 'Cancel' },
      ]);
      return;
    } else if (data.extLink) {
      Linking.openURL(data.extLink);
      return;
    }

    this.props.navigation.navigate(data.route, data.params);
  };

  _renderHeader = () => {
    return (
      <View
        style={[
          ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.spacingBottomMedium,
          ThemeStyle.spacingTopLarge,
        ]}
      >
        <Text category="h4">Profile</Text>
      </View>
    );
  };

  _renderItem = ({ item }) => (
    <View
      style={[
        ThemeStyle.pageHorizontalSpacing,
        item.section && { backgroundColor: ThemeColor.lightGreen },
      ]}
    >
      <ListItem
        testID={item.testID}
        title={item.title}
        subTitle={item.subTitle}
        onPress={this._onItemPressed(item)}
      />
    </View>
  );

  render() {
    const { list } = this.state;
    return (
      <ScreenWrapper scroll={false}>
        {this.props.isLogoutLoading && (
          <View style={styles.loaderWrapper}>
            <ActivityIndicator size="large" color={ThemeColor.green} />
          </View>
        )}
        <List
          title="Account"
          data={list}
          initialNumToRender={list.length}
          renderItem={this._renderItem}
          ListHeaderComponent={this._renderHeader}
          withSeparator
        />

        <View style={{ position: 'absolute', bottom: 10, right: 10 }}>
          <VersionText />
        </View>
      </ScreenWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  isLogoutLoading: state.user.isLogoutLoading,
  userFavorites: state.favourite?.favouriteData,
  notification: state.utility.settings.notification,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLogout: () => dispatch(logout()),
});

export {
  Profile,
  PaymentMethod,
  AddPayment,
  Notification,
  GiftCard,
  ReferAndEarn,
  ChoosePlan,
  GetHelp,
  Privacy,
  Legal,
  LegalBindingContract,
};
export default connect(mapStateToProps, mapDispatchToProps)(Account);
