import { createSlice } from '@reduxjs/toolkit';
import lodashIndexOf from 'lodash/indexOf';

const initialState = {
  favouriteData: [], // collection of store ids
};

export const favouriteSlice = createSlice({
  name: 'favourite',
  initialState,
  reducers: {
    setFavourites: (state, action) => {
      // payload is array of store ids, used after getting user data
      state.favouriteData = action.payload;
    },
    toggleFavourite: (state, action) => {
      // action.payload is store id
      const result = lodashIndexOf(state.favouriteData, action.payload);
      if (result === -1) {
        // add to favorite array
        state.favouriteData.push(action.payload);
      } else {
        // remove from favorite array
        state.favouriteData.splice(result, 1);
      }
    },
  },
});

export const { setFavourites, toggleFavourite } = favouriteSlice.actions;
export default favouriteSlice.reducer;
