import React, { Fragment, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Divider, Text } from '@ui-kitten/components';
import Modal from 'react-native-modal';

import ThemeStyle, { deviceHeight } from '../Theme/styles';

import Button from './Button';
import Image from './Image';
import RatingStar from './RatingStar';

function AddReviewOrDisputeModal({ show, data, onReview, onNotNow }) {
  const [star, setStar] = useState(0);

  const hasDispute = data?.dispute === 'true'; // from fcm data so dispute value is string

  const _onStarChange = (value) => {
    setStar(value);
    onReview(value);
  };

  return (
    <Modal
      isVisible={show}
      animationIn="fadeIn"
      animationOut="fadeOut"
      deviceHeight={deviceHeight}
      useNativeDriver
      statusBarTranslucent
    >
      <View style={styles.container}>
        <Image source={{ uri: data?.storeAvatar }} style={styles.image} />

        <View
          style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.spacingBottom]}
        >
          <Text
            category="h6"
            style={[ThemeStyle.textCenter, ThemeStyle.spacingBottomMedium]}
          >
            {hasDispute
              ? 'A Request for Your Updated Review'
              : `Tell us your order experience on ${data?.storeName}`}
          </Text>

          <Text category="p2" style={ThemeStyle.textCenter}>
            {hasDispute
              ? 'Hi there! The issue with the low rating on your order has been addressed. Mind giving your review a little update? Your thoughts mean a lot to us!'
              : 'Tap a star and it will redirect you to order rating screen.'}
          </Text>
        </View>

        {!hasDispute && (
          <View style={ThemeStyle.spacingBottom}>
            <RatingStar
              starValue={star}
              starSize={30}
              starStyle={{ marginHorizontal: 10 }}
              style={{ marginTop: 0 }}
              onStarChange={_onStarChange}
              view
            />
          </View>
        )}

        <Divider style={[ThemeStyle.divider, ThemeStyle.fitWidthContainer]} />

        <View
          style={[
            ThemeStyle.fitWidthContainer,
            ThemeStyle.flexDirectionRowCenter,
          ]}
        >
          <Button
            onPress={onNotNow}
            style={[
              ThemeStyle.flex1,
              ThemeStyle.pageVerticalSpacing,
              ThemeStyle.fitWidthContainer,
              ThemeStyle.alignItemsCenter,
            ]}
            plain
          >
            <Text category="h6">Not Now</Text>
          </Button>
          {hasDispute && (
            <Fragment>
              <View
                style={[{ height: '100%', width: 1 }, ThemeStyle.divider]}
              />
              <Button
                onPress={onReview}
                style={[
                  ThemeStyle.flex1,
                  ThemeStyle.pageVerticalSpacing,
                  ThemeStyle.fitWidthContainer,
                  ThemeStyle.alignItemsCenter,
                ]}
                plain
              >
                <Text status="success" category="h6">
                  OK
                </Text>
              </Button>
            </Fragment>
          )}
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    ...ThemeStyle.pageBackground,
    ...ThemeStyle.alignItemsCenter,
    borderRadius: 15,
  },
  image: {
    ...ThemeStyle.spacingTop,
    ...ThemeStyle.spacingBottom,
    width: 50,
    height: 50,
  },
});

export default AddReviewOrDisputeModal;
