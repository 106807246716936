import React, { Fragment } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { Divider, Layout, Text } from '@ui-kitten/components';

import Button from '../../Components/Button';
import List from '../../Components/List';
import Heading from '../../Components/HorizontalListing/Heading';
import StoreCard from '../../Components/Card/Store';
import { ScaleButtonAnimation } from '../../Components/Web/Intereaction';
import constants from '../../Config/constants';
import StoreHelper from '../../Helper/Store';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

function OrderForLaterSection({
  data,
  hasOpenStore,
  whenFilter,
  isSaved,
  disableSave,
  onSave,
  onPress,
}) {
  const conditionalItemOverlayProps = constants.isWeb
    ? { style: { width: 200, marginHorizontal: 50 } }
    : {};

  const _renderItem = ({ index, item }) => {
    const { statusText, statusDescription } = StoreHelper.getStoreHourStatus({
      storeHours: item?.store_hours,
      offDates: item?.off_dates,
      preOrderTime: item?.pre_order_to_order_queue_timer,
      dateAndTime: whenFilter?.value, // to adjust on the date filter not just current date and time
    });
    const onCardPressData = { ...item, orderForLater: true }; // add flag for order for later
    const ButtonComponent = constants.isWeb ? ScaleButtonAnimation : Button;
    return (
      <Layout key={index} style={ThemeStyle.pageVerticalSpacingMedium}>
        <StoreCard
          {...item}
          imageOverlay={
            <View style={[ThemeStyle.flex1, styles.itemOverlay]}>
              <View
                style={ThemeStyle.pageHorizontalSpacing}
                {...conditionalItemOverlayProps}
              >
                <Text
                  category="p1"
                  status="control"
                  style={[
                    ThemeStyle.textCenter,
                    ThemeStyle.spacingBottomMedium,
                  ]}
                >
                  {statusText}, {statusDescription}
                </Text>
                <ButtonComponent
                  buttonStyle={styles.scaleButtonStyle} // specific for web on ScaleButtonAnimation
                  contentStyle={styles.scaleContentStyle} // specific for web on ScaleButtonAnimation
                  size="small"
                  status="success" // specific for mobile on Button component
                  onPress={onPress(onCardPressData, 'Order for later')}
                >
                  {constants.isWeb ? (
                    <Text category="c1" status="control">
                      Order for later
                    </Text>
                  ) : (
                    'Order for later'
                  )}
                </ButtonComponent>
              </View>
            </View>
          }
          isSaved={isSaved(item.id)}
          disableSave={disableSave}
          onSave={onSave(item)}
          onPress={onPress(onCardPressData, 'Order for later')}
          fullWidthOnMobile
        />
      </Layout>
    );
  };

  return (
    <Fragment>
      {hasOpenStore && (
        <View style={!constants.isWeb && { marginHorizontal: -globalSpacing }}>
          <Divider style={ThemeStyle.sectionSeparator} />
        </View>
      )}
      <View style={ThemeStyle.pageVerticalSpacingMedium}>
        <Heading title="Order for later" noHPadding />
      </View>
      {constants.isWeb ? (
        <View dataSet={{ media: ids.container }} style={styles.container}>
          {data.map((item, index) => _renderItem({ index: index, item: item }))}
        </View>
      ) : (
        <List data={data} renderItem={_renderItem} withSeparator />
      )}
    </Fragment>
  );
}

const { ids, styles } = StyleSheet.create({
  itemOverlay: {
    // ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionRowCenterCenter,
    backgroundColor: `#00000066`, // 40% black
  },
  container: {
    display: 'grid',
    ...ThemeStyle.pageHorizontalSpacingSmall,
    columnGap: 10,
    gridColumnGap: 10,
    [`@media ${BreakPoints.md}`]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [`@media ${BreakPoints.lg}`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [`@media ${BreakPoints.xxl}`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [`@media ${BreakPoints.xxxl}`]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  scaleButtonStyle: {
    backgroundColor: ThemeColor.green,
    borderRadius: 30,
  },
  scaleContentStyle: {
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  scaleBtnLabel: {
    ...ThemeStyle.regular,
    fontSize: 12,
    color: ThemeColor.white,
  },
});

export default OrderForLaterSection;
