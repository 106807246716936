const getDisplayTitle = (address, defaultText) => {
  const { label } = address || {};
  return label || defaultText || '--';
};

const getDisplaySubTitle = (address) => address?.formatted_address || '--';

export default {
  getDisplayTitle,
  getDisplaySubTitle,
};
