import storeApi from './store';

function getSearchResults(searchValue, urlParams, isTag, ...args) {
  let params = '';
  if (isTag) {
    params += '?tags=' + searchValue;
  } else {
    params += '?name=' + searchValue;
  }
  if (urlParams) {
    params += urlParams;
  }
  return storeApi.getAllStores(params, ...args);
}

export default { getSearchResults };
