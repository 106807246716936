import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';

import ThemeColor from '../Theme/colors';
import ThemeStyle, { globalSpacing } from '../Theme/styles';

import Button from './Button';

const thumbSize = 25;
const dotSize = 6;

class StepSlider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stepArray: [],
      activeIndex: 3,
    };
  }

  componentDidMount() {
    const { defaultValue, min, max, step } = this.props;
    const arrayLength = (max - min) / step + 1;
    const stepArray = [];
    Array.from(Array(arrayLength).keys()).forEach((i) => {
      stepArray.push(min + step * i);
    });
    let activeIndex = stepArray.indexOf(defaultValue);
    this.setState({ stepArray, activeIndex });
  }

  _renderStepDot = (i) => {
    const { min, step, onChange } = this.props;
    const { activeIndex } = this.state;
    const isActive = i === activeIndex;
    const stepValue = min + step * i;

    if (isActive) {
      return (
        <View>
          <View style={styles.thumbButton} />
        </View>
      );
    }

    return (
      <View>
        <Button
          testID={`stepButton${stepValue}`}
          onPress={() => {
            this.setState({ activeIndex: i });
            onChange(stepValue);
          }}
          style={styles.stepDotButton}
          plain
        />
        <View
          style={[
            styles.stepDot,
            {
              backgroundColor:
                i >= activeIndex ? ThemeColor.green : ThemeColor.gray,
            },
          ]}
        />
      </View>
    );
  };

  _renderDivider = (i) => {
    const { activeIndex } = this.state;
    return (
      <View
        style={[
          styles.divider,
          {
            backgroundColor:
              i >= activeIndex ? ThemeColor.green : ThemeColor.gray,
          },
        ]}
      />
    );
  };

  render() {
    const { stepArray } = this.state;

    return (
      <View style={styles.wrapper}>
        <View
          style={[
            ThemeStyle.flexDirectionRowCenterSpaceBetween,
            ThemeStyle.pageVerticalSpacingMedium,
          ]}
        >
          {stepArray.map((step, i) => (
            <React.Fragment key={i}>
              {this._renderStepDot(i)}
              {i !== stepArray.length - 1 && this._renderDivider(i)}
            </React.Fragment>
          ))}
        </View>

        <View style={styles.textContainer}>
          {stepArray.map((i) => (
            <Text category="p1" key={i}>
              {i}
            </Text>
          ))}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: globalSpacing,
  },
  stepDotButton: {
    position: 'absolute',
    top: -20,
    left: -20,
    height: thumbSize * 2,
    width: thumbSize * 2,
    zIndex: 1,
  },
  stepDot: {
    width: dotSize,
    height: dotSize,
    borderRadius: dotSize / 2,
  },
  thumbButton: {
    position: 'absolute',
    backgroundColor: ThemeColor.green,
    top: -(thumbSize / 2),
    left: -(thumbSize / 2),
    width: thumbSize,
    height: thumbSize,
    borderRadius: thumbSize / 2,
    zIndex: 1,
    flex: 1,
  },
  divider: {
    flex: 1,
    height: 2,
    zIndex: -1,
    marginHorizontal: globalSpacing / 10,
  },
  textContainer: {
    ...ThemeStyle.flexDirectionRowCenterSpaceBetween,
    paddingVertical: globalSpacing / 2,
  },
});

StepSlider.defaultProps = {
  step: 1,
};

StepSlider.propTypes = {
  defaultValue: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default StepSlider;
