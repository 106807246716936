import lodashFind from 'lodash/find';

import constants from '../../Config/constants';
import { CHECKPOINTS } from '../../Helper/Order';

function useOrderProgress({ value, orderType }) {
  const isDelivery = orderType === constants.ORDER_TYPES.DELIVERY;
  const checkpoints = isDelivery
    ? CHECKPOINTS.DELIVERY
    : CHECKPOINTS.NONDELIVERY;
  const active = lodashFind(checkpoints, { value });

  const _getProgress = (data) => {
    if (isDelivery) {
      return data?.percent || data?.deliveryPercent || 0;
    } else {
      return data?.percent || data?.pickupPercent || 0;
    }
  };

  return {
    checkpoints,
    progress: _getProgress(active),
    getProgress: _getProgress,
  };
}

export default useOrderProgress;
