import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Text } from '@ui-kitten/components';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import { SECTION_SUB_TITLE } from '../../Theme/typographyProps';

import Button from '../Button';

import styles from './styles';

class ListInfiniteScrollFooter extends React.PureComponent {
  _renderContent() {
    const { error, loading, done, onLoadMore } = this.props;
    if (error) {
      // if error occur
      return <Text {...SECTION_SUB_TITLE}>{error}</Text>;
    } else if (loading) {
      // if loading or manual and press the load more button loadingMore become true (loader)
      return <ActivityIndicator color={ThemeColor.green} size="large" />;
    } else if (!done && onLoadMore) {
      // default footer for manual pagination if not yet done
      return (
        <View
          style={[
            ThemeStyle.pageHorizontalSpacing,
            ThemeStyle.fitWidthContainer,
          ]}
        >
          <Button
            testID="infiniteScrollFooterButton"
            onPress={onLoadMore}
            status="success"
            style={ThemeStyle.fitWidthContainer}
          >
            Load More
          </Button>
        </View>
      );
    }
    // default no footer
    return null;
  }

  render() {
    const { hide } = this.props;

    if (hide) {
      return null;
    }

    return (
      <SafeAreaView edges={['right', 'bottom', 'left']}>
        <View style={ThemeStyle.webPageWrapper}>
          <View style={ThemeStyle.webPageContainer}>
            <View style={styles.footer}>{this._renderContent()}</View>
          </View>
        </View>
      </SafeAreaView>
    );
  }
}

ListInfiniteScrollFooter.propTypes = {
  done: PropTypes.bool, // used with combination for manual load more button
  hide: PropTypes.bool, // if decide to no footer
  loading: PropTypes.bool, // flag if loading or loading more via manual button
  onLoadMore: PropTypes.func, // for manual load more button
};

export default ListInfiniteScrollFooter;
