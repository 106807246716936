import { createSelector } from '@reduxjs/toolkit';

const product = (state) => state.product;

const productLoadingSelector = createSelector(
  product,
  (state) => state.loading
);
const productSelector = createSelector(product, (state) => state.data);

export { productLoadingSelector, productSelector };
