import lodashFind from 'lodash/find';

import ThemeColor from '../../Theme/colors';

import { CATEGORIES } from './constants';

const colors = [
  {
    category: CATEGORIES.BASIC,
    solid: ThemeColor.darkGray,
    others: ThemeColor.darkGray,
  },
  {
    category: CATEGORIES.PRIMARY,
    solid: ThemeColor.white,
    others: ThemeColor.violet,
  },
  {
    category: CATEGORIES.SUCCESS,
    solid: ThemeColor.white,
    others: ThemeColor.green,
  },
  {
    category: CATEGORIES.INFO,
    solid: ThemeColor.white,
    others: ThemeColor.blue,
  },
  {
    category: CATEGORIES.WARNING,
    solid: ThemeColor.white,
    others: ThemeColor.yellow,
  },
  {
    category: CATEGORIES.DANGER,
    solid: ThemeColor.white,
    others: ThemeColor.red,
  },
];

export default function getTextColor(category, type) {
  const selectedColor = lodashFind(colors, { category });
  return selectedColor[type] || selectedColor.others;
}
