import dayjs, { ManipulateType } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

// Extend dayjs with the plugins for isOlderThanNow
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const convertNumberToDay = (dayInNumber: number) => {
  return (
    ['Sun', 'Mon', 'Tues', 'Wednes', 'Thurs', 'Fri', 'Satur'][dayInNumber] +
    'day'
  );
};

const isOlderThanNow = ({
  amount,
  time,
  unit,
}: {
  amount: number;
  unit: ManipulateType;
  time: number;
}) => {
  const now = dayjs();
  const timeToCompare = dayjs(time).add(amount, unit);
  return now.isSameOrAfter(timeToCompare);
};

export default {
  convertNumberToDay,
  isOlderThanNow,
};
