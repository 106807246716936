import React, { memo } from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import HorizontalList from '../../Components/HorizontalListing';

import Promotion from '../../Components/Promotion';
import constants from '../../Config/constants';

import ThemeStyle, { globalSpacing } from '../../Theme/styles';
import { SECTION_TITLE, SECTION_SUB_TITLE } from '../../Theme/typographyProps';

import usePromoListController from './controller/promotionList';
import Loader from './Loader/Promotion';
import { ids, styles } from './stylesResponsive';

function PromotionService() {
  const { isGettingPromo, promoList, showPromoDetails } =
    usePromoListController();

  const _renderLeftIcon = (
    <View style={ThemeStyle.pageHorizontalSpacing}>
      <Text {...(constants.isWeb ? { category: 's1' } : SECTION_TITLE)}>
        Promotions
      </Text>
      <Text {...(constants.isWeb ? { category: 'c1' } : SECTION_SUB_TITLE)}>
        Click the promotion to show more details.
      </Text>
    </View>
  );

  const _renderItem = ({ item, isFirstOfList, isLastOfList }) => {
    const margin = {
      marginLeft: isFirstOfList ? globalSpacing : globalSpacing / 4,
      marginRight: isLastOfList ? globalSpacing : globalSpacing / 4,
    };
    return (
      <View style={margin}>
        <Promotion
          item={item}
          onPress={(discount) => showPromoDetails({ ...item, discount })}
        />
      </View>
    );
  };

  const containerStyle = [
    styles.wrapper,
    ThemeStyle.spacingTopSmall,
    ThemeStyle.spacingBottom,
  ];

  if (isGettingPromo) {
    return (
      <View dataSet={{ media: ids.wrapper }} style={containerStyle}>
        <Loader />
      </View>
    );
  } else if (lodashIsEmpty(promoList)) {
    return null;
  }

  return (
    <View dataSet={{ media: ids.wrapper }} style={containerStyle}>
      <HorizontalList
        data={promoList}
        renderItem={_renderItem}
        leftContent={_renderLeftIcon}
      />
    </View>
  );
}

export default memo(PromotionService);
