import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import RatingStar from '../../Components/RatingStar';
import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';
import {
  SECTION_TITLE_LIGHT,
  SECTION_SUB_TITLE,
} from '../../Theme/typographyProps';
import { RateDeliveryProps } from './types';

export const feedbacks = [
  'Good communication',
  'Followed instructions',
  'Friendly',
  'Handled with care',
  'Above and beyond',
];

const RateDelivery = ({
  starValue,
  defaultFeedbacks,
  disabled,
  onStarChange,
  onFeedbackChange,
  ...rest
}: RateDeliveryProps) => {
  const conditionalProps = constants.isWeb
    ? { style: { minWidth: 200 } }
    : null;
  const conditionalPropsRatingStars = constants.isWeb
    ? { style: { alignSelf: 'flex-start' } }
    : null;

  return (
    <View style={ThemeStyle.spacingBottom} {...rest}>
      <View
        style={{
          width: 200,
        }}
      >
        <Text {...SECTION_TITLE_LIGHT}>Rate your Delivery</Text>
        <Text {...SECTION_SUB_TITLE}>How was your delivery?</Text>
      </View>
      <View {...conditionalProps}>
        <RatingStar
          defaultFeedbacks={defaultFeedbacks}
          feedbacks={feedbacks}
          starValue={starValue}
          onStarChange={onStarChange}
          onFeedbackChange={onFeedbackChange}
          disabled={disabled}
          {...conditionalPropsRatingStars}
        />
      </View>
    </View>
  );
};

export default RateDelivery;
