import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { Icon, Text, useTheme } from '@ui-kitten/components';

import constants from '../../Config/constants';
import Service from '../../Service';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import Badge from '../Badge';
import Button from '../Button';
import Image from '../Image';

function MealPlanItem(data) {
  const store_theme = useTheme();

  const { onRemove, ...item } = data;
  const { date, image, name, extras, is_exclusive } = item;
  return (
    <View
      style={[
        ThemeStyle.flexDirectionRow,
        ThemeStyle.pageVerticalSpacingMedium,
      ]}
    >
      <Image
        source={{
          uri: image,
        }}
        style={[styles.image, ThemeStyle.spacingRightMedium]}
        resizeMode={'cover'}
      />

      <View style={[ThemeStyle.flex1, ThemeStyle.spacingRightMedium]}>
        <View style={ThemeStyle.flexDirectionRowCenter}>
          <Icon
            name="calendar-check"
            pack="fa5"
            fill={store_theme?.['icon_color'] || ThemeColor.green}
            style={[styles.calendarIcon, ThemeStyle.spacingRightSmall]}
          />
          <Text category="p2" appearance={'hint'}>
            {date}
          </Text>
        </View>

        <Text category="p2">{name}</Text>

        {extras?.map((e, i) => (
          <Text category="p2" style={ThemeStyle.dimColor} key={e.id}>
            + {e.name}{' '}
            {!!e.price && `(${Service.commafyNumber(Number(e.price), true)})`}
          </Text>
        ))}
      </View>

      <View
        style={[
          ThemeStyle.flexDirectionColumnSpaceBetween,
          ThemeStyle.alignItemsEnd,
        ]}
      >
        {!!onRemove && (
          <Button onPress={onRemove} hitSlop={10} plain>
            <Icon
              name="trash"
              pack={'feather'}
              fill={store_theme?.['button_color'] || ThemeColor.red}
              style={styles.trashIcon}
            />
          </Button>
        )}

        {is_exclusive && (
          <View style={{ paddingRight: 1 }}>
            <Badge category={Badge.CATEGORIES.INFO} size="p2">
              Exclusive
            </Badge>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    width: 75,
    height: 75,
    borderRadius: 5,
  },
  calendarIcon: {
    width: 12,
    height: 12,
  },
  trashIcon: {
    width: 18,
    height: 18,
  },
});

MealPlanItem.propTypes = {
  date: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  extras: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.string,
    })
  ),
  is_exclusive: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default MealPlanItem;
