export const myFavorites = {
  title: 'My Favorites',
  tag: {
    key: 'funnel',
    value: 'favorite',
    cacheBuster: true,
  },
  size: 'medium',
};

const sections = [
  myFavorites,
  {
    title: 'Fastest Near You',
    tag: {
      key: 'distance',
      value: 5,
    },
    size: 'medium',
  },
  {
    title: 'Trending Right Now',
    tag: {
      key: 'funnel',
      value: 'popular_day',
    },
    size: 'medium',
  },
];

export default function getHomeSections(excludeFavorite) {
  let segments = sections;
  if (excludeFavorite) {
    segments = segments.filter((e) => e.tag.value !== 'favorite');
  }
  return segments;
}
