import lodashGet from 'lodash/get';
import { useRef, useEffect } from 'react';
import { useState } from 'react';

import modals from '../../../Components/Sheets/modals';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';
import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import constants from '../../../Config/constants';
import AnalyticsHelper from '../../../Helper/Analytics';

import useCommonController from './';

function useController({ route }) {
  const {
    state,
    getStoreReviews,
    getStoreReviewsSummary,
    mapItemToComponentProps,
    onAddReview,
  } = useCommonController();
  const reviewSummaryData = useRef();

  const { showModalPrompt } = useModalPrompt();

  const storeId = lodashGet(route, 'params.id');
  const storeName = lodashGet(route, 'params.name');
  const initialData = [{ loading: true }, { loading: true }, { loading: true }];

  const [selectedFilter, setSelectedFilter] = useState('newest');
  const [reviewData, setReviewData] = useState({ result: [] });
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    const { data } = await _getReviews(1, 'newest');
    const { data: reviewSummary, ok: okSummary } = await _getReviewSummary(1);
    if (okSummary) {
      if (reviewSummary?.rating_summary.length > 0) {
        reviewSummaryData.current = { ...reviewSummary, status: 'success' };
        setReviewData({ ...data, result: data?.result });
      } else {
        reviewSummaryData.current = { ...reviewSummary, status: 'empty' };
        setReviewData({ ...data, result: data?.result });
      }
    }
  };
  const _getReviews = (page, sortBy) => getStoreReviews(storeId, page, sortBy);
  const _getReviewSummary = (summary) =>
    getStoreReviewsSummary(storeId, summary);
  const _onAddReview = (data) => onAddReview(data, storeId, storeName);

  const _onProductPress = (data) => {
    AnalyticsHelper.itemSelection({
      ...data,
      fromSection: 'From full review list',
    });
    if (constants.isWeb) {
      showModalPrompt(MODALPROMPT.itemModal, {
        item: {
          item_id: data?.id,
          ...data,
        },
        route: route,
      });

      return;
    }
    modals.show(modals.PRODUCT, { id: data.id });
  };

  const _onSelectedFilter = async (filterType) => {
    //#region Possible values of filterType
    //newest
    //highest
    //lowest
    //#endregion

    const { data } = await _getReviews(1, filterType);
    setReviewData({ ...data, result: data?.result });
    setSelectedFilter(filterType);
  };
  const _onEndReached = async (e) => {
    if (e.distanceFromEnd > 0 && reviewData.has_next_page) {
      setFetching(true);
      const { data } = await _getReviews(reviewData.page + 1, selectedFilter);
      setReviewData({
        ...data,
        result: [...reviewData.result, ...data?.result],
      });
      setFetching(false);
    }
  };
  return {
    state: {
      ...state,
      reviewData,
      isFetching,
    },
    reviewSummaryData,
    initialData,
    storeId,
    storeName,
    getStoreReviews: _getReviews,
    getStoreReviewSummary: _getReviewSummary,
    mapItemToComponentProps,
    onAddReview: _onAddReview,
    onProductPress: _onProductPress,
    onSelectedFilter: _onSelectedFilter,
    onEndReached: _onEndReached,
  };
}

export default useController;
