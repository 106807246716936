// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* animation */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
`, "",{"version":3,"sources":["webpack://./App/Components/Web/PopupModal/index.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;EACE;IACE,mCAAmC;IACnC,UAAU;IACV,wCAAwC;EAC1C;EACA;IACE,uCAAuC;IACvC,UAAU;IACV,wCAAwC;EAC1C;EACA;IACE,mCAAmC;IACnC,UAAU;IACV,4CAA4C;EAC9C;AACF;AACA;EACE,yEAAyE;AAC3E","sourcesContent":["/* animation */\n\n@keyframes anvil {\n  0% {\n    transform: scale(1) translateY(0px);\n    opacity: 0;\n    box-shadow: 0 0 0 rgba(241, 241, 241, 0);\n  }\n  1% {\n    transform: scale(0.96) translateY(10px);\n    opacity: 0;\n    box-shadow: 0 0 0 rgba(241, 241, 241, 0);\n  }\n  100% {\n    transform: scale(1) translateY(0px);\n    opacity: 1;\n    box-shadow: 0 0 500px rgba(241, 241, 241, 0);\n  }\n}\n.popup-content {\n  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
