import { useDispatch } from 'react-redux';
import lodashPick from 'lodash/pick';

import constants from '../Config/constants';
import { setStoreInfo } from '../RTK/checkout';

type CheckoutInformationData = {
  id: string;
  additional_charges: Array<any>;
  days_accepting_in_advanced_orders: number;
  delivery_payment_methods: Array<string>;
  geocode_location: { lat: number; lng: number };
  is_accepting_in_advanced_orders: boolean;
  is_curbside_active: boolean;
  is_delivery_active: boolean;
  is_meal_plan_active: boolean;
  is_pickup_active: boolean;
  location: string;
  name: string;
  off_dates: Array<string>;
  pre_order_to_order_queue_timer: number;
  store_hours: Array<any>;
};
const useSetCheckoutStoreInfo = () => {
  const dispatch = useDispatch();
  return (storeInformation: CheckoutInformationData) => {
    const daysInAdvance = storeInformation.is_accepting_in_advanced_orders
      ? storeInformation.days_accepting_in_advanced_orders
      : 0;
    dispatch(
      setStoreInfo({
        days_accepting_in_advance: daysInAdvance,
        order_types: constants.ORDER_TYPE_API_KEYS.reduce((acc, key) => {
          acc[key] = storeInformation[key];
          return acc;
        }, {}),
        prep_time: storeInformation.pre_order_to_order_queue_timer,
        store_geocode_location: storeInformation.geocode_location,
        store_id: storeInformation.id,
        store_name: storeInformation.name,
        store_location: storeInformation.location,
        store_off_dates: storeInformation.off_dates,
        // pick same key to be dispatch on storeInformation
        ...lodashPick(storeInformation, [
          'additional_charges',
          'delivery_payment_methods',
          'store_hours',
        ]),
      })
    );
  };
};

export default useSetCheckoutStoreInfo;
