import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';
import { TITLE } from '../../Theme/typographyProps';
import { isEmpty } from 'lodash';

class ProductExtraGroup extends React.PureComponent {
  render() {
    const { name, description, is_required, store_theme = {} } = this.props;

    const required_color = !isEmpty(store_theme['icon_color'])
      ? `${store_theme['icon_color']}1A`
      : ThemeColor.lightGreen;

    if (constants.isWeb) {
      return (
        <>
          <View
            style={{
              ...ThemeStyle.sectionSeparator,
              height: 2,
            }}
          />

          <View style={styles.wrapper}>
            <View style={ThemeStyle.flex1}>
              <View
                style={[
                  ThemeStyle.flexDirectionRowSpaceBetween,
                  ThemeStyle.spacingBottomMedium,
                ]}
              >
                <Text category="s2">{name}</Text>
                <View
                  style={[
                    styles.requiredOrOptionalContainer,
                    {
                      justifyContent: 'center',
                      backgroundColor: is_required
                        ? required_color
                        : ThemeColor.gray,
                    },
                  ]}
                >
                  <Text
                    testID="indicator"
                    category="c1"
                    status={is_required ? 'success' : 'basic'}
                  >
                    {is_required ? 'Required' : 'Optional'}
                  </Text>
                </View>
              </View>

              {!!description && (
                <Text category="p2" status="primary">
                  {description}
                </Text>
              )}
            </View>
          </View>
        </>
      );
    }
    return (
      <Fragment>
        <View style={[ThemeStyle.sectionSeparator]} />

        <View style={styles.wrapper}>
          <View style={ThemeStyle.flex1}>
            <Text {...TITLE}>{name}</Text>
            {!!description && (
              <Text category="p2" style={ThemeStyle.dimColor}>
                {description}
              </Text>
            )}
          </View>

          <View
            style={[
              styles.requiredOrOptionalContainer,
              {
                backgroundColor: is_required
                  ? ThemeColor.green
                  : ThemeColor.gray,
              },
            ]}
          >
            <Text
              status="control"
              testID="indicator"
              category="p2"
              style={[
                {
                  color: is_required ? ThemeColor.white : ThemeColor.black,
                },
              ]}
            >
              {is_required ? 'Required' : 'Optional'}
            </Text>
          </View>
        </View>
      </Fragment>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: [
    ThemeStyle.pageVerticalSpacingMedium,
    ThemeStyle.pageHorizontalSpacing,
    ThemeStyle.flexDirectionColEnd,
  ],
  requiredOrOptionalContainer: {
    paddingVertical: globalSpacing / 6,
    paddingHorizontal: globalSpacing / 2,
    borderRadius: globalSpacing,
  },
});

ProductExtraGroup.propsTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
};

export default ProductExtraGroup;
