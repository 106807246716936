import { createSelector } from '@reduxjs/toolkit';
import lodashFind from 'lodash/find';

import constants from '../../Config/constants';

import { checkout as checkoutDV } from '../defaultValues';

const { ORDER_TYPE_DATA, ORDER_TYPES } = constants;

const checkout = (state) => state.checkout;

const getStoreCheckoutData = (checkoutData, storeId) => {
  return lodashFind(checkoutData, { store_id: storeId }) || {};
};

const checkoutFlagSelector = createSelector(
  checkout,
  (state) => state.checkoutFlag
);

const checkoutSelector = createSelector(
  checkout,
  (state) => state.checkoutData
);

const checkoutStoreInfoSelector = createSelector(
  checkout,
  (state) => state.storeInfo || {}
);

const storeCheckoutSelector = createSelector(
  [checkoutSelector, (_, storeId) => storeId],
  (checkoutData, storeId) => getStoreCheckoutData(checkoutData, storeId)
);

const checkoutOrderTypeSelector = createSelector(
  [
    checkoutSelector, // base selector
    (_, storeId) => storeId, // for 2nd param
    (_, __, shouldReturnObject) => shouldReturnObject, // for 3rd param
  ],
  (checkoutData, storeId, shouldReturnObject) => {
    let paramOrderType = ORDER_TYPES.DELIVERY; // default order type as string

    // Check if running in web
    if (constants.isWeb) {
      const params = new URLSearchParams(document?.location?.search); // check url params for order_type
      paramOrderType = params.get('order_type') || paramOrderType; // default order type as string
    }

    const result = getStoreCheckoutData(checkoutData, storeId);
    const orderType = result?.[checkoutDV.keys.ORDER_TYPE]; // selected order type on checkout
    if (shouldReturnObject) {
      // if want's to return as object
      return (
        orderType || lodashFind(ORDER_TYPE_DATA, { value: paramOrderType })
      ); // default order type as object
    } else {
      // if wants to return as string
      return orderType?.value || paramOrderType;
    }
  }
);

const checkoutPromoSelector = createSelector(
  [checkoutSelector, (_, storeId) => storeId],
  (checkoutData, storeId) => {
    const storeCheckoutData = getStoreCheckoutData(checkoutData, storeId);
    return storeCheckoutData[checkoutDV.keys.PROMO_CODE];
  }
);

const checkoutStorePromoSelector = createSelector(
  [checkoutSelector, (_, storeId) => storeId],
  (checkoutData, storeId) => {
    const storeCheckoutData = getStoreCheckoutData(checkoutData, storeId);
    return storeCheckoutData[checkoutDV.keys.PROMO_STORE];
  }
);

const selectPromoCodeInput = createSelector(
  storeCheckoutSelector,
  (checkoutData) => checkoutData[checkoutDV.keys.PROMO_CODE_INPUT] || ''
);

export {
  checkoutFlagSelector,
  checkoutOrderTypeSelector,
  checkoutPromoSelector,
  checkoutSelector,
  checkoutStoreInfoSelector,
  checkoutStorePromoSelector,
  storeCheckoutSelector,
  selectPromoCodeInput,
};
