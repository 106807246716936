import React, { useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { GoogleMap, Marker } from '@react-google-maps/api';
import lodashIsEqual from 'lodash/isEqual';

let isDoneFirstLoad = false;

const MapView = ({
  children,
  region,
  onRegionChanged,
  draggable = true,
  ...otherProps
}) => {
  const mapRef = useRef();
  const locationRef = useRef();
  const [marker, setMarker] = useState(region);

  const handleOnLoad = (map) => {
    locationRef.current = region;
    mapRef.current = map;
    google.maps.event.addListener(map, 'center_changed', handleCenterChanged);
  };

  const _onIdle = () => {
    if (!isDoneFirstLoad) {
      setTimeout(() => {
        isDoneFirstLoad = true;
      }, 1000);
    }
  };
  const mapOptions = {
    draggable: draggable,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: false,
  };

  const handleCenterChanged = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter().toJSON();
      if (!lodashIsEqual(locationRef.current, region)) {
        setMarker(newCenter);
        onRegionChanged(
          {
            latitude: newCenter.lat,
            longitude: newCenter.lng,
          },
          { isGesture: true }
        );
      }
    }
  };

  const onMoveMap = () => {
    locationRef.current = {};
    handleCenterChanged();
  };

  return (
    <GoogleMap
      mapContainerStyle={styles.mapContainer}
      options={mapOptions}
      center={region}
      zoom={18}
      onIdle={_onIdle}
      onLoad={handleOnLoad}
      onDrag={onMoveMap}
      onDragEnd={onMoveMap}
      {...otherProps}
    >
      <Marker position={marker} draggable={false} />
      {children}
    </GoogleMap>
  );
};

const styles = StyleSheet.create({
  mapContainer: {
    width: '100%',
    height: '100%',
  },
});

export default React.memo(MapView);
