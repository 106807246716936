import React, { useRef, useState } from 'react';
import { View } from 'react-native';
import ActionSheet, { useScrollHandlers } from 'react-native-actions-sheet';

import constants from '../../Config/constants';
import Product from '../../Screens/Product';
import { deviceHeight, globalSpacing } from '../../Theme/styles';

import commonProps from './commonProps';

// height calculation to show it below menu sections
const platformPercent = constants.isAndroid ? 0.185 : 0.205;
const percent = deviceHeight * platformPercent;
const height = deviceHeight - (percent + constants.statusBarHeight);

function ProductSheet({ sheetId }) {
  const sheetRef = useRef();
  const [params, setParams] = useState();

  const scrollHandlers = useScrollHandlers('productList', sheetRef);

  return (
    <ActionSheet
      id={sheetId}
      ref={sheetRef}
      onBeforeShow={setParams}
      // isModal and zIndex is for toast to appear on top of this sheet
      isModal={false}
      zIndex={1}
      {...commonProps}
    >
      <View style={{ height }}>
        <Product
          params={params}
          setParams={setParams}
          scrollHandlers={scrollHandlers}
        />
      </View>
    </ActionSheet>
  );
}

export default ProductSheet;
