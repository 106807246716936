import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import ScreenWrapper from '../../Components/ScreenWrapperWithTitleAnimation';

import styles from './styles';

class ReferAndEarn extends React.Component {
  render() {
    return (
      <ScreenWrapper title="Refer a friend">
        <View style={styles.wrapper}>
          <Text>Refer and Earn Screen</Text>
        </View>
      </ScreenWrapper>
    );
  }
}

export default ReferAndEarn;
