import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import constants from '../Config/constants';
import { mealPlan } from '../RTK/defaultValues';

// this function return array of date string of 3 or 5 depending of meal plan type
function generateSchedule(type) {
  dayjs.extend(weekday); // extend dayjs to have .weekday method
  const nextMonday = dayjs().weekday(8); // get next monday, 7 = monday first so +1 so it return monday
  const dateFormat = `${constants.DATE_ONLY_DISPLAY_FORMAT}, YYYY`;
  if (type === mealPlan.type.threeDay) {
    return [
      nextMonday.format(dateFormat),
      nextMonday.add(2, 'day').format(dateFormat),
      nextMonday.add(4, 'day').format(dateFormat),
    ];
  } else if (type === mealPlan.type.fiveDay) {
    return [
      nextMonday.format(dateFormat),
      nextMonday.add(1, 'day').format(dateFormat),
      nextMonday.add(2, 'day').format(dateFormat),
      nextMonday.add(3, 'day').format(dateFormat),
      nextMonday.add(4, 'day').format(dateFormat),
    ];
  } else {
    return [];
  }
}

export default {
  generateSchedule,
};
