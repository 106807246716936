import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import ReviewBox from '../../Components/ReviewBox';
import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { deviceWidth, globalSpacing } from '../../Theme/styles';
import {
  SECTION_TITLE_LIGHT,
  SECTION_SUB_TITLE,
} from '../../Theme/typographyProps';

import { RateStoreProps } from './types';

const RateStore = ({
  storeName,
  starValue,
  defaultFeedbacks,
  onStarChange,
  onFeedbackChange,
}: RateStoreProps) => {
  const conditionalProps = constants.isWeb
    ? {
        style: {
          ...ThemeStyle.spacingTopMedium,
          flex: 1,
          backgroundColor: ThemeColor.white,
          paddingHorizontal: 0,
          borderRadius: 5,
          paddingBottom: globalSpacing,
          maxWidth: deviceWidth,
        },
        starStyle: { alignSelf: 'flex-start' },
      }
    : {};

  return (
    <View>
      <Text {...SECTION_TITLE_LIGHT}>Review the product</Text>
      <Text {...SECTION_SUB_TITLE}>Rate your experience with {storeName}?</Text>

      <ReviewBox
        starValue={starValue}
        defaultFeedbacks={defaultFeedbacks}
        onStarChange={onStarChange}
        onFeedbackChange={onFeedbackChange}
        style={{ ...conditionalProps?.style }}
        starStyle={{ ...conditionalProps.starStyle }}
      />
    </View>
  );
};

export default RateStore;
