import { createSlice } from '@reduxjs/toolkit';

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    loading: true,
    data: null,
  },
  reducers: {
    setProductData: (state, action) => {
      const qty = action.payload?.quantity || 1;
      state.data = { ...action.payload, quantity: qty };
      state.loading = false;
    },
    setProductInstructions: (state, action) => {
      state.data.instructions = action.payload;
    },
    updateQuantity: (state, action) => {
      state.data.quantity = action.payload;
    },
    removeProductData: (state) => {
      state.data = null;
      state.loading = true;
    },
  },
});

export const {
  setProductData,
  removeProductData,
  setProductInstructions,
  updateQuantity,
} = productSlice.actions;
export default productSlice.reducer;
