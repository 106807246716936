import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import withFormik from '../../Hooks/withFormik';
import Service from '../../Service';
import ThemeStyle from '../../Theme/styles';

import Button from '../Button';

import ErrorPrompt from './ErrorPrompt';

class SubmitButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      errorTitle: '',
      fieldError: false,
      showError: false,
    };
  }

  componentDidUpdate() {
    const { formikProps } = this.props;
    const { showError } = this.state;
    if (!showError) {
      // will only check if ErrorPrompt is not visible
      if (!lodashIsEmpty(formikProps?.errors?.app)) {
        // show ErrorPrompt component when app version is not match
        const { title, message } = formikProps.errors.app;
        this.setState({
          showError: true,
          errorTitle: title,
          errorMessage: message,
        });
      } else if (!lodashIsEmpty(formikProps?.errors?.api)) {
        // show ErrorPrompt component when encounter api error / hint
        const { title, message } = formikProps.errors.api;
        this.setState({
          showError: true,
          errorTitle: title,
          errorMessage: message,
        });
      }
    }
  }

  _handleSubmitButton = async () => {
    const { onError, formikProps } = this.props;
    const { handleSubmit, validateForm, values } = formikProps;

    const result = await validateForm();

    if (!lodashIsEmpty(result)) {
      /* TIPS: if we need to show both field error and yellow alert box, put the api key first before the field key e.g:
       setErrors({
        api: { title: '', message: '' },
        ...Service.handleFormError(data?.message)
       })
      */
      const firstErrorKey = Object.keys(result)?.[0]; // we only need to check the first one to know if its field error
      const isErrorInFields = Object.keys(values).includes(firstErrorKey);
      const title = 'Field errors';
      const message = Service.handleErrorMessage(result);
      this.setState(
        {
          showError: true,
          errorTitle: title,
          errorMessage: message,
          fieldError: isErrorInFields,
        },
        () => onError?.(result)
      );
    } else {
      this.setState(
        {
          showError: false,
          errorTitle: '',
          errorMessage: '',
          fieldError: false,
        },
        handleSubmit
      );
    }
  };

  render() {
    const {
      children,
      customLoader,
      disabled,
      disableIfInvalid,
      formikProps,
      onRef,
      text,
      withShadow,
      ...rest
    } = this.props;
    const { dirty, isSubmitting } = formikProps;
    const { errorMessage, errorTitle, fieldError, showError } = this.state;
    const buttonDisable = disableIfInvalid ? !dirty : false;

    return (
      <Fragment>
        <ErrorPrompt
          errorTitle={errorTitle}
          errorMessage={errorMessage}
          show={!fieldError && showError}
        />
        <Button
          onRef={onRef}
          testID="submitButton"
          status="success"
          onPress={this._handleSubmitButton}
          style={[styles.submitButton, withShadow ? ThemeStyle.shadow : {}]}
          loading={!customLoader && isSubmitting}
          disabled={isSubmitting || buttonDisable || disabled}
          {...rest}
        >
          {children ? children : <Text category="label">{text}</Text>}
        </Button>
      </Fragment>
    );
  }
}

const styles = StyleSheet.create({
  submitButton: {
    marginTop: 20,
    borderRadius: 50,
  },
});

SubmitButton.propTypes = {
  children: PropTypes.node,
  customLoader: PropTypes.bool,
  disabled: PropTypes.bool,
  disableIfInvalid: PropTypes.bool,
  formikProps: PropTypes.object.isRequired,
  onRef: PropTypes.func,
  text: PropTypes.string,
  withShadow: PropTypes.bool,
};

export default withFormik(SubmitButton);
