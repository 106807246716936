import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Text, CheckBox } from '@ui-kitten/components';

import withFormik from '../../Hooks/withFormik';
import Button from '../Button';

import ThemeStyle, { globalSpacing } from '../../Theme/styles';

class FormFieldCheckbox extends React.Component {
  render() {
    const { name, label, subLabel, accessoryRight, formikProps } = this.props;
    const { setFieldTouched, setFieldValue, values } = formikProps;

    return (
      <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
        <Button
          onPress={() => {
            setFieldTouched(name);
            setFieldValue(name, !values[name]);
          }}
          plain
        >
          <View style={ThemeStyle.flexDirectionRowCenter}>
            <CheckBox
              status={values[name] ? 'success' : 'basic'}
              checked={values[name]}
              onChange={() => {
                setFieldTouched(name);
                setFieldValue(name, !values[name]);
              }}
              style={styles.checkboxStyle}
            />

            <View>
              <Text category="label">{label}</Text>
              {!!subLabel && <Text category="c1">{subLabel}</Text>}
            </View>
          </View>
        </Button>

        {accessoryRight}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  checkboxStyle: {
    marginRight: globalSpacing / 2,
  },
});

FormFieldCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  accessoryRight: PropTypes.any,
};

export default withFormik(FormFieldCheckbox);
