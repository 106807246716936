import moment from 'moment';
import dayjs from 'dayjs';
import lodashFindIndex from 'lodash/findIndex';

import constants from '../Config/constants';

const { DBTIME_FORMAT, TIME_ONLY_DISPLAY_FORMAT } = constants;

function getFormattedTime(time) {
  // time is 24 hours format
  return dayjs(`1/1/1 ${time}`).format(TIME_ONLY_DISPLAY_FORMAT);
}

function getGuestCountList(numberOfGuest) {
  const guestNum = [];
  for (let i = 2; i <= numberOfGuest; i++) {
    guestNum.push(i);
  }
  return guestNum;
}

function getNearestTime(selectedTime, timeSlots) {
  const isAsap = getNowTimeslot() === selectedTime; // check if asap
  const selectedTimeDBformat = dayjs(`1/1/1 ${selectedTime}`).format(
    DBTIME_FORMAT
  ); // format filter time
  const selectedTimeIndex = lodashFindIndex(timeSlots, {
    time: selectedTimeDBformat,
  }); // get its index to timeslot
  let data = [];
  if (isAsap) {
    // if asap, show first nearest timeslot as next timeslot to current time to not show the past time
    data = [
      timeSlots[selectedTimeIndex]?.time,
      timeSlots[selectedTimeIndex + 1]?.time,
      timeSlots[selectedTimeIndex + 2]?.time,
    ];
  } else {
    // if not asap, then show first the previous timeslot base on the time
    data = [
      timeSlots[selectedTimeIndex - 1]?.time,
      timeSlots[selectedTimeIndex]?.time,
      timeSlots[selectedTimeIndex + 1]?.time,
    ];
  }
  return data.sort((a, b) => !a - !b); // move the empty time to last
}

function getNowTimeslot() {
  const start = moment(new Date());
  const remainder = 30 - (start.minute() % 30);
  const dateTime = moment(start)
    .add(remainder, 'minutes')
    .format(TIME_ONLY_DISPLAY_FORMAT);
  return dateTime;
}

function getTimeslots() {
  const startString = '12:00 am';
  const endString = '10:00 pm';
  const timeSlots = [];
  const start = moment(startString, TIME_ONLY_DISPLAY_FORMAT);
  const end = moment(endString, TIME_ONLY_DISPLAY_FORMAT);
  start.minutes(Math.ceil(start.minutes() / 30) * 30);

  const current = moment(start);
  while (current <= end) {
    if (timeSlots.includes(current.format(TIME_ONLY_DISPLAY_FORMAT))) {
      return null;
    } else {
      timeSlots.push(current.format(TIME_ONLY_DISPLAY_FORMAT));
      current.add(30, 'minutes');
    }
  }

  return timeSlots;
}

export default {
  getFormattedTime,
  getGuestCountList,
  getNearestTime,
  getNowTimeslot,
  getTimeslots,
};
