import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import { filter } from '../../RTK/defaultValues';
import { isDefaultFilter } from '../../Helper';
import { updateFilter, removeFilter } from '../../RTK/filter';

import ListingHeading from '../HorizontalListing/Heading';

import data from './tagData';

import ListTag from './ListTag';

import useModalPrompt from '../Web/Modal/ModalPrompt/hooks/useModalPrompt';

import { MODALPROMPT } from '../Web/Modal/ModalPrompt/config';

const { useCase, filterType } = filter;

const FilterTag = ({
  isLoading = false,
  useIn = useCase.HOME,
  resultCount = 0,
}) => {
  const filterHomeCategory = useSelector(
    (state) => state.filter?.home?.category
  );
  const filterHomeTags = useSelector((state) => state.filter?.home?.tags);
  const filterSearch = useSelector((state) => state.filter?.search?.tags);
  const dispatch = useDispatch();

  const { showModalPrompt } = useModalPrompt();

  const filterTags = useIn === useCase.HOME ? filterHomeTags : filterSearch;
  const filterCategory = useIn === useCase.HOME ? filterHomeCategory : [];
  const isFilterDefault = isDefaultFilter(filterTags, filterCategory, useIn);
  const showResultCount = !isFilterDefault && !isLoading;

  const _saveToRedux = (data) => {
    dispatch(updateFilter({ useCase: useIn, type: filterType.TAG, ...data }));
  };
  //POSSIBLE modalName value are below
  //Dropdown
  //RatingSelector
  ///DistanceSelector

  const _onFilterSelect = (data) => async () => {
    const isHome = useIn === useCase.HOME; // check if filter in home
    const filterTags = isHome ? filterHomeTags : filterSearch;
    const { sheetData, sheetName: modalName, paramName, id, getLabel } = data;

    if (modalName) {
      const selectedValue = lodashFind(filterTags, { paramName: paramName }); // get selected filter on redux
      if (modalName === 'Dropdown') {
        const result = await showModalPrompt(MODALPROMPT.orderType, {
          value: selectedValue?.values[0].value,
          options: sheetData,
        });

        if (!lodashIsEmpty(result)) {
          const valueToSave = [{ label: result.label, value: result.value }];
          _saveToRedux({
            id: 0,
            paramName: paramName,
            label: valueToSave?.map((e) => e.label).join(','),
            values: valueToSave,
          });
        } else {
          _saveToRedux({
            id: id,
            paramName: paramName,
            label: '',
            values: null,
          });
        }
      } else if (modalName === 'RatingSelector') {
        const result = await showModalPrompt(MODALPROMPT.ratingsSelector, {
          value: !lodashIsEmpty(selectedValue)
            ? selectedValue.values[0].value
            : 4,
        });

        if (!lodashIsEmpty(result) && result.value !== null) {
          const valueToSave = [
            {
              label: getLabel({ value: result?.value }),
              value: result?.value,
            },
          ];
          _saveToRedux({
            id: 1,
            paramName: paramName,
            label: valueToSave?.map((e) => e.label).join(','),
            values: valueToSave,
          });
        }
        ///return only null value
        if (result?.value === null) {
          _saveToRedux({
            id: 1,
            paramName: paramName,
            label: '',
            values: null,
          });
        }
      } else if (modalName === 'DistanceSelector') {
        const result = await showModalPrompt(MODALPROMPT.distanceSelector, {
          value: selectedValue?.values[0]?.value,
        });

        if (!lodashIsEmpty(result)) {
          const valueToSave = [
            { label: getLabel({ value: result.value }), value: result.value },
          ];
          _saveToRedux({
            id: 2,
            paramName: paramName,
            label: valueToSave?.map((e) => e.label).join(','),
            values: valueToSave,
          });
        }
      }
    }
  };

  const _handleResetPressed = async () => {
    await dispatch(removeFilter(useIn));
  };

  return (
    <Fragment>
      <ListTag
        data={data}
        filterTags={filterTags}
        onFilterSelect={_onFilterSelect}
        filterHomeTags={filterHomeTags}
        filterSearch={filterSearch}
        useIn={useIn}
      />

      {showResultCount && (
        <ListingHeading
          title={resultCount > 0 ? 'Results' : ''}
          seeAllText="Reset"
          onPress={!isFilterDefault ? _handleResetPressed : null}
          noHPadding
        />
      )}
    </Fragment>
  );
};

export default FilterTag;
