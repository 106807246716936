import Service from './';

type ShareLink = {
  message: string;
  linkToShare: string;
};
const shareLink = ({ message, linkToShare }: ShareLink): void => {
  Service.copyToClipboard(`${message}\n\n${linkToShare}`);
};

export default {
  shareLink,
};
