import React, { useRef, useState } from 'react';
import ActionSheet, { ActionSheetRef } from 'react-native-actions-sheet';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { EvaStatus } from '@ui-kitten/components/devsupport';

import TextInput from '../../Components/TextInput';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import Button from '../Button';

import commonProps from './commonProps';
import styles from './styles';

type DataStateInput = {
  name: string;
  placeholder?: string;
  type?: string;
  value?: string;
};
type DataState = {
  buttonCancelText?: string;
  buttonOkText?: string;
  inputs?: DataStateInput[];
  message?: string;
  title?: string;
  buttonOkStatus?: EvaStatus;
  buttonCancelStatus?: EvaStatus;
};
const PromptWithInputSheet: React.FC = ({ sheetId }: { sheetId: string }) => {
  const sheetRef = useRef<ActionSheetRef>();
  const inputValueRef = useRef<{ [key: string]: string }>();
  const [data, setData] = useState<DataState>();
  inputValueRef.current = {};

  const title = data?.title || '';
  const message = data?.message || '';
  const inputs = data?.inputs || [{ name: 'input', type: 'text' }];
  const buttonOkText = data?.buttonOkText || 'Ok';
  const buttonOkStatus = data?.buttonOkStatus || 'success';
  const buttonCancelText = data?.buttonCancelText || 'Cancel';
  const buttonCancelStatus = data?.buttonCancelStatus || 'control';

  const _onClose = () => sheetRef.current.hide();

  const _onInputChange = (name: string, value: string) => {
    inputValueRef.current[name] = value;
  };

  const _onOk = () => {
    sheetRef.current.hide(inputValueRef.current);
  };

  return (
    <ActionSheet
      id={sheetId}
      ref={sheetRef}
      onBeforeShow={setData}
      {...commonProps}
    >
      <View style={styles.contentContainer}>
        <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_TITLE}>
          {title}
        </Text>

        <View style={ThemeStyle.spacingBottom}>
          <Text category="p2">{message}</Text>
        </View>

        {inputs.map((input: DataStateInput, index: number) => (
          <View
            style={
              index === inputs.length - 1
                ? ThemeStyle.spacingBottom
                : ThemeStyle.spacingBottomMedium
            }
            key={index}
          >
            <TextInput
              direction="column"
              withBorder
              {...input}
              onChangeText={(value: string) =>
                _onInputChange(input.name, value)
              }
            />
          </View>
        ))}

        <View style={ThemeStyle.flexDirectionRowCenter}>
          <View style={ThemeStyle.flex1}>
            <Button status={buttonCancelStatus} onPress={_onClose}>
              {buttonCancelText}
            </Button>
          </View>
          <View style={ThemeStyle.rowSeparator} />
          <View style={ThemeStyle.flex1}>
            <Button status={buttonOkStatus} onPress={_onOk}>
              {buttonOkText}
            </Button>
          </View>
        </View>
      </View>
    </ActionSheet>
  );
};

export default PromptWithInputSheet;
