import modals from '../../../Components/Sheets/modals';
import constants from '../../../Config/constants';
import ReservationHelper from '../../../Helper/Reservation';

const chevronDownIcon = { name: 'chevron-down-outline', pack: 'eva' };

const filterType = {
  LOCATION: 'location',
  DISTANCE: 'distance',
  TYPE: 'type',
  SEATS: 'seats',
  DATE: 'date',
  TIME: 'time',
};

const guestCount = ReservationHelper.getGuestCountList(10);
const otherFilter = [
  {
    type: filterType.SEATS,
    rightIcon: chevronDownIcon,
    sheetName: modals.DROPDOWN,
    sheetData: {
      title: 'Select a party size',
      options: guestCount.map((gc) => ({ label: `${gc} guests`, value: gc })),
    },
    getLabel: (val) => `Guest: ${val}`,
  },
  {
    type: filterType.DISTANCE,
    sheetName: modals.DISTANCE_SELECTOR,
    getLabel: (val) => `Within ${val} ${constants.DISTANCE_UNIT}`,
  },
];

const reservationType = {
  TABLE: 'table',
  EVENT: 'event',
};

const reservationTypeData = [
  {
    id: '1',
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-27.png',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-26.png',
    label: 'Table',
    value: reservationType.TABLE,
  },
  {
    id: '2',
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-13.png',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-12.png',
    label: 'Event',
    value: reservationType.EVENT,
  },
];

export { filterType, otherFilter, reservationType, reservationTypeData };
