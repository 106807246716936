import { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Layout, Select, SelectItem, Text } from '@ui-kitten/components';
import { useSelector } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import { UAParser } from 'ua-parser-js';
import lodashUniqBy from 'lodash/uniqBy';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../Config/constants';

import ScreenSize from '../../Helper/ScreenSize';
import StoreHelper from '../../Helper/Store';

//THEMES
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

///COMPONENTS
import Button from '../Button';
import PopupModal from '../Web/PopupModal';

import Service from '../../Service';

import routeList from '../../Routes/list';
import { checkout } from '../../RTK/defaultValues';

const { ORDER_TYPES } = constants;

dayjs.extend(customParseFormat);

const DatePicker = ({ isOpen, onClosed, onProceed }) => {
  const { device } = UAParser();
  const { xl } = ScreenSize();
  const route = useRoute();
  const currentDate = dayjs();

  const shopData = useSelector((state) => state.shop.data);
  const checkoutData = useSelector((state) => state.checkout.checkoutData);
  const filterHomeTags = useSelector((state) => state.filter?.home?.tags);
  const whenFilter = useSelector((state) => state.filter.home.when);

  const [isAsap, setAsap] = useState(false);

  const [selectedAdvanceDay, setSelectedAdvanceDay] = useState({});
  const [selectedAdvanceTime, setSelectedAdvanceTime] = useState({});

  const interval = constants.minuteInterval; // Example 8:00, 8:30, 9:00 etc

  const storeCheckoutData = StoreHelper.getStoreCheckoutData(
    shopData?.id,
    checkoutData
  );

  useEffect(() => {
    if (!lodashIsEmpty(whenFilter?.value)) {
      setSelectedAdvanceDay({
        text: dayjs(whenFilter?.value).format('dddd, MMM DD'),
        value: dayjs(whenFilter?.value).format('YYYY-MM-DD'),
      });
      setSelectedAdvanceTime({
        time_slot: !lodashIsEmpty(whenFilter?.value)
          ? `${dayjs(whenFilter?.time, 'hh:mm').format(
              constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
            )} - ${dayjs(whenFilter?.time, 'hh:mm')
              .add(30, 'minutes')
              .format(constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT)}`
          : '',
      });
      return;
    }
    setAsap(lodashIsEmpty(whenFilter));
  }, [whenFilter]);

  const orderType =
    route.name === routeList.HOME_TAB
      ? filterHomeTags.filter((item) => item.paramName === 'order_type') // Filter for home page
      : storeCheckoutData[checkout.keys.ORDER_TYPE]; // for store page

  const isPickup =
    route.name === routeList.HOME_TAB
      ? orderType[0]?.values[0]?.value?.includes(ORDER_TYPES.PICKUP)
      : orderType?.value?.includes(ORDER_TYPES.PICKUP);

  const label = `${isPickup ? 'Pickup' : 'Delivery'}`;

  const orderTypeLabel =
    route.name === routeList.HOME_TAB
      ? orderType.length > 0
        ? label
        : 'Order'
      : label;

  const dateOptions =
    route.name === routeList.HOME_TAB
      ? _generateDateOptions()
      : Service.generateDateOptions({
          storeHours: shopData?.store_hours,
          offDates: shopData?.off_dates,
          daysInAdvance: shopData?.days_accepting_in_advanced_orders,
          prepTime: shopData?.pre_order_to_order_queue_timer,
        });

  const orderAdvanceDay =
    dateOptions?.length > 0
      ? lodashUniqBy(dateOptions, 'date').map((item) => ({
          text: dayjs(item.date).format('dddd, MMM DD'),
          value: item.date,
        }))
      : [{ text: '', value: '' }];

  const orderAdvanceTime = dateOptions
    .filter(({ date }) =>
      selectedAdvanceDay?.value
        ? date === selectedAdvanceDay.value
        : date === dayjs(whenFilter?.value).format(constants.DBDATE_FORMAT)
    ) //filter the selected date value
    .sort((a, b) => a.time - b.time) //sort ascending
    .slice(0, -1) // remove the last item which is the closing time
    .map(({ time }) => ({
      time_slot: `${dayjs(time, 'hh:mm').format(
        constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
      )} - ${dayjs(time, 'hh:mm')
        .add(30, 'minutes')
        .format(constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT)}`,
      value: time,
    }));

  function _generateDateOptions() {
    const options = [];
    const daysArray = [];
    const initial = dayjs().startOf('day');
    const todayDate = initial.format('MM/DD/YYYY');

    for (let i = 0; i <= 7; i++) {
      const nextDay = currentDate.add(i, 'day');
      const storeDate = nextDay.format('MM/DD/YYYY');
      daysArray.push({
        text: nextDay.format('ddd, MMM DD'),
        value: nextDay.format(constants.DBDATE_FORMAT),
        opening: '01:00',
        closing: '23:30',
      });
      const isToday = todayDate === storeDate; // check if date is today

      // loop through time slots
      daysArray.forEach(({ opening, closing }) => {
        const current = dayjs().add(30, 'minute');
        const currentTime = current.format('HH:mm'); // 24 hours, to match DB opening time
        let [startHour, startMinutes] = opening.split(':'); // initial startHour is opening hour
        let [endHour] = closing.split(':'); // initial endHour is closing hour
        let canAsap = false;
        // for checking for start hour, use only current time if today and too late for opening time
        if (isToday && currentTime > opening) {
          // if today and current time is too late for opening time, use current time as start hour
          let currentHour = current.hour();
          let currentMinutes = current.minute();
          let minuteBeforeInterval = interval - currentMinutes;
          if (minuteBeforeInterval < 0) {
            currentHour += 1;
            currentMinutes = 0;
          } else {
            currentMinutes = 30;
          }
          startHour = currentHour;
          startMinutes = currentMinutes;
          canAsap = true;
        }
        // for generating date and time options
        const endTime = dayjs(`1/1/1 ${closing}`);
        let loopHr = Number(startHour);
        let loopMin = Number(startMinutes);
        while (loopHr <= endHour) {
          const endingTime = dayjs(`1/1/1 ${loopHr}:${loopMin}`);
          if (endingTime.isSameOrBefore(endTime)) {
            // if not exceeding store closing hour, generate date and time object
            const option = dayjs(`${storeDate} ${loopHr}:${loopMin}`);
            options.push({
              // prettier-ignore
              label: isToday ? `Today at ${option.format('hh:mm A')}` : option.format(constants.DATE_DISPLAY_FORMAT), // display
              value: option.toISOString(), // reference value
              date: option.format(constants.DBDATE_FORMAT), // this will be sent to checkout api
              time: option.format('HH:mm'), // this will be sent to checkout api
              canAsap,
            });
          }
          // after inserting the time to options, increment the loopHr and loopMin
          loopMin += interval;
          if (loopMin >= 60) {
            // if more than 60 the minutes then +1 to hours and reset minutes to 0
            loopHr += 1;
            loopMin = 0;
          }
        }
      });
    }
    return options;
  }

  const onSelectAdvanceDate = async (index) => {
    //get selected date
    const selectData = orderAdvanceDay[index - 1];
    setSelectedAdvanceDay(selectData);
    setSelectedAdvanceTime({});
  };

  const onSelectAdvanceTime = async (index) => {
    //get selected time
    const selectData = orderAdvanceTime[index - 1];
    setSelectedAdvanceTime(selectData);
  };

  const onPressProceed = async () => {
    if (isAsap) {
      setSelectedAdvanceDay({});
      setSelectedAdvanceTime({});
      onProceed?.(null);
      return;
    }
    if (
      lodashIsEmpty(selectedAdvanceDay.value) &&
      lodashIsEmpty(selectedAdvanceTime.value)
    ) {
      onProceed?.(
        new Date(`${whenFilter.date} ${whenFilter.time}`).toISOString()
      );
      return;
    }
    onProceed?.(
      new Date(
        `${selectedAdvanceDay.value || whenFilter.date} ${
          selectedAdvanceTime.value || whenFilter.time
        }`
      ).toISOString()
    );
  };
  const toggleOrderTime = () => setAsap(!isAsap);

  return (
    <PopupModal
      isOpen={isOpen}
      onClosed={onClosed}
      title={'Order Details'}
      hasHeaderLine
      mainContentWrapperStyle={{
        width: device.model === 'mobile' || !xl ? 'auto' : 550,
        height: 'auto',
      }}
    >
      <Layout
        style={[ThemeStyle.spacingTopSmall, ThemeStyle.spacingBottomSmall]}
      >
        <Layout style={[ThemeStyle.spacingBottom]}>
          <Layout style={styles.container}>
            <Layout style={styles.layout} level="1">
              <Button
                plain
                onPress={!isAsap && toggleOrderTime}
                style={isAsap ? styles.enableBtn : styles.disableBtn}
              >
                <Layout style={styles.innerBtn}>
                  <Text category="p2" status={isAsap && 'control'}>
                    ASAP
                  </Text>
                </Layout>
              </Button>
            </Layout>

            <Layout style={styles.layout} level="1">
              <Button
                plain
                onPress={isAsap && toggleOrderTime}
                style={!isAsap ? styles.enableBtn : styles.disableBtn}
              >
                <Layout style={styles.innerBtn}>
                  <Text category="p2" status={!isAsap && 'control'}>
                    Pre-Order
                  </Text>
                </Layout>
              </Button>
            </Layout>
          </Layout>
        </Layout>
        {!isAsap && (
          <Layout
            style={[ThemeStyle.spacingTopSmall, ThemeStyle.spacingBottomMedium]}
          >
            <Layout style={styles.container}>
              <Layout style={styles.layout} level="1">
                <Layout style={ThemeStyle.spacingBottomSmall} level="1">
                  <Text category="label">{orderTypeLabel} Date</Text>
                </Layout>
                <Select
                  onSelect={(index) => onSelectAdvanceDate(index)}
                  value={
                    selectedAdvanceDay?.text
                      ? selectedAdvanceDay?.text
                      : !lodashIsEmpty(whenFilter?.value)
                      ? dayjs(whenFilter?.value).format('dddd, MMM DD')
                      : ''
                  }
                  size="large"
                >
                  {orderAdvanceDay.map(({ text }, index) => (
                    <SelectItem
                      key={index}
                      title={text}
                      style={{ fontSize: 14 }}
                    />
                  ))}
                </Select>
              </Layout>

              <Layout style={styles.layout} level="1">
                <Layout style={ThemeStyle.spacingBottomSmall} level="1">
                  <Text category="label">
                    {orderTypeLabel === 'Delivery'
                      ? 'Dispatch'
                      : orderTypeLabel}{' '}
                    Time
                  </Text>
                </Layout>
                <Select
                  onSelect={(index) => onSelectAdvanceTime(index)}
                  value={selectedAdvanceTime?.time_slot}
                  size="large"
                >
                  {orderAdvanceTime.map((time, index) => (
                    <SelectItem key={index} title={time.time_slot} />
                  ))}
                </Select>
              </Layout>
            </Layout>
          </Layout>
        )}
        <Button
          disabled={
            isAsap
              ? false
              : (lodashIsEmpty(selectedAdvanceDay.value) ||
                  lodashIsEmpty(selectedAdvanceTime.value)) &&
                lodashIsEmpty(whenFilter?.value)
          }
          plain
          style={styles.proceedBtn}
          onPress={onPressProceed}
        >
          <Text category="p2" status="basic">
            Proceed
          </Text>
        </Button>
      </Layout>
    </PopupModal>
  );
};
const styles = StyleSheet.create({
  container: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionRowCenterSpaceBetween,
  },
  layout: {
    width: '48%',
  },
  selectWhole: {
    flex: 1,
  },
  selectSmall: {
    flex: 1,
    margin: 2,
  },
  enableBtn: {
    backgroundColor: ThemeColor.green,
    flex: 1,
    marginTop: 10,
    borderWidth: 1,
    borderColor: ThemeColor.green,
  },
  disableBtn: {
    backgroundColor: ThemeColor.gray,
    flex: 1,
    marginTop: 10,
    opacity: 0.5,
    elevation: 0,
  },
  innerBtn: {
    height: 50,
    minWidth: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  proceedBtn: {
    ...ThemeStyle.spacingTopMedium,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: ThemeColor.green,
  },
});
export default DatePicker;
