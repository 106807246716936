import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import ThemeStyle from '../../Theme/styles';

import { RectangleLoader } from '../Loader';

import BaseLoader from './indexLoader';
import styles, { ids } from './styles';

function StoreLoader({ fullWidthOnMobile }) {
  const _renderTags = (width) => (
    <View
      style={[
        ThemeStyle.spacingRightSmall,
        { width, borderRadius: 10, overflow: 'hidden' },
      ]}
    >
      <RectangleLoader width="100%" height={19} />
    </View>
  );

  return (
    <View
      dataSet={{
        media: fullWidthOnMobile
          ? ids.storeResponsiveMobileContainer
          : ids.storeResponsiveContainer,
      }}
      style={[
        styles.storeContainer,
        fullWidthOnMobile
          ? styles.storeResponsiveMobileContainer
          : styles.storeResponsiveContainer,
      ]}
    >
      <View style={ThemeStyle.spacingBottomSmall}>
        <BaseLoader widthTitle={false} withSubTitle={false} />
      </View>
      {/* Store name and heart button */}
      <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
        <View style={[ThemeStyle.spacingBottomSmall, { width: '75%' }]}>
          <RectangleLoader width="100%" height={19} />
        </View>
        <RectangleLoader width={19} height={19} />
      </View>
      {/* Delivery duration, distance and rating */}
      <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
        <View style={{ width: '45%' }}>
          <RectangleLoader width="100%" height={19} />
        </View>
        <View style={{ width: '35%' }}>
          <RectangleLoader width="100%" height={19} />
        </View>
      </View>
      {/* Store tags */}
      <View
        style={[ThemeStyle.flexDirectionRowCenter, ThemeStyle.spacingTopSmall]}
      >
        {_renderTags('25%')}
        {_renderTags('35%')}
        {_renderTags('20%')}
      </View>
    </View>
  );
}

StoreLoader.propTypes = {
  fullWidthOnMobile: PropTypes.bool,
};

export default StoreLoader;
