import React from 'react';

import useHomeDatePicker from './useHomeDatePicker';

export default function (Component) {
  return (props) => {
    const { showDatePicker, whenFilter, onConfirm, toggleDateTimePicker } =
      useHomeDatePicker();
    return (
      <Component
        {...props}
        showDatePicker={showDatePicker}
        whenFilter={whenFilter}
        onConfirm={onConfirm}
        toggleDateTimePicker={toggleDateTimePicker}
      />
    );
  };
}
