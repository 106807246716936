import { StyleSheet } from 'react-native';

import ThemeStyle, { globalSpacing } from '../../Theme/styles';

const imageSize = 60;

export default StyleSheet.create({
  listItemContainer: {
    ...ThemeStyle.alignItemsCenter,
    padding: globalSpacing / 2,
  },
  image: {
    width: imageSize,
    height: imageSize,
  },
});
