/*
  logic on this is
  example this is the request
  { url: '/v2/version' }
  this will mutate the url to { url: '/version' }
  this will return the 2 on /v2
*/

export default function (request, isTest) {
  const { url } = request;
  const regex = /\/v\d+/;
  const routeWithoutParams = url.split('?')[0];
  const [version = '/v1'] = routeWithoutParams.match(regex) || [];
  const versionNumber = Number(version.replace('/v', ''));

  // modify the request url to not include the version
  request.url = request.url.replace(regex, '');

  // if (isTest) {
  //   return {
  //     url: request.url,
  //     version: versionNumber,
  //   }
  // }

  return versionNumber;
}
