import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import { globalSpacing } from '../../Theme/styles';

export const scrollProps = {
  bounces: false,
  showsVerticalScrollIndicator: false,
};

export default {
  defaultOverlayOpacity: 0.7,
  gestureEnabled: true,
  safeAreaInsets: { left: 0, bottom: 0, right: 0, top: 0 },
  containerStyle: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    paddingBottom: (constants.isIOS ? 3 : 20) + globalSpacing,
  },
  indicatorStyle: {
    marginTop: globalSpacing / 2,
    backgroundColor: ThemeColor.darkGray,
    width: 150,
    zIndex: 1,
  },
};
