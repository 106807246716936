import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CANCEL_ERROR } from 'apisauce';
import lodashFilter from 'lodash/filter';

import modals from '../../../Components/Sheets/modals';
import constants from '../../../Config/constants';
import useCancellableRequest from '../../../Hooks/useCancellableRequest';

import { checkoutOrderTypeSelector } from '../../../RTK/checkout/selectors';
import storeApi from '../../../Service/api/store';
import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';
import { getPromoDisplayLimit } from '../../../Helper/RemoteConfig';

function usePromoListController() {
  const { createRequest } = useCancellableRequest();
  const storeId = useSelector((state) => state.shop.data?.id);
  const selectedOrderType = useSelector((state) =>
    checkoutOrderTypeSelector(state, storeId)
  );
  const { showModalPrompt } = useModalPrompt();
  const [isGettingPromo, setIsGettingPromo] = useState(true);
  const [promoList, setPromoList] = useState([]); // no need for error prompt, intended to hide if no promo found or error

  const filteredPromoList = lodashFilter(promoList, (pl) => {
    return pl.applies_to.includes(
      selectedOrderType || constants.ORDER_TYPES.DELIVERY
    );
  })
    ?.sort((a, b) => new Date(b.start_date) - new Date(a.start_date)) // sort latest first
    ?.slice(0, getPromoDisplayLimit()); // limit the promo

  useEffect(() => {
    if (storeId) {
      ///the default value store id in web is undefined need to minimize the call of api
      _getPromoList();
    }
  }, [storeId, _getPromoList]); //will call again if the value of storeid is changes

  const _getPromoList = async () => {
    setIsGettingPromo(true);
    const { ok, data, problem } = await createRequest(
      storeApi.getStorePromos,
      storeId
    );
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    // update state
    setIsGettingPromo(false);
    setPromoList(ok ? data : []);
  };

  const _showPromoDetails = (promo) => {
    if (constants.isWeb) {
      showModalPrompt(MODALPROMPT.promotion, promo);
    } else {
      modals.show(modals.PROMO_DETAILS, promo);
    }
  };

  return {
    isGettingPromo,
    promoList: filteredPromoList,
    showPromoDetails: _showPromoDetails,
  };
}

export default usePromoListController;
