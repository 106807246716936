import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

const PromoPercentOff = ({
  fill1,
  fill2,
  fill1_opacity = '100%',
  fill2_opacity = '100%',
  ...props
}) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="17.06 10.85 1045.87 396.88"
    preserveAspectRatio="none"
    {...props}
  >
    <Path
      fill={fill1 || '#cbefda'}
      fillOpacity={fill1_opacity}
      strokeWidth={0}
      d="m1061.98,53.83v101.03c-1.22-.09-2.43-.13-3.67-.13-29.52,0-53.46,24.43-53.46,54.56s23.94,54.56,53.46,54.56c1.23,0,2.45-.06,3.67-.13v101.03c.24,23.49-18.61,42.74-42.1,42.98H59.18c-23.49-.24-42.35-19.47-42.12-42.98v-100.89c29.52,0,53.46-24.43,53.46-54.56s-23.94-54.56-53.46-54.56V53.83c-.22-23.49,18.63-42.74,42.12-42.98h960.69c23.49.24,42.35,19.49,42.1,42.98Z"
    />
    <G
      id="percentage-circle_bold"
      data-name="percentage-circle bold"
      opacity=".37"
    >
      <Path
        id="Path_648"
        data-name="Path 648"
        fill={fill2 || '#8ac1b1'}
        fillOpacity={fill2_opacity}
        strokeWidth={0}
        d="m1058.82,156.32c1.23.01,2.45.06,3.66.17l.45-47.68c-6.88-10.69-14.82-20.62-23.7-29.67-33.35-33.99-79.66-55.25-131.03-55.74-102.74-.98-186.83,81.51-187.81,184.26s81.51,186.83,184.26,187.81c65.03.62,122.59-32.2,156.33-82.44l.45-47.68c-1.22.06-2.43.11-3.67.1-29.52-.28-53.22-24.94-52.94-55.07.29-30.13,24.46-54.33,53.98-54.05Zm-232-20.28c5.04-4.95,11.94-7.95,19.55-7.89,15.21.15,27.41,12.59,27.27,27.8-.15,15.23-12.59,27.43-27.81,27.29-15.21-.16-27.41-12.61-27.27-27.81.09-7.59,3.24-14.45,8.27-19.38Zm20.35,143.52v.06c-5.57,5.33-14.4,5.14-19.73-.43-5.24-5.46-5.16-14.12.19-19.49l123.04-120.71c5.66-5.22,14.49-4.86,19.71.81,4.98,5.4,4.92,13.76-.16,19.08l-123.04,120.69Zm138.9,3.36c-5.02,4.93-11.94,7.95-19.53,7.89v-.02c-15.23-.15-27.43-12.59-27.29-27.8.15-15.21,12.61-27.41,27.81-27.27s27.41,12.59,27.27,27.8c-.07,7.61-3.24,14.45-8.27,19.39Z"
      />
    </G>
  </Svg>
);

export default PromoPercentOff;
