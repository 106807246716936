import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Animated, View } from 'react-native';

import ThemeStyle from '../../Theme/styles';

import Button from '../Button';

const bottomOffset = -300;

class FloatingButtonBottom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bottomPosition: new Animated.Value(
        props.visible ? props.bottomOffset || 0 : bottomOffset
      ),
    };
  }

  componentDidMount() {
    this._animate();
  }

  componentDidUpdate(prevProps) {
    const { visible: prevVisible, bottomOffset: prevBottomOffset } = prevProps;
    const { visible: thisVisible, bottomOffset: thisBottomOffset } = this.props;

    if (thisVisible !== prevVisible) {
      this._animate();
    }

    if (thisBottomOffset !== prevBottomOffset) {
      const btPosition = thisVisible
        ? new Animated.Value(thisBottomOffset || 0)
        : new Animated.Value(bottomOffset);
      this.setState({ bottomPosition: btPosition });
    }
  }

  _animate = () => {
    Animated.timing(this.state.bottomPosition, {
      toValue: this.props.visible ? this.props.bottomOffset || 0 : bottomOffset,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Animated.View
        style={[styles.wrapper, { bottom: this.state.bottomPosition }]}
      >
        <View style={[ThemeStyle.spacingBottomMedium, styles.container]}>
          <View style={[ThemeStyle.pageBackground, ThemeStyle.fullRoundCorder]}>
            <Button style={styles.button} {...rest}>
              {children}
            </Button>
          </View>
        </View>
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
  },
  container: {
    ...ThemeStyle.alignSelfCenter,
    width: '90%',
  },
  button: {
    width: '100%',
  },
});

FloatingButtonBottom.propsTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default FloatingButtonBottom;
