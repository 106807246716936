import { createSlice } from '@reduxjs/toolkit';

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    cookies: '',
  },
  reducers: {
    SET_COOKIES: (state, action) => {
      state.cookies = action.payload;
    },
  },
});

export const { SET_COOKIES } = authenticationSlice.actions;

export default authenticationSlice.reducer;
