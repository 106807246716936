import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Text } from '@ui-kitten/components';

import ThemeStyle from '../../Theme/styles';

import PopupModal from '../Web/PopupModal';

const ErrorHandler = ({ isOpen, onClosed, style, children }) => {
  const { errorTitle, errorMessage } = useSelector((state) => state.error);
  return (
    <PopupModal
      title={errorTitle}
      hasHeaderLine
      isOpen={isOpen}
      onClosed={onClosed}
    >
      <View style={[styles.wrapper_web, style]}>
        {/* <Text category="s1" style={Them}>{errorTitle}</Text> */}

        <Text category="p1" style={ThemeStyle.textCenter}>
          {errorMessage}
        </Text>

        {children}
      </View>
    </PopupModal>
  );
};

const styles = StyleSheet.create({
  wrapper_web: {
    alignItems: 'center',
    paddingHorizontal: 20,
    marginBottom: 20,
  },

  body_container: {
    paddingTop: '5%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  error_title: {
    width: '80%',
    alignSelf: 'center',
    alignItems: 'center',
  },
  close_wrapper: { width: 40 },
  close_icon: { width: 32, height: 22 },
});
export default ErrorHandler;
