import React, { Fragment, useRef, useState } from 'react';
import ActionSheet, { useScrollHandlers } from 'react-native-actions-sheet';
import { View } from 'react-native';
import { Divider, Text } from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';
import lodashIsEmpty from 'lodash/isEmpty';

import SchedulePicker from '../../Components/SchedulePicker';
import constants from '../../Config/constants';
import { getCartTerm } from '../../Helper/RemoteConfig';
import useOrderAgain from '../../Hooks/useOrderAgain';
import ThemeStyle, { deviceHeight } from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import Button from '../Button';
import StoreCartItem from '../StoreCart/CartItem';

import commonProps, { scrollProps } from './commonProps';
import styles from './styles';

const topSpacing = deviceHeight * 0.3 + constants.statusBarHeight;
const maxHeight = deviceHeight - topSpacing;

/**
 * data shape is of item of order history
 */
function OrderAgainSheet({ sheetId }) {
  const sheetRef = useRef();
  const scrollHandlers = useScrollHandlers('orderAgain', sheetRef);
  const {
    scheduleList,
    showScheduleSelection,
    submitting,
    onCancelReOrder,
    onScheduleSelected,
    onSubmit,
  } = useOrderAgain();

  const [data, setData] = useState();

  const list = data?.items || [];

  return (
    <Fragment>
      <ActionSheet
        id={sheetId}
        ref={sheetRef}
        onBeforeShow={setData}
        closable={!submitting}
        {...commonProps}
      >
        <View style={styles.contentContainerNoHPadding}>
          <View style={ThemeStyle.pageHorizontalSpacing}>
            <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_TITLE}>
              This will be added to your {getCartTerm()}
            </Text>
          </View>

          <View style={[ThemeStyle.spacingBottom, { maxHeight }]}>
            <ScrollView {...scrollProps} {...scrollHandlers}>
              {list?.map((item, index) => (
                <Fragment key={index}>
                  <StoreCartItem index={index} sectionIndex={0} {...item} />

                  {index !== list.length - 1 && (
                    <Divider style={ThemeStyle.divider} />
                  )}
                </Fragment>
              ))}
            </ScrollView>
          </View>

          <View style={ThemeStyle.pageHorizontalSpacing}>
            <Button
              status="success"
              onPress={() => onSubmit(data, sheetRef)}
              loading={submitting}
            >
              OK
            </Button>
          </View>
        </View>
      </ActionSheet>

      {!lodashIsEmpty(data) && (
        <SchedulePicker
          scheduleTitle="Select Pre-order"
          scheduleList={scheduleList}
          show={showScheduleSelection}
          onCancel={onCancelReOrder}
          onConfirm={onScheduleSelected}
        />
      )}
    </Fragment>
  );
}

export default OrderAgainSheet;
