import React from 'react';

import withFormik from '../../Hooks/withFormik';
import Dropdown from '../Dropdown';

class FormFieldDropdown extends React.PureComponent {
  _onDropDownChange = (data) => {
    const { name, formikProps } = this.props;
    const { setFieldTouched, setFieldValue } = formikProps;

    setFieldTouched(name);
    setFieldValue(name, data);
  };

  render() {
    const { name, label, options, formikProps } = this.props;
    const { values } = formikProps;

    return (
      <Dropdown
        label={label}
        value={values[name].value}
        options={options}
        onChange={this._onDropDownChange}
      />
    );
  }
}

export default withFormik(FormFieldDropdown);
