import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import ThemeColor from '../../Theme/colors';

const TabBarBasket = (props) => {
  return (
    <Svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      stroke={props?.color || ThemeColor.darkGray}
      strokeWidth={props?.strokeWidth || 40}
      {...props}
    >
      <G id="basket">
        <Path
          id="Path_686"
          data-name="Path 686"
          d="M261.21 540h557.48l-55.76 306.67H316.95L261.22 540zm501.71-111.56L595.69 233.33M316.94 428.44L484.2 233.33M233.33 428.44h613.33V540H233.33V428.44zm195.11 195.11v139.38m222.99-139.38v139.38M540 623.58v139.38"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  );
};

export default TabBarBasket;
