import { useState } from 'react';
import { useNavigation } from '@react-navigation/native';

import constants from '../../../Config/constants';
import UrlParameter from '../../../Helper/UrlParameter';
import useCancellableRequest from '../../../Hooks/useCancellableRequest';
import routeList from '../../../Routes/list';
import storeApi from '../../../Service/api/store';

// General controller for same code for mini and full review list
function useController() {
  const navigation = useNavigation();
  const { createRequest } = useCancellableRequest();

  const [defaultAddReviewStar, setDefaultAddReviewStar] = useState(0);
  const [webAddReview, setWebAddReview] = useState(false);

  const _getStoreReviews = async (storeId, page = 1, sortBy = '') => {
    const params =
      sortBy === ''
        ? `?${UrlParameter.getLimitAndOffset(page)}`
        : `?${UrlParameter.getLimitAndOffset(page)}&sort_by=${sortBy}`;
    const result = await createRequest(storeApi.getRatings, storeId, params);

    return result;
  };

  const _getStoreReviewsSummary = async (storeId, summary = 0) => {
    const result = await createRequest(
      storeApi.getRatingSummary,
      storeId,
      summary
    );

    return result;
  };

  const _mapItemToComponentProps = (item) => {
    const [customFeedback, ...genericFeedback] = item?.store_feedbacks || [];

    return {
      id: item.id,
      name: item.customer,
      rating: item.store,
      date: item.timestamp,
      isVerified: true, // currently default to true, no
      comment: customFeedback,
      feedbacks: genericFeedback,
      items: item?.item_reactions?.map((e) => ({
        id: e.id,
        name: e.item?.name,
        image: e.item?.image,
        active_discount: e.item?.active_discount,
        discount_type: e.item?.discount_type,
        discounted_price: e.item?.discounted_price,
        discount_value: e.item?.discount_value,
        regular_price: e.item?.regular_price,
        price: e.item?.price,
      })),
    };
  };

  const _onAddReview = (data, storeId, storeName) => {
    const selectedStar = typeof data === 'number' ? data : 0;

    if (constants.isWeb) {
      setDefaultAddReviewStar(selectedStar);
      setWebAddReview((prev) => !prev);
      return;
    }

    navigation.navigate(routeList.STORE_ADD_REVIEWS, {
      id: storeId,
      name: storeName,
      defaultAddReviewStar: selectedStar,
    });
  };

  return {
    state: {
      defaultAddReviewStar,
      webAddReview,
    },
    getStoreReviews: _getStoreReviews,
    getStoreReviewsSummary: _getStoreReviewsSummary,
    mapItemToComponentProps: _mapItemToComponentProps,
    onAddReview: _onAddReview,
  };
}

export default useController;
