import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
  useCallback,
  useContext,
  MutableRefObject,
} from 'react';
import {
  ProviderContext,
  ModalIDContext,
  ModalRefContext,
  ModalPayloadContext,
} from './context';
import { ModalRef, Modals } from '../types/types';
import { actionModalEventManager } from './useModalEventManager';

type PropsWithChildren = {
  context?: string;
  children: React.ReactNode;
};

export const providerRegistryStack: string[] = [];

/**
 * Get the current Modal internal ref.
 * @returns
 */

export function useModalRef<SheetId extends keyof Modals = never>(
  id?: SheetId | (string & {})
) {
  return useContext(ModalRefContext) as MutableRefObject<ModalRef<SheetId>>;
}

/**
 * Get the payload this modal was opened with.
 * @returns
 */
export function useModalPayload<ModalId extends keyof Modals = never>(
  id?: ModalId | (string & {})
) {
  return useContext(ModalPayloadContext) as Modals[ModalId]['payload'];
}

/**
 * An object that holds all the modal components against their ids.
 */
export const modalRegistry: {
  [context: string]: { [id: string]: React.ElementType };
} = {
  globalModal: {},
};

export interface ModalProps<ModalId extends keyof Modals = never> {
  modalId: ModalId | (string & {});
  payload?: Modals[ModalId]['payload'];
}

// Registers your Modal with the Modal Provider.
export function registerModal<ModalId extends keyof Modals = never>(
  id: ModalId | (string & {}),
  Modal: React.ElementType,
  ...contexts: string[]
) {
  if (!id || !Modal) return;
  if (!contexts || contexts.length === 0) contexts = ['globalModal'];
  for (let context of contexts) {
    const registry = !modalRegistry[context]
      ? (modalRegistry[context] = {})
      : modalRegistry[context];
    registry[id] = Modal;
    actionModalEventManager.publish(`${context}-on-register`);
  }
}

export const ModalProvider = ({
  context = 'globalModal',
  children,
}: PropsWithChildren) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const modalIds = Object.keys(
    modalRegistry[context] || modalRegistry['globalModal'] || {}
  );
  const onRegister = React.useCallback(() => {
    // Rerender when a new sheet is added.
    forceUpdate();
  }, [forceUpdate]);

  useEffect(() => {
    providerRegistryStack.indexOf(context) > -1
      ? providerRegistryStack.indexOf(context)
      : providerRegistryStack.push(context) - 1;
    const unsub = actionModalEventManager.subscribe(
      `${context}-on-register`,
      onRegister
    );
    return () => {
      providerRegistryStack.splice(providerRegistryStack.indexOf(context), 1);
      unsub?.unsubscribe();
    };
  }, [context, onRegister]);

  const renderModal = (sheetId: string) => (
    <RenderModal key={sheetId} id={sheetId} context={context} />
  );

  return (
    <Fragment>
      {children}
      {modalIds.map(renderModal)}
    </Fragment>
  );
};

const RenderModal = ({ id, context }: { id: string; context: string }) => {
  const [payload, setPayload] = useState();
  const [visible, setVisible] = useState(false);
  const ref = useRef<ModalRef | null>(null);
  const Modal = context.startsWith('$$-auto-')
    ? modalRegistry?.globalModal?.[id]
    : modalRegistry[context]
    ? modalRegistry[context]?.[id]
    : undefined;

  const onShow = useCallback(
    (data: any, ctx = 'globalModal') => {
      if (ctx !== context) return;
      setPayload(data);
      setVisible(true);
    },
    [context]
  );

  const onClose = useCallback(
    (_data: any, ctx = 'globalModal') => {
      if (context !== ctx) return;
      setVisible(false);
      setTimeout(() => {
        setPayload(undefined);
      }, 1);
    },
    [context]
  );

  const onHide = useCallback(
    (data: any, ctx = 'globalModal') => {
      actionModalEventManager.publish(`hide_${id}`, data, ctx);
    },
    [id]
  );

  useEffect(() => {
    if (visible) {
      actionModalEventManager.publish(`show_${id}`, payload, context);
    }
  }, [context, id, payload, visible]);

  useEffect(() => {
    let subs = [
      actionModalEventManager.subscribe(`show_wrap_${id}`, onShow),
      actionModalEventManager.subscribe(`onclose_${id}`, onClose),
      actionModalEventManager.subscribe(`hide_wrap_${id}`, onHide),
    ];
    return () => {
      subs.forEach((s) => s.unsubscribe());
    };
  }, [id, context, onShow, onHide, onClose]);

  if (!Modal) return null;

  return (
    visible && (
      <ProviderContext.Provider value={context}>
        <ModalIDContext.Provider value={id}>
          <ModalRefContext.Provider value={ref}>
            <ModalPayloadContext.Provider value={payload}>
              <Modal modalId={id} payload={payload} />
            </ModalPayloadContext.Provider>
          </ModalRefContext.Provider>
        </ModalIDContext.Provider>
      </ProviderContext.Provider>
    )
  );
};
