import { createSlice } from '@reduxjs/toolkit';
import constants from '../../Config/constants';

import { checkout, mealPlan } from '../defaultValues';

export const mealPlanSlice = createSlice({
  name: 'mealPlan',
  initialState: {
    data: mealPlan.initialValue,
  },
  reducers: {
    addItem: (state, action) => {
      const qty = action.payload.quantity || 1;
      for (let i = 0; i < qty; i++) {
        state.data.items.push({ ...action.payload, quantity: 1 }); // save payload to items
      }
      // NOTE: we dont use .reduce method here, bcs i found on net that below function is much faster than reduce. sauce: https://stackoverflow.com/a/62071369
      // sort state.data.items with exclusive at the end of array
      state.data.items = [
        ...state.data.items.filter((item) => !item.is_exclusive),
        ...state.data.items.filter((item) => item.is_exclusive),
      ];
    },
    removeItem: (state, action) => {
      if (typeof action.payload === 'number') {
        // if has payload, remove individual
        state.data.items.splice(action.payload, 1); // remove item by index
      } else {
        // if no payload, remove all
        state.data.items = mealPlan.initialValue.items;
      }
    },
    reset: (state) => {
      state.data = mealPlan.initialValue;
    },
    setAddressSelection: (state, action) => {
      state.data.checkoutFlag = action.payload;
    },
    setCheckoutData: (state, action) => {
      const { key, data } = action.payload;
      state.data.checkoutData[key] = data;
      if (key === checkout.keys.DELIVERY_ADDRESS && !constants.isWeb) {
        state.data.checkoutFlag = false;
      }
    },
    setItems: (state, action) => {
      state.data.items = action.payload;
    },
    setType: (state, action) => {
      state.data.type = action.payload;
    },
  },
});

export const {
  addItem,
  removeItem,
  reset,
  setAddressSelection,
  setCheckoutData,
  setItems,
  setType,
} = mealPlanSlice.actions;
export default mealPlanSlice.reducer;
