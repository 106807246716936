import { StyleSheet } from 'react-native';

const pinSize = 50;

export default StyleSheet.create({
  mapContainer: {
    width: '100%',
    height: '100%',
  },
  markerStyle: {
    height: pinSize,
    width: pinSize,
  },
});
