import { StyleSheet } from 'react-native';
import { color } from 'react-native-reanimated';

import colors from '../colors';

import commonStyles from './common';

export * from './common';

export const webPaddingHorizontal = 80;

export default StyleSheet.create({
  ...commonStyles,
  bold: {
    fontWeight: 'bold',
    fontFamily: 'Poppins',
  },
  medium: {
    fontWeight: '500',
    fontFamily: 'Poppins',
  },
  regular: {
    fontWeight: 'normal',
    fontFamily: 'Poppins',
  },
  h1: {
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    fontSize: 36,
    fontWeight: '500',
  },
  h2: {
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    fontSize: 30,
    fontWeight: '500',
  },
  h3: {
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    fontSize: 24,
    fontWeight: '500',
  },
  h4: {
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    fontSize: 20,
  },

  h5: {
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: '500',
  },
  h6: {
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: '500',
  },

  textCenter: {
    textAlign: 'center',
    fontFamily: 'Poppins',
  },
  noticeText: {
    color: colors.red,
  },
  noticeTextLight: {
    color: colors.lightRed,
    fontFamily: 'Poppins',
  },
  pageBackground: {
    backgroundColor: colors.white,
    fontFamily: 'Poppins',
  },
  buttonHovered1: {
    backgroundColor: colors.green100,
    borderRadius: 5,
  },
});
