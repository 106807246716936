import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Animated, ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../Config/constants';
import ThemeStyle from '../Theme/styles';
import Header, {
  getListStyle,
  renderTitlePlaceholder,
} from './HeaderWithTitleAnimation';
import List from './List';
import ListInfiniteScroll from './ListInfiniteScroll';

class ScreenWrapperWithTitleAnimation extends React.Component {
  state = {
    scroll: new Animated.Value(0),
    scrollStyle: getListStyle(),
  };

  componentDidMount() {
    this.setState({ scrollStyle: getListStyle() });
  }

  render() {
    const {
      accessoryRight,
      children,
      data,
      infiniteScroll,
      ListHeaderComponent,
      renderFooter,
      renderItem,
      statusBar,
      title,
      onBackPress,
      onRef,
      ...rest
    } = this.props;
    const hasRenderFooter = typeof renderFooter === 'function' ? true : false;
    const isList = !lodashIsEmpty(data);

    if ((isList && renderItem) || infiniteScroll) {
      const listCommonProps = {
        contentContainerStyle: this.state.scrollStyle,
        style: ThemeStyle.pageBackground,
        bounces: false,
        scrollEventThrottle: constants.scrollThrottle,
        addStatusbarOffset: true,
        onScroll: Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: {
                  y: this.state.scroll,
                },
              },
            },
          ],
          { useNativeDriver: false }
        ),
      };

      return (
        <Fragment>
          {!constants.isWeb && (
            <Header
              title={title}
              scroll={this.state.scroll}
              accessoryRight={accessoryRight}
              statusBar={statusBar}
              withBorder
            />
          )}

          {infiniteScroll ? (
            <ListInfiniteScroll
              ref={(r) => onRef?.(r)}
              ListHeaderComponent={() => {
                return (
                  <Fragment>
                    {!constants.isWeb && (
                      <View style={ThemeStyle.pageHorizontalSpacing}>
                        {renderTitlePlaceholder(title)}
                      </View>
                    )}
                    {ListHeaderComponent && <ListHeaderComponent />}
                  </Fragment>
                );
              }}
              renderItem={renderItem}
              {...listCommonProps}
              {...rest}
            />
          ) : (
            <List
              ref={(r) => onRef?.(r)}
              data={data}
              renderItem={renderItem}
              ListHeaderComponent={() => (
                <Fragment>
                  {!constants.isWeb && (
                    <View style={ThemeStyle.pageHorizontalSpacing}>
                      {renderTitlePlaceholder(title)}
                    </View>
                  )}
                  {ListHeaderComponent && <ListHeaderComponent />}
                </Fragment>
              )}
              ListFooterComponent={() => <SafeAreaView />}
              {...listCommonProps}
              {...rest}
            />
          )}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Header
          title={title}
          scroll={this.state.scroll}
          accessoryRight={accessoryRight}
          statusBar={statusBar}
          onBackPress={onBackPress}
          withBorder
        />

        <ScrollView
          ref={(r) => onRef?.(r)}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {
                    y: this.state.scroll,
                  },
                },
              },
            ],
            { useNativeDriver: false }
          )}
          scrollEventThrottle={constants.scrollThrottle}
          bounces={false}
          contentContainerStyle={this.state.scrollStyle}
          showsVerticalScrollIndicator={false}
          {...rest}
        >
          {!constants.isWeb && (
            <View style={ThemeStyle.pageHorizontalSpacing}>
              {renderTitlePlaceholder(title)}
            </View>
          )}
          <View style={[ThemeStyle.flex1, ThemeStyle.spacingTopMedium]}>
            {children}
          </View>
        </ScrollView>

        {hasRenderFooter && (
          <View style={ThemeStyle.pageBackground}>
            <SafeAreaView edges={['right', 'bottom', 'left']}>
              {renderFooter()}
            </SafeAreaView>
          </View>
        )}
      </Fragment>
    );
  }
}

ScreenWrapperWithTitleAnimation.propTypes = {
  onRef: PropTypes.func,
  data: PropTypes.array,
  title: PropTypes.string,
  statusBar: PropTypes.bool,
  infiniteScroll: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  accessoryRight: PropTypes.node,
  renderItem: PropTypes.func,
  renderFooter: PropTypes.func,
};

export default ScreenWrapperWithTitleAnimation;
