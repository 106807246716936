import { useDispatch, useSelector } from 'react-redux';
import { CANCEL_ERROR } from 'apisauce';

import constants from '../Config/constants';
import Sentry from '../Helper/Sentry';
import useCancellableRequest from '../Hooks/useCancellableRequest';
import { setAsLoggedIn } from '../RTK/user';
import { isLoggedInSelector } from '../RTK/user/selectors';
import { setCountdown } from '../RTK/noSession';
import Service from '../Service';
import userApi from '../Service/api/user';
import Cookie from '../Service/Cookie';

import usePolling from './usePolling';

const sessionRenewalInterval = 45 * 60000; // 45 multiply by 60 seconds = 45 minutes
export default function () {
  const dispatch = useDispatch();
  const { createRequest } = useCancellableRequest();
  const isLoggedIn = useSelector(isLoggedInSelector);

  // call polling if user isLoggedIn
  usePolling(userApi.getUser, sessionRenewalInterval, isLoggedIn);

  const _sendOtp = async ({ formData, onSuccess, onError }) => {
    const { ok, data, status, problem } = await createRequest(
      userApi.signIn,
      formData
    );
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    // proceed the logic
    if (ok && data?.success) {
      await dispatch(setCountdown());
      onSuccess();
    } else {
      const message = typeof data === 'string' ? data : data?.message;
      Sentry.reportError('Error requesting OTP', data);
      if (message === 'UNHANDLED_ERROR') {
        onError({
          api: {
            message: 'Cannot process request at the moment.',
          },
        });
      } else if (status === 400 && Array.isArray(message)) {
        onError(Service.handleFormError(message));
      } else {
        onError({
          api: {
            title: 'Please try again later.',
            message: Service.handleErrorMessage(message || problem),
          },
        });
      }
    }
  };

  const _verifyOtp = async ({
    formData,
    onSuccess,
    onError,
    shouldLogin = true,
  }) => {
    const payload = {
      email: formData.email,
      code: formData.otp,
    };
    const { ok, data, headers, problem } = await createRequest(
      userApi.verifyOtp,
      payload
    );
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    // proceed the logic
    if (ok && data?.success) {
      if (!constants.isWeb) {
        await Cookie.set(headers['set-cookie']); // save session cookie on mobile, on web its automatic
      }
      await onSuccess?.(); // call on success if has
      if (shouldLogin) dispatch(setAsLoggedIn()); // set login state as logged in
    } else {
      const message = typeof data === 'string' ? data : data?.message;
      let title = 'Ooops!';
      let body = Service.handleErrorMessage(message || problem);
      if (
        ['MAX_CHECK_ATTEMPTS_REACHED', 'MAX_SEND_ATTEMPTS_REACHED'].includes(
          message
        )
      ) {
        title = 'Verification Limit Reached';
      } else if (message === 'SERVICE_RATE_LIMITED') {
        title = 'Service Rate Limits Reached';
      } else if (message === 'TOO_MANY_REQUEST') {
        title = 'Too Many Concurrent Requests';
      } else {
        body = 'Please check the OTP and try again.';
      }
      Sentry.reportError('Error verifying OTP', {
        title,
        message: body,
      });
      onError({
        title,
        message: body,
      });
    }
  };

  return { sendOtp: _sendOtp, verifyOtp: _verifyOtp };
}
