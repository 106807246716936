import React from 'react';
import { useFormikContext } from 'formik';

export default function (Component) {
  return (props) => {
    const formikProps = useFormikContext();

    return <Component {...props} formikProps={formikProps} />;
  };
}
