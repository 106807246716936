import React from 'react';
import PropTypes from 'prop-types';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { StyleType } from '@ui-kitten/components';

import ThemeStyle, { globalSpacing } from '../Theme/styles';

import RatingStar from './RatingStar';

const feedbacks = [
  'Well-packed',
  'Flavourful',
  'Excellent',
  'Good Stuff',
  'Will order again',
  'Good food quality',
];

type ReviewBoxProps = {
  starValue: number;
  defaultFeedbacks: string[];
  disabled?: boolean;
  onStarChange: (isDelivery: boolean) => (star: any) => void;
  onFeedbackChange: (data: any) => () => void;
  starStyle?: StyleProp<StyleType>;
  style?: StyleProp<ViewStyle>;
};

const ReviewBox = ({
  starValue,
  defaultFeedbacks,
  onStarChange,
  onFeedbackChange,
  starStyle,
  disabled,
  style,
}: ReviewBoxProps) => {
  return (
    <View style={[styles.reviewStoreContainer, style]}>
      <View>
        <RatingStar
          defaultFeedbacks={defaultFeedbacks}
          disabled={disabled}
          feedbackPlaceholder="Share more thoughts about the product"
          feedbacks={feedbacks}
          starValue={starValue}
          style={starStyle}
          onFeedbackChange={onFeedbackChange}
          onStarChange={onStarChange}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  reviewStoreContainer: {
    borderRadius: 5,
    paddingBottom: globalSpacing,
  },
  iconStyle: {
    width: 15,
    height: 15,
  },
  tooltipContainer: {
    ...ThemeStyle.alignItemsStart,
    borderRadius: 5,
    padding: globalSpacing / 4,
  },
});

export default ReviewBox;
