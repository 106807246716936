import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import constants from '../Config/constants';
import DateHelper from '../Helper/Date';
import ThemeStyle from '../Theme/styles';

const textCategory = constants.isWeb ? 'c1' : 'p1';

function StoreHours({ hours }) {
  return hours.map((ad, x) => (
    <View
      style={[
        ThemeStyle.pageVerticalSpacingSmall,
        ThemeStyle.flexDirectionRowSpaceBetween,
      ]}
      key={x}
    >
      <Text
        category={textCategory}
        style={[
          ThemeStyle.capitalize,
          ad.isToday ? ThemeStyle.bold : ThemeStyle.dimColor,
        ]}
      >
        {DateHelper.convertNumberToDay(ad.day)}
        {ad.isToday ? ' (Today)' : ''}
      </Text>

      {Array.isArray(ad.opening) && ad?.opening?.length > 1 ? (
        <View category={textCategory} style={ThemeStyle.alignItemsEnd}>
          {ad.opening.map((e, i) => (
            <Text
              category="c1"
              style={ad.isToday ? ThemeStyle.bold : ThemeStyle.dimColor}
              key={i}
            >
              {e} - {ad.closing[i]}
            </Text>
          ))}
        </View>
      ) : (
        <Text
          category={textCategory}
          style={ad.isToday ? ThemeStyle.bold : ThemeStyle.dimColor}
        >
          {ad.is_open ? `${ad.opening} - ${ad.closing}` : 'CLOSED'}
        </Text>
      )}
    </View>
  ));
}

StoreHours.defaultProps = {
  hours: [],
};

StoreHours.propTypes = {
  hours: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // "0" or 0 (number of day 0 - sunday, 6 - saturday)
      is_open: PropTypes.bool,
      opening: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // e.g 12:00 AM or ["12:00 AM", "03:00 PM"]
      closing: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // e.g 11:30 PM or ["07:00 PM", "11:30 PM"]
    }).isRequired
  ).isRequired,
};

export default StoreHours;
