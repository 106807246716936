import React, { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, View, ViewProps } from 'react-native';
import { GOOGLE_MAPS_API_KEY, RAZZLE_GOOGLE_MAPS_API_KEY } from '@env';

import Image from './Image';
import { deviceWidth } from '../Theme/styles';
import constants from '../Config/constants';

type Props = {
  height: number;
  markers: Array<{ latitude: number; longitude: number }>;
  zoom;
  style?: StyleProp<ViewProps>;
  width: number;
  withRadius: boolean;
  props?: any;
};
const MapSnapShotPreview = ({
  height = 200,
  markers,
  zoom,
  style,
  width = deviceWidth,
  withRadius,
  ...props
}: PropsWithChildren<Props>) => {
  const size = `${Math.round(width)}x${height}`;
  let staticGmap = `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=${size}&key=${
    constants.isWeb ? RAZZLE_GOOGLE_MAPS_API_KEY : GOOGLE_MAPS_API_KEY
  }&`;

  markers.forEach((marker) => {
    staticGmap += `markers=color:red|${marker.latitude},${marker.longitude}&`;
  });

  return (
    <View
      style={[withRadius ? styles.container : {}, StyleSheet.flatten(style)]}
    >
      {
        //@ts-ignore:JS Code error
        <Image
          source={{ uri: staticGmap }}
          style={[styles.snapshot, { height, width }]}
          resizeMode={'contain'}
        />
      }
      {props.children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  snapshot: {
    width: '100%',
  },
});

export default MapSnapShotPreview;
