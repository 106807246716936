import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Layout, Text } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';
import lodashIsEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import HorizontalListing, {
  getMarginStyle,
} from '../../Components/HorizontalListing';
import ItemCardSmall from '../../Components/Card/ItemSmall';
import RatingStar from '../../Components/RatingStar';
import ThemeStyle from '../../Theme/styles';
import Badge from '../../Components/Badge';

dayjs.extend(relativeTime);

const ReviewItem = ({
  id,
  comment,
  date,
  feedbacks,
  isVerified,
  items,
  name,
  rating,
  onProductPress,
}) => {
  const _renderItem = ({ isFirstOfList, isLastOfList, item }) => (
    <View style={getMarginStyle(isFirstOfList, isLastOfList)}>
      <ItemCardSmall {...item} onPress={() => onProductPress(item)} />
    </View>
  );

  return (
    <Layout dataSet={{ media: ids.mainWrapper }} style={styles.mainWrapper}>
      {/* Author Name */}
      <View>
        <Text category={'p2'}>{name}</Text>
      </View>

      {/* RatingStar, Date rated, is verified order ? */}
      <View
        style={[
          ThemeStyle.flexDirectionRowCenter,
          ThemeStyle.flexWrap,
          { columnGap: 10 },
        ]}
      >
        {/* negative margin must match the starStyle margin */}
        <View>
          <RatingStar
            starValue={rating}
            starSize={15}
            style={{ marginTop: 0 }}
            view
            disabled
          />
        </View>

        {/* Date */}
        {!!date && (
          <Fragment>
            <Text category="c1" appearance="hint">
              {`${dayjs(date).fromNow()}`}
            </Text>
          </Fragment>
        )}
      </View>

      <View
        style={[
          ThemeStyle.flexDirectionRowCenter,
          ThemeStyle.flexWrap,
          { columnGap: 10 },
        ]}
      >
        {/* is verified */}
        {isVerified && (
          <Fragment>
            <Text category="c1" status="success">
              Verified Order
            </Text>
          </Fragment>
        )}
      </View>

      {/* Rating comment */}
      {!!comment && (
        <View>
          <Text category="c1">{comment}</Text>
        </View>
      )}

      {/* Rated Feedbacks */}
      {!lodashIsEmpty(feedbacks) && (
        <View style={styles.rateFeedBackContainer}>
          {feedbacks.map((f, i) => (
            <Badge
              type={Badge.TYPES.SEMI_OUTLINE}
              category={Badge.CATEGORIES.BASIC}
            >
              <Text category="c1">{f}</Text>
            </Badge>
          ))}
        </View>
      )}

      {/* Rated items */}
      {!!items?.length && (
        <HorizontalListing
          key={id}
          data={items}
          renderItem={_renderItem}
          style={styles.horizontalListing}
        />
      )}
    </Layout>
  );
};

const { ids, styles } = StyleSheet.create({
  mainWrapper: {
    padding: 10,
    borderRadius: 5,
    rowGap: 5,
  },
  rateFeedBackContainer: {
    ...ThemeStyle.flexWrap,
    ...ThemeStyle.flexDirectionRow,
    columnGap: 10,
    rowGap: 10,
  },
  rateFeedBackItem: {
    ...ThemeStyle.pageHorizontalSpacingSmall,
    ...ThemeStyle.pageVerticalSpacingSmall,
    borderRadius: 20,
  },
  horizontalListing: { ...ThemeStyle.pageBackground },
});

ReviewItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  isVerified: PropTypes.bool,
  comment: PropTypes.string,
  feedbacks: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.array,
  lite: PropTypes.bool,
  onProductPress: PropTypes.func,
};

export default ReviewItem;
