import { useRoute } from '@react-navigation/native';

const useGetCurrentRoute = (isUrLPath = false) => {
  const route = isUrLPath
    ? window?.location?.pathname?.slice?.(1)
    : useRoute().name;
  return route;
};

export default useGetCurrentRoute;
