/**
 * This file contain common style or props for typography of ui kitten
 */

import styles from './styles';

// This usually used in store name and item name in card
export const TITLE = {
  category: 'p1',
  style: styles.bold,
};

// This usually used in store section in homepage and action sheet title
export const SECTION_TITLE = {
  category: 'h6',
};

export const SECTION_TITLE_LIGHT = {
  category: 'p1',
};

// This usually used below of SECTION_TITLE
export const SECTION_SUB_TITLE = {
  category: 'p2',
};
