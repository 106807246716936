// this file contain warning from library and decided to hide it
import { LogBox } from 'react-native';

if (__DEV__) {
  const ignoreWarns = [
    'ViewPropTypes will be removed from React Native', // from react-native-star-rating
    'Sending `onAnimatedValueUpdate` with no listeners registered', // Sending `onAnimatedValueUpdate` with no listeners registered
    'new NativeEventEmitter', // from react-native-element-timer
  ];

  LogBox.ignoreLogs(ignoreWarns);
}
