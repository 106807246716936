import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';

import Button from '../../Components/Button';
import Header from '../../Components/Header';
import ScreenWrapper from '../../Components/ScreenWrapper';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import constants from '../../Config/constants';

function SavedAddressesEmptyState({ onBackPress, onAddNewAddress }) {
  const conditionalProps = !constants.isWeb
    ? {
        header: (
          <Header
            backType="close"
            title="Addresses"
            alignTitle="left"
            actionLeftPress={onBackPress}
            withBorder
          />
        ),
      }
    : null;

  return (
    <ScreenWrapper {...conditionalProps} modal>
      <View
        style={[
          ThemeStyle.flex1,
          ThemeStyle.alignItemsCenter,
          ThemeStyle.justifyContentCenter,
          ThemeStyle.pageHorizontalSpacing,
        ]}
      >
        <View style={ThemeStyle.spacingBottom}>
          <Icon
            name="search-location"
            pack="fa5"
            fill={ThemeColor.green}
            style={{ width: 100, height: 100 }}
          />
        </View>

        <View style={ThemeStyle.spacingBottom}>
          <Text category="h4">It's empty here</Text>
        </View>

        <View style={ThemeStyle.spacingBottom}>
          <Text
            style={[
              ThemeStyle.textCenter,
              !constants.isWeb && ThemeStyle.spacingBottomSmall,
            ]}
          >
            You haven't saved any address yet.
          </Text>
          <Text style={ThemeStyle.textCenter}>
            Click Add New Address to get started.
          </Text>
        </View>

        <Button onPress={onAddNewAddress} status="success" size="small">
          Add New Address
        </Button>
      </View>
    </ScreenWrapper>
  );
}

SavedAddressesEmptyState.propTypes = {
  onBackPress: PropTypes.func.isRequired,
  onAddNewAddress: PropTypes.func.isRequired,
};

export default SavedAddressesEmptyState;
