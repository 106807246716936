import React, { useRef, useState } from 'react';
import { Animated, View } from 'react-native';
import { Spinner, Text } from '@ui-kitten/components';
import { useSelector } from 'react-redux';

import constants from '../../Config/constants';
import useCart from '../../Hooks/useCart';
import {
  selectCartItemTotalQty,
  selectCartItemVariants,
} from '../../RTK/cart/selectors';
import { checkoutOrderTypeSelector } from '../../RTK/checkout/selectors';
import { shopSelector } from '../../RTK/shop/selectors';
import ThemeStyle from '../../Theme/styles';
import { Item } from '../../types/item';

import Button from '../Button';
import modals from '../Sheets/modals';

import QuickCartQtyBadge from './Badge';
import QuickCartIcon from './Icon';
import { buttonSize } from './styles';

type Props = {
  item: Item;
  onError?: Function; // required on web
};
const animationDuration = 250; // ms
const timeoutDuration = 3; // seconds
const QuickCartFull = ({ item, onError }: Props) => {
  const animatedOpacity = useRef(new Animated.Value(0));
  const timeout = useRef<NodeJS.Timeout | undefined>();
  const storeId = useSelector((state) => shopSelector(state)?.id);
  const storeName = useSelector((state) => shopSelector(state)?.name);
  const itemVariants = useSelector((state) =>
    //@ts-ignore selector is not yet typescript file
    selectCartItemVariants(state, storeId, item.id)
  );
  const cartQty = useSelector((state) =>
    //@ts-ignore selector is not yet typescript file
    selectCartItemTotalQty(state, storeId, item.id)
  );
  const orderType = useSelector((state) =>
    //@ts-ignore selector is not yet typescript file
    checkoutOrderTypeSelector(state, storeId)
  );
  const isAddingOrUpdatingCart = useSelector(
    (state: any) => state.cart.isAddingOrUpdatingCart
  );
  const { addOrUpdateCart, removeThisProductOnCart } = useCart();

  const [showButtons, setShowButtons] = useState(false); // this control the show/hide of -/+ button
  const [submitting, setSubmitting] = useState(false);

  const isMealPlan = orderType === constants.ORDER_TYPES.MEAL_PLAN;
  const isAlreadyOnCart = cartQty !== 0;

  const _onIncrement = () => _updateCart(1);

  const _onDecrement = () => _updateCart(-1);

  const _removeSubmitting = () => setSubmitting(false);

  const _onUpdateCartError = (error: any) => {
    if (constants.isWeb) {
      onError?.(error);
    }
    _removeSubmitting();
  };

  const _updateCart = (qty: 1 | -1) => {
    _handleButtonAnimation();
    const itemOnCart = itemVariants[0]; // only need to get the first variant because this function will not be called if it has 2 or more variant
    const updatedQty = qty + (itemOnCart?.quantity || 0);
    const isRemove = updatedQty === 0;
    const itemWithReduxState = {
      ...item,
      cart_details_id: itemOnCart?.cart_details_id,
      extras: itemOnCart?.extras,
      quantity: updatedQty,
    };
    setSubmitting(true);
    if (isRemove) {
      removeThisProductOnCart({
        item: itemWithReduxState,
        storeId: storeId,
        onError: _onUpdateCartError,
        onSuccess: () => {
          _removeSubmitting();
          _handleButtonAnimation(true);
        },
      });
    } else {
      addOrUpdateCart({
        from: 'Quick Cart',
        item: itemWithReduxState,
        orderType: orderType,
        store: { id: storeId, name: storeName },
        onCancel: _removeSubmitting,
        onError: _onUpdateCartError,
        onSuccess: _removeSubmitting,
      });
    }
  };

  const _onQtyBadgePress = () => {
    if (itemVariants.length === 1) {
      _handleButtonAnimation();
    } else {
      modals.show(modals.MULTIPLE_VARIANT, { items: itemVariants });
    }
  };

  const _handleButtonAnimation = async (immediate?: boolean) => {
    if (isMealPlan) {
      // no animation on meal plan
      return;
    }
    clearTimeout(timeout.current); // clear previous timeout
    setShowButtons(true); // update state to show the - + buttons (but it has opacity of default 0)
    if (timeout.current === undefined) {
      // if no timeout yet it means first show of - + buttons, animate it to show
      await _animateOpenOrClose(true); // opening animation
    }
    timeout.current = setTimeout(
      async () => {
        if (submitting) {
          // recursion, do not hide the -/+ buttons if still submitting
          _handleButtonAnimation(immediate);
        } else {
          // if no interaction or not submitting
          await _animateOpenOrClose(false); // closing animation
          setShowButtons(false); // update state to hide the element
          timeout.current = undefined;
        }
      },
      immediate ? 0 : timeoutDuration * 1000
    );
  };

  const _animateOpenOrClose = (isOpen: boolean) => {
    return new Promise((resolve) => {
      Animated.timing(animatedOpacity.current, {
        toValue: isOpen ? 1 : 0,
        duration: animationDuration,
        useNativeDriver: true,
      }).start(resolve);
    });
  };

  if (!isMealPlan && showButtons) {
    return (
      <Animated.View
        style={[
          ThemeStyle.flexDirectionRowCenterCenter,
          ThemeStyle.pageHorizontalSpacingSmall,
          ThemeStyle.pageBackground,
          { opacity: animatedOpacity.current, borderRadius: 50 },
        ]}
      >
        {/* - Button */}
        <QuickCartIcon
          disabled={submitting || isAddingOrUpdatingCart}
          iconName={cartQty > 1 ? 'minus' : 'trash-outline'}
          onPress={_onDecrement}
        />
        {/* Text & Loader */}
        <View
          style={[
            ThemeStyle.flexDirectionRowCenterCenter,
            ThemeStyle.pageHorizontalSpacingSmall,
          ]}
        >
          <Text category="c1">{cartQty || 1}</Text>
          {submitting && (
            <View style={{ position: 'absolute' }}>
              <Spinner status="success" size="large" />
            </View>
          )}
        </View>
        {/* + Button */}
        <QuickCartIcon
          disabled={submitting || isAddingOrUpdatingCart}
          onPress={_onIncrement}
        />
      </Animated.View>
    );
  } else if (isAlreadyOnCart) {
    return (
      <Button
        disabled={isAddingOrUpdatingCart}
        hitSlop={10}
        onPress={_onQtyBadgePress}
        plain
      >
        <QuickCartQtyBadge size={buttonSize} textSize="p1" value={cartQty} />
      </Button>
    );
  }
  return (
    <QuickCartIcon disabled={isAddingOrUpdatingCart} onPress={_onIncrement} />
  );
};

export default QuickCartFull;
