import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import {
  CheckBox,
  Divider,
  Radio,
  RadioGroup,
  Text,
} from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';
import lodashFindIndex from 'lodash/findIndex';

import constants from '../../Config/constants';
import Service from '../../Service';
import ThemeStyle from '../../Theme/styles';

class ProductExtras extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckboxIndex: this._getDefaultValue(props),
      selectedRadioIndex: this._getDefaultValue(props),
    };
  }

  componentDidUpdate(prevProps) {
    // this is for checking the previous and current default value
    const { defaultValue: prevDefaultValue } = prevProps;
    const { defaultValue: currentDefaultValue } = this.props;

    const isPropsDataEqual = lodashIsEqual(
      prevDefaultValue,
      currentDefaultValue
    );

    if (!isPropsDataEqual) {
      this.setState({
        selectedCheckboxIndex: this._getDefaultValue(this.props),
        selectedRadioIndex: this._getDefaultValue(this.props),
      });
    }
  }

  _getDefaultValue = (props) => {
    if (lodashIsEmpty(props.defaultValue)) {
      return props.multiple ? [] : null;
    } else {
      if (props.multiple) {
        return props.defaultValue.map((e) => {
          return lodashFindIndex(props.data, { id: e.id });
        });
      } else {
        return lodashFindIndex(props.data, { id: props.defaultValue.id });
      }
    }
  };

  _onCheckboxChange = (index) => () => {
    const { multipleLimit, data, onSelect } = this.props;
    const checkboxes = JSON.parse(
      JSON.stringify(this.state.selectedCheckboxIndex)
    );
    const foundIndex = lodashFindIndex(checkboxes, (e) => e === index);
    let saveToState = true;

    if (foundIndex === -1) {
      // on not found, check if limit is not yet reach then push. if limit reached do nothing
      if (checkboxes.length !== multipleLimit) {
        checkboxes.push(index);
      } else {
        saveToState = false;
        onSelect();
      }
    } else {
      // found, remove it using the foundIndex
      checkboxes.splice(foundIndex, 1);
    }

    if (saveToState) {
      this.setState({ selectedCheckboxIndex: checkboxes }, () =>
        onSelect(data[index])
      );
    }
  };

  _onRadioChange = (index) => () => {
    const { data, onSelect } = this.props;
    this.setState({ selectedRadioIndex: index }, onSelect(data[index]));
  };

  _getCommonProps = (isSelected, price) => ({
    checked: isSelected,
    status: isSelected ? 'success' : 'basic',
    style: price ? styles.withExtraCharge : styles.withoutExtraCharge,
  });

  _renderExtrasContent = (extras) => () => {
    const hasExtraCharge = !!extras.price;
    const chargeWithoutSign = Math.abs(extras.price); // remove negative sign (-) on the number
    const isNegative = extras.price < 0 ? true : false;

    return (
      <View style={[ThemeStyle.flex1, ThemeStyle.spacingLeft]}>
        <Text category={constants.isWeb ? 'p2' : 'p1'}>{extras.name}</Text>

        {hasExtraCharge && (
          <Text
            category={constants.isWeb ? 'c1' : 'p2'}
            style={ThemeStyle.dimColor}
          >
            {isNegative ? '-' : '+'}
            {Service.commafyNumber(chargeWithoutSign, true)}
          </Text>
        )}
      </View>
    );
  };

  render() {
    const { multiple, multipleMin, multipleLimit, required, data } = this.props;
    const { selectedCheckboxIndex, selectedRadioIndex } = this.state;

    if (multiple) {
      const isSelectAll = multipleMin === multipleLimit;
      const defaultNote = `Select up to ${multipleLimit}`;
      const selectionNote1 = required
        ? `Select ${multipleMin} up to ${multipleLimit}`
        : defaultNote;
      const selectionNote2 = required ? `Select ${multipleLimit}` : defaultNote;
      return (
        <View testID="extras" style={ThemeStyle.pageHorizontalSpacing}>
          <Text style={ThemeStyle.dimColor}>
            {isSelectAll ? selectionNote2 : selectionNote1}
          </Text>
          {data.map((extra, index) => {
            const isSelected = selectedCheckboxIndex.includes(index);
            const isNotLast = index !== this.props.data.length - 1;
            const commonProps = this._getCommonProps(isSelected, extra.price);

            return (
              <React.Fragment key={index}>
                <CheckBox
                  testID={`checkbox${index}`}
                  onChange={this._onCheckboxChange(index)}
                  {...commonProps}
                >
                  {this._renderExtrasContent(extra)}
                </CheckBox>

                {isNotLast && <Divider style={ThemeStyle.indentedDivider} />}
              </React.Fragment>
            );
          })}
        </View>
      );
    } else {
      return (
        <View testID="extras" style={ThemeStyle.pageHorizontalSpacing}>
          <Text style={ThemeStyle.dimColor}>Select 1</Text>
          <View style={ThemeStyle.flexDirectionRowCenter}>
            <RadioGroup style={ThemeStyle.flex1}>
              {data.map((extra, index) => {
                const isSelected = index === selectedRadioIndex;
                const isNotLast = index !== this.props.data.length - 1;
                const commonProps = this._getCommonProps(
                  isSelected,
                  extra.price
                );

                return (
                  <React.Fragment key={index}>
                    <Radio
                      testID={`radio${index}`}
                      onChange={this._onRadioChange(index)}
                      {...commonProps}
                    >
                      {this._renderExtrasContent(extra)}
                    </Radio>

                    {isNotLast && (
                      <Divider style={ThemeStyle.indentedDivider} />
                    )}
                  </React.Fragment>
                );
              })}
            </RadioGroup>
          </View>
        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  withExtraCharge: {
    ...ThemeStyle.pageVerticalSpacingMedium,
  },
  withoutExtraCharge: {
    ...ThemeStyle.pageVerticalSpacing,
  },
});

ProductExtras.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      charge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired
  ).isRequired,
  multiple: PropTypes.bool.isRequired,
  multipleMin: PropTypes.number,
  multipleLimit: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};

export default ProductExtras;
