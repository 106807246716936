import { useRef } from 'react';
import lodashIsEmpty from 'lodash/isEmpty';

/*
examle usage:
getInputProps(1) // 1 is order of input in the UI
getInputProps(2, true) // last params is flag if the form input is last
on submit button of formik
onRef={setSubmitButtonRef} // to set the ref for submit button so on last input it can automatically press it
*/
function useFormField() {
  const fieldsRef = useRef({ current: {} });
  const submitRef = useRef();

  const _getInputProps = (fieldNumber, isLast = false) => {
    // fieldNumber is where the field is located on the UI, must be 1, 2 or 3 etc.
    // NOTE: fieldNumber should be unique and incremental e.g: 1,2,3,4 and so on
    return {
      blurOnSubmit: isLast,
      returnKeyType: isLast ? 'done' : 'next',
      onRef: (r) => (fieldsRef.current[`field${fieldNumber}`] = r),
      onSubmitEditing: _onEnterPress(fieldNumber),
    };
  };

  const _onEnterPress = (fn) => () => {
    const nextFieldKey = `field${fn + 1}`;
    const nextField = fieldsRef.current[nextFieldKey];
    const isLast = lodashIsEmpty(nextField);
    if (isLast) {
      // submit button if last
      submitRef?.current?.props?.onPress?.();
    } else {
      // if not last, move to next input
      nextField?.focus?.();
    }
  };

  // must be set on component mount
  const _setSubmitButtonRef = (ref) => (submitRef.current = ref);

  return {
    getInputProps: _getInputProps,
    setSubmitButtonRef: _setSubmitButtonRef,
  };
}

export default useFormField;
