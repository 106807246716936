import React from 'react';
import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

Icon.loadFont();

function RenderIcon({ name, fill, style }) {
  const { height, width, ...iconStyle } = StyleSheet.flatten(style);
  return <Icon name={name} color={fill} size={height} style={iconStyle} />;
}

const IconProvider = (name) => ({
  toReactElement: (props) => RenderIcon({ name, ...props }),
});

function createIconsMap() {
  return new Proxy(
    {},
    {
      get(target, name) {
        return IconProvider(name);
      },
    }
  );
}

export default {
  name: 'fa',
  icons: createIconsMap(),
};
