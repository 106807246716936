import { createSlice } from '@reduxjs/toolkit';
import lodashFindIndex from 'lodash/findIndex';
import constants from '../../Config/constants';

import { checkout } from '../defaultValues';

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: checkout.initialValue,
  reducers: {
    updateCheckoutDetails: (state, action) => {
      const { store_id, keyToUpdate, keyValue } = action.payload;
      const checkoutDataIndex = lodashFindIndex(state.checkoutData, {
        store_id,
      });
      if (checkoutDataIndex === -1) {
        state.checkoutData = [
          ...state.checkoutData,
          {
            store_id,
            [keyToUpdate]: keyValue,
          },
        ];
      } else {
        state.checkoutData[checkoutDataIndex][keyToUpdate] = keyValue;
      }
      if (keyToUpdate === checkout.keys.DELIVERY_ADDRESS && !constants.isWeb) {
        state.checkoutFlag.addressSelection = false;
      }
    },
    removeCheckoutDetails: (state, action) => {
      const checkoutDataIndex = lodashFindIndex(state.checkoutData, {
        store_id: action.payload,
      });
      if (checkoutDataIndex !== -1) {
        state.checkoutData.splice(checkoutDataIndex, 1);
      }
    },
    setStoreInfo: (state, action) => {
      state.storeInfo = action.payload;
    },
    setAddressSelection: (state, action) => {
      if (action.payload) {
        state.checkoutFlag.addressSelection = true;
        state.checkoutFlag.checkoutStoreId = action.payload;
      } else {
        state.checkoutFlag.addressSelection = false;
        state.checkoutFlag.checkoutStoreId = null;
      }
    },
    setDropOffOptionSelection: (state, action) => {
      if (action.payload) {
        state.checkoutFlag.dropOffOptionSelection = true;
        state.checkoutFlag.checkoutStoreId = action.payload;
      } else {
        state.checkoutFlag.dropOffOptionSelection = false;
        state.checkoutFlag.checkoutStoreId = null;
      }
    },
    setIsPromoOk: (state, action) => {
      state.isPromoOk = action.payload;
    },
    setOrderNumber: (state, action) => {
      state.trackOrder = action.payload;
    },
    clearOrderNumber: (state) => {
      state.trackOrder = '';
    },
    setStoreId: (state, action) => {
      state.checkoutFlag.storeId = action.payload;
    },
  },
});

export const {
  clearOrderNumber,
  removeCheckoutDetails,
  setAddressSelection,
  setDropOffOptionSelection,
  setIsPromoOk,
  setOrderNumber,
  setStoreId,
  setStoreInfo,
  updateCheckoutDetails,
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
