import React from 'react';
import { View } from 'react-native';
import { Divider } from '@ui-kitten/components';

import ItemCardLargeLoader from '../../../Components/Card/ItemLargeLoader';
import ItemCardMediumLoader from '../../../Components/Card/ItemMediumLoader';
import { RectangleLoader } from '../../../Components/Loader';
import constants from '../../../Config/constants';
import { numberToArray } from '../../../Helper';
import ThemeStyle from '../../../Theme/styles';

import { ids, styles } from '../stylesResponsive';

const featuredItemsCount = constants.isWeb ? 10 : 4;
const menuItemCount = 4;

function Menu() {
  return (
    <View dataSet={{ media: ids.wrapper }} style={styles.wrapper}>
      <View
        dataSet={{ media: ids.dividerContainer }}
        style={styles.dividerContainer}
      >
        <Divider
          style={[
            constants.isWeb ? ThemeStyle.divider : ThemeStyle.sectionSeparator,
          ]}
        />
      </View>
      {/* Featured Items */}
      <View style={[ThemeStyle.pageVerticalSpacing, ThemeStyle.paddingLeft]}>
        {/* Title */}
        <RectangleLoader width={200} height={25} />
        {/* List */}
        <View style={[ThemeStyle.flexWrap, ThemeStyle.flexDirectionRow]}>
          {numberToArray(featuredItemsCount).map((d) => (
            <ItemCardMediumLoader
              key={d}
              containerStyle={[ThemeStyle.spacingTop, ThemeStyle.spacingRight]}
            />
          ))}
        </View>
      </View>
      {/* Divider from featured items to menu */}
      <View
        dataSet={{ media: ids.dividerContainer }}
        style={styles.dividerContainer}
      >
        <Divider
          style={[
            constants.isWeb ? ThemeStyle.divider : ThemeStyle.sectionSeparator,
          ]}
        />
      </View>
      {/* Category Menu */}
      <View style={[ThemeStyle.pageVerticalSpacing, ThemeStyle.paddingLeft]}>
        {/* title */}
        <RectangleLoader width={120} height={25} />
        <View
          dataSet={{ media: ids.menuContainer }}
          style={styles.menuContainer}
        >
          {/* Items */}
          {numberToArray(menuItemCount).map((_, i) => (
            <View
              key={i}
              dataSet={{ media: ids.menuItemContainer }}
              style={[
                ThemeStyle.flex1,
                ThemeStyle.spacingTop,
                styles.menuItemContainer,
              ]}
            >
              <ItemCardLargeLoader />
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

export default Menu;
