import React from 'react';
import PropTypes from 'prop-types';
import cardValidator from 'card-validator';
import { PaymentIcon } from 'react-native-payment-icons';
import { StyleSheet, View } from 'react-native';
import { Text, Input, Icon } from '@ui-kitten/components';

import constants from '../Config/constants';
import { formatCardNumber } from '../Helper';
import customMapping from '../Theme/mapping';
import ThemeColor from '../Theme/colors';
import ThemeStyle, { globalSpacing } from '../Theme/styles';
import Button from './Button';

const fieldDirections = ['row', 'column'];
const labelAlignments = ['left', 'center', 'right'];

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false,
      showPassword: false,
    };
  }

  _toggleFocus = () => {
    this.setState({ isFocus: !this.state.isFocus });
  };

  _toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  _onBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }
    this._toggleFocus();
  };

  _onInputChange = (text) => {
    const { type } = this.props;
    const isCardNumber = type === 'cardnumber';
    const isPhone = type === 'phone';
    if (isPhone) {
      if (text?.includes('+63')) {
        text = text.substring(3);
      } else if (text?.[0] === '0') {
        text = text.substring(1);
      }
    } else if (isCardNumber) {
      const gaps = cardValidator.number(text)?.card?.gaps;
      text = formatCardNumber(text, gaps);
    }
    this.props.onChangeText(text);
  };

  render() {
    const {
      type,
      direction,
      label,
      labelProps,
      placeholder,
      alignLabel,
      maxLength,
      showCharacterUsage,
      oneLine,
      textarea,
      noBorder,
      disabled,
      withBackground,
      withBorder,
      withClear,
      style,
      textStyle: tstyle,
      value,
      error,
      onChangeText,
      onRef,
      ...rest
    } = this.props;
    const { showPassword } = this.state;
    const isCardNumber = type === 'cardnumber';
    const isEmail = type === 'email';
    const isPhone = type === 'phone';
    const isNumber = type === 'number';
    const isPassword = type === 'password';
    const showCharacterUsageCondition =
      textarea && showCharacterUsage && !!maxLength;
    const inputProps = { disabled, autoCorrect: false, ...rest };
    let containerStyle = {
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: noBorder ? 0 : 1,
      borderColor: ThemeColor.darkGray,
    };
    let labelStyle = {
      flex: 1,
      textAlign: alignLabel,
      paddingRight: globalSpacing / 2,
    };
    let inputStyle = { ...ThemeStyle.flex3 };
    let inputTextStyle = {};

    if (direction === fieldDirections[1]) {
      containerStyle = {};
      inputStyle = {};
      if (textarea) {
        labelStyle = { marginBottom: 10 };
        if (oneLine) {
          inputTextStyle = {
            ...ThemeStyle.pageHorizontalSpacingSmall,
            marginHorizontal: -9,
            paddingBottom: globalSpacing / 4,
          };
        } else {
          inputTextStyle = {
            ...ThemeStyle.pageHorizontalSpacingSmall,
            marginHorizontal: -9,
            borderRadius: 5,
            minHeight: 120,
            textAlignVertical: 'top',
          };
        }
      } else {
        labelStyle = { marginBottom: globalSpacing / 2 };
        inputTextStyle = {};
      }
    }

    if (isEmail) {
      inputProps.autoCapitalize = 'none';
      inputProps.keyboardType = 'email-address';
    } else if (isPhone) {
      inputTextStyle = {
        marginLeft: constants.isAndroid ? 0 : globalSpacing / 4,
      };
      inputProps.maxLength = 10;
      inputProps.keyboardType = 'numeric';
      inputProps.accessoryLeft = () => (
        <Text style={styles.inputTextStyle}>+63</Text>
      );
    } else if (isPassword) {
      inputProps.secureTextEntry = !showPassword;
      inputProps.accessoryRight = () => (
        <Button
          testID="toggleShowPasswordButton"
          onPress={this._toggleShowPassword}
          plain
        >
          <Icon
            name={showPassword ? 'eye-off' : 'eye'}
            fill={ThemeColor.black}
            style={styles.iconRight}
          />
        </Button>
      );
    } else if (isCardNumber) {
      const result = cardValidator.number(value);
      const { gaps, lengths, type } = result?.card || {};
      const max = lengths?.length || 0;
      const gapsLength = gaps?.length || 0;
      const maxChar = lengths?.[max - 1] + gapsLength; // include the gap/spacing to the last lengths for maxChar
      const cardSize = 25;
      if (!isNaN(maxChar)) {
        inputProps.maxLength = maxChar;
      }
      if (type) {
        inputProps.accessoryLeft = () => (
          <PaymentIcon type={type} width={cardSize} />
        );
      } else {
        inputProps.accessoryLeft = () => (
          <Icon
            name="credit-card-alt"
            fill={ThemeColor.black}
            pack="fa"
            style={{ height: cardSize - 6 }}
          />
        );
      }
    }

    if (isCardNumber || isNumber) {
      inputProps.keyboardType = 'numeric';
    }

    if (value && withClear) {
      inputProps.accessoryRight = () => (
        <Button onPress={() => onChangeText('')} disabled={disabled} plain>
          <Icon
            name="close-circle-outline"
            pack="ion"
            fill={ThemeColor.green}
            style={styles.iconRight}
          />
        </Button>
      );
    }

    if (withBorder) {
      inputStyle.borderColor = ThemeColor.gray;
      inputStyle.borderWidth = 1;
    }

    if (withBackground) {
      inputStyle.backgroundColor = ThemeColor.lightGray;
      inputStyle.borderRadius = 5;
    }

    if (disabled) {
      inputTextStyle.opacity = 0.5;
    }

    const _labelProps = {
      category: 'label',
      style: labelStyle,
      appearance: disabled ? 'hint' : 'default',
      ...labelProps,
    };

    return (
      <View style={[containerStyle, style]}>
        {typeof label === 'string' && <Text {..._labelProps}>{label}</Text>}

        {typeof label === 'function' && label(_labelProps)}

        <View style={[styles.inputWrapper, inputStyle]}>
          <Input
            ref={onRef}
            status={textarea ? 'basic' : 'control'}
            placeholder={placeholder}
            placeholderTextColor={ThemeColor.darkGray}
            textStyle={[styles.inputTextStyle, inputTextStyle, tstyle]}
            onFocus={this._toggleFocus}
            onBlur={this._onBlur}
            onChangeText={this._onInputChange}
            value={value}
            style={[
              ThemeStyle.flex1,
              textarea &&
                this.state.isFocus && { borderColor: ThemeColor.green },
              !!error && { borderColor: ThemeColor.red },
            ]}
            multiline={textarea}
            maxLength={maxLength}
            {...inputProps}
          />
        </View>

        {showCharacterUsageCondition && (
          <View style={[ThemeStyle.spacingTopSmall, ThemeStyle.alignSelfEnd]}>
            <Text category="c1" style={ThemeStyle.dimColor}>
              {value.length}/{maxLength}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

const mappingKey = constants.isWeb ? 'label' : 'paragraph-1';

const styles = StyleSheet.create({
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputTextStyle: {
    color: ThemeColor.black,
    fontFamily: customMapping.strict['text-font-family'],
    fontSize: customMapping.strict[`text-${mappingKey}-font-size`],
    fontWeight: customMapping.strict[`text-${mappingKey}-font-weight`],
  },
  iconRight: {
    width: 20,
    height: 20,
  },
});

TextInput.defaultProps = {
  alignLabel: labelAlignments[2],
  direction: fieldDirections[0],
  disabled: false,
  noBorder: false,
  showCharacterUsage: false,
  textarea: false,
};

TextInput.propTypes = {
  type: PropTypes.string,
  direction: PropTypes.oneOf(fieldDirections),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  labelProps: PropTypes.object,
  placeholder: PropTypes.string,
  alignLabel: PropTypes.oneOf(labelAlignments),
  maxLength: PropTypes.number,
  showCharacterUsage: PropTypes.bool,
  textarea: PropTypes.bool,
  noBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  withBackground: PropTypes.bool,
  withBorder: PropTypes.bool,
  withClear: PropTypes.bool,
  error: PropTypes.string,
  onRef: PropTypes.func,
};

export default TextInput;
