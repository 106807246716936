import appjson from '../../../app.json';

// NOTE, if has link on the string add flag [Link]https://...[/Link] or [Link]gg@gg.com[/Link]

const AppName = `${appjson.displayName} Marketplace App`;

const termsAndConditions = [
  {
    content: `Welcome to ${AppName}! By accessing or using our services, you agree to be bound by these terms. If you do not agree with any part of these terms, please do not use our app.`,
  },
  {
    heading: '1. Acceptance of Terms',
    content: `By using ${AppName}, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. We reserve the right to update or modify these terms at any time without prior notice. It is your responsibility to review these terms regularly.`,
  },
  {
    heading: '2. User Eligibility',
    content: `To use ${AppName}, you must be at least 18 years old and capable of forming a binding contract. If you are using the app on behalf of an organization, you must have the authority to bind that organization to these terms.`,
  },
  {
    heading: '3. Account Registration',
    content: `To access certain features of our app, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.`,
  },
  {
    heading: '4. User Responsibilities',
    contentList: [
      'You are solely responsible for maintaining the confidentiality of your account information.',
      'You are responsible for all activities that occur under your account.',
      'You agree to notify us immediately of any unauthorized use of your account or any other breach of security.',
      `You must not use ${AppName} for any illegal or unauthorized purpose.`,
    ],
  },
  {
    heading: '5. Listing and Transactions',
    contentList: [
      `All listings on ${AppName} must comply with our guidelines.`,
      'Buyers and sellers are responsible for all transactions conducted on the platform.',
      'We are not responsible for the quality, safety, or legality of the items or services listed on our app.',
    ],
  },
  {
    heading: '6. Prohibited Activities',
    content: 'You agree not to engage in any of the following activities:',
    contentList: [
      'Violating any applicable laws or regulations.',
      'Interfering with the proper functioning of the app.',
      'Attempting to bypass security measures.',
      'Engaging in fraudulent or deceptive practices.',
    ],
  },
  {
    heading: '7. Termination of Accounts',
    content: `We reserve the right to suspend or terminate your account at our discretion if we believe you have violated these terms or for any other reason.`,
  },
  {
    heading: '8. Limitation of Liability',
    content: `${AppName} is provided "as is" without any warranties. We shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our app.`,
  },
  {
    heading: '9. Governing Law',
    content: `These terms are governed by and construed in accordance with the laws of the Philippines.`,
  },
  {
    content: `Thank you for using ${AppName}! If you have any questions or concerns, please contact us at [Link]hello@pickup.ph[/Link].`,
  },
];

const reviewGuidelines = [
  {
    content: `We appreciate your willingness to contribute to the ${AppName} community by leaving reviews. Honest and constructive feedback is vital for maintaining a trustworthy and reliable platform. To ensure the quality and fairness of reviews, we have established the following guidelines:`,
  },
  {
    heading: '1. Honest and Genuine Feedback:',
    contentList: [
      'Provide truthful and accurate information based on your own experience with the product or service.',
      'Avoid creating fake or misleading reviews. Reviews should reflect your personal experience.',
    ],
  },
  {
    heading: '2. Respect and Fairness:',
    contentList: [
      'Be respectful in your language and tone. Avoid offensive or inappropriate content.',
      'Focus on the product or service rather than making personal attacks on other users.',
    ],
  },
  {
    heading: '3. Relevant and Helpful Information:',
    contentList: [
      'Share details that are relevant to potential buyers or users.',
      "Include information about the product's quality, functionality, and your overall satisfaction.",
    ],
  },
  {
    heading: '4. Avoid Prohibited Content:',
    contentList: [
      'Do not include any personal information about yourself or others, such as addresses or contact details.',
      'Refrain from posting content that violates our Terms of Service or any applicable laws.',
    ],
  },
  {
    heading: '5. Balanced Perspective:',
    contentList: [
      'Present a balanced view by highlighting both positive and negative aspects of your experience.',
      'If you encounter issues, consider reaching out to the seller or service provider first to resolve problems before leaving a negative review.',
    ],
  },
  {
    heading: '6. No Spam or Self-Promotion:',
    contentList: [
      'Reviews should not be used for self-promotion or to advertise other products or services.',
      'Avoid submitting multiple reviews for the same product or service from the same account.',
    ],
  },
  {
    heading: '7. Language and Clarity:',
    contentList: [
      'Write reviews in a clear and understandable manner.',
      'Avoid excessive use of jargon or technical terms that may confuse other users.',
    ],
  },
  {
    heading: '8. Timeliness:',
    contentList: [
      'Submit reviews in a timely manner to ensure the information is relevant to potential buyers or users.',
    ],
  },
  {
    heading: '9. Reporting Inappropriate Content:',
    contentList: [
      'If you come across a review that violates these guidelines, please report it to our support team.',
    ],
  },
  {
    heading: '10. Moderation and Removal:',
    contentList: [
      'We reserve the right to moderate and remove reviews that violate these guidelines or our Terms of Service.',
    ],
  },
  {
    content: `By adhering to these guidelines, you contribute to creating a helpful and supportive community on ${AppName}. Thank you for being a responsible and valued member of our platform.`,
  },
  {
    content:
      'If you have any questions or concerns about these guidelines, please contact us at [Link]hello@pickup.ph[/Link].',
  },
];

export default {
  termsAndConditions,
  reviewGuidelines,
};
