import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import PropTypes from 'prop-types';
import { NODE_ENV } from '@env';

import Button from './Button';
import CloseButton from './Web/CloseButton';
import VersionText from './VersionText';

import ThemeStyle from '../Theme/styles';
import ThemeColor from '../Theme/colors';

const LeftDrawerContent = ({ data, navigation, children }) => {
  const [active, setActive] = useState(
    window.location.pathname.replace(/^\/|\/$/g, '')
  );
  const isDevelopment = NODE_ENV === 'development';

  const toggleDrawer = () => navigation.getParent('LeftDrawer').closeDrawer();
  const onPress = (item) => {
    setActive(item.value);
    navigation.navigate(item.value, item.params);
  };
  return (
    <View style={styles.container}>
      <View style={styles.closeButtonContainer}>
        <CloseButton onPress={toggleDrawer} />
      </View>
      <DrawerContentScrollView style={styles.drawerContentScrollview}>
        {data?.map((item, index) => {
          const { SideBarIcon, onPressItem, text, value } = item;

          return (
            <Button
              key={index}
              testID="menu_btn"
              status="success"
              onPress={() => (!onPressItem ? onPress(item) : onPressItem())}
              style={styles.drawerItem}
              plain
            >
              <View style={{ width: '15%' }}>
                <SideBarIcon
                  color={active === value ? ThemeColor.green : ThemeColor.black}
                />
              </View>
              <View>
                <Text
                  style={{
                    color:
                      active === value ? ThemeColor.green : ThemeColor.black,
                  }}
                >
                  {text}
                </Text>
              </View>
            </Button>
          );
        })}

        {children}
      </DrawerContentScrollView>
      <View
        style={[ThemeStyle.appVersionContainer, ThemeStyle.spacingBottomMedium]}
      >
        {isDevelopment && <VersionText />}
      </View>
    </View>
  );
};
LeftDrawerContent.propTypes = {
  //string to be displayed at the top of drawer.
  title: PropTypes.string,

  //data to be rendered in drawer component.
  data: PropTypes.array,

  //props for navigation provided by react-navigation.
  navigation: PropTypes.any,

  //it allow to render element body inside the drawer component.
  children: PropTypes.element,
};

const styles = StyleSheet.create({
  container: { flex: 1, height: '100%' },
  closeButtonContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    ...ThemeStyle.spacingTopMedium,
    ...ThemeStyle.pageHorizontalSpacingSmall,
  },
  drawerContentScrollview: { paddingLeft: '5%' },
  drawerItem: {
    borderBottomWidth: 1,
    borderBottomColor: ThemeColor.lightGray,
    width: '90%',
    height: 50,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

export default LeftDrawerContent;
