import { StyleSheet } from 'react-native';

import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

export default StyleSheet.create({
  starStyle: {
    marginHorizontal: globalSpacing / 4,
  },
  feedbackContainer: {
    ...ThemeStyle.buttonContentGraySmall,
    ...ThemeStyle.spacingTopMedium,
    ...ThemeStyle.spacingRightMedium,
  },
  feedbackActive: {
    backgroundColor: ThemeColor.green,
  },
});
