import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActivityIndicator,
  StyleSheet,
  Animated,
  Dimensions,
  View,
  FlatList,
} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Divider } from '@ui-kitten/components';
import { CANCEL_ERROR } from 'apisauce';
import lodashIsEmpty from 'lodash/isEmpty';

import FilterTag from '../../Components/Filter/Tag';
import Header from '../../Components/Header';
import List from '../../Components/List';

import constants from '../../Config/constants';
import AnalyticsHelper from '../../Helper/Analytics';
import UrlParameter from '../../Helper/UrlParameter';
import ScreenSize from '../../Helper/ScreenSize';

import useCancellableRequest from '../../Hooks/useCancellableRequest';
import useFavorite from '../../Hooks/useFavorite';
import { filter } from '../../RTK/defaultValues';
import { removeFilter } from '../../RTK/filter';
import { pushRecentlySearch } from '../../RTK/search';
import routeList from '../../Routes/list';
import searchApi from '../../Service/api/search';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import CuisinesSection from './CuisinesSection';
import NoResult from './NoResult';
import RecentlyViewedSection from './RecentlyViewedSection';
import Result from './Result';
import ResultSuggestionItem from './ResultSuggestionItem';
import SearchSection from './SearchSection';

import useModalPrompt from '../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';

import { MODALPROMPT } from '../../Components/Web/Modal/ModalPrompt/config';

import styles from './styles';

const { useCase } = filter;

const listMinHeight = Dimensions.get('window').height + 100;

const initialState = {
  scroll: new Animated.Value(0),
  isLoading: false,
  listWithSeparator: false,
  searchWithTag: false,
  resultSuggestion: false,
  modalItem: false,
  results: [],
  searchValue: '',
  noResult: false,
  error: '',
};

const Search = () => {
  const dispatch = useDispatch();
  const route = useRoute();
  const navigation = useNavigation();
  const containerRef = useRef(null);
  const listRef = useRef(null);
  const searchRef = useRef(null);

  const { xs } = ScreenSize();

  const filter = useSelector((state) => state.filter.search);

  const { isSavedToFavourite, toggleFavourite, disableFavourite } =
    useFavorite();

  const api = useCancellableRequest();
  const { showModalPrompt } = useModalPrompt();
  const [state, setState] = useState(initialState);

  const _resetScroll = () => {
    state.scroll?.setValue(0); // reset style scroll value to 0
    listRef?._scrollToOffset?.({
      animated: true,
      offset: 0,
    });
  };

  const _apiCallForSearchContain = async (searchValue) => {
    const urlproduct = UrlParameter.getUrlParameterFromFilter({
      tagFilter: filter.tags,
      startWithAmpersand: true,
    });
    setState({
      ...state,
      isLoading: true,
    });

    const { ok, data, problem } = await api.createRequest(
      searchApi.getSearchResults,
      searchValue,
      urlproduct,
      state.searchWithTag
    );

    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }

    setState({
      ...state,
      searchValue,
      isLoading: false,
      listWithSeparator: true,
      resultSuggestion: false,
      results: ok ? data.result : [],
      noResult: !ok || data.result.length === 0,
      error: problem,
    });
    if (!state.searchWithTag) {
      dispatch(pushRecentlySearch(searchValue));
    }
    _resetScroll();
  };

  const _apiCallForSearchSuggestion = async (searchValue) => {
    await dispatch(removeFilter(useCase.SEARCH));
    setState({
      ...state,
      isLoading: true,
    });
    const { ok, data, problem } = await api.createRequest(
      searchApi.getSearchResults,
      searchValue
    );
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    // need to stringify and parse it so in test it didnt mutate on the mock data
    const resultData = ok ? JSON.parse(JSON.stringify(data.result)) : [];
    resultData.push({ searchFor: true, searchValue: searchValue });
    setState({
      ...state,
      isLoading: false,
      listWithSeparator: true,
      resultSuggestion: true,
      results: resultData,
    });
  };

  const _renderHeader = () => {
    return null;
  };

  const _onSearchChanged = (val, immediate) => {
    if (val) {
      setState({ ...state, searchWithTag: false });
      _apiCallForSearchContain(val);
    } else {
      // when pressed the x button on the right of the search input, reset state
      setState(initialState);
      _resetScroll();
    }
    return;
    if (immediate) {
      // has 2nd parameter immediate, meaning search come from text suggestion on this screen such as:
      // recently viewerd and cuisines
      _apiCallForSearchContain(val);
    } else if (val) {
      // make the searchWithTag false, searchWithTag will only become true if user click the cuisines
      // so lets make it false when user type on the search bar
      setState({ searchWithTag: false }, () => {
        _apiCallForSearchSuggestion(val);
      });
    } else {
      // when pressed the x button on the right of the search input, reset state
      setState(initialState, _resetScroll);
    }
  };

  const _onCuisinesOrRecentlyViewedPressed = async (val, isTag = false) => {
    searchRef.current._onInputChange(val, true);
    containerRef.current.scrollToIndex({
      index: 0,
      animated: true,
      viewOffset: 0,
      viewPosition: 0,
    });
    if (!isTag) {
      dispatch(pushRecentlySearch(val));
    }
  };

  const _onItemResultPressed = (item) => () => {
    if (item.searchFor) {
      _apiCallForSearchContain(item.searchValue);
    } else {
      AnalyticsHelper.storeSelection({
        ...item,
        fromSection: `Search result from '${state.searchValue}'`,
      });
      navigation.navigate(routeList.STORE, {
        id: item.id,
        distance: item.distance,
      });
    }
  };

  const _onStoreItemResultPressed = (storeInfo) => (storeItem) => {
    AnalyticsHelper.itemSelection({
      ...storeItem,
      fromSection: `Search result from '${state.searchValue}'`,
    });
    showModalPrompt(MODALPROMPT.itemModal, {
      item: {
        item_id: storeItem?.id,
        ...storeItem,
      },
      route: route,
      navigation: navigation,
    });
  };

  const _renderItem = ({ index, item }) => {
    if (item.search) {
      return (
        <SearchSection
          onRef={searchRef}
          scroll={state.scroll}
          onSearchChange={_onSearchChanged}
          autoFocus
        />
      );
    } else if (item.filter) {
      return (
        <View style={ThemeStyle.spacingLeft}>
          <FilterTag
            isLoading={state.isLoading}
            resultCount={state.results.length}
            useIn={useCase.SEARCH}
          />
        </View>
      );
    } else if (item.noResult) {
      return <NoResult error={state.error} />;
    } else if (state.resultSuggestion) {
      // not using anymore
      return (
        <ResultSuggestionItem
          {...item}
          index={index}
          onPress={_onItemResultPressed(item)}
        />
      );
    } else if (item.recentlyView) {
      return (
        <RecentlyViewedSection onPress={_onCuisinesOrRecentlyViewedPressed} />
      );
    } else if (item.cuisines) {
      return <CuisinesSection onPress={_onCuisinesOrRecentlyViewedPressed} />;
    } else {
      return (
        <Result
          {...item}
          isSaved={isSavedToFavourite(item.id)}
          disableSave={disableFavourite}
          onSave={toggleFavourite(item)}
          onPress={_onItemResultPressed(item)}
          onItemPress={_onStoreItemResultPressed(item)}
          fullWidthOnMobile
        />
      );
    }
  };

  const _renderItemSeparator = ({ leadingItem }) => {
    if (state.listWithSeparator && !leadingItem.search && !leadingItem.filter) {
      return <Divider style={ThemeStyle.divider} />;
    }

    return null;
  };
  const _onCloseModalItem = () => {
    setState({ ...state, modalItem: false });
  };
  const { isLoading, resultSuggestion, results, noResult } = state;
  let listData = [];
  if (noResult) {
    listData = [{ search: true }, { filter: true }, { noResult: true }];
  } else if (lodashIsEmpty(results)) {
    listData = [{ search: true }, { recentlyView: true }, { cuisines: true }];
  } else if (!resultSuggestion) {
    listData = [{ search: true }, { filter: true }, ...results];
  } else {
    listData = [{ search: true }, ...results];
  }

  return (
    <View style={searchStyles.mainWrapper}>
      <Header
        plain={true}
        backButtonText={'Back'}
        onPressBack={() => navigation.goBack()}
      />
      {isLoading && (
        <View style={styles.loaderWrapper}>
          <ActivityIndicator size="large" color={ThemeColor.green} />
        </View>
      )}

      <FlatList
        data={[0]}
        ref={containerRef}
        showsVerticalScrollIndicator
        contentContainerStyle={[
          ThemeStyle.pageBottomSpacer,
          ThemeStyle.flex1,
          !xs
            ? searchStyles.webContentContainerStyle
            : searchStyles.mobileContentContainerStyle,
        ]}
        style={[ThemeStyle.pageBackground, ThemeStyle.spacingTopMedium]}
        renderItem={() => (
          <List
            ref={listRef}
            plain
            data={listData}
            ListHeaderComponent={_renderHeader}
            renderItem={_renderItem}
            ItemSeparatorComponent={_renderItemSeparator}
            stickyHeaderIndices={[1]}
            // to control border bottom of search
            onScroll={Animated.event(
              [
                {
                  nativeEvent: {
                    contentOffset: {
                      y: state.scroll,
                    },
                  },
                },
              ],
              { useNativeDriver: false }
            )}
            showsVerticalScrollIndicator={true}
            scrollEventThrottle={constants.scrollThrottle}
            bounces={false}
          />
        )}
      />
    </View>
  );
};

const searchStyles = StyleSheet.create({
  mainWrapper: {
    ...ThemeStyle.pageBackground,
    height: '100vh',
  },
  webContentContainerStyle: {
    minHeight: listMinHeight,
    paddingHorizontal: '5rem',
  },
  mobileContentContainerStyle: {
    minHeight: listMinHeight,
    paddingHorizontal: '0.2rem',
  },
});

export default Search;
