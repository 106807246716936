import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';

import Button from '../../Components/Button';
import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';

class ListRow extends React.PureComponent {
  render() {
    const {
      status,
      title,
      titleLines,
      subTitle,
      leftAccessory,
      rightAccessory,
      addClickableStyle,
      ...rest
    } = this.props;
    const Container = addClickableStyle ? Button : View;

    return (
      <Container {...rest}>
        <View
          style={[
            ThemeStyle.flex1,
            ThemeStyle.flexDirectionRowCenter,
            ThemeStyle.pageHorizontalSpacing,
            addClickableStyle && ThemeStyle.pageVerticalSpacingMedium,
            addClickableStyle && ThemeStyle.pageHorizontalSpacing,
          ]}
        >
          {leftAccessory}

          <View
            style={[
              styles.textContainer,
              leftAccessory && ThemeStyle.spacingLeft,
              rightAccessory && ThemeStyle.spacingRight,
            ]}
          >
            <Text
              category="p2"
              numberOfLines={titleLines}
              style={styles.textWrap}
            >
              {title}
            </Text>
            {!!subTitle && (
              <Text
                category={constants.isWeb ? 'c1' : 'p2'}
                style={[
                  styles.textWrap,
                  constants.isWeb ? ThemeStyle.regular : ThemeStyle.dimColor,
                ]}
              >
                {subTitle}
              </Text>
            )}
          </View>
          {rightAccessory}
        </View>
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
  },
  textWrap: {
    flex: 1,
    flexWrap: 'wrap',
  },
});

ListRow.defaultProps = {
  status: 'basic',
};

ListRow.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleLines: PropTypes.number,
  subTitle: PropTypes.string,
  leftAccessory: PropTypes.node,
  rightAccessory: PropTypes.node,
  addClickableStyle: PropTypes.bool,
};

export default ListRow;
