import React from 'react';
import { View } from 'react-native';

import ThemeStyle from '../../Theme/styles';

import { CircleLoader, RectangleLoader } from '../Loader';

import styles, { ids } from './styles';

function ItemLargeLoader() {
  return (
    <View style={[ThemeStyle.flex1, styles.itemLargeContainer]}>
      <View
        style={[
          ThemeStyle.flex1,
          ThemeStyle.flexDirectionRowCenter,
          ThemeStyle.spacingRightSmall,
        ]}
      >
        {/* 1st Row, Image */}
        <View style={ThemeStyle.spacingRightMedium}>
          <View
            dataSet={{ media: ids.itemLargeImage }}
            style={styles.itemLargeImage}
          >
            <RectangleLoader width="100%" height="100%" />
          </View>
        </View>
        {/* 2nd Row, Name, Tag, description price etc. */}
        <View style={ThemeStyle.flex1}>
          <View
            style={[
              ThemeStyle.flexDirectionRowSpaceBetween,
              ThemeStyle.spacingBottomSmall,
            ]}
          >
            {/* Name */}
            <View style={[ThemeStyle.flex1, ThemeStyle.spacingRightSmall]}>
              <RectangleLoader width="100%" height={22} />
            </View>
            {/* Tag */}
            <RectangleLoader width={65} height={30} />
          </View>
          {/* Description */}
          <View
            style={[ThemeStyle.spacingBottomMedium, ThemeStyle.spacingRight]}
          >
            <RectangleLoader width="100%" height={50} />
          </View>
          {/* Price */}
          <View style={ThemeStyle.spacingBottomMedium}>
            <RectangleLoader width={75} height={20} />
          </View>
        </View>
      </View>
      {/* Add / Update cart button */}
      <View style={{ position: 'absolute', bottom: 10, right: 15 }}>
        <CircleLoader size={25 * 1.5} />
      </View>
    </View>
  );
}

export default ItemLargeLoader;
