import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import lodashIsEmpty from 'lodash/isEmpty';

import ThemeStyle from '../../Theme/styles';
import { setProductInstructions } from '../../RTK/product';
import ScreenWrapper from '../../Components/ScreenWrapper';
import Header from '../../Components/Header';
import Form from '../../Components/FormFields/Form';
import FormField from '../../Components/FormFields/FormField';
import FormFieldDropdown from '../../Components/FormFields/FormFieldDropdown';
import SubmitButton from '../../Components/FormFields/SubmitButton';

let formInitialValues = {};
const characterLimit = 500;
const dropdownOptions = [
  { label: 'Go with merchant recommendation', value: 'recommendation' },
  { label: 'Refund this item', value: 'refund' },
  { label: 'Contact me', value: 'contact_me' },
  { label: 'Cancel the entire order', value: 'cancel_order' },
];
const formDefaultValues = {
  instruction: '',
  if_unavailable: dropdownOptions[0],
};

class ProductSpecialInstruction extends React.Component {
  constructor(props) {
    super(props);
    if (lodashIsEmpty(props.productInstruction)) {
      formInitialValues = formDefaultValues;
    } else {
      formInitialValues = props.productInstruction;
    }
  }

  _renderHeader = () => (
    <Header title="User Preferences" alignTitle="left" withBorder />
  );

  _handleSubmit = (formData) => {
    this.props.dispatchSetProductInstructions(formData);
    this.props.navigation.goBack();
  };

  render() {
    const isSpecialInstructionAvailable = true;

    return (
      <ScreenWrapper header={this._renderHeader()} statusBar={false}>
        <Form initialValues={formInitialValues} onSubmit={this._handleSubmit}>
          <View style={styles.wrapper}>
            <View style={ThemeStyle.flex1}>
              <FormField
                testID="instruction"
                name="instruction"
                label="Add Special Instructions"
                placeholder="Add any special requests (e.g., food allergies, extra spicy etc.) and the store will do its best to accomodate you."
                direction="column"
                disabled={!isSpecialInstructionAvailable}
                maxLength={characterLimit}
                showCharacterUsage
                textarea
              />

              <FormFieldDropdown
                name="if_unavailable"
                label="If item is unavailable"
                options={dropdownOptions}
              />
            </View>

            <SubmitButton text="Save" />
          </View>
        </Form>
      </ScreenWrapper>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: [
    ThemeStyle.flex1,
    ThemeStyle.pageVerticalSpacingMedium,
    ThemeStyle.pageHorizontalSpacing,
  ],
});

const mapStateToProps = (state) => ({
  productInstruction: state.product?.data?.instructions || {},
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetProductInstructions: (data) =>
    dispatch(setProductInstructions(data)),
});

export { dropdownOptions };
export { formDefaultValues as specialInstructionDefaultValues };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSpecialInstruction);
