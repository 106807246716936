import React, { Fragment } from 'react';
import { Linking, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import { RectangleLoader } from '../../Components/Loader';
import ScreenWrapper from '../../Components/ScreenWrapperWithTitleAnimation';
import ThemeStyle, { deviceHeight } from '../../Theme/styles';

import styles from './styles';
import legalContent from './legalContent';
import constants from '../../Config/constants';

class LegalBindingContract extends React.Component {
  state = {
    loading: true,
    result: '',
  };

  componentDidMount() {
    const { route } = this.props;
    const { key } = route.params;
    this.shouldSetState = true;

    setTimeout(() => {
      if (this.shouldSetState) {
        this.setState({ loading: false, result: legalContent[key] });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this.shouldSetState = false;
  }

  _randomizePercent() {
    const min = 4;
    const max = 10;
    const randomNumber = Math.ceil(Math.random() * (max - min) + min); // 4 - 10
    const multipleByTen = randomNumber * 10; // 40 - 100
    return `${multipleByTen.toFixed(2)}%`; // 40.00% - 100.00%
  }

  _renderLoader() {
    const paragraphCount = 5;
    // generate paragraph count
    return Array.from(Array(paragraphCount).keys()).map((e) => {
      const min = 3;
      const max = 8;
      const linesCount = Math.ceil(Math.random() * (max - min) + min);
      return (
        <View style={ThemeStyle.spacingBottom} key={e}>
          {/* generate paragraph length between min and max */}
          {Array.from(Array(linesCount).keys()).map((l) => (
            <RectangleLoader
              width={this._randomizePercent()}
              height={21}
              key={l}
            />
          ))}
        </View>
      );
    });
  }

  _onHighlightPressed(highlight) {
    const isLink = highlight.includes('http') || highlight.includes('www');
    const isEmail = highlight.includes('@');
    if (isLink) {
      Linking.openURL(highlight);
    } else if (isEmail) {
      Linking.openURL(`mailto:${highlight}`);
    }
  }

  _getContent(content, hasList) {
    const style = hasList ? ThemeStyle.spacingBottomMedium : {};
    const links = content.match(/\[Link\].*?\[\/Link\]/gi);
    if (!lodashIsEmpty(links)) {
      // if has link, create highlight and button + click on it
      // logic for concating the link to proper location
      const contentToReturn = [];
      let start = 0;
      links.forEach((link, i) => {
        const linkWOtag = link.replace(/\[Link\]|\[\/Link\]/gi, '');
        const strLocStart = content.indexOf(link);
        contentToReturn.push(
          <Fragment key={i}>
            {content.substring(start, strLocStart)}
            {/* Note, if we wrap the below text to button and put the onpress there, the alignment of paragraph will messed it up */}
            <Text
              category="p2"
              status="success"
              style={ThemeStyle.bold}
              onPress={() => this._onHighlightPressed(linkWOtag)}
            >
              {linkWOtag}
            </Text>
          </Fragment>
        );
        start = strLocStart + link.length;
      });
      // return the element
      return (
        <View
          style={[
            style,
            ThemeStyle.flexDirectionRow,
            ThemeStyle.flexWrap,
            { alignItems: 'baseline' },
          ]}
        >
          <Text category="p2">{contentToReturn}</Text>
        </View>
      );
    }
    return (
      <Text category="p2" style={style}>
        {content}
      </Text>
    );
  }

  render() {
    const { route } = this.props;
    const { loading, result } = this.state;
    const { title } = route.params;
    const conditionalProps = constants.isWeb
      ? {
          style: { height: deviceHeight / 2 },
          showsVerticalScrollIndicator: true,
        }
      : {};
    return (
      <ScreenWrapper title={title} {...conditionalProps}>
        <View style={styles.wrapper}>
          {loading
            ? this._renderLoader()
            : result.map((r, i) => {
                const hasHeading = Boolean(r.heading);
                const hasContent = Boolean(r.content);
                const hasList = !lodashIsEmpty(r.contentList);
                return (
                  <View key={i} style={ThemeStyle.spacingBottom}>
                    {/* Heading */}
                    {hasHeading && (
                      <Text
                        category="s2"
                        style={[
                          ThemeStyle.bold,
                          ThemeStyle.spacingBottomMedium,
                        ]}
                      >
                        {r.heading}
                      </Text>
                    )}

                    {/* Content */}
                    {hasContent && this._getContent(r.content, hasList)}

                    {/* Content List (bullet) */}
                    {r.contentList?.map((rcl, x) => (
                      <View
                        key={`rcl_${i}_${x}`}
                        style={[
                          ThemeStyle.flexDirectionRow,
                          ThemeStyle.spacingLeftMedium,
                          x !== r.contentList.length - 1 &&
                            ThemeStyle.spacingBottomSmall, // do not apply spacing bottom on last
                        ]}
                      >
                        <Text
                          category="p2"
                          style={ThemeStyle.spacingRightSmall}
                        >
                          &#8226;
                        </Text>
                        <Text category="p2">{rcl}</Text>
                      </View>
                    ))}
                  </View>
                );
              })}
        </View>
      </ScreenWrapper>
    );
  }
}

export default LegalBindingContract;
