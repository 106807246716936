import { useToast as useRNToast } from 'react-native-toast-notifications';

import Toast from '../Components/Toast';
import constants from '../Config/constants';

const { isWeb, SUCCESS_CONFIG, ERROR_CONFIG } = constants;

function useToast() {
  const toast = useRNToast();

  const _hide = (toastId) => {
    if (isWeb) {
      if (toastId) {
        // if has toastId, hide specific toast
        toast.hide(toastId);
      } else {
        // if no toastId, hide all toast
        toast.hideAll();
      }
    } else {
      // no hide toast in mobile, toast in mobile is not stacking
    }
  };

  const _show = (data, isSuccess = true) => {
    if (isWeb) {
      toast.show(data, isSuccess ? SUCCESS_CONFIG : ERROR_CONFIG);
    } else {
      Toast.show(data, isSuccess ? Toast.TYPE_SUCCESS : Toast.TYPE_ERROR);
    }
  };

  return {
    hide: _hide,
    show: _show,
  };
}

export default useToast;
