import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Switch as RNSwitch } from 'react-native';

import constants from '../Config/constants';
import ThemeColor from '../Theme/colors';

class Switch extends React.PureComponent {
  render() {
    const { value, onChange, ...rest } = this.props;
    return (
      <RNSwitch
        trackColor={{ false: ThemeColor.green100, true: ThemeColor.green }}
        thumbColor={value ? ThemeColor.green : ThemeColor.white}
        onValueChange={onChange}
        value={value}
        style={constants.isIOS ? styles.switch : {}}
        {...rest}
      />
    );
  }
}

const styles = StyleSheet.create({
  switch: {
    transform: [{ scaleX: 0.7 }, { scaleY: 0.7 }],
  },
});

Switch.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Switch;
