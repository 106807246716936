import { CancelToken, create } from 'apisauce';
import lodashFindIndex from 'lodash/findIndex';

import config from '../../Config';
import constants from '../../Config/constants';
import store from '../../RTK/store';
import { logout } from '../../RTK/user';

import api from './endpoints';
import getApiVersion from './getApiVersion';

import { RAZZLE_BUILD_MODE } from '@env';

const endpoints = api();

const clientStatic = create({
  baseURL: config.STATIC_API,
  withCredentials: RAZZLE_BUILD_MODE !== 'branded',
});

const clientAWS = create({
  baseURL: config.BUCKET,
});

const client = create({
  baseURL: config.ROOT_API,
  withCredentials: RAZZLE_BUILD_MODE !== 'branded',
  timeout: 60000,
  timeoutErrorMessage: { ok: false, data: { message: 'Error Timeout' } },
});

const isWhiteListed = (whiteListed, config) => {
  // check if request config url and method is in white listed route
  const res = lodashFindIndex(whiteListed, (d) => {
    if (d.apiVersion) {
      // remove api version prefix to d.url this should handle dynamic api route
      // e.g: /store/:id, /product/:id etc.
      const urlWOapiVersion = d.url.replace(`/v${d.apiVersion}`, '');
      return (
        d.method === config?.method && config?.url.includes(urlWOapiVersion)
      );
    } else {
      return d.method === config?.method && d.url.includes(config?.url);
    }
  });
  // return false if not white listed, return true if white listed
  return res >= 0;
};

const asyncRequestTransform = (request) => async () => {
  let whiteListRoute = [];

  if (request.baseURL && request.baseURL !== config.ROOT_API) {
    // if has baseURL and not same baseURL to ROOT_API, just return
    return;
  }

  const apiVersion = getApiVersion(request);

  if (RAZZLE_BUILD_MODE !== 'branded') {
    request.headers.crossDomain = true;
    request.headers['x-api-version'] = apiVersion;
  }

  if (RAZZLE_BUILD_MODE !== 'branded') {
    // do not cancel request even user is logout on white listed route below
    whiteListRoute = [
      { method: 'post', url: endpoints.USER_SIGNIN },
      { method: 'post', url: endpoints.USER_SIGNIN_VERIFY },
      { method: 'post', url: endpoints.USER },
      { method: 'patch', url: endpoints.USER },
      { method: 'post', url: endpoints.USER_RESET_PASSWORD },
      { method: 'patch', url: endpoints.USER_RESET_PASSWORD, apiVersion },
      { method: 'post', url: endpoints.VERIFY_EMAIL(''), apiVersion },
      // for reservation, in order for it to work without isLoggedIn flag in redux
      { method: 'get', url: endpoints.RESERVATION_SEARCH },
      { method: 'post', url: endpoints.RESERVATION },
      { method: 'post', url: endpoints.RESERVATION_CONFIRM },
      { method: 'post', url: endpoints.RESERVATION_ATTACH_PAYMENT },
      { method: 'get', url: endpoints.RESERVATION_TYPES(''), apiVersion },
      // { method: 'get', url: endpoints.RESERVATION_STATUS(''), apiVersion },
      { method: 'get', url: endpoints.RESERVATION_STATUS(''), apiVersion },
      { method: 'get', url: endpoints.RESERVATION_DETAILS(''), apiVersion },
    ];
  }

  if (
    !constants.isWeb &&
    !store.getState().user.isLoggedIn &&
    !isWhiteListed(whiteListRoute, request)
  ) {
    // Cancel request because user is already logged out and not white listed
    const src = CancelToken.source();
    request.cancelToken = src.token;
    src.cancel();
  }
};

const apiRequestMonitor = async ({ data }) => {
  if (
    constants.isWeb &&
    store.getState().user.isLoggedIn &&
    (data?.statusCode === 401 || data?.statusCode === 403)
  ) {
    store.dispatch(logout());
  }
};

clientStatic.addAsyncRequestTransform(asyncRequestTransform);
client.addAsyncRequestTransform(asyncRequestTransform);

if (RAZZLE_BUILD_MODE !== 'branded') client.addMonitor(apiRequestMonitor);

export { clientStatic, clientAWS };

export default client;
