import React, { Fragment, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { Layout, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import EmptyData from '../../Components/EmptyData';
import Footer from '../../Components/Footer';
import StoreCard from '../../Components/Card/Store';
import StoreCardLoader from '../../Components/Card/StoreLoader';
import useCancellableRequest from '../../Hooks/useCancellableRequest';
import useFavorite from '../../Hooks/useFavorite';
import withController from '../../Hooks/withController';
import ThemeStyle, { webPaddingHorizontal } from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

import useController from './index.controller';

const initialData = {
  has_next_page: false,
  has_prev_page: false,
  page: 1,
  result: [
    { loading: true },
    { loading: true },
    { loading: true },
    { loading: true },
    { loading: true },
    { loading: true },
  ],
  total_pages: 1,
};
const SeeAll = (props) => {
  const { title, getStoresData, onCardPressed } = useController(props);

  const [seeAllStores, setSeeAllStores] = useState(initialData);

  const { disableFavourite, isSavedToFavourite, toggleFavourite } =
    useFavorite();

  useEffect(() => {
    _getStoresData(1);
  }, []);

  const _getStoresData = async (page) => {
    const { ok, data } = await getStoresData(page);
    if (ok) {
      const listData =
        page === 1 ? data.result : [...seeAllStores.result, ...data.result];
      setSeeAllStores({
        ...data,
        result: listData,
      });
    } else {
      const mergedList = [...seeAllStores.result, ...data.result];
      const listData = mergedList.filter((e) => !e.loading);
      setSeeAllStores({
        ...data,
        result: listData,
      });
    }
  };

  const _isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    const paddingToBottom = 30;
    const isNearBottom =
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;

    if (isNearBottom && seeAllStores.has_next_page) {
      _getStoresData(seeAllStores.page + 1);
    }
  };

  const _renderHeader = () => (
    <View style={ThemeStyle.spacingTop}>
      <Text category="h5" style={ThemeStyle.flex1}>
        {title}
      </Text>
    </View>
  );

  const _renderItem = (index, item) => (
    <Layout key={index} style={ThemeStyle.pageVerticalSpacing}>
      {item.loading ? (
        <StoreCardLoader />
      ) : (
        <StoreCard
          {...item}
          isSaved={isSavedToFavourite(item.id)}
          disableSave={disableFavourite}
          onSave={toggleFavourite(item)}
          onPress={onCardPressed(item)}
          fullWidthOnMobile
        />
      )}
    </Layout>
  );

  const _renderEmpty = () => (
    <Layout
      style={[
        ThemeStyle.alignItemsCenter,
        ThemeStyle.pageTopSpacing,
        ThemeStyle.pageBottomSpacer,
      ]}
    >
      <EmptyData type="favorites" svgWidth={300} svgHeight={300} />
    </Layout>
  );

  return (
    <Layout style={ThemeStyle.flex1}>
      <ScrollView
        dataSet={{ media: ids.contentContainer }}
        contentContainerStyle={styles.contentContainer}
        showsVerticalScrollIndicator
        scrollEventThrottle={1500}
        onScroll={({ nativeEvent }) => _isCloseToBottom(nativeEvent)}
      >
        {!lodashIsEmpty(seeAllStores.result) ? (
          <Fragment>
            <Layout>{_renderHeader()}</Layout>
            <Layout dataSet={{ media: ids.container }} style={styles.container}>
              {seeAllStores.result.map((item, index) =>
                _renderItem(index, item)
              )}
            </Layout>
          </Fragment>
        ) : (
          _renderEmpty()
        )}

        <Footer />
      </ScrollView>
    </Layout>
  );
};
const { ids, styles } = StyleSheet.create({
  contentContainer: {
    ...ThemeStyle.pageBackground,
    justifyContent: 'center',
    [`@media ${BreakPoints.xs}`]: {
      paddingHorizontal: webPaddingHorizontal - 70,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingHorizontal: webPaddingHorizontal - 60,
    },
    [`@media ${BreakPoints.md}`]: {
      paddingHorizontal: webPaddingHorizontal - 50,
    },
    [`@media ${BreakPoints.lg}`]: {
      paddingHorizontal: webPaddingHorizontal - 40,
    },
    [`@media ${BreakPoints.xl}`]: {
      paddingHorizontal: webPaddingHorizontal - 30,
    },
    [`@media ${BreakPoints.xxl}`]: {
      paddingHorizontal: webPaddingHorizontal,
    },
  },
  container: {
    display: 'grid',
    ...ThemeStyle.pageHorizontalSpacingSmall,
    columnGap: 10,
    gridColumnGap: 10,

    [`@media ${BreakPoints.md}`]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [`@media ${BreakPoints.lg}`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [`@media ${BreakPoints.xxl}`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [`@media ${BreakPoints.xxxl}`]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
});

export default withController(SeeAll, useCancellableRequest, 'api');
