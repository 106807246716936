import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import EmptyData from '../../Components/EmptyData';
import Service from '../../Service';
import ThemeStyle, { deviceHeight } from '../../Theme/styles';

class NoResult extends React.PureComponent {
  render() {
    const { error } = this.props;

    return (
      <View
        testID="noResultFound"
        style={[
          ThemeStyle.pageVerticalSpacing,
          ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.alignItemsCenter,
          ThemeStyle.justifyContentCenter,
          { height: deviceHeight - 300 }, // flex 1 not working on list item, so we have to this height here to fill available
        ]}
      >
        <EmptyData
          type="filter"
          title={error ? 'An error has occurred' : undefined}
          subtitle={error ? Service.handleErrorMessage(error) : undefined}
        />
      </View>
    );
  }
}

NoResult.propTypes = {
  error: PropTypes.string,
};

export default NoResult;
