import { Text } from '@ui-kitten/components';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import lodashIsUndefined from 'lodash/isUndefined';
import constants from '../../../../Config/constants';

import ThemeStyle from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import Button from '../../../Button';
import ErrorPromptBelowInput from '../../../FormFields/ErrorPromptBelowInput';
import TextInput from '../../../TextInput';
import ModalPrompt from './ModalPrompt';
import useModalPrompt from './hooks/useModalPrompt';

const DistanceSelector = ({ modalId }) => {
  const { hideModalPrompt } = useModalPrompt();
  const [data, setData] = useState<any>();
  const [distance, setDistance] = useState(constants.DEFAULT_DISTANCE);

  useEffect(() => {
    if (!lodashIsUndefined(data?.value) && data?.value !== '10') {
      setDistance(data.value);
    }
  }, [data?.value]);

  const isLessThanMin = distance < constants.DEFAULT_DISTANCE;
  const isMoreThanMax = distance > constants.DISTANCE_MAX;
  const disableViewResult = isLessThanMin || isMoreThanMax;

  const _onClose = () => {
    hideModalPrompt(modalId, {});
  };

  const _onSelectOnList = (data: any) => {
    hideModalPrompt(modalId, data);
  };
  return (
    <ModalPrompt
      id={modalId}
      title={'Set store distance'}
      dataSet={{ media: ids.distanceSelectorModal }}
      mainContentWrapperStyle={styles.distanceSelectorModal}
      hasHeader
      hasHeaderLine
      onCloseButtonClick={_onClose}
      closeOnDocumentClick={false}
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <View>
        <Text category="p1" style={ThemeStyle.spacingBottomMedium}>
          Min. {constants.DEFAULT_DISTANCE}
          {constants.DISTANCE_UNIT}
        </Text>

        <View style={ThemeStyle.spacingBottomMedium}>
          <TextInput
            direction="column"
            type="number"
            placeholder={`e.g: 10 (10 ${constants.DISTANCE_UNIT})`}
            value={String(distance)}
            onChangeText={(text) => setDistance(text)}
            withBackground
          />

          {isLessThanMin && (
            <ErrorPromptBelowInput
              text={`Minimum of ${constants.DEFAULT_DISTANCE} ${constants.DISTANCE_UNIT}`}
            />
          )}
          {isMoreThanMax && (
            <ErrorPromptBelowInput
              text={`More than ${constants.DISTANCE_MAX} ${constants.DISTANCE_UNIT} is not allowed`}
              //  warning
            />
          )}
        </View>

        <Button
          status="success"
          style={ThemeStyle.spacingBottomMedium}
          onPress={() => _onSelectOnList({ value: distance })}
          disabled={disableViewResult}
        >
          View Results
        </Button>

        <Button
          status="control"
          onPress={() => {
            setDistance(constants.DEFAULT_DISTANCE);
            _onSelectOnList({ value: constants.DEFAULT_DISTANCE });
          }}
          disabled={distance == constants.DEFAULT_DISTANCE}
        >
          Reset
        </Button>
      </View>
    </ModalPrompt>
  );
};
const { ids, styles } = StyleSheet.create({
  distanceSelectorModal: {
    [`@media ${BreakPoints.xs}`]: {
      width: 380,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 420,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 440,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 460,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 480,
    },
  },
});
export default DistanceSelector;
