import { useState } from 'react';
import { Alert } from 'react-native';

import modals from '../../../Components/Sheets/modals';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';
import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import constants from '../../../Config/constants';

function useController({ storeId, noHPadding }) {
  const [rating, setRating] = useState(null);
  const { showModalPrompt } = useModalPrompt();

  const _onViewReviewGuidelinesPressed = () => {
    // caller element is hidden for now
    Alert.alert(
      'TBA',
      'Add review guidelines link on web version (open browser)'
    );
  };

  const _setRatingLoaded = (data) => setRating(data);

  const _toggleShowNote = () => {
    if (constants.isWeb) {
      showModalPrompt(MODALPROMPT.publicReviewsNotice, {});
    } else {
      modals.show(modals.PUBLIC_REVIEW);
    }
  };

  return {
    state: { rating },
    storeId,
    noHPadding,
    onViewReviewGuidelinesPressed: _onViewReviewGuidelinesPressed,
    setRatingLoaded: _setRatingLoaded,
    toggleShowNote: _toggleShowNote,
  };
}

export default useController;
