import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import { useFormikContext } from 'formik';
import lodashIsEmpty from 'lodash/isEmpty';

import Button from '../../Components/Button';
import FormField from '../../Components/FormFields/FormField';
import ErrorPromptBelowInput from '../../Components/FormFields/ErrorPromptBelowInput';
import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

const labelName = 'label';
const labelSelectionName = 'labelSelection';

function AddLabelForm({ existingError = '' }) {
  const { setFieldTouched, setFieldValue, values, errors } = useFormikContext();
  const labelSelectionValue = values[labelSelectionName];

  return (
    <View style={!constants.isWeb && ThemeStyle.pageHorizontalSpacing}>
      <Text category={'s2'} style={ThemeStyle.spacingBottomMedium}>
        Add a label
      </Text>

      <View
        style={[
          ThemeStyle.flexDirectionRowCenter,
          ThemeStyle.spacingBottomMedium,
        ]}
      >
        {constants.LABEL_TYPE.map((lt, i) => {
          const activeLabel = labelSelectionValue?.label?.toLowerCase();
          const isSelected = activeLabel === lt.label?.toLowerCase();
          return (
            <Button
              key={i}
              onPress={() => {
                setFieldTouched(labelSelectionName);
                setFieldValue(labelSelectionName, lt);
                setFieldTouched(labelName);
                setFieldValue(labelName, lt.showInput ? '' : lt.label);
              }}
              style={[ThemeStyle.alignItemsCenter, ThemeStyle.spacingRight]}
              plain
            >
              <View
                style={[
                  styles.prefixLabelContainer,
                  isSelected && styles.prefixLabelContainerActive,
                ]}
              >
                <Icon
                  name={lt.icon}
                  fill={isSelected ? ThemeColor.white : ThemeColor.green}
                  style={styles.labelIcon}
                  pack={lt.pack}
                />
              </View>

              <Text category="p2">{lt.label}</Text>
            </Button>
          );
        })}
      </View>

      {labelSelectionValue?.showInput && lodashIsEmpty(existingError) ? (
        <FormField
          direction="column"
          name="label"
          placeholder="Address Name (e.g: Home, Work, etc.)"
          maxLength={20}
          withClear
          withBorder
        />
      ) : (
        <ErrorPromptBelowInput text={errors.label || existingError} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  prefixLabelContainer: {
    ...ThemeStyle.shadowThin,
    ...ThemeStyle.pageBackground,
    ...ThemeStyle.alignItemsCenter,
    ...ThemeStyle.justifyContentCenter,
    ...ThemeStyle.spacingBottomSmall,
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  prefixLabelContainerActive: {
    backgroundColor: ThemeColor.green,
  },
  backIcon: {
    width: 20,
    height: 20,
  },
  labelIcon: {
    width: 25,
    height: 25,
  },
});

export default AddLabelForm;
