import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { FadeOut } from 'react-native-reanimated';

class RectangleLoader extends React.PureComponent {
  render() {
    const { width, height } = this.props;

    return (
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        backgroundColor="#f3f2f2"
        foregroundColor="#e6e5e5"
        exiting={FadeOut.duration(200)}
        animate
      >
        <Rect x="0" y="0" rx="5" ry="5" width={width} height={height} />
      </ContentLoader>
    );
  }
}

RectangleLoader.defaultProps = {
  width: '100%',
  height: 200,
};

RectangleLoader.propsTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RectangleLoader;
