import client, { clientStatic } from './client';
import api from './endpoints';
const endpoints = api();

function getDetails(storeId, orderDate, orderTime, cancelToken) {
  let path = endpoints.STORE_DETAILS(storeId);

  if (orderDate && orderTime) {
    path += `?order_date=${orderDate}&order_time=${orderTime}`;
  }
  return client.get(path, {}, { cancelToken });
}

function getAdditionalCharge(payload, cancelToken) {
  const path = endpoints.ADDITIONAL_CHARGE;
  return client.post(path, payload, { cancelToken });
}

function getStoreOrderDates(storeId, cancelToken) {
  const path = endpoints.STORE_ORDER_DATES(storeId);
  return client.get(path, {}, { cancelToken });
}

function getMenu(storeId, orderDate, orderTime, cancelToken) {
  let path = endpoints.STORE_MENUS(storeId);
  if (orderDate && orderTime) {
    path += `?order_date=${orderDate}&order_time=${orderTime}`;
  }
  return client.get(path, {}, { cancelToken });
}

function getTenStores(params, cancelToken) {
  let path = `${endpoints.STORE_SEARCH}?limit=10&offset=0`;
  params.forEach((t) => {
    path += `&${t.key}=${t.value}`;
  });
  return client.get(path, {}, { cancelToken });
}

function getAllStores(urlParams, cancelToken) {
  const path = `${endpoints.STORE_SEARCH}${urlParams || ''}`;
  return client.get(path, {}, { cancelToken });
}

function getRatings(storeId, params, cancelToken) {
  const path = endpoints.STORE_RATING(storeId);
  return client.get(`${path}${params || ''}`, {}, { cancelToken });
}

function getTotalRatings(storeId, cancelToken) {
  const path = endpoints.STORE_TOTAL_RATING(storeId);
  return client.get(path, {}, { cancelToken });
}

function getRatingDetails(storeId, ratingId, cancelToken) {
  const path = endpoints.STORE_RATING_DETAILS(storeId, ratingId);

  return client.get(path, {}, { cancelToken });
}
function getRatingSummary(storeId, summary, cancelToken) {
  const path = endpoints.STORE_RATING_SUMMARY(storeId, summary);
  return client.get(path, {}, { cancelToken });
}

function addRating(storeId, payload, cancelToken) {
  const path = endpoints.STORE_RATING(storeId);
  return client.post(path, payload, { cancelToken });
}

function updateRating(storeId, ratingId, payload, cancelToken) {
  const path = endpoints.STORE_RATING_DETAILS(storeId, ratingId);
  return client.patch(path, payload, { cancelToken });
}

function getStorePromos(storeId, cancelToken) {
  const path = endpoints.STORE_PROMOS(storeId);
  return client.get(path, {}, { cancelToken });
}

function getStoreAds(cancelToken) {
  const path = endpoints.STORE_ADS;
  return clientStatic.get(path, {}, { cancelToken });
}

export default {
  addRating,
  getAdditionalCharge,
  getAllStores,
  getDetails,
  getMenu,
  getRatingDetails,
  getRatings,
  getRatingSummary,
  getStoreAds,
  getStoreOrderDates,
  getStorePromos,
  getTenStores,
  getTotalRatings,
  updateRating,
};
