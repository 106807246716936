import { StyleSheet } from 'react-native';

import constants from '../../Config/constants';
import customMapping from '../../Theme/mapping';
import ThemeStyle from '../../Theme/styles';
import ThemeColor from '../../Theme/colors';

const iconSize = 20;
const spacing = 20;

export default StyleSheet.create({
  separator: {
    height: 10,
    backgroundColor: ThemeColor.gray,
  },
  spacingBottom: {
    marginBottom: spacing,
  },
  spacingRight: {
    marginRight: spacing,
  },
  loaderRowContainer: {
    ...StyleSheet.absoluteFill,
    ...ThemeStyle.alignItemsCenter,
    ...ThemeStyle.justifyContentCenter,
    backgroundColor: ThemeColor.gray,
  },
  nearbyPlacesStyle: {
    container: {
      flex: 0,
    },
    row: {
      alignItems: 'center',
      paddingHorizontal: 0,
    },
    loader: {
      position: 'absolute',
      left: spacing,
      backgroundColor: ThemeColor.white,
      zIndex: 1,
    },
  },
  addressDetailsStyle: {
    addressName: ThemeStyle.spacingBottomSmall,
    submitButtonContainer: {
      paddingBottom: constants.isAndroid ? 20 : 0,
    },
  },
  autoSuggestionStyle: {
    container: {
      flex: 1,
      alignItems: 'center',
    },
    textInputContainer: {
      alignItems: 'center',
      backgroundColor: '#fff',
      paddingHorizontal: spacing,
    },
    textInput: {
      color: ThemeColor.black,
      fontFamily: customMapping.strict['text-font-family'],
      fontSize: customMapping.strict['text-paragraph-1-font-size'],
      fontWeight: customMapping.strict['text-paragraph-1-font-weight'],
    },
    row: {
      paddingHorizontal: 0,
    },
  },
  //FOR WEB
  screenWrapper: {
    width: '90%',
    padding: 10,
    alignSelf: 'center',
    height: 'auto',
  },
  searchContainer: {
    flex: 1,
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  saveIcon: {
    width: 30,
    height: 30,
  },
  modal_wrapper: {
    height: 600,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 5,
  },
  googleplace_autocomplete: {
    ...ThemeStyle.regular,
    marginTop: '2%',
  },
  icon: { width: iconSize, height: iconSize },
});
