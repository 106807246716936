import React, { Fragment } from 'react';
import { StatusBar, View } from 'react-native';

import constants from '../../../Config/constants';
import { CircleLoader, RectangleLoader } from '../../../Components/Loader';
import ThemeColor from '../../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../../Theme/styles';

import pageStyle from '../styles';
import { ids, styles as responsiveStyle } from '../stylesResponsive';

import Menu from './Menu';
import PromotionLoader from './Promotion';

class Loader extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <View
          dataSet={{ media: ids.wrapper }}
          style={[
            responsiveStyle.wrapper,
            pageStyle.container,
            constants.isWeb && ThemeStyle.pageHorizontalSpacing,
          ]}
        >
          {/* Status bar color for mobile, no effect on web */}
          <StatusBar
            backgroundColor="transparent"
            barStyle="dark-content"
            translucent
          />
          {/* Banner & Avatar */}
          <View
            style={[
              constants.isWeb && ThemeStyle.spacingTop,
              constants.isWeb
                ? { marginBottom: 55 }
                : ThemeStyle.spacingBottomMedium,
            ]}
          >
            {/* Banner */}
            <View
              style={
                constants.isWeb && { borderRadius: 20, overflow: 'hidden' }
              }
            >
              <RectangleLoader width="100%" height={220} />
            </View>
            {/* Avatar */}
            <View
              style={[
                {
                  position: 'absolute',
                  left: globalSpacing,
                  bottom: constants.isWeb ? -45 : globalSpacing,
                },
                !constants.isWeb && ThemeStyle.shadow,
              ]}
            >
              <CircleLoader size={80} />
            </View>
          </View>
          {/* Title, Tags, Ratings, Opening/Closing and Info button */}
          <View
            style={[
              !constants.isWeb && ThemeStyle.pageHorizontalSpacing,
              ThemeStyle.spacingBottom,
            ]}
          >
            {/* Title */}
            <RectangleLoader width={200} height={30} />
            <View
              style={[
                ThemeStyle.flexDirectionRowSpaceBetween,
                ThemeStyle.spacingTopSmall,
              ]}
            >
              <View>
                {/* Tags */}
                <View style={ThemeStyle.spacingBottomSmall}>
                  <RectangleLoader width={120} height={18} />
                </View>
                {/* Ratings */}
                <View style={ThemeStyle.spacingBottomSmall}>
                  <RectangleLoader width={150} height={18} />
                </View>
                {/* Opening/Closing */}
                <RectangleLoader width={180} height={18} />
              </View>
              {/* Info button */}
              {constants.isWeb ? (
                <View style={ThemeStyle.flexDirectionRowCenter}>
                  <View style={{ borderRadius: 20, overflow: 'hidden' }}>
                    <RectangleLoader width={200} height={40} />
                  </View>
                  <View style={ThemeStyle.rowSeparator} />
                  <View style={{ borderRadius: 20, overflow: 'hidden' }}>
                    <RectangleLoader width={100} height={40} />
                  </View>
                </View>
              ) : (
                <CircleLoader size={30} />
              )}
            </View>
          </View>
          {/* Order type selector for mobile */}
          {!constants.isWeb && (
            <View
              style={[
                {
                  borderWidth: 1,
                  borderColor: ThemeColor.gray400,
                  borderRadius: 50,
                },
                ThemeStyle.spacingLeft,
                ThemeStyle.spacingRight,
                ThemeStyle.spacingBottom,
              ]}
            >
              <View
                style={{ borderRadius: 50, overflow: 'hidden', width: '25%' }}
              >
                <RectangleLoader width="100%" height={35} />
              </View>
            </View>
          )}
          {/* Promotion */}
          <View
            style={[
              constants.isWeb && { marginLeft: -20 },
              ThemeStyle.spacingBottom,
            ]}
          >
            <PromotionLoader />
          </View>
        </View>
        {/* Menu */}
        <Menu />
      </Fragment>
    );
  }
}

export default Loader;
