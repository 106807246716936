import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import Countdown from '../../Components/Countdown';
import ThemeStyle from '../../Theme/styles';
import customMapping from '../../Theme/mapping';

function OTPTimer({ timer, text, onCountdownEnd }) {
  if (timer <= 0) {
    return null;
  }
  return (
    <View style={ThemeStyle.flexDirectionRowCenterCenter}>
      <Text category="label" appearance="hint">
        {text}
      </Text>
      <View style={[ThemeStyle.spacingLeftSmall, ThemeStyle.spacingRightSmall]}>
        <Countdown
          textStyle={{
            fontFamily: customMapping.strict['text-font-family'],
            fontSize: customMapping.strict['text-paragraph-1-font-size'],
            fontWeight: customMapping.strict['text-paragraph-1-font-weight'],
            opacity: 0.35,
          }}
          initialSeconds={timer}
          onEnd={onCountdownEnd}
          autoStart
        />
      </View>
      <Text appearance="hint">seconds</Text>
    </View>
  );
}

export default OTPTimer;
