import React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import ThemeColor from '../../Theme/colors';
import { useTheme } from '@ui-kitten/components';
const LoadingIndicator = ({ isOpen }) => {
  const store_theme = useTheme();
  return (
    <Modal isVisible={isOpen} style={styles.loading_container}>
      <ActivityIndicator
        size="large"
        color={store_theme['icon_color'] || ThemeColor.green}
      />
    </Modal>
  );
};
const styles = StyleSheet.create({
  loading_container: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999999,
  },
  image_gif: {
    shadowColor: 'red',
    shadowOpacity: 5,
    width: 200,
    height: 200,
  },
});
export default LoadingIndicator;
