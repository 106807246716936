import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import { CANCEL_ERROR } from 'apisauce';
import lodashFilter from 'lodash/filter';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';
import { isDefaultFilter, numberToArray } from '../../../../Helper';
import AnalyticsHelper from '../../../../Helper/Analytics';
import CheckoutHelper from '../../../../Helper/Checkout';
import Sentry from '../../../../Helper/Sentry';
import StoreHelper from '../../../../Helper/Store';
import UrlParameter from '../../../../Helper/UrlParameter';
import useCancellableRequest from '../../../../Hooks/useCancellableRequest';
import routeList from '../../../../Routes/list';
import {
  parentCategorySelector,
  categoryFilterSelector,
  tagsFilterSelector,
} from '../../../../RTK/filter/selectors';
import {
  activeAddressSelector,
  isLoggedInSelector,
} from '../../../../RTK/user/selectors';
import { userAddressFetchingSelector } from '../../../../RTK/utility/selectors';
import storeApi from '../../../../Service/api/store';

import getHomeSections from '../../sections';

const allStoreLoader = numberToArray(5).map(() => ({ loading: true }));
let lastScrollY = 0;
let isScrollDragging = false;
let onEndReachedCalledDuringMomentum = false;

const useHomeController = () => {
  const navigation = useNavigation();
  const { createRequest } = useCancellableRequest();
  const scrollableRef = useRef();
  const route = useRoute();

  const isLoggedIn = useSelector(isLoggedInSelector);
  const userFavorites = useSelector((state) => state.favourite?.favouriteData);
  const { home } = useSelector((state) => state.filter);
  const categoryFilter = useSelector(categoryFilterSelector);
  const parentCategory = useSelector(parentCategorySelector);
  const tagsFilter = useSelector(tagsFilterSelector);
  const activeAddress = useSelector(activeAddressSelector);
  const address = useSelector(userAddressFetchingSelector);
  const loadAllStore = useSelector(
    (state) => state.utility.loadAllStoresAndActiveOrders
  );

  const [isFilterLoading, setIsFilterLoading] = useState(false); // loader for filter
  const [isGettingAllStore, setGettingAllStore] = useState(true); // loader for all store fetching
  const [homeSegments, setHomeSegments] = useState(
    getHomeSections(lodashIsEmpty(userFavorites))
  ); // homepage sections
  const [allStorePage, setAllStorePage] = useState(); // all store pagination
  const [allStoreEnd, setAllStoreEnd] = useState(false); // all store flag for page paginated page
  const [homeAllStores, setHomeAllStores] = useState(allStoreLoader); // all store data
  const [totalResults, setTotalResults] = useState(0); // filter results count
  const [refreshing, setRefreshing] = useState(false); // pull to refresh
  const [isScrollingUp, setIsScrollingUp] = useState(true); // show search bar if scrolling up

  const loadHomeData = isLoggedIn ? !address.loading && loadAllStore : true;
  const isHaveFilter =
    !lodashIsEmpty(tagsFilter) || !lodashIsEmpty(categoryFilter);
  const isFilterDefault = isDefaultFilter(tagsFilter, categoryFilter);
  const showAdAndHorizontalList =
    (!isHaveFilter && !isFilterLoading) || isFilterDefault;

  // effect for scrolling to top when received scrollToTop on url parameter
  useEffect(() => {
    // not strict comparison, on web scrollToTop value is string boolean ("true")
    if (route?.params?.scrollToTop == true) {
      // wait a little to trigger the scroll to top
      setTimeout(() => {
        scrollableRef.current?._scrollToOffset?.({
          animated: true,
          offset: 0,
        });
      }, 100);
      // remove the url parameter of scrollToTop
      navigation.setParams({ scrollToTop: undefined });
    }
  }, [route?.params?.scrollToTop]);

  useEffect(() => {
    if (isLoggedIn) {
      setHomeAllStores(allStoreLoader);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (loadHomeData) {
      if (isFilterDefault) {
        _getHomepageData();
      } else {
        _handleFilterChange();
      }
    }
  }, [
    loadHomeData, // should be able to load the all store (after my favorite and fastest near you loaded)
    // refetch all stores when user changed the
    activeAddress, // active address
    categoryFilter, // category filter
    tagsFilter, // tags filter (the one with order type, ratings and distance)
  ]);

  // called when filter changed
  const _handleFilterChange = () => {
    setAllStorePage();
    setHomeAllStores(allStoreLoader);
    setIsFilterLoading(true);
    _fetchAllStoreNextData();
  };

  const _getHomepageData = () => {
    const sections = getHomeSections(lodashIsEmpty(userFavorites));
    setAllStoreEnd(false);
    setAllStorePage();
    setHomeAllStores([]);
    setHomeSegments(sections);
    setIsFilterLoading(false);
    setTotalResults(0);
    _fetchAllStoreNextData();
  };

  const _fetchAllStoreNextData = async (page = 1) => {
    setGettingAllStore(true);
    const { ok, data, problem } = await _getAllStoresData(page);
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    if (ok) {
      const { result, has_next_page, total_pages } = data;
      let totalResults = UrlParameter.page_limit * total_pages;
      if (result?.length <= 10 && total_pages === 1) {
        totalResults = result?.length;
      }
      setIsFilterLoading(false);
      setAllStorePage(page);
      setAllStoreEnd(!has_next_page);
      setHomeAllStores((d) => [...d.filter((e) => !e.loading), ...result]);
      setTotalResults(totalResults);
      setRefreshing(false); // for _onRefresh method (no effect if not pull to refresh)
      setGettingAllStore(false);
    } else {
      setIsFilterLoading(false);
      setAllStorePage();
      setAllStoreEnd(true);
      setHomeAllStores((d) => d.filter((e) => !e.loading));
      setTotalResults(0);
      setRefreshing(false); // for _onRefresh method (no effect if not pull to refresh)
      setGettingAllStore(false);
      Sentry.reportError('Error getting all store data', data);
    }
  };

  const _getAllStoresData = async (page) => {
    const urlParams = UrlParameter.getUrlParameterFromFilter({
      categoryFilter: home.category,
      page,
      parentCategory,
      tagFilter: home.tags,
      whenFilter: home.when,
    });
    return await createRequest(
      storeApi.getAllStores,
      `${urlParams}&place_id=${activeAddress?.place_id}`
    );
  };

  const _onSearchPressed = () => {
    navigation.navigate(routeList.SEARCH);
  };

  // prettier-ignore
  const _onCardPressed = (item, fromSection = 'All Store') => () => {
    AnalyticsHelper.storeSelection({...item, fromSection})
    navigation.navigate(routeList.STORE, { id: item.id, distance: item.distance, orderForLater: item.orderForLater })
  }

  const _onRefresh = () => {
    // you should not pull to refresh if all store is loading & already refreshing
    if (!isGettingAllStore && !refreshing) {
      setHomeSegments(getHomeSections(lodashIsEmpty(userFavorites)));
      // reset states
      setIsFilterLoading(false);
      setAllStoreEnd(false);
      setHomeAllStores([]);
      setTotalResults(0);
      setRefreshing(true);
      // call function
      _fetchAllStoreNextData();
    }
  };

  const _onReachedEnd = async () => {
    // call next page for mobile
    if (
      !isGettingAllStore && // must not be fetching the all stores
      !allStoreEnd && // is not yet end of pagination
      !onEndReachedCalledDuringMomentum // called during user intent scroll not onload
    ) {
      _fetchAllStoreNextData(allStorePage + 1);
      onEndReachedCalledDuringMomentum = true;
    }
  };

  const _onMomentumScrollBegin = () => {
    onEndReachedCalledDuringMomentum = false;
  };

  // specific for web functions
  const _isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    const paddingToBottom = 30;
    const isNearBottom =
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;

    if (!isGettingAllStore && !allStoreEnd && isNearBottom) {
      _fetchAllStoreNextData(allStorePage + 1);
    }
  };

  // for filter of the store to show in 'All Store' and 'Order for later' section
  const _isStoreOpen = (returnOpen) => (store) => {
    // if loader
    if (returnOpen && store.loading) {
      return true;
    } else if (store.loading) {
      return false;
    }
    // loading done, process the store if its open or not
    const { isOpen, nextSchedule } = StoreHelper.getStoreAvailability(
      store?.store_hours,
      store?.off_dates,
      'object',
      home.when?.value // to adjust on the date filter not just current date and time
    );
    const { isClosing } = CheckoutHelper.isCanCheckout(
      store?.store_hours,
      store?.off_dates,
      store?.pre_order_to_order_queue_timer,
      home.when?.value
    );
    if (returnOpen) {
      // return if store is open AND if closing, store should be accepting order in advance else, just true
      return (
        isOpen && (isClosing ? store.is_accepting_in_advanced_orders : true)
      );
    } else {
      // this condition will return stores that currently close but will open later today
      return (
        !isOpen &&
        store.is_accepting_in_advanced_orders &&
        lodashIsEmpty(nextSchedule)
      );
    }
  };

  const _onStartDragging = () => {
    isScrollDragging = true;
  };

  const _onStopDragging = () => {
    isScrollDragging = false;
  };

  const _toggleScrollingUp = ({
    nativeEvent: { contentOffset, contentSize, layoutMeasurement },
  }) => {
    const scrollY = Math.round(contentOffset.y);
    const isCurrentScrollingUp = scrollY < lastScrollY;
    const maxY = Math.round(contentSize.height - layoutMeasurement.height);
    if (scrollY > 180 && scrollY < maxY) {
      if (isCurrentScrollingUp !== isScrollingUp && isScrollDragging) {
        // this should only get trigger once on user begin scrolling up or scrolling down
        setIsScrollingUp(isCurrentScrollingUp);
      }
    }
    lastScrollY = scrollY;
  };

  return {
    scrollableRef,
    showAdAndHorizontalList,
    // states
    allStoreEnd,
    allStorePage,
    homeAllStores: lodashFilter(homeAllStores, _isStoreOpen(true)),
    homeClosedStores: lodashFilter(homeAllStores, _isStoreOpen(false)),
    homeSegments,
    isFilterLoading,
    isGettingAllStore,
    isScrollingUp,
    refreshing,
    totalResults,
    // functions
    onSearchPressed: _onSearchPressed,
    onCardPressed: _onCardPressed,
    onRefresh: _onRefresh,
    onMomentumScrollBegin: _onMomentumScrollBegin,
    onReachedEnd: _onReachedEnd,
    isCloseToBottom: _isCloseToBottom,
    onStartDragging: _onStartDragging,
    onStopDragging: _onStopDragging,
    toggleScrollingUp: _toggleScrollingUp,
  };
};

export default useHomeController;
