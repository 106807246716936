import { createSelector } from '@reduxjs/toolkit';

const shop = (state) => state.shop;

const shopFeaturedItemsSelector = createSelector(
  shop,
  (state) => state.data.featured_items
);
const shopLoadingSelector = createSelector(shop, (state) => state.loading);
const shopRawSelector = createSelector(shop, (state) => state.rawData);
const shopSelector = createSelector(shop, (state) => state.data);

export {
  shopFeaturedItemsSelector,
  shopLoadingSelector,
  shopRawSelector,
  shopSelector,
};
