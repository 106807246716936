import { useSelector } from 'react-redux';

import { isLoggedInSelector, userSelector } from '../RTK/user/selectors';

export default function () {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const user = useSelector(userSelector);
  const shouldCallApi = isLoggedIn && !user?.guest;
  return shouldCallApi;
}
