import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

const PromoAmountOff = ({
  fill1,
  fill2,
  fill1_opacity = '100%',
  fill2_opacity = '100%',
  ...props
}) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="17.06 10.52 1044.92 396.88"
    preserveAspectRatio="none"
    {...props}
  >
    <Path
      fillOpacity={fill1_opacity}
      fill={fill1 || '#cbefda'}
      strokeWidth={0}
      d="m1061.98,53.5v101.03c-1.22-.09-2.43-.13-3.67-.13-29.52,0-53.46,24.43-53.46,54.56s23.94,54.56,53.46,54.56c1.23,0,2.45-.06,3.67-.13v101.03c.24,23.49-18.61,42.74-42.1,42.98H59.18c-23.49-.24-42.35-19.47-42.12-42.98v-100.89c29.52,0,53.46-24.43,53.46-54.56s-23.94-54.56-53.46-54.56V53.5c-.22-23.49,18.63-42.74,42.12-42.98h960.69c23.49.24,42.35,19.49,42.1,42.98Z"
    />
    <G
      id="percentage-circle_bold"
      data-name="percentage-circle bold"
      opacity=".37"
    >
      <Path
        fillOpacity={fill2_opacity}
        fill={fill2 || '#8ac1b1'}
        strokeWidth={0}
        d="m1004.85,210.04c-.29,30.13,23.95,53.2,53.46,53.48,1.24,0,2.45-.06,3.67-.13v49.26c-33.73,50.24-92.28,83.11-157.31,82.49-102.74-.98-185.24-85.06-184.26-187.81.98-102.74,85.07-185.23,187.81-184.25,51.36.49,97.68,21.75,131.02,55.74,8.88,9.04,15.87,17.76,22.74,28.45v47.3c-1.22-.1-2.43-.15-3.67-.16-29.51-.28-53.18,25.52-53.46,55.65Z"
      />
    </G>
    <G fillOpacity={fill2_opacity} fill={fill2 || '#8ac1b1'}>
      <Path d="m824.89,325.67c-2.19,0-2.91-.73-2.91-2.91v-124.95h-15.66c-1.82,0-2.55-.36-2.55-1.82v-12.75c0-1.46.73-2.19,2.19-2.19h16.03v-14.94h-16.03c-1.46,0-2.19-.73-2.19-2.55v-11.66c0-1.46.73-2.55,2.19-2.55h16.03v-53.91c0-1.46.73-2.55,1.82-2.91h56.83c52.46,0,74.68,25.86,81.96,56.83h14.21c1.46,0,1.82.36,1.82,1.82v13.11c0,1.46-.36,1.82-1.82,1.82h-11.66c0,5.1,0,11.29-.73,14.94h12.39c1.46,0,1.82.36,1.82,1.82v13.11c0,1.46-.73,1.82-1.82,1.82h-14.94c-9.11,40.8-45.53,59.01-82.69,59.01h-17.12v66.3c0,2.19-.73,2.55-2.55,2.55h-34.61Zm97.63-176.31c-5.83-16.03-21.13-22.22-42.26-22.22h-18.21v22.22h60.47Zm2.19,31.69c.73-4.01,1.09-10.2.36-14.94h-63.02v14.94h62.66Zm-62.66,16.76v22.95h16.03c20.04,0,37.52-4.37,43.71-22.95h-59.74Z" />
    </G>
  </Svg>
);

export default PromoAmountOff;
