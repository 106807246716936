import React from 'react';
import { View, Linking } from 'react-native';
import { Divider, Text } from '@ui-kitten/components';
import lodashGet from 'lodash/get';

import ThemeStyle from '../../../Theme/styles';
import externalLinks from '../../../Config/externalLinks';
import ScreenWrapper from '../../../Components/ScreenWrapper';
import Button from '../../../Components/Button';

import styles from '../styles';
import Footer from '../../../Components/Footer';

const GetHelp = () => {
  const _onFAQPressed = () => {
    Linking.openURL(externalLinks.FAQ);
  };

  const statusBar = lodashGet(this.props, 'route.params.statusBar');

  return (
    <ScreenWrapper title="Get Help" footer={<Footer />}>
      <View style={[styles.wrapper, { marginTop: '2%' }]}>
        <Text category="h5" style={ThemeStyle.spacingBottomMedium}>
          Which order do you need help with?
        </Text>

        <Button onPress={_onFAQPressed} plain>
          <Text>Need help with something other than an order?</Text>
          <Text category="p2">
            Check out{' '}
            <Text category="s2" style={ThemeStyle.bold}>
              FAQ Page.
            </Text>
          </Text>
        </Button>

        <Divider
          style={[
            ThemeStyle.divider,
            ThemeStyle.spacingTop,
            ThemeStyle.spacingBottom,
          ]}
        />

        <Text category="h5" style={ThemeStyle.spacingBottom}>
          Completed
        </Text>

        <Text category="h5">No recent orders</Text>
      </View>
    </ScreenWrapper>
  );
};

export default GetHelp;
