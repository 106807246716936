import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import { RectangleLoader } from '../../../Components/Loader';
import ThemeStyle from '../../../Theme/styles';

function ItemLoader() {
  return (
    <View
      style={[
        ThemeStyle.flex1,
        ThemeStyle.pageVerticalSpacing,
        ThemeStyle.pageHorizontalSpacing,
      ]}
    >
      <View style={ThemeStyle.flexDirectionRow}>
        <View style={ThemeStyle.spacingRight}>
          <View style={ThemeStyle.spacingBottomSmall}>
            {/* Store Image */}
            <RectangleLoader width={70} height={70} />
          </View>
        </View>

        <View style={[ThemeStyle.flex1, ThemeStyle.spacingRight]}>
          {/* Title */}
          <View style={ThemeStyle.spacingBottomMedium}>
            <RectangleLoader width="100%" height={21} />
          </View>

          {/* Description */}
          <RectangleLoader width="90%" height={21} />
        </View>

        <View>
          {/* Price */}
          <RectangleLoader width={70} height={21} />
        </View>
      </View>

      <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
        {/* Completed Time */}
        <RectangleLoader width={70} height={21} />

        {/* Button */}
        <RectangleLoader width={90} height={27} />
      </View>
    </View>
  );
}

export default ItemLoader;
