import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import ThemeStyle from '../../Theme/styles';

import { RectangleLoader } from '../Loader';

import styles from './styles';

function BaseLoader({ style, widthTitle = true, withSubTitle = true }) {
  return (
    <Fragment>
      <View
        style={[
          styles.baseImage,
          style,
          (widthTitle || withSubTitle) && ThemeStyle.spacingBottomSmall,
        ]}
      >
        <RectangleLoader width="100%" height="100%" />
      </View>
      {widthTitle && (
        <View style={[ThemeStyle.spacingBottomSmall, { width: '75%' }]}>
          <RectangleLoader width="100%" height={19} />
        </View>
      )}
      {withSubTitle && (
        <View style={{ width: '50%' }}>
          <RectangleLoader width="100%" height={19} />
        </View>
      )}
    </Fragment>
  );
}

BaseLoader.propTypes = {
  widthTitle: PropTypes.bool,
  withSubTitle: PropTypes.bool,
};

export default BaseLoader;
