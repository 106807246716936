import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from '../../Config';

import type { BaseQueryInterceptorType } from './types';

const baseQueryInterceptor: BaseQueryInterceptorType = async (
  args,
  api,
  extraOptions
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: config.ROOT_API,
    prepareHeaders: async (headers, { endpoint }) => {
      headers.set('Accept', 'application/json');
      if (endpoint !== 'logout') {
        headers.set('Content-Type', 'application/json');
      }
      return headers;
    },
    credentials: 'include', //For setting the cookie include in every request
  });

  try {
    return await baseQuery(args, api, extraOptions);
  } catch (error: any) {
    throw error;
  }
};

export const baseApi = createApi({
  baseQuery: baseQueryInterceptor,
  endpoints: () => ({}),
  reducerPath: 'baseApi',
});
