import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Text, Icon } from '@ui-kitten/components';
import Collapsible from 'react-native-collapsible';

const iconSize = 20;

class ErrrorPrompt extends React.PureComponent {
  render() {
    const { show, errorTitle, errorMessage } = this.props;

    return (
      <Collapsible collapsed={!show} renderChildrenCollapsed={false}>
        <View testID="errorPrompt" style={styles.wrapper}>
          <Icon
            name="alert-triangle"
            fill="#d9a50b"
            height={iconSize}
            width={iconSize}
          />
          <View style={styles.container}>
            {Boolean(errorTitle) && (
              <Text category="h6" testID="errorTitle">
                {errorTitle}
              </Text>
            )}
            {Boolean(errorMessage) && (
              <Text testID="errorMessage">{errorMessage}</Text>
            )}
          </View>
        </View>
      </Collapsible>
    );
  }
}

ErrrorPrompt.propTypes = {
  show: PropTypes.bool.isRequired,
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.string,
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    backgroundColor: '#ffffa3',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
  },
  container: {
    flex: 1,
    marginLeft: 10,
  },
});

export default ErrrorPrompt;
