import React from 'react';

import useFavorite from './useFavorite';

export default function (Component) {
  return (props) => {
    const { disableFavourite, isSavedToFavourite, toggleFavourite } =
      useFavorite();

    return (
      <Component
        disableFavourite={disableFavourite}
        isSavedToFavourite={isSavedToFavourite}
        toggleFavourite={toggleFavourite}
        {...props}
      />
    );
  };
}
