import React from 'react';
import { View } from 'react-native';

import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';

import { RectangleLoader, CircleLoader } from '../Loader';

function QuantitySelectorLoader() {
  return (
    <View style={!constants.isWeb && ThemeStyle.pageVerticalSpacing}>
      <View style={ThemeStyle.flexDirectionRowCenter}>
        {/* Minus Icon */}
        <CircleLoader size={constants.isWeb ? 40 : 50} />
        {/* Text */}
        <View style={[ThemeStyle.pageHorizontalSpacingSmall, { height: 40 }]}>
          <RectangleLoader width={30} height={40} />
        </View>
        {/* Plus Icon */}
        <CircleLoader size={constants.isWeb ? 40 : 50} />
      </View>
    </View>
  );
}

export default QuantitySelectorLoader;
