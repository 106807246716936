import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Icon, Text, Spinner } from '@ui-kitten/components';

import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

import Button from '../Button';

const SIZES = {
  icon: 15,
  radius: 20,
};

function QuantitySelectorSmall({
  disabled,
  disableIncrement,
  loading,
  value,
  onQtyChange,
  onRemove,
}) {
  const isRemove = value <= 1; // mark as remove if value is less than equal 1
  return (
    <View style={styles.wrapper}>
      {/* - or Remove button */}
      <View style={styles.btnContainer}>
        <Button
          onPress={() => {
            if (isRemove) {
              onRemove();
            } else {
              onQtyChange(value - 1, -1);
            }
          }}
          hitSlop={15}
          // disable if disable props is true OR not using the last quantity as remove AND not last value
          disabled={disabled || (!onRemove && isRemove)}
          plain
        >
          <Icon name="minus" fill={ThemeColor.green} style={styles.icon} />
        </Button>
      </View>

      <View style={styles.valueContainer}>
        {/* Loader */}
        {loading && (
          <View
            style={[
              StyleSheet.absoluteFill,
              ThemeStyle.alignItemsCenter,
              ThemeStyle.justifyContentCenter,
            ]}
          >
            <Spinner size="large" status="success" />
          </View>
        )}
        {/* Value */}
        <Text category="p2">{value}</Text>
      </View>

      {/* + Button */}
      <View style={styles.btnContainer}>
        <Button
          onPress={() => onQtyChange(value + 1, 1)}
          hitSlop={15}
          disabled={disabled || disableIncrement}
          lighterDisabledOpacity
          plain
        >
          <Icon name="plus" fill={ThemeColor.green} style={styles.icon} />
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: [ThemeStyle.flexDirectionRowCenter],
  btnContainer: [
    ThemeStyle.alignItemsCenter,
    ThemeStyle.justifyContentCenter,
    {
      backgroundColor: ThemeColor.lightGray,
      borderRadius: SIZES.radius,
      width: SIZES.icon * 2,
      height: SIZES.icon * 2,
    },
  ],
  valueContainer: [
    ThemeStyle.pageHorizontalSpacingSmall,
    ThemeStyle.spacingLeftSmall,
    ThemeStyle.spacingRightSmall,
  ],
  icon: {
    width: SIZES.icon,
    height: SIZES.icon,
  },
});

QuantitySelectorSmall.defaultProps = {
  disableIncrement: true,
};

QuantitySelectorSmall.propTypes = {
  disabled: PropTypes.bool,
  disableIncrement: PropTypes.bool,
  loading: PropTypes.bool,
  value: PropTypes.number.isRequired,
  onQtyChange: PropTypes.func.isRequired, // 1st param is new value of selector, 2nd param is added quantity -1 or +1 for specific purpose
  onRemove: PropTypes.func,
};

export const QtySizes = SIZES;
export default QuantitySelectorSmall;
