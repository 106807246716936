import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import ThemeStyle from '../../Theme/styles';
import Button from '../../Components/Button';

import styles from './styles';

class OrderHeader extends React.PureComponent {
  render() {
    const { onHistoryPress } = this.props;

    return (
      <View
        style={[
          ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.flexDirectionRowCenter,
        ]}
      >
        <View style={ThemeStyle.flex1}>
          <Text category="s1">Orders</Text>
        </View>
        <Button size="small" status="success" onPress={onHistoryPress}>
          {() => (
            <Text
              category="p2"
              appearance="alternative"
              style={ThemeStyle.pageHorizontalSpacingSmall}
            >
              History
            </Text>
          )}
        </Button>
      </View>
    );
  }
}

OrderHeader.propTypes = {
  onHistoryPress: PropTypes.func.isRequired,
};

export default OrderHeader;
