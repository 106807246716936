import lodashFilter from 'lodash/filter';

import constants from '../../Config/constants';

import modals from '../Sheets/modals';

const starIcon = { name: 'star', pack: 'eva' };
const chevronDownIcon = { name: 'chevron-down-outline', pack: 'eva' };

export default [
  {
    id: 0,
    label: 'Order Type',
    paramName: 'order_type',
    value: 'order_type',
    rightIcon: chevronDownIcon,
    sheetName: modals.DROPDOWN,
    sheetData: {
      title: 'Select Order Type',
      options: lodashFilter(
        constants.ORDER_TYPE_DATA,
        (d) => d.value !== constants.ORDER_TYPES.MEAL_PLAN // exclude meal plan here
      ),
      clearText: 'Reset',
    },
    getLabel: ({ label }) => label,
  },
  {
    id: 1,
    label: 'Ratings',
    paramName: 'rating',
    value: 'ratings',
    leftIcon: starIcon,
    rightIcon: chevronDownIcon,
    sheetName: modals.RATING_SELECTOR,
    getLabel: ({ value }) => (value === 5 ? value : `Over ${value}`),
  },
  {
    id: 2,
    label: `Within ${constants.DEFAULT_DISTANCE} ${constants.DISTANCE_UNIT}`,
    paramName: 'distance',
    value: 'distance',
    sheetName: modals.DISTANCE_SELECTOR,
    getLabel: ({ value }) => `Within ${value} ${constants.DISTANCE_UNIT}`,
  },
  // {
  //   id: 3,
  //   label: 'Price',
  //   paramName: 'price_range',
  //   value: 'price',
  //   rightIcon: chevronDownIcon,
  //   sheetName: modals.PRICE_SELECTOR,
  //   getLabel: ({ isAll, value }) => (isAll ? 'Price: ALL' : value.map((e) => e.label).join(',')),
  // },
  // {
  //   id: 4,
  //   value: 5,
  //   label: 'Within 5 km',
  //   paramName: 'distance',
  // },
];
