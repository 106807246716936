import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import ActionSheet from 'react-native-actions-sheet';

import { deviceWidth } from '../../Theme/styles';

import DatePicker from '../DatePicker';

import commonProps from './commonProps';
import modals from './modals';

// used for web only
function WebCheckoutSchedulePickerSheet({ sheetId }) {
  const [data, setData] = useState();

  const value = data?.value;

  const _onConfirm = (data) =>
    modals.hide(modals.WEB_CHECKOUT_SCHEDULE_PICKER, data);

  return (
    <ActionSheet id={sheetId} onBeforeShow={setData} {...commonProps}>
      <View style={localStyles.wrapper}>
        <DatePicker
          datePickerContainerStyle={localStyles.container}
          initialSelectedDays={[value]}
          onConfirm={_onConfirm}
        />
      </View>
    </ActionSheet>
  );
}

const localStyles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 30,
  },
  container: {
    flex: 1,
    width: (deviceWidth / 100) * 90,
  },
});

export default WebCheckoutSchedulePickerSheet;
