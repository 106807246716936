import constants from '../Config/constants';

const { DEFAULT_DISTANCE, DISTANCE_UNIT, PRODUCT_TYPES } = constants;

const checkout = {
  keys: {
    ORDER_TYPE: 'orderType',
    DELIVERY_ADDRESS: 'deliveryAddress',
    DELIVERY_SCHEDULE: 'deliverySchedule',
    USER_DETAILS: 'userDetails',
    PAYMENT_METHOD: 'paymentMethod',
    PAYMENT_OPTIONS: 'paymentOptions',
    VEHICLE_OPTIONS: 'vehicleOptions',
    THIRD_PARTY_PICKUP: 'thirdPartyPickup',
    VEHICLE_INFORMATION: 'vehicleInformation',
    REQUEST_CUTLERY: 'requestCutlery',
    NOTE: 'note',
    PROMO_CODE_INPUT: 'promoCodeInput',
    PROMO_CODE: 'promoCode',
    PROMO_STORE: 'promoStore', // promo code added to basket in store page
    CONTINUE_TO_PAYMENT: 'continueToPayment', // not in form, used for going back to payment link
  },
  initialValue: {
    checkoutFlag: {
      storeId: null, // general use e.g: flag for updating pre order date using date picker on homepage
      checkoutStoreId: null, // for flag when navigating to address selection when user is in checkout page
      addressSelection: false,
      dropOffOptionSelection: false,
    },
    checkoutData: [],
    trackOrder: '',
    storeInfo: {}, // this value must be dispatch before navigating to checkout page
    isPromoOk: true,
  },
};

const filter = {
  useCase: { HOME: 'home', SEARCH: 'search' },
  filterType: { CATEGORY: 'category', TAG: 'tag' },
  parentCategory: PRODUCT_TYPES[0],
  homeInitialFilterValue: {
    category: [],
    tags: [
      {
        id: 2,
        label: `Within ${DEFAULT_DISTANCE} ${DISTANCE_UNIT}`,
        values: [
          {
            label: `Within ${DEFAULT_DISTANCE} ${DISTANCE_UNIT}`,
            value: DEFAULT_DISTANCE,
          },
        ],
        paramName: 'distance',
      },
    ],
  },
  searchInitialFilterValue: {
    category: [],
    tags: [
      {
        id: 2,
        label: `Within ${DEFAULT_DISTANCE} ${DISTANCE_UNIT}`,
        values: [
          {
            label: `Within ${DEFAULT_DISTANCE} ${DISTANCE_UNIT}`,
            value: DEFAULT_DISTANCE,
          },
        ],
        paramName: 'distance',
      },
    ],
  },
};

const mealPlan = {
  type: {
    threeDay: '3_day',
    fiveDay: '5_day',
  },
  initialValue: {
    type: '',
    items: [],
    checkoutData: {},
    checkoutFlag: false,
  },
};

export { checkout, filter, mealPlan };
