import { createContext, RefObject, useContext } from 'react';

/*
 * Provider for all modals
 */

const ProviderContext = createContext('globalModal');

/*
 * Collection for modal id's
 */

const ModalIDContext = createContext<string | undefined>(undefined);

/*
 * Collection for modal ref
 */
const ModalRefContext = createContext<RefObject<any | null>>({} as any);

/*
 * Collection of modal payload
 */
const ModalPayloadContext = createContext<any>(undefined);

const useProviderContext = () => useContext(ProviderContext);

export {
  ProviderContext,
  ModalIDContext,
  ModalRefContext,
  ModalPayloadContext,
  useProviderContext,
};
