import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

import withFormik from '../../Hooks/withFormik';
import ThemeStyle from '../../Theme/styles';

import TextInput from '../TextInput';

import ErrorPromptBelowInput from './ErrorPromptBelowInput';

class FormField extends React.Component {
  render() {
    const { formikProps, name, onRef, ...rest } = this.props;
    const { setFieldTouched, setFieldValue, values, errors } = formikProps;

    return (
      <View style={ThemeStyle.spacingBottom}>
        <TextInput
          onRef={onRef}
          value={values[name]}
          onBlur={() => setFieldTouched(name)}
          onChangeText={(text) => setFieldValue(name, text)}
          error={errors[name]}
          {...rest} // can overwrite the error props
        />
        <ErrorPromptBelowInput text={errors[name] || rest.error} absolute />
      </View>
    );
  }
}

FormField.propTypes = {
  formikProps: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onRef: PropTypes.func,
};

export default withFormik(FormField);
