import React from 'react';
import { ActivityIndicator } from 'react-native';
import { Text } from '@ui-kitten/components';
import { useQuery } from '@tanstack/react-query';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../Config/constants';
import { pluralize } from '../Helper';
import ThemeStyle from '../Theme/styles';
import Service from '../Service';
import storeAPI from '../Service/api/store';

type Props = {
  storeId: string;
  trailing: any;
  type: 1 | 2;
  onLoad: Function;
  [key: string]: any;
};

const RatingText: React.FC = ({
  storeId,
  trailing,
  type = 1,
  onLoad,
  ...rest
}: Props) => {
  const { data: result, isFetching: isLoading } = useQuery({
    queryKey: [storeId, 'total-order-rating'],
    queryFn: () => storeAPI.getTotalRatings(storeId),
    enabled: Boolean(storeId),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const data = isLoading ? {} : result?.data;
  const hide = !storeId || (!isLoading && lodashIsEmpty(data));

  if (hide) {
    return null;
  }

  if (isLoading) {
    return (
      <ActivityIndicator
        size={constants.isIOS ? 16 : 18}
        style={[
          ThemeStyle.alignSelfStart,
          ThemeStyle.spacingRightMedium,
          rest.style,
        ]}
      />
    );
  }

  if (type === 1) {
    return (
      <Text {...rest}>
        {data.average.toFixed(2)}{' '}
        <Text {...rest} status="success">
          ★
        </Text>{' '}
        ({Service.commafyNumber(data.total)}){trailing}
      </Text>
    );
  }

  return (
    <Text {...rest}>
      {data.average.toFixed(2)}{' '}
      <Text {...rest} status="success">
        ★
      </Text>{' '}
      {Service.commafyNumber(data.total)} {pluralize(data.total, 'review')}
      {trailing}
    </Text>
  );
};

export default RatingText;
