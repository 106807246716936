import React from 'react';
import { Text } from '@ui-kitten/components';
import { NODE_ENV } from '@env';

import { capitalize } from '../Helper';
import pkg from '../../package.json';

function VersionText() {
  const isProduction = NODE_ENV === 'production';
  const envPrefix = isProduction ? '' : NODE_ENV?.substr?.(0, 3);
  return (
    <Text category="c1">
      v: {capitalize(envPrefix)}
      {pkg.version}
    </Text>
  );
}

export default VersionText;
