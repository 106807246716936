import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';
import { RAZZLE_BUILD_MODE } from '@env';

import MealPlanTypes from '../../Components/MealPlanTypes';
import constants from '../../Config/constants';
import { setType } from '../../RTK/mealPlan';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';
import breakpoints from '../../Theme/styles/breakpoints';
import { SECTION_SUB_TITLE } from '../../Theme/typographyProps';

const { ORDER_TYPES } = constants;

function OrderTypeNote({
  mealPlanRequiredItem,
  storeAddress,
  type,
  goToLocation,
}) {
  const dispatch = useDispatch();
  const store_theme = useTheme();
  const mealPlanType = useSelector((state) => state.mealPlan.data?.type);

  let paramMealPlanType = '';

  // Check if running in web
  if (constants.isWeb) {
    if (RAZZLE_BUILD_MODE === 'branded') {
      const params = new URLSearchParams(document?.location?.search); // check url params for order_type
      paramMealPlanType = params.get('meal_plan_type') || paramMealPlanType; // default order type as string
    }
  }

  const isPickup = type.includes('pickup');
  const isMealPlan = type === ORDER_TYPES.MEAL_PLAN;

  const _onMealPlanTypeSelected = (data) => {
    dispatch(setType(data.value));
  };

  const _renderTitle = (iconName, packIcon, title) => (
    <View
      style={[ThemeStyle.flexDirectionRowCenter, ThemeStyle.spacingBottomSmall]}
    >
      <Icon
        name={iconName}
        fill={store_theme['icon_color'] || ThemeColor.green}
        pack={packIcon}
        style={styles.icon}
      />

      <Text category="p1">{title}</Text>
    </View>
  );

  if (isPickup) {
    return (
      <View style={ThemeStyle.spacingVerticalMedium}>
        {_renderTitle('shopping-bag', 'feather', 'This is a Pickup order.')}

        <View style={[ThemeStyle.flexWrap, ThemeStyle.flexDirectionRowCenter]}>
          <Text {...SECTION_SUB_TITLE}>
            Please head over to{' '}
            <Text
              status="success"
              onPress={goToLocation}
              {...SECTION_SUB_TITLE}
            >
              {storeAddress}
            </Text>{' '}
            to pick up your order.
          </Text>
        </View>
      </View>
    );
  } else if (isMealPlan && mealPlanRequiredItem) {
    return (
      <View
        dataSet={{ media: ids.mealPlanNoteContainer }}
        style={[styles.mealPlanNoteContainer, ThemeStyle.spacingVerticalMedium]}
      >
        <View
          dataSet={{ media: ids.mealPlanText }}
          style={[styles.mealPlanText, ThemeStyle.flex1]}
        >
          {_renderTitle('calendar-check', 'fa5', 'This is a Meal Plan order.')}
          <Text category="p2" style={ThemeStyle.flex1}>
            You have to add {mealPlanRequiredItem} items to your meal plan order
            and must have one exclusive item.
          </Text>
        </View>
        <View
          dataSet={{ media: ids.mealPlanTypes }}
          style={styles.mealPlanTypes}
        >
          <MealPlanTypes
            value={mealPlanType || paramMealPlanType}
            onChange={_onMealPlanTypeSelected}
            small
          />
        </View>
      </View>
    );
  }

  return null;
}

const { ids, styles } = StyleSheet.create({
  mealPlanNoteContainer: {
    [`@media ${breakpoints.sm}`]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  },
  mealPlanText: {
    ...ThemeStyle.spacingBottomMedium,
    [`@media ${breakpoints.sm}`]: {
      ...ThemeStyle.spacingRightMedium,
    },
  },
  mealPlanTypes: {
    width: '100%',
    [`@media ${breakpoints.sm}`]: {
      maxWidth: 260,
    },
    [`@media ${breakpoints.xl}`]: {
      maxWidth: 320,
    },
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: globalSpacing / 2,
  },
});

OrderTypeNote.propsTypes = {
  type: PropTypes.oneOf(Object.keys(ORDER_TYPES)),
  storeAddress: PropTypes.string.isRequired,
  goToLocation: PropTypes.func.isRequired,
};

export default OrderTypeNote;
