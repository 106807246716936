import React, { useState } from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';
import lodashIsEmpty from 'lodash/isEmpty';

import { ScaleButtonAnimation } from '../Web/Intereaction';
import Button from '../Button';
import constants from '../../Config/constants';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

const FilterRatingsType = [
  {
    label: 'Newest',
    value: 'newest',
  },
  {
    label: 'Highest rating ',
    value: 'highest',
  },
  {
    label: 'Lowest rating',
    value: 'lowest',
  },
];

const Ratings = ({ data, onSelectFilter }) => {
  const [selectedFilter, setSelectedFilter] = useState('newest');

  const ClickableComponent = constants.isWeb ? ScaleButtonAnimation : Button;

  const _onSelectedFilter = (filterType) => {
    setSelectedFilter(filterType);
    onSelectFilter(filterType);
  };
  const conditionalProps = (selected) =>
    constants.isWeb
      ? {
          buttonStyle: ({ hovered }) => [
            selectedFilter === selected
              ? styles.activeFilterButton
              : styles.inactiveFilterButton,
            styles.filterButtonStyle,
            hovered && ThemeStyle.buttonHovered1,
          ],
          contentStyle: styles.filterButtonContentStyle,
        }
      : {
          plain: true,
          style: [
            selectedFilter === selected
              ? styles.activeFilterButton
              : styles.inactiveFilterButton,
            styles.filterButtonStyle,
            ThemeStyle.pageHorizontalSpacingSmall,
            ThemeStyle.spacingRightSmall,
            ThemeStyle.spacingBottomSmall,
          ],
        };

  //To check if there's a data that need to filter
  if (lodashIsEmpty(data)) {
    return null;
  }

  return (
    <View
      style={
        constants.isWeb
          ? styles.filterRatingsContainerWeb
          : styles.filterRatingsContainerMobile
      }
    >
      {FilterRatingsType.map((item, index) => (
        <View key={index}>
          <ClickableComponent
            onPress={() => _onSelectedFilter(item.value)}
            {...conditionalProps(item.value)}
          >
            <Text
              status={selectedFilter === item.value ? 'control' : 'basic'}
              category="c1"
            >
              {item.label}
            </Text>
          </ClickableComponent>
        </View>
      ))}
    </View>
  );
};
const { ids, styles } = StyleSheet.create({
  filterRatingsContainerWeb: {
    columnGap: 10,
    rowGap: 10,
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
  },
  filterRatingsContainerMobile: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
  },
  filterButtonStyle: {
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  filterButtonContentStyle: {
    padding: 10,
  },
  activeFilterButton: {
    backgroundColor: ThemeColor.green,
  },
  inactiveFilterButton: {
    backgroundColor: ThemeColor.white,
    borderWidth: 1,
    borderColor: ThemeColor.gray400,
  },
});

export default Ratings;
