import React, { useState } from 'react';
import { View } from 'react-native';
import { Text, ButtonProps } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsUndefined from 'lodash/isUndefined';

import Button from '../../../Button';
import ThemeStyle, { globalSpacing } from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import { MODALPROMPT } from './config';
import ModalPrompt from './ModalPrompt';
import useModalPrompt from './hooks/useModalPrompt';

interface ButtonsProps extends ButtonProps {
  status?: EvaStatus;
  text: string;
  value: any;
  onPress?: (data?: any) => Promise<void> | void;
}

type PromptState = {
  title: string;
  message: string;
  buttonStatus?: EvaStatus;
  buttonText?: string;
  buttons?: Array<ButtonsProps>;
  withButtonLoader?: boolean;
  onCloseButtonClick?: () => void;
};

const Prompt = () => {
  const { hideModalPrompt } = useModalPrompt();
  const [data, setData] = useState<PromptState | undefined>();
  const [loadingIndex, setLoadingIndex] = useState<number | undefined>();

  const title = data?.title || '';
  const message = data?.message || '';
  const buttonStatus = data?.buttonStatus || '';
  const buttonText = data?.buttonText || '';
  const buttons = data?.buttons || [];
  const withButtonLoader = data?.withButtonLoader || false;
  const onCloseButtonClick = data?.onCloseButtonClick;

  const hasButton =
    (Boolean(buttonStatus) && Boolean(buttonText)) || !lodashIsEmpty(buttons);

  const _onClose = (data?: ButtonsProps) =>
    hideModalPrompt(MODALPROMPT.prompt, data);

  const _onCloseButtonClick = () => {
    _onClose({ text: 'x', value: 'x' });
    onCloseButtonClick();
  };

  const _onClick = async (button: ButtonsProps, index: number) => {
    if (button.onPress) {
      if (withButtonLoader) {
        setLoadingIndex(index);
        await button.onPress?.(button);
        setLoadingIndex(undefined);
      } else {
        button.onPress?.(button);
      }
    }
    _onClose();
  };

  const _renderButton = () => {
    if (Boolean(buttonStatus) && Boolean(buttonText)) {
      return (
        <Button
          status={buttonStatus}
          onPress={() => _onClose({ text: buttonText, value: buttonText })}
        >
          {buttonText}
        </Button>
      );
    } else if (!lodashIsEmpty(buttons)) {
      const isRow = buttons.length <= 2;
      return (
        <View
          dataSet={{ media: ids.buttonContainer }}
          //@ts-ignore : rowgap and columngap definition not avaialble in react native media query
          style={{
            ...(isRow && styles.buttonContainer),
            ...{ rowGap: globalSpacing / 2, columnGap: globalSpacing / 2 },
          }}
        >
          {buttons.map((button, i) => {
            const { text, onPress, ...rest } = button;
            const hasLoading = !lodashIsUndefined(loadingIndex);
            return (
              <Button
                key={i}
                loading={i === loadingIndex}
                disabled={hasLoading ? i !== loadingIndex : false}
                onPress={() => _onClick(button, i)}
                {...rest}
              >
                {(props: any) => (
                  <Text {...props} numberOfLines={isRow ? 1 : undefined}>
                    {text}
                  </Text>
                )}
              </Button>
            );
          })}
        </View>
      );
    }
    return null;
  };

  return (
    <ModalPrompt
      id={MODALPROMPT.prompt}
      dataSet={{ media: ids.promptModal }}
      mainContentWrapperStyle={styles.promptModal}
      closeOnDocumentClick={false}
      title={title}
      onBeforeShow={setData}
      onCloseButtonClick={onCloseButtonClick ? _onCloseButtonClick : undefined}
      hasHeader
      hasHeaderLine
    >
      <View style={hasButton && ThemeStyle.spacingBottomMedium}>
        <Text>{message}</Text>
      </View>
      {_renderButton()}
    </ModalPrompt>
  );
};

const { ids, styles } = StyleSheet.create({
  promptModal: {
    [`@media ${BreakPoints.xs}`]: {
      width: 300,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 320,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 340,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 360,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 380,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 400,
    },
  },
  buttonContainer: {
    [`@media ${BreakPoints.xs}`]: {
      ...ThemeStyle.flexDirectionRowCenterCenter,
    },
    [`@media ${BreakPoints.sm}`]: {
      ...ThemeStyle.flexDirectionRowCenterCenter,
    },
    [`@media ${BreakPoints.md}`]: {
      ...ThemeStyle.flexDirectionRowEndEnd,
    },
    [`@media ${BreakPoints.lg}`]: {
      ...ThemeStyle.flexDirectionRowEndEnd,
    },
    [`@media ${BreakPoints.xl}`]: {
      ...ThemeStyle.flexDirectionRowEndEnd,
    },
    [`@media ${BreakPoints.xxl}`]: {
      ...ThemeStyle.flexDirectionRowEndEnd,
    },
  },
});

export default Prompt;
