import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { Layout } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import Rating from './Rating';
import ReviewItem from './ReviewItem';
import ReviewItemLoader from './ReviewItemLoader';
import useController from './controller/fullReviewList';

import EmptyData from '../../Components/EmptyData';
import List from '../../Components/List';

const FullReviewList = (props) => {
  const {
    storeId,
    state,
    initialData,
    reviewSummaryData,
    onEndReached,
    mapItemToComponentProps,
    onSelectedFilter,
    onProductPress,
  } = useController(props);

  const _renderItem = ({ isFirstOfList, isLastOfList, item, index }) => {
    if (item?.loading) {
      return (
        <View
          key={index}
          style={[
            !isFirstOfList && ThemeStyle.spacingTop,
            isLastOfList && ThemeStyle.spacingBottom,
          ]}
        >
          <ReviewItemLoader />
        </View>
      );
    }
    const reviewItemProps = mapItemToComponentProps(item);
    return (
      <View
        key={index}
        style={[
          !isFirstOfList && ThemeStyle.spacingTop,
          isLastOfList && ThemeStyle.spacingBottom,
        ]}
      >
        <ReviewItem {...reviewItemProps} onProductPress={onProductPress} />
      </View>
    );
  };
  const _renderHeader = (
    <Rating
      storeId={storeId}
      reviewData={
        reviewSummaryData.current || {
          average: 0,
          total: 0,
          rating_summary: [],
          status: 'pending',
        }
      }
      onSelectFilter={onSelectedFilter}
    />
  );
  return (
    <Layout level="2">
      <List
        plain
        key={'reviewList'}
        data={
          !lodashIsEmpty(state.reviewData.result)
            ? state.reviewData.result
            : reviewSummaryData.current?.status === 'empty'
            ? []
            : initialData
        }
        keyExtractor={(item) => {
          `reviewList-${item.id}`;
        }}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        ListEmptyComponent={
          <EmptyData
            type="filter"
            title="No reviews found"
            subtitle=" "
            svgWidth={200}
            svgHeight={200}
          />
        }
        ListFooterComponent={
          state.isFetching && (
            <ActivityIndicator color={ThemeColor.green} size={30} />
          )
        }
        ListHeaderComponent={_renderHeader}
        ListHeaderComponentStyle={ThemeStyle.pageVerticalSpacingMedium}
        contentContainerStyle={
          lodashIsEmpty(state.reviewData.result)
            ? [ThemeStyle.flex1, ThemeStyle.justifyContentCenter]
            : ThemeStyle.pageHorizontalSpacingSmall
        }
        style={{ height: 550 }}
        renderItem={_renderItem}
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
      />
    </Layout>
  );
};

export default React.memo(FullReviewList);
