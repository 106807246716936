import { createSelector } from '@reduxjs/toolkit';

import { checkout } from '../defaultValues';

const mealPlan = (state) => state.mealPlan.data;

const mealPlanCheckoutFlagSelector = createSelector(
  mealPlan,
  (state) => state.checkoutFlag
);
const mealPlanTypeSelector = createSelector(mealPlan, (state) => state.type);
const mealPlanItemsSelector = createSelector(mealPlan, (state) => state.items);
const mealPlanCheckoutPromoSelector = createSelector(
  mealPlan,
  (state) => state.checkoutData[checkout.keys.PROMO_CODE]
);
const mealPlanStorePromoSelector = createSelector(
  mealPlan,
  (state) => state.checkoutData[checkout.keys.PROMO_STORE]
);

export {
  mealPlanCheckoutFlagSelector,
  mealPlanCheckoutPromoSelector,
  mealPlanTypeSelector,
  mealPlanItemsSelector,
  mealPlanStorePromoSelector,
};
