import React, { useState, useRef, Fragment } from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashFindIndex from 'lodash/findIndex';
import lodashReduce from 'lodash/reduce';
import lodashValues from 'lodash/values';
import lodashIsEmpty from 'lodash/isEmpty';

import ThemeStyle from '../../../Theme/styles';

import { pluralize } from '../../../Helper';

//COMPONENTS
import TabBar from './TabBar';

import ListGroupSection from './ListGroupSection';

const TabSectionList = ({
  data = {},
  search,
  searchResult,
  onSearch,
  onPressSearch,
  scrollEnabled,
  renderSectionTitle,
  renderSectionItem,
  contentContainerStyle,
  ListHeaderComponent,
  ListEmptyComponent,
  ListFooterComponent,
}) => {
  const scroll = useRef();
  const isPressTab = useRef();
  const section = useRef({});
  const headerRef = useRef({});

  const itemResultSearchCount = searchResult
    ?.map((item) => item.data)
    ?.map((arr) => arr.map((obj) => obj.id))
    ?.flat(searchResult.length).length;

  ///STATES
  const [activeSlide, setActiveSlide] = useState(-1);

  const prepareSections = data?.map((item, index) => ({ ...item, index }));

  const sectionArray = lodashValues(section.current);

  const _onScroll = ({ layoutMeasurement, contentOffset, contentSize }) => {
    if (!isPressTab.current) {
      if (
        layoutMeasurement.height + contentOffset.y >=
        contentSize.height - 50
      ) {
        setActiveSlide(sectionArray.length - 1);
      } else {
        const getNearestValue = lodashReduce(section.current, (a, b) => {
          return Math.abs(b.y - contentOffset.y) <
            Math.abs(a.y - contentOffset.y)
            ? b
            : a;
        });

        const result = lodashFindIndex(
          sectionArray,
          (sc) => sc.y === getNearestValue?.y
        );

        if (contentOffset.y > getNearestValue?.y - 70) {
          //This condition is when reaching the sticky point
          setActiveSlide(result);
        } else if (
          contentOffset.y < section.current[0]?.y &&
          activeSlide <= 0
        ) {
          //This is when scrolling the top the active slide should be disable
          setActiveSlide(-1);
        }
      }
    }
  };

  const _renderTab = ({ title, isActive, index }) => {
    return (
      <Text
        key={index}
        category={isActive ? 'p1' : 'p2'}
        status={isActive ? 'basic' : 'primary'}
        numberOfLines={1}
      >
        {title}
      </Text>
    );
  };

  const _onPressTab = (index) => {
    isPressTab.current = true;
    setActiveSlide(index);
    scroll.current.scrollTo({
      animated: true,
      y: section.current[index]?.y - 70,
    });
    setTimeout(() => (isPressTab.current = false), [2000]);
  };

  const _onSectionLayout = (key) => (ev) => {
    const { x, y, width, height } = ev.nativeEvent.layout;
    section.current[key] = {
      x,
      y,
      width,
      height,
    };
  };

  const _onSectionResultLayout = () => {
    scroll.current.scrollTo({
      animated: true,
      y: headerRef.current,
    });
  };

  const _onHeaderLayout = (ev) => {
    const { height } = ev.nativeEvent.layout;
    headerRef.current = height;
  };
  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        ref={scroll}
        stickyHeaderIndices={[1]}
        stick
        scrollEventThrottle={16}
        onScroll={({ nativeEvent }) => _onScroll(nativeEvent)}
        contentContainerStyle={contentContainerStyle}
        scrollEnabled={scrollEnabled}
      >
        <View onLayout={_onHeaderLayout}>{ListHeaderComponent()}</View>
        <TabBar
          sections={prepareSections || []}
          renderTab={_renderTab}
          currentIndex={activeSlide}
          onPress={(index) => _onPressTab(index)}
          onSearch={onSearch}
          onPressSearch={onPressSearch}
        />

        {
          // This is showing the result and list item
          // so the onlayout callback will be called after show render
          !lodashIsEmpty(search) &&
            (!lodashIsEmpty(searchResult) ? (
              <Fragment>
                <View style={styles.headerResultTitle}>
                  <Text
                    category="h6"
                    style={{ fontSize: 17 }}
                  >{`We found ${itemResultSearchCount} ${pluralize(
                    itemResultSearchCount,
                    'result'
                  )} for "${search}"`}</Text>
                </View>

                {searchResult?.map((section, index) => (
                  <ListGroupSection
                    key={index}
                    title={section.title}
                    onLayout={_onSectionResultLayout}
                    sections={section}
                    renderItem={({ section }) =>
                      renderSectionItem({ section: section, isResult: true })
                    }
                  />
                ))}

                {prepareSections?.map((section, index) => (
                  <ListGroupSection
                    key={index}
                    onLayout={_onSectionLayout(index)}
                    title={section.title}
                    sections={section}
                    renderSectionHeader={renderSectionTitle}
                    renderItem={renderSectionItem}
                  />
                ))}
              </Fragment>
            ) : (
              <Fragment>
                {ListEmptyComponent()}
                {prepareSections?.map((section, index) => (
                  <ListGroupSection
                    key={index}
                    title={section.title}
                    sections={section}
                    onLayout={_onSectionResultLayout}
                    renderSectionHeader={renderSectionTitle}
                    renderItem={renderSectionItem}
                  />
                ))}
              </Fragment>
            ))
        }

        {
          // This is  the default view
          // so the onlayout callback will be called after show render
          lodashIsEmpty(search) &&
            prepareSections?.map((section, index) => (
              <ListGroupSection
                key={index}
                onLayout={_onSectionLayout(index)}
                title={section.title}
                sections={section}
                renderSectionHeader={renderSectionTitle}
                renderItem={renderSectionItem}
              />
            ))
        }
        {ListFooterComponent()}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  headerResultTitle: {
    ...ThemeStyle.spacingTop,
    ...ThemeStyle.spacingBottom,
    paddingLeft: 20,
    marginHorizontal: 50,
  },
});

export default TabSectionList;
