import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import ThemeColor from '../../Theme/colors';

const TabBarHome = (props) => {
  return (
    <Svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      stroke={props?.color || ThemeColor.darkGray}
      strokeWidth={props?.strokeWidth || 40}
      {...props}
    >
      <G id="home">
        <G id="Group_1051" data-name="Group 1051">
          <Path
            id="Path_695"
            data-name="Path 695"
            d="M233.33 540l68.12-68.12m0 0l238.54-238.54 238.48 238.48m-476.96 0V812.5c-.05 18.82 15.16 34.12 33.98 34.17h102.23M778.4 471.82l68.25 68.19m-68.12-68.12v340.62c.05 18.82-15.16 34.12-33.98 34.17H642.13m-204.4 0c18.82 0 34.08-15.26 34.08-34.08V676.23c0-18.82 15.26-34.08 34.08-34.08h68.12c18.82 0 34.08 15.26 34.08 34.08v136.28c-.05 18.82 15.16 34.12 33.98 34.17h.06m-204.4 0h204.4"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </G>
      </G>
    </Svg>
  );
};

export default TabBarHome;
