import React, { useState } from 'react';
import { View } from 'react-native';
import ActionSheet from 'react-native-actions-sheet';
import { Text } from '@ui-kitten/components';
import lodashFilter from 'lodash/filter';
import dayjs from 'dayjs';

import Button from '../../Components/Button';
import constants from '../../Config/constants';
import { getCartTerm } from '../../Helper/RemoteConfig';
import usePromoCart from '../../Hooks/usePromoCart';
import Service from '../../Service';
import ThemeStyle from '../../Theme/styles';

import commonProps from './commonProps';
import modals from './modals';
import styles from './styles';

/**
 * data shape must be is
 * {
 *   discount: string,
 *   code: string,
 *   minimum_purchase: string,
 *   applies_to: Array<string>,
 *   start_date?: string,
 *   end_date?: string,
 * }
 */
function PromoDetailsSheet({ sheetId }) {
  const { addPromoToCart } = usePromoCart();
  const [data, setData] = useState();

  const name = data?.discount || '';
  const code = data?.code || '';
  const minimumPurchase = data?.minimum_purchase || '';
  const applicableOn = data?.applies_to || '';
  const startedOn = data?.start_date || '';
  const endOn = data?.end_date || '';

  const _onAdd = () => {
    addPromoToCart(data);
    modals.hide(modals.PROMO_DETAILS);
  };

  return (
    <ActionSheet id={sheetId} onBeforeShow={setData} {...commonProps}>
      <View style={styles.contentContainer}>
        {/* Promo Name */}
        <Text category="h5" style={ThemeStyle.spacingBottom}>
          {name}
        </Text>

        <View style={ThemeStyle.spacingBottom}>
          {/* Promo Code */}
          <View style={ThemeStyle.flexDirectionRowCenter}>
            <Text style={ThemeStyle.flex1}>Code</Text>
            <Text style={ThemeStyle.flex2}>: {code}</Text>
          </View>

          {/* Minimum spent */}
          <View style={ThemeStyle.flexDirectionRowCenter}>
            <Text style={ThemeStyle.flex1}>For orders over</Text>
            <Text style={ThemeStyle.flex2}>
              : {Service.commafyNumber(minimumPurchase, true)}
            </Text>
          </View>

          {/* Applies to */}
          {(() => {
            const appliesToType = lodashFilter(
              constants.ORDER_TYPE_DATA,
              (ot) => applicableOn?.includes(ot.value)
            );
            const appliesTo = appliesToType?.map((t) => t.label).join(', ');
            if (!appliesTo) {
              return null;
            }
            return (
              <View style={ThemeStyle.flexDirectionRowCenter}>
                <Text style={ThemeStyle.flex1}>Applicable on</Text>
                <Text
                  style={ThemeStyle.flex2}
                  numberOfLines={1}
                  adjustsFontSizeToFit
                >
                  : {appliesTo}
                </Text>
              </View>
            );
          })()}

          {/* Promo started */}
          {!!startedOn && (
            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Text style={ThemeStyle.flex1}>Started on</Text>
              <Text style={ThemeStyle.flex2}>
                : {dayjs(startedOn).format(constants.DATE_DISPLAY_FORMAT2)}
              </Text>
            </View>
          )}

          {/* Promo end */}
          {!!endOn && (
            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Text style={ThemeStyle.flex1}>Valid until</Text>
              <Text style={ThemeStyle.flex2}>
                : {dayjs(endOn).format(constants.DATE_DISPLAY_FORMAT2)}
              </Text>
            </View>
          )}
        </View>

        <Button status="success" onPress={_onAdd}>
          Add to {getCartTerm()}
        </Button>
      </View>
    </ActionSheet>
  );
}

export default PromoDetailsSheet;
