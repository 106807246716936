import React, { Fragment, useEffect, useRef, useState } from 'react';
import { WebView } from 'react-native-webview';
import lodashGet from 'lodash/get';
import { useDispatch } from 'react-redux';

import Header from '../../Components/Header/index.branded';
import ScreenWrapper from '../../Components/ScreenWrapper';
import withCheckout from '../../Hooks/withCheckout';
import { removeCart } from '../../RTK/cart';
import { changeWhenFilter } from '../../RTK/filter';

import { isValidJson } from '../../Helper';

const tryParse = (res, def = null) => {
  try {
    JSON.parse(res);
  } catch (err) {
    return def || res;
  }
};

function CheckoutWebview({ navigation, route }) {
  const webViewRef = useRef();
  const dispatch = useDispatch();
  const { storeId, url } = lodashGet(route, 'params');
  const [isTrackingPage, setTrackingPage] = useState(true);
  const lastVisitedStore = window?.localStorage?.getItem('BRANDED_LVS');

  const _listenToMessage = ({ nativeEvent: { data, origin } }) => {
    const eventName = tryParse(data)?.message || data;
    if (eventName === 'tansaction-completed' || eventName === 'trackingPage') {
      // Reset cart and order date and time
      dispatch(removeCart(storeId));
      dispatch(changeWhenFilter(null));
      window?.localStorage?.removeItem('BRANDED_LVS');
      setTrackingPage(true);
    } else {
      setTrackingPage(false);
    }
  };

  useEffect(() => {
    if (lastVisitedStore) {
      setTrackingPage(false);
    }
  }, []);

  const shouldHaveBackButton =
    !isTrackingPage && (navigation.canGoBack() || lastVisitedStore);

  return (
    <Fragment>
      <Header
        plain={true}
        store_id={storeId}
        backButtonText="Back"
        hasBackButton={shouldHaveBackButton}
        onPressBack={() => {
          if (navigation.goBack()) {
            return navigation.goBack();
          }

          if (lastVisitedStore) {
            return window?.location?.assign(lastVisitedStore);
          }
        }}
      />

      <ScreenWrapper
        header={false}
        statusBar={true}
        scroll={false}
        fullWidth={true}
      >
        <WebView
          ref={webViewRef}
          source={{ uri: url }}
          bounces={false}
          onMessage={_listenToMessage}
        />
      </ScreenWrapper>
    </Fragment>
  );
}

export default withCheckout(CheckoutWebview);
