import client from './client';
import api from './endpoints';

const endpoints = api();

function getDetails(productId, orderDate, orderTime, cancelToken) {
  let path = endpoints.ITEM_DETAILS(productId);
  if (orderDate && orderTime) {
    path += `?order_date=${orderDate}&order_time=${orderTime}`;
  }
  return client.get(path, {}, { cancelToken });
}

function getTenProducts(tag, cancelToken) {
  let path = `${endpoints.ITEM}?limit=10`;
  if (typeof tag === 'string') {
    path += `&tags=${tag}`;
  } else if (Array.isArray(tag)) {
    tag.forEach((t) => {
      path += `&${t.key}=${t.value}`;
    });
  } else if (typeof tag === 'object') {
    path += `&${tag.key}=${tag.value}`;
  }
  return client.get(path, {}, { cancelToken });
}

export default {
  getDetails,
  getTenProducts,
};
