import { StyleSheet } from 'react-native';

import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';

export default StyleSheet.create({
  list: [
    ThemeStyle.scrollContainer,
    ThemeStyle.pageBackground,
    ThemeStyle.pageBottomSpacer,
  ],
  footer: [
    ThemeStyle.pageVerticalSpacing,
    ThemeStyle.alignItemsCenter,
    constants.isAndroid && ThemeStyle.spacingBottomMedium,
  ],
});
