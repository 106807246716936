import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashPick from 'lodash/pick';

import constants from '../../Config/constants';
import withNavigation from '../../Hooks/withNavigation';
import { filter } from '../../RTK/defaultValues';
import { updateFilter } from '../../RTK/filter';
import routeList from '../../Routes/list';
import { globalSpacing } from '../../Theme/styles';

import { isScrollEndReached } from '../../Helper/Routines';

import ArrowControl from '../ArrowControl';
import List from '../List';
import Image from '../Image';
import { RectangleLoader } from '../Loader';
import ScaleButtonAnimation from '../Web/Intereaction/ScaleButtonAnimation';

import ThemeStyle from '../../Theme/styles';

import styles from './styles';
import Button from '../Button';
import { getCategories } from '../../Helper/RemoteConfig';

const { filterType, useCase } = filter;

class FilterCategory extends React.Component {
  constructor(props) {
    super(props);
    this.list = React.createRef();
    this.state = {
      cuisines: getCategories(),
      isEndReached: false,
      scrollOffset: 0,
    };
  }

  _onFilterPressed = (index) => async () => {
    const { filter, navigation, dispatchUpdateFilter, onClick } = this.props;
    const { cuisines } = this.state;
    const selectedCategory = cuisines[index];

    if (onClick) {
      return onClick(selectedCategory);
    } else if (lodashIsEmpty(filter) && selectedCategory.all) {
      // do nothing if all category is click while no category filter
      return;
    } else if (selectedCategory.offer) {
      // TODO - go to offfer screen
      navigation.navigate(routeList.SEE_ALL, {
        title: selectedCategory.name,
        value: selectedCategory.name.toLowerCase(),
      });
      return;
    }

    const objectToSave = lodashPick(selectedCategory, [
      'id',
      'name',
      'value',
      'all',
    ]);
    await dispatchUpdateFilter({
      useCase: useCase.HOME,
      type: filterType.CATEGORY,
      ...objectToSave,
    });
  };

  _renderImage = (activeImg, inactiveImg, isActive) => (
    <View
      style={[
        ThemeStyle.spacingTopSmall,
        { overflow: 'hidden', alignItems: 'center' },
      ]}
    >
      {!!inactiveImg && (
        <Image
          source={{ uri: activeImg }}
          style={[
            styles.image,
            { position: 'absolute', top: isActive ? 0 : '-100%', zIndex: 1 },
          ]}
          resizeMode="contain"
        />
      )}
      <Image
        source={{ uri: inactiveImg }}
        style={styles.image}
        resizeMode="contain"
      />
    </View>
  );

  _renderItem = ({ isFirstOfList, isLastOfList, item, index }) => {
    const { filter, isActiveRemovable, value } = this.props;
    const isAllActive = item.all && lodashIsEmpty(filter); // if no filter, automatically select All as active
    const isActive = value?.id === item.id || filter?.id === item.id; // if value is pass use it as comparison
    const firstAndLastMargin = constants.isWeb ? 0 : globalSpacing;
    const marginStyle = {
      marginLeft: isFirstOfList ? firstAndLastMargin : globalSpacing / 4,
      marginRight: isLastOfList ? firstAndLastMargin : globalSpacing / 4,
    };
    const ButtonContainer = constants.isWeb ? ScaleButtonAnimation : Button;
    const Container = isActive && !isActiveRemovable ? View : ButtonContainer;

    const conditionalProps = constants.isWeb
      ? {
          buttonStyle: ({ hovered }) => [
            marginStyle,
            hovered && ThemeStyle.buttonHovered1,
          ],

          contentStyle: { ...styles.listItemContainer },
        }
      : {
          style: marginStyle,
          plain: true,
        };
    return (
      <Container
        testID={`categoryButton${index}`}
        onPress={this._onFilterPressed(index)}
        {...conditionalProps}
      >
        <View style={!constants.isWeb && styles.listItemContainer}>
          {this._renderImage(
            !item.offer ? item.active : item.image,
            !item.offer ? item.inactive : '',
            isAllActive || isActive
          )}
          <Text
            status={isAllActive || isActive ? 'success' : 'basic'}
            category="c1"
            style={[ThemeStyle.spacingTopSmall, ThemeStyle.textCenter]}
          >
            {item.label || item.name}
          </Text>
        </View>
      </Container>
    );
  };

  //FOR WEB ONLY
  _onScroll = ({ nativeEvent }) => {
    const isEnd = isScrollEndReached(nativeEvent);
    if (!isEnd && this.state.isEndReached) {
      this.setState({
        isEndReached: false,
      });
    } else if (isEnd && !this.state.isEndReached) {
      this.setState({
        isEndReached: true,
      });
    }
    this.setState({
      scrollOffset: nativeEvent?.contentOffset?.x,
    });
  };

  render() {
    const { isLoading } = this.props;

    if (constants.isWeb) {
      if (!isLoading && !lodashIsEmpty(this.state.cuisines)) {
        return (
          <View style={{ marginHorizontal: -15 }}>
            <ArrowControl
              listRef={this.list}
              position="middle"
              scrollOffset={this.state.scrollOffset}
              isEndReached={this.state.isEndReached}
              isHideDisabledButton={false}
            >
              <List
                ref={this.list}
                data={this.state.cuisines}
                extraData={this.props.filter}
                renderItem={this._renderItem}
                style={{ marginHorizontal: 20 }}
                onScroll={this._onScroll}
                horizontal
                plain
              />
            </ArrowControl>
          </View>
        );
      } else {
        return (
          <View style={[ThemeStyle.flex, ThemeStyle.spacingTopMedium]}>
            <RectangleLoader width={'100%'} height={60} />
          </View>
        );
      }
    }

    const { parentCategory } = this.props;

    const isNonFood =
      parentCategory?.value !== constants.PRODUCT_TYPES[0].value;
    if (isNonFood) {
      // TODO - if we have subcategory for non food, implement it here, might not be different render but should be implement on this file
      return null;
    }

    return (
      <List
        data={this.state.cuisines}
        extraData={this.props.filter}
        renderItem={this._renderItem}
        horizontal
        plain
      />
    );
  }
}

FilterCategory = withNavigation(FilterCategory);

FilterCategory.defaultProps = {
  isActiveRemovable: true,
};

FilterCategory.propTypes = {
  value: PropTypes.object,
  isActiveRemovable: PropTypes.bool, // true if can remove the active, false if not be able to remove the selected
  onClick: PropTypes.func, // optional, else will use the dispatch to homepage category filter
};

const mapStateToProps = (state) => ({
  parentCategory: state.filter.parentCategory,
  filter: state.filter.home.category[0],
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateFilter: (data) => dispatch(updateFilter(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterCategory);
