// key is backend response and value is human readable message
export default {
  // Api response
  'first_name format not accepted': 'Please enter a valid first name',
  'first_name should not be empty': 'First name is required',
  'last_name format not accepted': 'Please enter a valid last name',
  'last_name should not be empty': 'Last name is required',
  'contact.email must be an email': 'Please input a valid email',
  'contact.email already in use': 'Email is already in use',
  'contact.phone_number must be a phone number':
    'Please input a valid phone number',
  'phone_number must be a phone number': 'Please input a valid phone number',
  'phone_number already in use': 'Phone number is already in use',
  'store_id should not be empty': 'Store ID is required',
  'store_id must be a string': 'Store ID is missing on the payload',
  'order_type must be a valid enum value':
    'Order type is missing on the payload',
  order_date: 'order_date must not be in the past',
  'order - not found': 'No record found',
  'required extra not in cart':
    'Store has changed the required extra for this item',
  Unauthorized: 'Invalid login credentials',
  'Something went wrong on our end.': 'Ooops! Something went wrong',
  'store maximum delivery distance limit exceeded':
    'The delivery address you have specified is outside our range. Please select a different address or try with out other stores.',
  'address - store maximum delivery distance limit exceeded':
    'Address out of delivery area.',
  'password verification failed': 'Please input the correct old password',
  'item is currently out of stock': 'Sorry, this item just sold out :(',
  'item limit exceeded': 'Sorry, this item just sold out :(',
  'customer.mobile_number must be a phone number':
    'Customer mobile number is not valid',
  // Others
  TIMEOUT_ERROR: 'Please check your device connectivity',
  NETWORK_ERROR: 'Please check your device connectivity',
  MAX_SEND_ATTEMPTS_REACHED:
    'You have reached the maximum number of verification attempts allowed (5). Please try again after 10 minutes.',
  MAX_CHECK_ATTEMPTS_REACHED:
    'You have reached the maximum number of verification attempts allowed (5). Please try again after 10 minutes.',
  SERVICE_RATE_LIMITED:
    'The service has reached the maximum number of rate limits allowed.',
  TOO_MANY_REQUEST:
    'You have exceeded the maximum number of verifications for the same phone number within a short period of time.',
  'Too early for retry.':
    'You have tried logging in repeatedly using the same email.',
};
