import { useMediaQuery } from 'react-responsive';

const ScreenSize = () => {
  const isWeb = useMediaQuery({ query: '(min-width: 1280px)' });
  const isQuarterScreenWeb = useMediaQuery({ query: '(max-width: 850px)' });
  const isMediumWeb = useMediaQuery({ query: '(min-width: 769px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1279px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 420px)' });
  const isMedium = useMediaQuery({ query: '(max-width: 680px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 500px)' });
  const isSmallHeightScreen = useMediaQuery({ query: '(max-height: 700px' });

  const xs = useMediaQuery({ query: '(max-width: 576px' });
  const xs2 = useMediaQuery({ query: '(max-width: 768px' });
  const sm = useMediaQuery({ query: '(min-width: 576px' });
  const md = useMediaQuery({ query: '(min-width: 768px' });
  const lg = useMediaQuery({ query: '(min-width: 992px' });
  const xl = useMediaQuery({ query: '(min-width: 1200px' });
  const xxl = useMediaQuery({ query: '(min-width: 1400px' });

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  return {
    isWeb,
    isQuarterScreenWeb,
    isMediumWeb,
    isTabletOrMobile,
    isMobile,
    isMedium,
    isSmallScreen,
    isSmallHeightScreen,
    xs,
    xs2,
    sm,
    md,
    lg,
    xl,
    xxl,
    isPortrait,
  };
};

export default ScreenSize;
