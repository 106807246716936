import FontAwesome from './fontawesome-icons';
import FontAwesome5 from './fontawesome5-icons';
import MaterialCommunity from './materialcommunity-icons';
import Ionicons from './ion-icons';
import Entypo from './entypo-icons';
import Feather from './feather-icons';
export {
  FontAwesome,
  FontAwesome5,
  MaterialCommunity,
  Ionicons,
  Entypo,
  Feather,
};
