import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import dayjs from 'dayjs';
import { Divider, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashSortBy from 'lodash/sortBy';

import Button from '../../Components/Button';
import Header from '../../Components/Header';
import MapSnapShotPreview from '../../Components/MapSnapshotPreview';
import ScreenWrapper from '../../Components/ScreenWrapper';
import constants from '../../Config/constants';
import StoreHelper from '../../Helper/Store';
import ThemeStyle, { deviceWidth } from '../../Theme/styles';

import withController from '../Address/controller/withController';
import useStoreDetailsController from '../Store/controller/details';

import InformationList, { TYPES } from './List';
import ReadMore from '../../Components/Readmore';

class StoreInformation extends React.Component {
  _renderHeader = (title) => {
    return <Header title={title} alignTitle="left" withBorder />;
  };

  _getTitleAndSubTitleOfAccordion = () => {
    const { controller } = this.props;
    const status = controller.getStoreStatus();
    const accordionStatus = controller.getStoreStatus(true);
    return {
      title: `${status.statusText} · ${status.statusDescription}`,
      subTitle: accordionStatus.statusDescription,
    };
  };

  render() {
    const { shopData, store_theme = {} } = this.props;
    const now = dayjs();
    if (lodashIsEmpty(shopData)) {
      return null;
    }

    const { title: acdnTitle, subTitle: acdnSubTitle } =
      this._getTitleAndSubTitleOfAccordion();
    const snapshotWidth =
      deviceWidth - ThemeStyle.pageHorizontalSpacing.paddingHorizontal * 2; // * 2 because left and right
    const {
      contact_number,
      geocode_location,
      location,
      name,
      store_description,
      store_hours,
      website,
    } = shopData;
    // prettier-ignore
    const hasGeocodeLocation = typeof geocode_location === 'object' && !lodashIsEmpty(geocode_location)

    const compiledData = [
      {
        iconName: 'clock-outline',
        title: acdnTitle,
        subTitle: acdnSubTitle,
        accordionData: StoreHelper.getStoreAvailability(
          store_hours,
          [], // no need off dates if return type is array
          'array',
          now // This should todays date not value for filtered date
        ),
        type: TYPES.ACCRODION,
        order: 2,
      },
    ];

    if (name && location && hasGeocodeLocation) {
      compiledData.push({
        iconName: 'pin-outline',
        title: location,
        titleLines: 2,
        storeName: name,
        location: {
          latitude: geocode_location.lat,
          longitude: geocode_location.lng,
        },
        type: TYPES.ADDRESS,
        order: 1,
      });
    }

    if (website) {
      compiledData.push({
        iconName: 'globe-outline',
        title: website,
        type: TYPES.WEBSITE,
        order: 3,
      });
    }

    if (contact_number) {
      compiledData.push({
        iconName: 'phone-outline',
        title: contact_number,
        type: TYPES.PHONE,
        order: 4,
      });
    }
    const Wrapper = constants.isWeb ? React.Fragment : ScreenWrapper;
    // prettier-ignore
    const conditionalProps = constants.isWeb ? {} : { header: this._renderHeader(name), statusBar: true }
    return (
      <Wrapper {...conditionalProps}>
        <View
          style={
            constants.isWeb
              ? { ...ThemeStyle.pageVerticalSpacingMedium }
              : styles.wrapper
          }
        >
          {hasGeocodeLocation && (
            <View style={ThemeStyle.spacingBottom}>
              <MapSnapShotPreview
                markers={[
                  {
                    latitude: geocode_location.lat,
                    longitude: geocode_location.lng,
                  },
                ]}
                height={150}
                width={constants.isWeb ? 600 : snapshotWidth}
                zoom={15}
                withRadius
              />
            </View>
          )}

          <View style={ThemeStyle.spacingBottom}>
            <InformationList
              data={lodashSortBy(compiledData, ['order'])}
              store_theme={store_theme}
            />
          </View>

          {!!store_description && (
            <View style={ThemeStyle.spacingBottomMedium}>
              <Text
                category={constants.isWeb ? 's1' : 'p1'}
                style={ThemeStyle.spacingBottomMedium}
              >
                About Us
              </Text>

              <ReadMore numberOfLines={2} value={store_description} />
            </View>
          )}
        </View>
      </Wrapper>
    );
  }
}

StoreInformation = withController(StoreInformation, useStoreDetailsController);

const styles = StyleSheet.create({
  wrapper: [ThemeStyle.pageVerticalSpacing, ThemeStyle.pageHorizontalSpacing],
});

const mapStateToProps = (state) => ({
  shopData: state.shop.data,
  whenFilter: state.filter.home.when,
});

export default connect(mapStateToProps)(StoreInformation);
