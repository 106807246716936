export type EventHandler = (...args: any[]) => void;
export type EventHandlerSubscription = {
  unsubscribe: () => void;
};

const useModalEventManager = () => {
  const registry = new Map<
    EventHandler,
    {
      name: string;
      once: boolean;
    }
  >();

  const unsubscribeAll = () => {
    registry.clear();
  };

  const subscribe = (name: string, handler: EventHandler, once = false) => {
    if (!name || !handler) throw new Error('name and handler are required.');
    registry.set(handler, { name, once });
    return { unsubscribe: () => unsubscribe(name, handler) };
  };

  const unsubscribe = (_name: string, handler: EventHandler) => {
    return registry.delete(handler);
  };

  const publish = (name: string, ...args: any[]) => {
    registry.forEach((props, handler) => {
      if (props.name === name) handler(...args);
      if (props.once) registry.delete(handler);
    });
  };

  const remove = (...names: string[]) => {
    registry.forEach((props, handler) => {
      if (names.includes(props.name)) registry.delete(handler);
    });
  };

  return {
    unsubscribeAll,
    subscribe,
    unsubscribe,
    publish,
    remove,
  };
};

export default useModalEventManager;

export const actionModalEventManager = useModalEventManager();
