import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader, { Circle } from 'react-content-loader/native';
import { FadeOut } from 'react-native-reanimated';

class RectangleLoader extends React.PureComponent {
  render() {
    const { size, style } = this.props;
    const radius = size / 2;

    return (
      <ContentLoader
        speed={2}
        width={size}
        height={size}
        backgroundColor="#f3f2f2"
        foregroundColor="#e6e5e5"
        style={style}
        exiting={FadeOut.duration(150)}
        animate
      >
        <Circle cx={size / 2} cy={size / 2} r={radius} />
      </ContentLoader>
    );
  }
}

RectangleLoader.defaultProps = {
  size: 50,
};

RectangleLoader.propsTypes = {
  size: PropTypes.number,
};

export default RectangleLoader;
