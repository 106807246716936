import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Icon, Text } from '@ui-kitten/components';
import Collapsible from 'react-native-collapsible';
import LottieView from 'lottie-react-native';
import lodashIsEmpty from 'lodash/isEmpty';

import lottieLiveJson from '../../Assets/lottie/live.json';
import OrderHelper from '../../Helper/Order';
import { isLoggedInSelector } from '../../RTK/user/selectors';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import List from '../List';
import Button from '../Button';
import OrderProgress from '../OrderProgress/Simple';

import useOngoingOrderBanner from './index.controller';

const iconSize = 25;
const OngoingOrderBanner: React.FC = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { hasSeeMore, isMinimized, orders, goToOrders, toggleBanner } =
    useOngoingOrderBanner();

  if (!isLoggedIn || lodashIsEmpty(orders) || orders[0]?.loading) {
    // return nothing if not logged in or no orders
    return null;
  }

  const _renderItem = ({ item }) => (
    <View style={styles.buttonContainer}>
      <Button onPress={() => goToOrders(item.id)} plain>
        <View style={ThemeStyle.flexDirectionRowCenter}>
          <View style={ThemeStyle.spacingRightMedium}>
            <LottieView
              source={lottieLiveJson}
              style={styles.icon}
              autoPlay
              loop
            />
          </View>
          <View style={ThemeStyle.flex1}>
            <Text style={ThemeStyle.spacingBottomSmall}>
              {item.store_details?.name}
            </Text>
            <View style={ThemeStyle.spacingBottomSmall}>
              <OrderProgress value={item.status} orderType={item.order_type} />
            </View>
            <Text category="c1">
              {OrderHelper.getOrderStatusText(item.status, item.order_type)}
            </Text>
          </View>
        </View>
      </Button>
    </View>
  );

  const _renderItemSeparator = () => (
    <View style={ThemeStyle.spacingBottomMedium} />
  );

  return (
    <View style={styles.wrapper}>
      <Button onPress={toggleBanner} plain>
        <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
          <Text category="p2">Active Orders</Text>
          <Icon
            name={isMinimized ? 'angle-up' : 'angle-down'}
            pack="fa"
            fill={ThemeColor.green}
            style={styles.icon}
          />
        </View>
      </Button>

      <Collapsible collapsed={isMinimized}>
        <View style={ThemeStyle.spacingTopMedium}>
          <List
            data={orders}
            renderItem={_renderItem}
            ItemSeparatorComponent={_renderItemSeparator}
            plain
          />
        </View>

        {hasSeeMore && (
          <View style={[ThemeStyle.spacingTopMedium, ThemeStyle.alignItemsEnd]}>
            <Button onPress={() => goToOrders()} plain>
              <Text category="c1" status="success">
                View all orders
              </Text>
            </Button>
          </View>
        )}
      </Collapsible>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    ...ThemeStyle.pageVerticalSpacing,
    ...ThemeStyle.pageHorizontalSpacing,
    backgroundColor: ThemeColor.lightGreen,
  },
  buttonContainer: {
    ...ThemeStyle.pageBackground,
    ...ThemeStyle.pageVerticalSpacingMedium,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    borderColor: ThemeColor.green,
    borderRadius: 5,
    borderWidth: 2,
  },
  icon: {
    width: iconSize,
    height: iconSize,
  },
});

export default OngoingOrderBanner;
