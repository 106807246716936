import React, { useEffect, useRef, useState } from 'react';
import { Animated, DeviceEventEmitter, StyleSheet, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';

import constants from '../Config/constants';
import ThemeColor from '../Theme/colors';
import ThemeStyles from '../Theme/styles';

const type_success = 'success';
const type_error = 'error';

const ToastEventName = 'PickupSuperAppToast';
const initialTopPosition = constants.isIOS ? constants.statusBarHeight : 0;
const maxTopPosition = initialTopPosition + (constants.isIOS ? 75 : 80);
const TOAST_DURATION = 2000;
let timeoutId = null;

function Toast() {
  const animatedRef = useRef(new Animated.Value(0)).current;

  const [toastUpdate, setToastUpdate] = useState({});

  useEffect(() => {
    const listener = DeviceEventEmitter.addListener(
      ToastEventName,
      _handleToast
    );
    return () => {
      listener.remove();
    };
  }, []);

  const _handleToast = (data, type = type_success) => {
    const updateState = { type };
    if (typeof data === 'string') {
      // data is "Message"
      updateState.message = data;
    } else if (typeof data === 'object') {
      // data is { title: "title", message: "message" }
      updateState.title = data.title;
      updateState.message = data.message;
    }
    // update state
    setToastUpdate(updateState);
    // toast animation
    _showToast();
  };

  const _showToast = () => {
    // clear timeout and pending animation
    clearTimeout(timeoutId);
    Animated.timing(animatedRef).stop();
    // show animation
    Animated.timing(animatedRef, {
      toValue: 2,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      // add timeout to hide animation
      timeoutId = setTimeout(() => {
        Animated.timing(animatedRef, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }).start();
      }, TOAST_DURATION);
    });
  };

  const backgroundColor =
    toastUpdate?.type === type_success
      ? ThemeColor.lightGreen
      : ThemeColor.red100;
  const iconColor =
    toastUpdate?.type === type_success ? ThemeColor.green : ThemeColor.red;

  return (
    <Animated.View
      style={[
        styles.wrapper,
        {
          elevation: animatedRef,
          opacity: animatedRef,
          zIndex: animatedRef,
        },
      ]}
    >
      <View
        style={[
          ThemeStyles.pageHorizontalSpacing,
          constants.isWeb && { maxWidth: 300, alignSelf: 'center' },
        ]}
      >
        <View
          style={[
            ThemeStyles.flexDirectionRowCenterSpaceBetween,
            ThemeStyles.pageVerticalSpacingMedium,
            ThemeStyles.pageHorizontalSpacingSmall,
            ThemeStyles.shadow,
            {
              backgroundColor,
              borderRadius: 10,
            },
          ]}
        >
          <View
            style={[
              ThemeStyles.flex1,
              ThemeStyles.flexDirectionRowCenterCenter,
            ]}
          >
            {/* Status Icon */}
            <View style={ThemeStyles.spacingRightMedium}>
              <Icon
                name={
                  toastUpdate?.type === type_success
                    ? 'checkmark-circle-2-outline'
                    : 'close-circle-outline'
                }
                fill={iconColor}
                style={{ width: 25, height: 25 }}
              />
            </View>

            {/* Title, Sub Title */}
            <View style={ThemeStyles.flex1}>
              {!!toastUpdate?.title && (
                <Text style={ThemeStyles.flex1} category="s2" numberOfLines={1}>
                  {toastUpdate.title}
                </Text>
              )}

              {!!toastUpdate?.message && (
                <Text category="p2" numberOfLines={2}>
                  {toastUpdate.message}
                </Text>
              )}
            </View>
          </View>
        </View>
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    top: maxTopPosition,
    flex: 1,
    left: 0,
    right: 0,
  },
});

Toast.TYPE_SUCCESS = type_success;
Toast.TYPE_ERROR = type_error;
Toast.show = (...args) => {
  DeviceEventEmitter.emit(ToastEventName, ...args);
};

export default Toast;
