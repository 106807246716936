import React, { Fragment, useState } from 'react';
import StyleSheet from 'react-native-media-query';

import { ScrollView, View } from 'react-native';
import { Icon, Text, Divider } from '@ui-kitten/components';

import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsUndefined from 'lodash/isUndefined';

import ThemeColor from '../../../../Theme/colors';
import ThemeStyle from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import Button from '../../../Button';

import ModalPrompt from './ModalPrompt';
import useModalPrompt from './hooks/useModalPrompt';

const OrderType = ({ modalId }) => {
  const [data, setData] = useState<any>();
  const { hideModalPrompt } = useModalPrompt();

  const { value, options } = data || {};

  const defaultClearStatus = 'control';

  const clearStatus = lodashIsUndefined(options?.clearStatus)
    ? defaultClearStatus
    : options.clearStatus;

  const onClose = () => {
    hideModalPrompt(modalId, {});
  };

  const _onSelectOnList = (option: any) => {
    hideModalPrompt(modalId, option);
  };

  return (
    <ModalPrompt
      id={modalId}
      title={options?.title || ''}
      dataSet={{ media: ids.orderType }}
      mainContentWrapperStyle={styles.orderType}
      hasHeader
      hasHeaderLine
      onCloseButtonClick={onClose}
      closeOnDocumentClick={false}
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <View style={{ maxHeight: 300 }}>
        {lodashIsEmpty(options) ? (
          <Text category="p1" style={[ThemeStyle.flex1, ThemeStyle.textCenter]}>
            No result found
          </Text>
        ) : (
          <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
            {options?.options?.map((option, index) => {
              const isSelected = value === option.value;
              console.log(value);
              console.log(option);
              const isLast = index === options.options.length;
              return (
                <Fragment key={index}>
                  <Button
                    onPress={() => _onSelectOnList(option)}
                    style={[
                      ThemeStyle.pageVerticalSpacing,
                      ThemeStyle.pageHorizontalSpacing,
                      ThemeStyle.flexDirectionRowCenterSpaceBetween,
                    ]}
                    plain
                  >
                    {isSelected && <View style={{ width: 20, height: 20 }} />}
                    <Text
                      category="p1"
                      style={[ThemeStyle.flex1, ThemeStyle.textCenter]}
                      status={isSelected ? 'success' : 'basic'}
                    >
                      {option.label}
                    </Text>
                    {isSelected && (
                      <Icon
                        name="checkmark-outline"
                        fill={ThemeColor.green}
                        style={{ width: 20, height: 20 }}
                      />
                    )}
                  </Button>
                  {!isLast && (
                    <Divider style={{ ...ThemeStyle.divider, height: 0.5 }} />
                  )}
                </Fragment>
              );
            })}
          </ScrollView>
        )}

        {!!options?.clearText && (
          <View
            style={[
              ThemeStyle.spacingTopMedium,
              ThemeStyle.pageHorizontalSpacing,
              ThemeStyle.spacingBottomMedium,
            ]}
          >
            <Button
              status={clearStatus}
              onPress={onClose}
              disabled={
                clearStatus === defaultClearStatus
                  ? lodashIsEmpty(value)
                  : false
              }
            >
              {options.clearText}
            </Button>
          </View>
        )}
      </View>
    </ModalPrompt>
  );
};

const { ids, styles } = StyleSheet.create({
  orderType: {
    [`@media ${BreakPoints.xs}`]: {
      width: 380,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 420,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 440,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 460,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 480,
    },
  },
});
export default OrderType;
