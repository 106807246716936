import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import lodashSortBy from 'lodash/sortBy';

import modals from '../../Components/Sheets/modals';

import Group from './Group';
import Extras from './Extras';

class ExtraGroup extends React.PureComponent {
  _onExtraSelection = (data) => {
    const { group, onGroupSelection } = this.props;
    if (data) {
      onGroupSelection(group.maximum_number > 1, data);
    } else {
      // if no data pass show slide up error prompt to notify about multiple maximum limit
      const message = `You can only select maximum of ${group.maximum_number}`;
      modals.show(modals.PROMPT, {
        title: 'Error',
        message,
        buttonStatus: 'danger',
        buttonText: 'Close',
      });
    }
  };

  render() {
    const { defaultSelected, group, onLayout, store_theme = {} } = this.props;
    const { minimum_number, maximum_number, data } = group;

    if (data.length === 0) {
      return null;
    }

    return (
      <View testID="extraGroup" onLayout={onLayout}>
        <Group {...group} store_theme={store_theme} />
        <Extras
          defaultValue={defaultSelected}
          data={lodashSortBy(data, ['position'])}
          multiple={maximum_number > 1}
          multipleMin={minimum_number}
          multipleLimit={maximum_number}
          required={group.is_required}
          onSelect={this._onExtraSelection}
        />
      </View>
    );
  }
}

ExtraGroup.propTypes = {
  group: PropTypes.object.isRequired,
  defaultSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onGroupSelection: PropTypes.func.isRequired,
};

export default ExtraGroup;
