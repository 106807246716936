import { Alert } from 'react-native';

import messages from '../Config/messages';
import { getCartTerm } from '../Helper/RemoteConfig';

function useClosedOrClosingPrompt() {
  const _getPrompt = (isAcceptingAdvanceOrder, isClosed) => {
    const prompt = isClosed
      ? messages.STORE_CLOSED_MOBILE
      : messages.STORE_CLOSING;
    const { title, message, messageExtension } = prompt;
    return {
      title,
      message: `${message}${
        isAcceptingAdvanceOrder ? ` ${messageExtension}` : ''
      }`,
    };
  };

  const _showClosedOrClosingPrompt = ({
    backText,
    isAcceptingAdvanceOrder,
    isClosed,
    rescheduleText,
    onBack = () => {},
    onReschedule = () => {},
  }) => {
    const rescheduleButton = {
      text: rescheduleText || 'Reschedule',
      onPress: onReschedule,
    };
    const backToBasketButton = {
      text: backText || `Back to ${getCartTerm()}`,
      onPress: onBack,
    };
    const alertButton = isAcceptingAdvanceOrder
      ? [rescheduleButton, backToBasketButton] // reschedule & back to basket if store is accepting advance order
      : [backToBasketButton]; // back to basket if store is not accepting advance order
    const { title, message } = _getPrompt(isAcceptingAdvanceOrder, isClosed);
    return Alert.alert(title, message, alertButton);
  };

  return {
    getPrompt: _getPrompt,
    showClosedOrClosingPrompt: _showClosedOrClosingPrompt,
  };
}

export default useClosedOrClosingPrompt;
