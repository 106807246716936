import { Analytics, getAnalytics } from 'firebase/analytics';
import { initializeApp, getApps, FirebaseApp } from 'firebase/app';
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
  RemoteConfig,
} from 'firebase/remote-config';
import lodashGet from 'lodash/get';

import firebaseConfigWeb from '../../../firebase-config-web.json';

const firebaseConfig = lodashGet(firebaseConfigWeb, 'result.sdkConfig');
let app: FirebaseApp;
let analytics: Analytics;
let remoteConfig: RemoteConfig;
if (!getApps()?.length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}
if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
  remoteConfig = getRemoteConfig(app);
}

const getRemoteValue = (key: string) => getValue(remoteConfig, key);
const remoteConfigActive = () => fetchAndActivate(remoteConfig);

export { analytics, getRemoteValue, remoteConfig, remoteConfigActive };
