enum RouteList {
  ONBOARDING = 'onboarding',
  AUTH = 'auth',
  OTP = 'otp',
  FORGOT_PASSWORD = 'forgot_password',
  RESET_PASSWORD = 'reset_password',
  SIGNUP_VERIFICATION_SUCCESS = 'signup_verification_success',

  ROOT_TAB = 'root_tab', // for bottom tab navigation (to serve the bottom tab navigation)
  HOME = 'home',
  HOME_TAB = 'home_tab',
  BASKET = 'basket',
  BASKET_TAB = 'basket_tab',
  FEED_TAB = 'feed_tab',
  RESERVATION_TAB = 'reservation_tab',
  ACCOUNT_TAB = 'account_tab',

  ADDRESS_SELECTOR = 'address_selector',
  ADDRESS_ADD_EDIT = 'address_add_edit',
  ADDRESS_MAP = 'address_map',
  SEARCH = 'search',
  STORE = 'store',
  STORE_INFORMATION = 'store_information',
  STORE_REVIEWS = 'store_reviews',
  PRODUCT = 'product', // not using on mobile anymore, product page is now on modal
  PRODUCT_SPECIAL_INSTRUCTION = 'product_special_instruction', // not using on mobile anymore
  SEE_ALL = 'see_all',
  CART = 'cart',
  CHECKOUT_NAVIGATOR = 'checkout_navigator',
  CHECKOUT = 'checkout',
  CHECKOUT_MEAL_PLAN = 'checkout_meal_plan',
  CHECKOUT_USER_DETAILS = 'checkout_guest_user_details',
  CHECKOUT_WEBVIEW = 'checkout_webview',
  ORDER_RATING = 'order_rating',
  ORDER_TRACKING = 'order_tracking',
  ORDER_DETAILS = 'order_details',
  ORDER_HISTORY = 'order_history',
  FEED_DESCRIPTION = 'feed_description',
  RESERVATION_HOME = 'reservation_home',
  RESERVATION_DETAILS = 'reservation_details',
  STORE_PROFILE_RESERVATION = 'store_profile_reservation',
  RESERVATION_PER_STORE = 'reservation_per_store',
  COMPLETE_RESERVATION = 'complete_reservation',
  RESERVATION_ON_HOLD = 'reservation_on_hold',
  RESERVATION_SELECT_PAYMENT_METHOD = 'reservation_select_payment_method',
  RESERVATION_PAYMENT_WEBVIEW = 'reservation_payment_webview',

  ACCOUNT = 'account',
  PROFILE = 'profile',
  CHANGE_PASSWORD = 'change_password',
  PAYMENT_METHOD = 'payment_method',
  ADD_PAYMENT = 'add_payment',
  NOTIFICATION = 'notification',
  GIFT_CARD = 'gift_card',
  REFER_AND_EARN = 'refer_and_earn',
  CHOOSE_PLAN = 'choose_plan',
  GET_HELP = 'get_help',
  PRIVACY = 'privacy',
  LEGAL = 'legal',
  LEGAL_BINDING_CONTRACT = 'legal_binding_contract',
}

export default RouteList;
