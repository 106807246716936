import React from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';
import BreakPoints from '../../../Theme/styles/breakpoints';

import ItemCardLarge from '../../Card/ItemLarge';
import useMealPlan from '../../../Hooks/useMealPlan';

const Section = ({ onError, onItemPress, section, isResult }) => {
  const { isMealPlan } = useMealPlan();

  return (
    <View
      dataSet={{ media: !Boolean(isResult) ? ids.wrapper : ids.resultWrapper }}
      style={!Boolean(isResult) ? styles.wrapper : styles.resultWrapper}
    >
      {section.data.map((item, index) => (
        <View
          key={index}
          dataSet={{ media: ids.itemMainWrapper }}
          style={styles.itemMainWrapper}
        >
          <View
            style={[
              ThemeStyle.flex1,
              ThemeStyle.spacingTop,
              ThemeStyle.spacingRight,
              ThemeStyle.pageBackground,
              { borderRadius: 10 },
            ]}
          >
            <ItemCardLarge
              {...item}
              isMealPlan={isMealPlan}
              onError={onError}
              onPress={() => onItemPress(item)}
            />
          </View>
        </View>
      ))}
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  wrapper: {
    flex: 1,
    marginRight: -20, // to offset the margin right of each item
    [`@media ${BreakPoints.xs}`]: {
      ...ThemeStyle.paddingLeft,
      flexDirection: 'column',
      marginRight: 0,
    },
    [`@media ${BreakPoints.sm}`]: {
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.md}`]: {
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.lg}`]: {
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xl}`]: {
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xxl}`]: {
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
  },
  resultWrapper: {
    flex: 1,
    backgroundColor: ThemeColor.gray300,
    paddingLeft: 20,
    paddingBottom: 20,
    borderRadius: 10,
    [`@media ${BreakPoints.xs}`]: {
      ...ThemeStyle.paddingLeft,
      flexDirection: 'column',
      marginRight: 0,
    },
    [`@media ${BreakPoints.sm}`]: {
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.md}`]: {
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.lg}`]: {
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xl}`]: {
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xxl}`]: {
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
  },
  itemMainWrapper: {
    [`@media ${BreakPoints.xs}`]: {
      width: '100%',
    },
    [`@media ${BreakPoints.sm}`]: {
      width: '100%',
    },
    [`@media ${BreakPoints.md}`]: {
      width: '100%',
    },
    [`@media ${BreakPoints.lg}`]: {
      width: '50%',
    },
    [`@media ${BreakPoints.xl}`]: {
      width: '50%',
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: '50%',
    },
  },
});

export default React.memo(Section);
