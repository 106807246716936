import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, FlatList, StyleSheet } from 'react-native';
import { Divider, Icon, Text } from '@ui-kitten/components';
import lodashGet from 'lodash/get';
import PaymentPaypal from '../../../Assets/paymentPaypal.png';
import PaymentAfterpay from '../../../Assets/paymentAfterpay.png';
import withPaymentMethod from '../../../Hooks/withPaymentMethod';
import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';
import routeList from '../../../Routes/list';
import { updateCheckoutDetails } from '../../../RTK/checkout';
import { checkout } from '../../../RTK/defaultValues';
import { setPaymentMethodAsDefault } from '../../../RTK/utility';
import ScreenWrapper from '../../../Components/ScreenWrapper';
import ListItem from '../../../Components/ListItem';
import Image from '../../../Components/Image';
import styles from '../styles';
import ScreenSize from '../../../Helper/ScreenSize';
import Footer from '../../../Components/Footer';

const PaymentMethods = (props) => {
  const dispatch = useDispatch();
  const { isWeb, isTabletOrMobile, isSmallScreen } = ScreenSize();
  const { navigation, maskCardNumber, getPaymentMethodIcon } = props;
  const { savedPayment } = useSelector((state) => state.utility.settings);
  const _getAddPaymentMethodImage = (src) => {
    const imageWidth = 30;
    const imageHeight = 25;

    if (src === 'credit/debit') {
      return (
        <Icon
          name="credit-card-alt"
          pack="fa"
          fill={ThemeColor.black}
          style={[
            { width: imageWidth - 3, height: imageHeight - 3 },
            ThemeStyle.spacingRightMedium,
          ]}
        />
      );
    }

    return (
      <Image
        source={src}
        style={[
          { width: imageWidth, height: imageHeight },
          ThemeStyle.spacingRightMedium,
        ]}
        resizeMode="contain"
      />
    );
  };

  const _onSavedPaymentMethodPressed = (item) => async () => {
    const { route, navigation } = props;

    //dispatchSetPaymentMethodAsDefault, dispatchUpdateCheckoutDetails } = props
    const { storeId, fromCheckout } = lodashGet(route, 'params');
    await dispatch(setPaymentMethodAsDefault(item));
    //dispatchSetPaymentMethodAsDefault(item)
    if (fromCheckout) {
      // save to the store checkout the payment method
      await dispatch(
        updateCheckoutDetails({
          store_id: storeId,
          keyToUpdate: checkout.keys.PAYMENT_METHOD,
          keyValue: item,
        })
      ),
        navigation.goBack();
    }
  };

  const _renderItem = (payment, index) => (
    <ListItem
      testID={`paymentMethod${index}`}
      accessoryLeft={getPaymentMethodIcon(payment.cardNumber)}
      title={payment.name || maskCardNumber(payment.cardNumber)}
      rightIcon={payment.default ? 'checkmark' : null}
      onPress={() => _onSavedPaymentMethodPressed(payment)}
    />
  );
  return (
    <ScreenWrapper title="Payment" scroll={true} footer={<Footer />}>
      <View style={paymentMethodStyles.main_wrapper}>
        <View style={[styles.wrapper]}>
          <View style={paymentMethodStyles.header_box}>
            <Text category="h5" style={ThemeStyle.spacingBottomSmall}>
              Payment Methods
            </Text>
          </View>
          <View style={paymentMethodStyles.body_box}>
            <View style={{ marginTop: '2%' }}>
              <Text>Saved Payment Method</Text>
            </View>
            <View style={{ paddingLeft: '2%', paddingRight: '2%' }}>
              <FlatList
                data={savedPayment}
                renderItem={_renderItem}
                keyExtractor={(item, index) => index.toString()}
                ItemSeparatorComponent={() => (
                  <Divider style={ThemeStyle.divider} />
                )}
              />
            </View>
          </View>
          <View style={paymentMethodStyles.body_box}>
            <View style={{ marginTop: '2%' }}>
              <Text>Add Payment Method</Text>
            </View>

            <View style={{ paddingLeft: '2%', paddingRight: '2%' }}>
              <ListItem
                testID="paymentDebitOrCreditCardButton"
                accessoryLeft={() => _getAddPaymentMethodImage('credit/debit')}
                title="Credit/Debit Card"
                rightIcon="arrow-ios-forward-outline"
                onPress={() => navigation.navigate(routeList.ADD_PAYMENT)}
              />

              <Divider style={ThemeStyle.divider} />

              <ListItem
                testID="paymentPaypalButton"
                accessoryLeft={() => _getAddPaymentMethodImage(PaymentPaypal)}
                title="Paypal"
                rightIcon="arrow-ios-forward-outline"
                onPress={() => console.log('payment paypal')}
              />

              <Divider style={ThemeStyle.divider} />

              <ListItem
                testID="paymentAfterpayButton"
                accessoryLeft={() => _getAddPaymentMethodImage(PaymentAfterpay)}
                title="Afterpay"
                rightIcon="arrow-ios-forward-outline"
                onPress={() => console.log('payment afterpay')}
              />
            </View>
          </View>

          <View style={[paymentMethodStyles.header_box, { marginTop: '5%' }]}>
            <Text category="h5" style={ThemeStyle.spacingBottomSmall}>
              Pickup Credits
            </Text>
          </View>

          <View style={paymentMethodStyles.body_box}>
            <View
              style={{ marginTop: '2%', paddingLeft: '2%', paddingRight: '2%' }}
            >
              <View
                style={[
                  isWeb
                    ? ThemeStyle.flexDirectionRowSpaceBetween
                    : ThemeStyle.flexDirectionColumnSpaceBetween,
                  { height: 30 },
                ]}
              >
                <Text>0.00</Text>
                <Text>Can be applied only in Philippines</Text>
              </View>
              {isWeb ? (
                <View
                  style={[
                    { paddingLeft: '1%', paddingRight: '1%', height: 10 },
                  ]}
                >
                  <Divider style={ThemeStyle.divider} />
                </View>
              ) : null}

              <View style={[{ height: 50 }]}>
                <Text>
                  Your credits will be automatically applied to your next order
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* <Divider style={ThemeStyle.sectionSeparator} /> */}

        {/* <View style={styles.wrapperBig}>
          <Text category="h6" style={ThemeStyle.spacingBottomSmall}>
            Credits
          </Text>
          <Text category="p2" style={[ThemeStyle.dimColor, ThemeStyle.spacingBottom]}>
            Your PHP credit will automatically be applied to your next order
          </Text>

          <Divider style={ThemeStyle.divider} />

          <ListItem
            testID="giftCardButton"
            title="Redeem a gift card to add credits"
            rightIcon="arrow-ios-forward-outline"
            onPress={() => navigation.navigate(routeList.GIFT_CARD)}
          />

          <Divider style={ThemeStyle.divider} />

          <ListItem
            testID="referButton"
            title="Invite friends to earn credits"
            rightIcon="arrow-ios-forward-outline"
            onPress={() => navigation.navigate(routeList.REFER_AND_EARN)}
          />
        </View> */}
      </View>
    </ScreenWrapper>
  );
};

const paymentMethodStyles = StyleSheet.create({
  main_wrapper: {
    paddingLeft: '20%',
    paddingRight: '20%',
    marginTop: '2%',
  },
  header_box: {
    height: 50, //'10%',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: ThemeColor.gray,
    paddingLeft: '2%',
  },
  body_box: {
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: ThemeColor.gray,
    borderLeftWidth: 1,
    borderLeftColor: ThemeColor.gray,
    borderRightWidth: 1,
    borderRightColor: ThemeColor.gray,
    paddingLeft: '2%',
  },
});
export default withPaymentMethod(PaymentMethods);
