import React, { Fragment } from 'react';
import { View } from 'react-native';
import StarRating, { StarRatingDisplay } from 'react-native-star-rating-widget';

import ThemeStyle from '../../Theme/styles';
import TextInput from '../TextInput';
import useController from './index.controller';

import { RatingStarProps } from './types';

const RatingStar = ({
  view,
  starValue,
  style,
  feedbackPlaceholder,
  disabled,
  feedbacks,
  defaultFeedbacks,
  onStarChange,
  onFeedbackChange,
}: RatingStarProps) => {
  const { renderFeedbacks, state, onCustomFeedbackChanged } = useController({
    disabled,
    feedbacks,
    starValue,
    defaultFeedbacks,
    onFeedbackChange,
  });

  return (
    <Fragment>
      {disabled ? (
        <StarRatingDisplay
          style={[
            ThemeStyle.spacingTop,
            ThemeStyle.justifyContentCenter,
            { alignSelf: 'center' },
            style,
          ]}
          rating={starValue}
        />
      ) : (
        <StarRating
          enableSwiping={false}
          rating={starValue}
          onChange={onStarChange}
          enableHalfStar={false}
          style={[
            ThemeStyle.spacingTop,
            ThemeStyle.justifyContentCenter,
            { alignSelf: 'center' },
            style,
          ]}
        />
      )}
      <View style={[ThemeStyle.flexWrap, ThemeStyle.flexDirectionRow]}>
        {renderFeedbacks()}
      </View>

      {!view && (
        <View style={ThemeStyle.spacingTop}>
          <TextInput
            value={state.customFeedback || ''}
            onChangeText={onCustomFeedbackChanged}
            placeholder={feedbackPlaceholder}
            disabled={disabled}
            direction="column"
            maxLength={500}
            textarea
            showCharacterUsage
          />
        </View>
      )}
    </Fragment>
  );
};

export default RatingStar;
