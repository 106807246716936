import { initializeRemoteConfig } from './Helper/RemoteConfig';
import Routes from './Routes/index.branded';
import App from './main';

initializeRemoteConfig();

export default function () {
  return (
    <App>
      <Routes />
    </App>
  );
}
