import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import constants from '../../Config/constants';
import AnalyticsHelper from '../../Helper/Analytics';
import { Item } from '../../types/item';

import modals from '../Sheets/modals';

import QuickCartIcon from './Icon';
import useModalPrompt from '../Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../Web/Modal/ModalPrompt/config';

const Preview = ({
  item,
  showWebLoginPrompt,
}: {
  item: Item;
  showWebLoginPrompt: boolean;
}) => {
  const navigation = useNavigation();
  const route = useRoute();
  const { showModalPrompt } = useModalPrompt();
  const isAddingOrUpdatingCart = useSelector(
    (state: any) => state.cart.isAddingOrUpdatingCart
  );

  const _onPress = () => {
    if (showWebLoginPrompt) {
      // stop the code from going and show login prompt modal
      showModalPrompt(MODALPROMPT.authentication, {
        isLogin: true,
        navigation,
      });
    } else {
      AnalyticsHelper.itemSelection({
        ...item,
        fromSection: 'From store page menu',
      });
      if (constants.isWeb) {
        showModalPrompt(MODALPROMPT.itemModal, {
          item: {
            item_id: item?.id,
            ...item,
          },
          route: route,
          navigation: navigation,
        });
      } else {
        modals.show(modals.PRODUCT, item);
      }
    }
  };
  return <QuickCartIcon disabled={isAddingOrUpdatingCart} onPress={_onPress} />;
};

export default Preview;
