import React from 'react';
import { Icon, useTheme } from '@ui-kitten/components';

import ThemeColor from '../../Theme/colors';

import Button from '../Button';

import styles from './styles';

type Props = {
  iconName?: string;
  [key: string]: any;
};
function QuickCartIcon({ iconName = 'plus', ...rest }: Props) {
  const theme = useTheme();
  return (
    <Button hitSlop={10} style={styles.button} plain {...rest}>
      <Icon
        name={iconName}
        fill={theme['icon_color'] || ThemeColor.green}
        style={styles.icon}
      />
    </Button>
  );
}

export default QuickCartIcon;
