export default {
  black: '#2B2B2B',

  darkGray: '#808080',
  gray: '#DCDCDC',
  lightGray: '#F0F0F0',
  gray100: '#dcdcdc1a',
  gray200: '#dcdcdc33',
  gray300: '#dcdcdc4d',
  gray400: '#dcdcdc66',
  gray500: '#dcdcdc80',
  gray600: '#dcdcdc99',
  gray700: '#dcdcdcb3',
  gray800: '#dcdcdccc',
  gray900: '#dcdcdce6',

  white: '#fff',
  lightWhite: '#fafafa80',

  darkGreen: '#0f8a6e',
  green: '#14c49b',
  lightGreen: '#e8fdf8',
  green100: '#e8f4ea',

  red100: '#ffe2e6',
  red: '#ff0000',
  lightRed: '#ff0000cc',

  blue: '#007afe',

  violet: '#8950fc',

  yellow: '#ffaa00',
  yellow100: '#fcba03',

  gold: '#FFD700',

  orange: 'lightsalmon',

  google: '#4285f6',
  facebook: '#3c5999',

  shadow: '#00000029',
};
