import React, { useMemo } from 'react';
import { Image as ImageView } from 'react-native';
import ImageHelper from '../Helper/Image';

const Image = ({ resizeMode, size, style, source, ...props }) => {
  return (
    <ImageView
      resizeMode={resizeMode}
      style={style}
      source={{
        uri: useMemo(
          () => ImageHelper.getResizedImage(source?.uri, size || undefined),
          [source?.uri, size]
        ),
      }}
      {...props}
    />
  );
};

export default Image;
