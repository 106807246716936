const TYPES = {
  SOLID: 'solid',
  SEMI_OUTLINE: 'semioutline',
  OUTLINE: 'outline', // default type
};

const CATEGORIES = {
  BASIC: 'basic', // default category
  PRIMARY: 'primary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
};

export { TYPES, CATEGORIES };
