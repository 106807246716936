import { useEffect, useRef } from 'react';

let intervalId = null;
export default function usePolling(callback, delay, shouldRun = false) {
  const savedCallback = useRef();

  // remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // setup the interval
  useEffect(() => {
    const tick = () => savedCallback.current();
    // only run the tick when delay is number and shouldRun flag is true
    if (typeof delay === 'number' && shouldRun) {
      intervalId = setInterval(tick, delay);
      return () => {
        clearInterval(intervalId);
      };
    } else {
      clearInterval(intervalId);
    }
  }, [callback, delay, shouldRun]);
}
