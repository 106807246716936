import { Layout } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import Collapsible from 'react-native-collapsible';
import StyleSheet from 'react-native-media-query';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFindIndex from 'lodash/findIndex';

//THEMES
import ThemeColor from '../../../../Theme/colors';
import ThemeStyle from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import useAuth from '../../../../Hooks/useAuth';

import OtpForm from '../../../../Screens/Auth/Otp';
import SigninForm from '../../../../Screens/Auth/SigninForm';
import SignupForm from '../../../../Screens/Auth/SignupForm';

import ModalPrompt from './ModalPrompt';

import SwitchTab from '../../../SwitchTab';

const switchTabData = [
  { value: 'login', label: 'Log In' },
  { value: 'signup', label: 'Sign Up' },
];

const Authentication = ({ modalId }) => {
  const [data, setData] = useState<{
    isLogin?: boolean;
    navigation: any;
  }>();

  const {
    isOTPResending,
    isOTPSubmitting,
    isSigninForm,
    payload,
    timer,
    onBack,
    onCountdownEnd,
    onSigninSubmit,
    onOTPSubmitSignUp,
    onOTPSubmit,
    onOTPResend,
  } = useAuth();

  const [activeTabindex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    if (!lodashIsEmpty(data)) setActiveTabIndex(data?.isLogin ? 0 : 1);
  }, [data]);

  const toggleOption = (val) => {
    const index = lodashFindIndex(switchTabData, val);
    setActiveTabIndex(index);
  };

  return (
    <ModalPrompt
      id={modalId}
      title={
        isSigninForm
          ? activeTabindex === 0
            ? 'Continue with Email'
            : 'Create your account'
          : 'Enter OTP code'
      }
      dataSet={{ media: ids.mainWrapper }}
      mainContentWrapperStyle={styles.mainWrapper}
      hasHeader
      hasHeaderLine
      closeOnDocumentClick={false}
      onBeforeShow={setData}
    >
      <Layout style={styles.contentContainer}>
        {isSigninForm && (
          <SwitchTab
            data={switchTabData}
            value={switchTabData[activeTabindex]}
            onChange={toggleOption}
          />
        )}

        <Collapsible collapsed={activeTabindex === 1}>
          <Layout>
            {isSigninForm ? (
              <SigninForm
                timer={timer}
                onCountdownEnd={onCountdownEnd}
                onSubmit={onSigninSubmit}
              />
            ) : (
              <OtpForm
                email={payload?.email}
                isResending={isOTPResending}
                isSubmitting={isOTPSubmitting}
                timer={timer}
                onBack={onBack}
                onCountdownEnd={onCountdownEnd}
                onSubmit={onOTPSubmit}
                onResend={onOTPResend}
              />
            )}
          </Layout>
        </Collapsible>

        <Collapsible collapsed={activeTabindex === 0}>
          {isSigninForm ? (
            <SignupForm
              modalId={modalId}
              timer={timer}
              onCountdownEnd={onCountdownEnd}
              onSignupSubmit={onSigninSubmit}
            />
          ) : (
            <OtpForm
              isResending={isOTPResending}
              isSubmitting={isOTPSubmitting}
              email={payload?.email || ''}
              timer={timer}
              onBack={onBack}
              onCountdownEnd={onCountdownEnd}
              onSubmit={onOTPSubmitSignUp}
              onResend={onOTPResend}
            />
          )}
        </Collapsible>
      </Layout>
    </ModalPrompt>
  );
};

const { ids, styles }: { ids: any; styles: any } = StyleSheet.create({
  mainWrapper: {
    borderRadius: 5,
    [`@media ${BreakPoints.xs}`]: {
      width: 370,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 420,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 440,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 460,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 480,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 500,
    },
  },

  contentContainer: {
    //@ts-ignore:row gap definition is not available react-native-media-query
    rowGap: 10,
    justifyContent: 'center',
  },
  containerSelector: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionRowCenterSpaceBetween,
  },
  layoutBtnSelector: {
    width: '50%',
  },

  enableBtn: {
    backgroundColor: ThemeColor.green,
    flex: 1,
    marginTop: 10,
    borderWidth: 1,
    borderColor: ThemeColor.green,
  },
  disableBtn: {
    backgroundColor: ThemeColor.gray,
    flex: 1,
    marginTop: 10,
    opacity: 0.5,
    elevation: 0,
    borderWidth: 1,
    borderColor: ThemeColor.gray,
  },

  innerBtn: {
    height: 50,
    minWidth: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },

  underline: {
    textDecorationLine: 'underline',
  },
  //@ts-ignore:row gap definition is not available react-native-media-query
  rowSignUpContent: { rowGap: 20 },
  //@ts-ignore:row gap definition is not available react-native-media-query
  colSignupContent: { ...ThemeStyle.flexDirectionRow, columnGap: 20 },
  legalBindingRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...ThemeStyle.spacingVerticalMedium,
  },
});

export default Authentication;
