import React from 'react';
import {
  StyleSheet,
  ScrollView,
  View,
  StyleProp,
  ViewStyle,
} from 'react-native';
import Popup from 'reactjs-popup';
import { Layout, Text } from '@ui-kitten/components';
import { UAParser } from 'ua-parser-js';
import lodashIsUndefined from 'lodash/isUndefined';
import lodashIsEmpty from 'lodash/isEmpty';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';

import CloseButton from '../CloseButton';

import './index.css';

type PopupModalProp = {
  isOpen: boolean;
  onClosed: () => void;
  onCloseButtonClick?: () => void;
  title: string;
  closeOnDocumentClick?: boolean;
  dataSet?: { media: StyleProp<ViewStyle> };
  mainContentWrapperStyle?: StyleProp<ViewStyle>;
  childContentContainerStyle?: StyleProp<ViewStyle>;
  hasHeader?: boolean;
  hasHeaderLine?: boolean;
  hasFooterLine?: boolean;
  headerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  scrollEnabled?: boolean;
};
const PopupModal = ({
  isOpen = false,
  onClosed,
  onCloseButtonClick,
  title = '',
  closeOnDocumentClick = false,
  dataSet,
  mainContentWrapperStyle,
  childContentContainerStyle,
  hasHeader = true,
  hasHeaderLine,
  hasFooterLine = false,
  headerStyle,
  children,
  footer,
  scrollEnabled = true,
}: PopupModalProp) => {
  const { device } = UAParser();
  const contentStyle = {
    background: 'transparent',
  };
  const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
  const arrowStyle = { color: '#000' }; // style for an svg element
  const ChildrenWrapper = scrollEnabled ? ScrollView : View;
  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick={closeOnDocumentClick}
      closeOnEscape={false}
      onClose={(e) => {
        e?.preventDefault?.();
        onClosed?.();
      }}
      modal
      nested
      contentStyle={contentStyle}
      overlayStyle={overlayStyle}
      arrowStyle={arrowStyle}
    >
      {(close) => (
        <View
          dataSet={dataSet}
          style={[
            device.type !== 'mobile'
              ? styles.mainContentWrapper
              : styles.wrapperMobile,
            mainContentWrapperStyle,
          ]}
        >
          {hasHeader && (
            <View
              style={[
                hasHeaderLine
                  ? styles.headerContainer2
                  : styles.headerContainer,
                headerStyle,
              ]}
            >
              {!lodashIsEmpty(title) && typeof title === 'string' ? (
                <Layout level="1">
                  <Text category="s2">{title}</Text>
                </Layout>
              ) : (
                title
              )}
              <View
                style={
                  hasHeaderLine
                    ? styles.closeButtonContainer2
                    : styles.closeButtonContainer
                }
              >
                <CloseButton onPress={onCloseButtonClick || close} />
              </View>
            </View>
          )}
          <ChildrenWrapper
            style={[styles.contentContainer, childContentContainerStyle]}
          >
            {children}
          </ChildrenWrapper>

          {!lodashIsUndefined(footer) && (
            <View
              style={[
                styles.contentFooterContainer,
                hasFooterLine && styles.hasFooterLine,
              ]}
            >
              {footer}
            </View>
          )}
        </View>
      )}
    </Popup>
  );
};

const styles = StyleSheet.create({
  wrapperMobile: {
    flex: null,
    width: '90%',
    height: 'auto',
    overflow: 'hidden',
    padding: 5,
    alignSelf: 'center',
    backgroundColor: ThemeColor.white,
    borderRadius: 10,
  },
  contentContainer: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.spacingBottomMedium,
  },
  contentFooterContainer: { ...ThemeStyle.spacingBottomMedium },
  mainContentWrapper: {
    flex: null,
    width: 400,
    height: 'auto',
    alignSelf: 'center',
    backgroundColor: ThemeColor.white,
    borderRadius: 10,
    overflow: 'hidden',
  },
  headerContainer: {
    flex: 1,
    minHeight: 50,
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.spacingBottomSmall,
  },
  headerContainer2: {
    flex: 1,
    minHeight: 50,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: ThemeColor.lightGray,
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.spacingBottomMedium,
  },
  hasFooterLine: {
    borderTopColor: ThemeColor.lightGray,
    borderTopWidth: 1,
  },
  closeButtonContainer: {
    width: '100%',
    position: 'absolute',
    alignItems: 'flex-end',
  },
  closeButtonContainer2: {
    width: '100%',
    position: 'absolute',
    alignItems: 'flex-end',
    paddingRight: 15,
  },
});
export default PopupModal;
