import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CANCEL_ERROR, CLIENT_ERROR } from 'apisauce';

import userApi from '../Service/api/user';

import useCancellableRequest from '../Hooks/useCancellableRequest';

import { setUserAddressAsFirst } from '../RTK/user';
import { isGuestSelector } from '../RTK/user/selectors';
import { updateCheckoutDetails } from '../RTK/checkout';
import { checkout } from '../RTK/defaultValues';
import useMealPlan from './useMealPlan';
import { setCheckoutData } from '../RTK/mealPlan';

const useAddress = () => {
  const dispatch = useDispatch<any>();
  const { createRequest } = useCancellableRequest();
  const isGuest = useSelector(isGuestSelector);
  const { isMealPlan } = useMealPlan();
  const [isLoading, setLoading] = useState(false);

  const _setActiveAddress = async (address) => {
    if (!address?.storeId) {
      setLoading(true);
      const { ok, problem } = await createRequest(
        userApi.setAddressAsActive,
        address?.id
      );
      if (problem === CANCEL_ERROR) {
        setLoading(false);
        return ok;
      }
      if (problem === CLIENT_ERROR && isGuest) {
        setLoading(false);
        dispatch(setUserAddressAsFirst(address?.id));
        return ok;
      }
      if (ok) {
        dispatch(setUserAddressAsFirst(address?.id));
        setLoading(false);
        return ok;
      }

      setLoading(false);
      return ok;
    } else {
      if (isMealPlan) {
        const data = {
          key: checkout.keys.DELIVERY_ADDRESS,
          data: { ...address },
        };
        dispatch(setCheckoutData(data));
        return;
      }

      const data = {
        store_id: address?.storeId,
        keyToUpdate: checkout.keys.DELIVERY_ADDRESS,
        keyValue: { ...address },
      };
      dispatch(updateCheckoutDetails(data));
    }
  };

  return {
    setActiveAddress: _setActiveAddress,
    isLoading,
  };
};

export default useAddress;
