import Service from '../Service';

const promoTypes = {
  FREE_ITEM: 'free_item',
  FREE_DELIVERY: 'free_delivery',
  PERCENT_OFF: 'percentage_off',
  AMOUNT_OFF: 'amount_off',
};

const getPercentOfNumber = (num, percent) => num * (percent / 100);

// this function return object { total, deliveryFee }
// total = deducted promo to subtotal
// deliveryFee = deducted promo to delivery fee
const getDeductedPriceFromPromo = (subtotal, df, promoObject) => {
  const amtOrPctOff = [promoTypes.AMOUNT_OFF, promoTypes.PERCENT_OFF];
  if (promoObject?.type === promoTypes.FREE_DELIVERY) {
    const delPromoOff = df - promoObject.delivery_discount;
    const delFee = delPromoOff < 0 ? 0 : delPromoOff; // delFree should not become negative, if so set it as 0
    return { total: subtotal, deliveryFee: delFee };
  } else if (amtOrPctOff.includes(promoObject?.type)) {
    const promoOff = Math.round(subtotal - promoObject.promo.applied);
    return { total: promoOff, deliveryFee: df };
  }
  return { total: subtotal, deliveryFee: df };
};

const getFormatedPromoValue = (
  { delivery_discount = 0, description, promo, type = '' },
  delFee
) => {
  switch (type) {
    case 'percentage_off':
      return `${promo?.value || 0} OFF`;
    case 'amount_off':
      return `Amount Off ${Service.commafyNumber(
        Number(promo?.applied) || 0,
        true
      )}`;
    case 'free_delivery':
      const promoDelFee = Number(delivery_discount) || 0;
      const hasDelFee = Boolean(delFee);
      return `Delivery Off ${Service.commafyNumber(
        hasDelFee && promoDelFee > delFee ? delFee : promoDelFee,
        true
      )}`;
    default:
      // free item
      return description;
  }
};

const getDiscount = (promo) => {
  const { type, free_item_description, subsidized_delivery_amount } = promo;
  const promotion = {
    label: '',
    discount: `${Service.commafyNumber(0, true)} off`,
  };
  if (type === promoTypes.FREE_ITEM) {
    promotion.label = 'Free Item';
    promotion.discount = free_item_description;
  } else if (type === promoTypes.PERCENT_OFF) {
    promotion.label = `${promo[type]}% off`;
    promotion.discount = `${promo[type]}% off`;
  } else if (type === promoTypes.FREE_DELIVERY) {
    promotion.label = 'Delivery fee';
    promotion.discount = `${Service.commafyNumber(
      subsidized_delivery_amount,
      true
    )} off`;
  } else if (type === promoTypes.AMOUNT_OFF) {
    promotion.label = `${Service.commafyNumber(promo[type], true)} off`;
    promotion.discount = `${Service.commafyNumber(promo[type], true)} off`;
  }
  return promotion;
};

export { promoTypes };

export default {
  getPercentOfNumber,
  getDeductedPriceFromPromo,
  getFormatedPromoValue,
  getDiscount,
};
