import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import constants from '../../Config/constants';
import { setParentCategory } from '../../RTK/filter';
import { globalSpacing } from '../../Theme/styles';

import Button from '../Button';
import List from '../List';
import Image from '../Image';

import styles from './styles';

function MainCategory() {
  const dispatch = useDispatch();
  const parentCategory = useSelector((state) => state.filter.parentCategory);

  const _onSelect = (filter) => dispatch(setParentCategory(filter));

  const _renderItem = ({ isFirstOfList, isLastOfList, item, index }) => {
    const firstAndLastMargin = constants.isWeb ? 0 : globalSpacing;
    const marginStyle = {
      marginLeft: isFirstOfList ? firstAndLastMargin : globalSpacing / 4,
      marginRight: isLastOfList ? firstAndLastMargin : globalSpacing / 4,
    };
    const isActive = parentCategory?.value === item.value;
    const Container = isActive ? View : Button;
    const imageSrc = isActive ? item.active : item.inactive;
    return (
      <Container onPress={() => _onSelect(item)} style={marginStyle} plain>
        <View style={styles.listItemContainer}>
          <Image
            source={{ uri: imageSrc }}
            style={styles.image}
            resizeMode="contain"
          />
          <Text status={isActive ? 'success' : 'basic'} category="label">
            {item.label}
          </Text>
        </View>
      </Container>
    );
  };

  return (
    <List
      data={constants.PRODUCT_TYPES}
      renderItem={_renderItem}
      horizontal
      plain
    />
  );
}

export default MainCategory;
