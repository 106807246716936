import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import lodashIsEqual from 'lodash/isEqual';
import lodashOmit from 'lodash/omit';
import { CANCEL_ERROR } from 'apisauce';

import constants from '../../Config/constants';
import messages from '../../Config/messages';
import Service from '../../Service';

import List from '../List';

import styles from './styles';
import Footer from './Footer';

// this component handle the required props for List component to make the List behave as infinite scroll
// this also handle what type of infinite the list behave such as manual={false} or manual={true}
// this component also called 'onRequest' on mount
// 'manual' default to false means when user scroll to the bottom screen it will call the 'onRequest' automatically
// 'manual=true' means at the end of the list there will be button to call 'onRequest'
// this also the one handle the data of List
// 'onRequest' must be instance of App/Service/api/client.js example:
// function onRequest() {
//    return userApi.getOrders()
// }
// which is userApi is imported via (import userApi from '../../Service/api/user')
class ListInfiniteScroll extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onEndReachedCalledDuringMomentum = true;
    this.shouldSetState = null;
    this.state = {
      data: props.initialData,
      error: '',
      loadingMore: false,
      noMore: false,
      page: 1,
      refreshing: false,
    };
  }

  componentDidMount() {
    this.shouldSetState = true;
    this._request();
  }

  componentWillUnmount() {
    this.shouldSetState = false;
  }

  _getState() {
    return this.state;
  }

  _request() {
    this.setState({ loadingMore: true }, async () => {
      const { onFilter, onRequest, onRequestEnd, reduxDispatch } = this.props;
      const { page, data: stateData } = this.state;
      const { ok, data: apiData, problem } = await onRequest(page);

      // stop the code from going if request is cancelled
      if (problem === CANCEL_ERROR) {
        return;
      }

      if (this.shouldSetState) {
        if (ok) {
          const { has_next_page, result } = apiData;
          const data = onFilter(result); // has default function of return the result if no props is pass
          const listData = page === 1 ? data : [...stateData, ...data];
          reduxDispatch?.({ ...apiData, result: listData });
          this.setState(
            {
              error: '',
              page: page + 1,
              noMore: !has_next_page,
              loadingMore: false,
              data: listData,
              refreshing: false, // for _onRefresh method (no effect if not pull to refresh)
            },
            () => onRequestEnd?.()
          );
        } else {
          const listData = this.state.data.filter((e) => !e.loading);
          reduxDispatch?.({ has_next_page: false, page, result: listData });
          this.setState(
            {
              noMore: true,
              loadingMore: false,
              error:
                Service.handleErrorMessage(apiData?.message) ||
                messages.COMMON_ERROR_MESSAGE,
              data: listData,
              refreshing: false, // for _onRefresh method (no effect if not pull to refresh)
            },
            () => onRequestEnd?.()
          );
        }
      }
    });
  }

  _onReachedEnd = () => {
    if (!this.props.manual && !this.state.noMore) {
      if (!this.onEndReachedCalledDuringMomentum || constants.isWeb) {
        this._request();
        this.onEndReachedCalledDuringMomentum = true;
      }
    }
  };

  _onMomentumScrollBegin = () => {
    this.onEndReachedCalledDuringMomentum = false;
  };

  _onRefresh = () => {
    const { initialData, reduxDispatch } = this.props;
    // reset all state to initial and set refreshing as true and call _request method
    // below variable is just for readability and easy to understand
    const isAlreadyRefreshing = this.state.refreshing;
    const isListInitiallyLoaded = lodashIsEqual(this.state.data, initialData);
    if (!isAlreadyRefreshing && !isListInitiallyLoaded) {
      // only if not yet refreshing and state data is not equal to props initialData
      // if state data and props initialData is equal it just mean the page is not yet loaded for the first time
      // so we don't need to refresh it
      reduxDispatch?.();
      this.setState(
        {
          data: initialData,
          error: '',
          loadingMore: false,
          noMore: false,
          page: 1,
          refreshing: true,
        },
        this._request
      );
    }
  };

  _onLoadMorePressed = () => {
    this._request();
    this.onEndReachedCalledDuringMomentum = false;
  };

  _renderFooter = () => {
    const { manual, footer } = this.props;
    const { error, loadingMore, noMore, page } = this.state;
    return (
      <Fragment>
        <Footer
          error={error}
          hide={page === 1 && loadingMore}
          done={noMore}
          loading={manual ? loadingMore : !noMore}
          onLoadMore={manual ? this._onLoadMorePressed : null}
        />
        {footer}
      </Fragment>
    );
  };

  render() {
    const { addStatusbarOffset, pullToRefresh, ...rest } = this.props;
    const listProps = lodashOmit(rest, [
      'initialData',
      'manual',
      'onRequest',
      'reduxDispatch',
    ]);
    const { loadingMore, data, refreshing } = this.state;

    const conditionalProps = {};

    if (pullToRefresh) {
      // for pull to refresh
      conditionalProps.pullToRefreshProps = {
        refreshing,
        onRefresh: this._onRefresh,
        // prettier-ignore
        progressViewOffset: addStatusbarOffset ? constants.statusBarHeight + (constants.isAndroid ? 50 : 80) : 0,
      };
    }

    return (
      <List
        data={data}
        extraData={loadingMore}
        ListFooterComponent={this._renderFooter}
        contentContainerStyle={styles.list}
        // for infinite scroll
        onEndReachedTreshold={0.1}
        onEndReached={this._onReachedEnd}
        onMomentumScrollBegin={this._onMomentumScrollBegin}
        {...listProps}
        {...conditionalProps}
      />
    );
  }
}

ListInfiniteScroll.defaultProps = {
  initialData: [],
  onFilter: (data) => data,
};

ListInfiniteScroll.propTypes = {
  addStatusbarOffset: PropTypes.bool,
  initialData: PropTypes.array,
  manual: PropTypes.bool,
  pullToRefresh: PropTypes.bool,
  onFilter: PropTypes.func, // to filter the data got from onRequest
  onRequest: PropTypes.func.isRequired,
  onRequestEnd: PropTypes.func, // gets called after calling onRequest and successfully updating the states
};

export default ListInfiniteScroll;
