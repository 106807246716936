export default {
  FAQ: 'https://dev.pickup.ph/faqs',
  PARTNER_RESTAURANT: 'https://dev.pickup.ph/signup',
  JOIN_BETA: 'https://app.pickup.ph/auth/login',
  PRIVACY_POLICY: 'https://dev.pickup.ph/privacy-policy',
  BLOG: 'https://dev.pickup.ph/blog',
  ABOUT: 'https://dev.pickup.ph/about',
  CONTACT: 'https://dev.pickup.ph/contact',
  TERMS_OF_SERVICES: 'https://dev.pickup.ph/terms-of-service',
  TRUST_CENTRE: 'https://dev.pickup.ph/trust-centre',
  ENTERPRISE: 'https://dev.pickup.ph/enterprise',
  CHARITIES: 'https://dev.pickup.ph/charities',
  AMBASSADOR: 'https://dev.pickup.ph/ambassador',
  APP_STORE: 'https://apps.apple.com/app/pickupph-app/id1635944209',
  PLAY_STORE:
    'https://play.google.com/store/apps/details?id=com.prosource.pickup&hl=en&gl=US',
  FACEBOOK: 'https://www.facebook.com/pickup.ph',
  INSTRAGRAM: 'https://www.instagram.com/pickup.ph/',
  YOUTUBE: 'https://www.youtube.com/channel/UCLvVKfRo-NRfQRaQSqlaAFw',
};
