import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import TextInput from './TextInput';

const TextInputDebounce = ({ value = '', onChange, ...rest }) => {
  const timeout = useRef();
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  const _clearTimeout = () => {
    clearTimeout(timeout.current); // clear the timeout
    timeout.current = null; // remove value
  };

  const _onInputBlur = () => {
    // if still has timeout
    if (timeout.current) {
      _clearTimeout();
      onChange(inputValue); // call the onChange
    }
  };

  const _onInputChange = (value) => {
    setInputValue(value); // update local state
    _clearTimeout();
    // add timeout
    timeout.current = setTimeout(() => {
      // wait for 1s before clearing timeout and
      _clearTimeout();
      onChange(value); // call the onChange
    }, 1000);
  };

  return (
    <TextInput
      {...rest}
      value={inputValue}
      onChangeText={_onInputChange}
      onBlur={_onInputBlur}
    />
  );
};

TextInputDebounce.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TextInputDebounce;
