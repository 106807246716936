import React from 'react';
import { useSelector } from 'react-redux';
import StyleSheet from 'react-native-media-query';

import BreakPoints from '../../../../Theme/styles/breakpoints';

import ModalPrompt from './ModalPrompt';
import { FullReviewList } from '../../../../Screens/StoreReviews';
import { shopSelector } from '../../../../RTK/shop/selectors';

const Reviews = ({ modalId }) => {
  const shopData = useSelector(shopSelector);

  return (
    <ModalPrompt
      id={modalId}
      title={'Ratings & Reviews'}
      dataSet={{ media: ids.reviewPopupModal }}
      mainContentWrapperStyle={styles.reviewPopupModal}
      childContentContainerStyle={{
        paddingHorizontal: 0,
      }}
      headerStyle={{ marginBottom: 0 }}
      hasHeader
      hasHeaderLine
      closeOnDocumentClick={false}
      scrollEnabled={false}
    >
      <FullReviewList
        route={{ params: { id: shopData?.id, name: shopData?.name } }}
      />
    </ModalPrompt>
  );
};

const { ids, styles } = StyleSheet.create({
  reviewPopupModal: {
    [`@media ${BreakPoints.xs}`]: {
      width: 400,
      height: 600,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 450,
      height: 600,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 500,
      height: 600,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 550,
      height: 600,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 600,
      height: 600,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 600,
      height: 600,
    },
  },
});

export default Reviews;
