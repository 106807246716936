import { StyleSheet } from 'react-native';

import ThemeStyle from '../../Theme/styles';

export default StyleSheet.create({
  contentContainer: [
    ThemeStyle.spacingTopMedium,
    ThemeStyle.pageHorizontalSpacing,
  ],
  contentContainerNoHPadding: ThemeStyle.spacingTopMedium,
});
