import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';
import { ScaleButtonAnimation } from '../Web/Intereaction';

import Promo, { promoTypes } from '../../Helper/Promo';

import constants from '../../Config/constants';
import Service from '../../Service';

import Button from '../Button';
import PromoAmountOff from '../SVG/PromoAmountOff';
import PromoFreeDelivery from '../SVG/PromoFreeDelivery';
import PromoFreeGift from '../SVG/PromoFreeGift';
import PromoPercentOff from '../SVG/PromoPercentOff';
import { isEmpty } from 'lodash';

const SVGWdth = 300;
const SVGHeight = 120;

const Promotion = ({
  children,
  item,
  width = SVGWdth,
  height = SVGHeight,
  onPress,
  ...rest
}) => {
  const { minimum_purchase, type } = item;
  const { label, discount } = Promo.getDiscount(item);
  const store_theme = useTheme();

  const CouponType = (promoType) => {
    const rest_coupon_style = {
      ...rest,
      ...(!isEmpty(store_theme['icon_color']) && {
        fill1: store_theme['icon_color'],
        fill2: store_theme['icon_color'],
        fill1_opacity: '20%',
        fill2_opacity: '30%',
      }),
    };
    if (promoType === promoTypes.AMOUNT_OFF) {
      return (
        <PromoAmountOff width={width} height={height} {...rest_coupon_style} />
      );
    } else if (promoType === promoTypes.FREE_DELIVERY) {
      return (
        <PromoFreeDelivery
          width={width}
          height={height}
          {...rest_coupon_style}
        />
      );
    } else if (promoType === promoTypes.FREE_ITEM) {
      return (
        <PromoFreeGift width={width} height={height} {...rest_coupon_style} />
      );
    } else if (promoType === promoTypes.PERCENT_OFF) {
      return (
        <PromoPercentOff width={width} height={height} {...rest_coupon_style} />
      );
    } else {
      return null;
    }
  };

  const ContainerButton = constants.isWeb ? ScaleButtonAnimation : Button;
  const Container = onPress ? ContainerButton : View;
  return (
    <Container onPress={() => onPress?.(discount)} plain>
      {!!children ? (
        children
      ) : (
        <View style={styles.promotionDescriptionContainer}>
          <Text
            style={{ ...styles.promoTitle, color: store_theme['icon_color'] }}
          >
            {label}
          </Text>
          <Text category="c2">
            {`Min. order ${Service.commafyNumber(
              minimum_purchase,
              true
            )}. Valid for selected items. Auto applied.`}
          </Text>
        </View>
      )}

      {CouponType(type)}
    </Container>
  );
};

const styles = StyleSheet.create({
  promotionDescriptionContainer: {
    width: 150,
    height: constants.isWeb ? 120 : 110,
    left: 40,
    position: 'absolute',
    justifyContent: 'center',
    zIndex: 1,
  },
  promoTitle: {
    fontSize: 20,
    fontFamily: 'Poppins-SemiBold',
    fontWeight: '500',
    lineHeight: 27,
  },
});
export default Promotion;
