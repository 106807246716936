import React from 'react';

// this is for the page that is class base component so it can use the page controller
export default function (Component, controller, keyName = 'controller') {
  return (props) => {
    const ctrler = controller(props);
    const otherProps = { [keyName]: ctrler };
    return <Component {...props} {...otherProps} />;
  };
}
