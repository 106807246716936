import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { Layout, Select, SelectItem, Text } from '@ui-kitten/components';
import dayjs from 'dayjs';
import Collapsible from 'react-native-collapsible';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFilter from 'lodash/filter';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import Button from '../Button';
import PopupModal from '../Web/PopupModal';

const { DATE_ONLY_DISPLAY_FORMAT, TIME_ONLY_DISPLAY_CAPITAL_FORMAT } =
  constants;

// This component is same on index.web.js BUT it's not generating the store hours
function SchedulePickerWeb({
  data,
  isOpen,
  showAsap = true,
  onClosed,
  onProceed,
}) {
  const [isAsap, setIsAsap] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedHours, setSelectedHours] = useState();

  const dates = data;
  const hours = lodashFilter(
    selectedDate?.time_options || [],
    (e) => e?.value !== 'asap_order_time'
  );
  const canAsap =
    showAsap &&
    (dates?.[0]?.time_options?.[0].value === 'asap_order_time' || false);

  useEffect(() => {
    setIsAsap(canAsap);
  }, [canAsap]);

  const _changeSchedule = (isAsap = false) => {
    setIsAsap(isAsap);
    setSelectedDate();
    setSelectedHours();
  };

  const _formatDate = (data) => {
    if (lodashIsEmpty(data)) return undefined;
    const { iso } = data;
    return dayjs(iso).format(DATE_ONLY_DISPLAY_FORMAT);
  };

  const _formatTime = (data) => {
    if (lodashIsEmpty(data)) return undefined;
    const { start, end } = data;
    return `${dayjs(start).format(TIME_ONLY_DISPLAY_CAPITAL_FORMAT)} - ${dayjs(
      end
    ).format(TIME_ONLY_DISPLAY_CAPITAL_FORMAT)}`;
  };

  const _onClose = () => {
    setSelectedDate();
    setSelectedHours();
    onClosed();
  };

  const _onProceed = () => onProceed(selectedHours.start);

  return (
    <PopupModal
      isOpen={isOpen}
      onCloseButtonClick={_onClose}
      title="Select Schedule"
      hasHeaderLine
      mainContentWrapperStyle={{ width: 550 }}
    >
      <Layout
        style={[ThemeStyle.spacingTopSmall, ThemeStyle.spacingBottomSmall]}
      >
        {canAsap && (
          <Layout style={ThemeStyle.spacingBottomMedium}>
            <Layout style={styles.container}>
              <Layout style={styles.layout} level="1">
                <Button
                  style={[styles.button, isAsap && styles.buttonActive]}
                  onPress={isAsap ? undefined : () => _changeSchedule(true)}
                  plain
                >
                  <Layout style={styles.innerBtn}>
                    <Text category="p2" status={isAsap && 'control'}>
                      ASAP
                    </Text>
                  </Layout>
                </Button>
              </Layout>

              <Layout style={styles.layout} level="1">
                <Button
                  disabled={!isAsap}
                  style={[styles.button, !isAsap && styles.buttonActive]}
                  onPress={!isAsap ? undefined : () => _changeSchedule()}
                  plain
                >
                  <Layout style={styles.innerBtn}>
                    <Text category="p2" status={!isAsap && 'control'}>
                      Pre-Order
                    </Text>
                  </Layout>
                </Button>
              </Layout>
            </Layout>
          </Layout>
        )}

        <Collapsible collapsed={isAsap}>
          <Layout
            style={[ThemeStyle.spacingTopSmall, ThemeStyle.spacingBottomMedium]}
          >
            <Layout style={styles.container}>
              <Layout style={styles.layout} level="1">
                <Layout style={ThemeStyle.spacingBottomSmall} level="1">
                  <Text category="label">Date</Text>
                </Layout>
                <Select
                  onSelect={({ row }) => setSelectedDate(dates[row])}
                  value={_formatDate(selectedDate)}
                  size="large"
                >
                  {dates.map((data, i) => (
                    <SelectItem
                      key={i}
                      title={_formatDate(data)}
                      style={{ fontSize: 14 }}
                    />
                  ))}
                </Select>
              </Layout>

              <Layout style={styles.layout} level="1">
                <Layout style={ThemeStyle.spacingBottomSmall} level="1">
                  <Text category="label">Time</Text>
                </Layout>
                <Select
                  disabled={lodashIsEmpty(selectedDate)}
                  onSelect={({ row }) => setSelectedHours(hours[row])}
                  value={_formatTime(selectedHours)}
                  size="large"
                >
                  {hours.map((data, i) => (
                    <SelectItem key={i} title={_formatTime(data)} />
                  ))}
                </Select>
              </Layout>
            </Layout>
          </Layout>
        </Collapsible>

        <Button
          disabled={isAsap ? false : lodashIsEmpty(selectedHours)}
          plain
          style={styles.proceedBtn}
          onPress={_onProceed}
        >
          <Text category="p2" status="basic">
            Proceed
          </Text>
        </Button>
      </Layout>
    </PopupModal>
  );
}

const styles = StyleSheet.create({
  container: [ThemeStyle.flex1, ThemeStyle.flexDirectionRowCenterSpaceBetween],
  layout: {
    width: '48%',
  },
  button: {
    ...ThemeStyle.flex1,
    backgroundColor: ThemeColor.gray,
    marginTop: 10,
  },
  buttonActive: {
    backgroundColor: ThemeColor.green,
  },
  innerBtn: {
    height: 50,
    minWidth: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  proceedBtn: {
    ...ThemeStyle.spacingTopMedium,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: ThemeColor.green,
  },
});

SchedulePickerWeb.propTypes = {
  // date is store hours got from https://marketplace-api.stg.pickup.ph/api#/Stores/StoresController_getDates
  data: PropTypes.arrayOf(
    PropTypes.shape({
      iso: PropTypes.string.isRequired,
      time_options: PropTypes.arrayOf(
        PropTypes.shape({
          end: PropTypes.string,
          start: PropTypes.string,
          value: PropTypes.string.isRequired,
        })
      ).isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  showAsap: PropTypes.bool, // if possible to asap but showAsap is false, Hide asap
  onClosed: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired, // must pass Date ISO format when calling
};
export default SchedulePickerWeb;
