import client from './client';
import api from './endpoints';

const endpoints = api();

function placeOrder(payload, cancelToken) {
  const path = endpoints.CHECKOUT;
  return client.post(path, payload, { cancelToken });
}

function placeOrderMealPlan(payload, cancelToken) {
  const path = endpoints.CHECKOUT_MEALPLAN;
  return client.post(path, payload, { cancelToken });
}

function getDeliveryFeeQuotation(payload, cancelToken) {
  const path = endpoints.DELIVERY_QUOTATION;
  return client.post(path, payload, { cancelToken });
}

function verifyPromoCode(payload, cancelToken) {
  const path = endpoints.PROMO;
  return client.post(path, payload, { cancelToken });
}

export default {
  placeOrder,
  placeOrderMealPlan,
  getDeliveryFeeQuotation,
  verifyPromoCode,
};
