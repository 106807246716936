import { initializeApp, getApps } from 'firebase/app';
import lodashGet from 'lodash/get';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';

import firebaseConfigWeb from '../../../firebase-config-web.json';
import commonAnalytics from './common';

const firebaseConfig = lodashGet(firebaseConfigWeb, 'result.sdkConfig');
let app;
let analytics;

if (!getApps()?.length) {
  app = initializeApp(firebaseConfig);
}

if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
}

commonAnalytics.setLibrary({
  analytics,
  logEvent,
  setUserId,
});

export * from './common';

export default commonAnalytics;
