import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Divider, Icon, Text } from '@ui-kitten/components';
import { useRoute } from '@react-navigation/native';
import Tooltip from 'rn-tooltip';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';

import Button from '../../Components/Button';
import Header from '../../Components/Header';
import Image from '../../Components/Image';
import ListItem from '../../Components/ListItem';
import ScreenWrapper from '../../Components/ScreenWrapper';

import ImageHelper from '../../Helper/Image';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

import PageLoader from './PageLoader';
import RateDelivery from './RateDelivery';
import RateStore from './RateStore';
import RatingItem from './RatingItem';
import ErrorHint from '../../Components/ErrorHint';
import TextInput from '../../Components/TextInput';
import { SECTION_TITLE_LIGHT } from '../../Theme/typographyProps';
import useOrderRating from '../../Hooks/useOrderRating';

import EmptyData from '../../Components/EmptyData';

import { userSelector } from '../../RTK/user/selectors';

const { ORDER_TYPES } = constants;

const OrderRating = () => {
  const route = useRoute<any>();
  const user = useSelector(userSelector);
  const ratingParam = lodashGet(route, 'params.rating');
  const transactionNumberParam = lodashGet(route, 'params.id');
  const {
    refs: { scrollList, publicReviewRef },
    orderDateAndTime,
    state,
    lowestStarRatings,
    onGoBack,
    onSubmitPressed,
    onDeliveryFeedbackChange,
    onItemReaction,
    onRatingChange,
    onHeaderActionRightPressed,
    onStoreFeedbackChange,
    setCustomFeedbackEnchancement,
    setErrorRateDelivery,
    setErrorRateStore,
    setErrorEnchanceExperience,
  } = useOrderRating({
    defaultDeliveryStar: ratingParam,
    defaultStoreStar: ratingParam,
    transaction_number: transactionNumberParam,
  });
  const image = ImageHelper.getOrderImage(state.details?.store_details);
  const _renderHeader = () => {
    return (
      <Header
        backType="close"
        title={
          lodashIsEmpty(state.details)
            ? ''
            : state.isUpdate
            ? 'Update your order rating'
            : 'Rate your order'
        }
        actionLeftPress={() => onGoBack(Boolean(ratingParam))}
        actionRightPress={onHeaderActionRightPressed}
      />
    );
  };

  const _renderFooter = () => {
    return (
      <View
        style={[
          ThemeStyle.pageVerticalSpacingMedium,
          ThemeStyle.pageHorizontalSpacing,
        ]}
      >
        <Button
          status={state.isFailedToRetrieveReview ? 'danger' : 'success'}
          onPress={state.isFailedToRetrieveReview ? onGoBack : onSubmitPressed}
          loading={state.submitting}
        >
          {state.isFailedToRetrieveReview
            ? 'Back'
            : state.isUpdate
            ? 'Update Review'
            : 'Submit Review'}
        </Button>
      </View>
    );
  };

  if (state.isLoading) {
    return <PageLoader header={_renderHeader()} />;
  }

  if (!lodashIsEmpty(state.errorFetchingOrderDetails.title)) {
    return (
      <ScreenWrapper
        onRef={(r) => (scrollList.current = r)}
        header={_renderHeader()}
        scroll
      >
        <EmptyData
          type="filter"
          title={state.errorFetchingOrderDetails.title}
          subtitle={state.errorFetchingOrderDetails.message}
        />
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper
      onRef={(r) => (scrollList.current = r)}
      header={_renderHeader()}
      footer={_renderFooter()}
      scroll
    >
      <View style={styles.wrapper}>
        <View
          style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.spacingBottom]}
        >
          <ListItem
            title={state.details.store_details.name}
            subTitle={orderDateAndTime}
            accessoryRight={
              <View
                style={[
                  ThemeStyle.alignItemsCenter,
                  { backgroundColor: ThemeColor.green },
                ]}
              >
                <Image
                  source={{ uri: image }}
                  style={styles.storeImage}
                  size={50}
                  resizeMode="contain"
                />
              </View>
            }
          />
        </View>

        <Divider style={ThemeStyle.divider} />

        {state.details?.order_type === ORDER_TYPES.DELIVERY && (
          <ErrorHint
            name="rateDelivery"
            errorText="You need to rate your delivery experience"
            show={state.errorRateDelivery}
            scrollReference={scrollList}
            onClose={setErrorRateDelivery}
            height={60}
          >
            <View
              style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.spacingTop]}
            >
              <RateDelivery
                starValue={state.deliveryRating}
                defaultFeedbacks={state.deliveryFeedback}
                onStarChange={onRatingChange(true)}
                onFeedbackChange={onDeliveryFeedbackChange}
              />
            </View>
          </ErrorHint>
        )}

        <ErrorHint
          name="rateStore"
          errorText="You need to rate the store services"
          show={state.errorRateStore}
          scrollReference={scrollList}
          onClose={setErrorRateStore}
          height={60}
        >
          <View
            style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.spacingTop]}
          >
            <RateStore
              storeName={state.details.store_details.name}
              starValue={state.storeRating}
              defaultFeedbacks={state.storeFeedback}
              onStarChange={onRatingChange(false)}
              onFeedbackChange={onStoreFeedbackChange}
            />
          </View>
        </ErrorHint>

        {lowestStarRatings && (
          <ErrorHint
            name="enhanceExperience"
            errorText="Kindly review your highlights for a better customer experience"
            show={state.errorEnchanceExperience}
            scrollReference={scrollList}
            onClose={setErrorEnchanceExperience}
            height={80}
          >
            <View
              style={[
                ThemeStyle.pageHorizontalSpacing,
                ThemeStyle.pageVerticalSpacing,
              ]}
            >
              <Text>What areas can be improved?</Text>
              <View style={ThemeStyle.spacingTop}>
                <TextInput
                  value={state.customFeedbackEnchancement}
                  onChangeText={setCustomFeedbackEnchancement}
                  placeholder={
                    'Could you share more insights so we can enhance your experience?'
                  }
                  disabled={false}
                  direction="column"
                  maxLength={500}
                  textarea
                  showCharacterUsage
                />
              </View>
            </View>
          </ErrorHint>
        )}

        <View style={ThemeStyle.spacingBottom}>
          <Divider style={ThemeStyle.divider} />
        </View>

        <View
          style={[
            ThemeStyle.pageHorizontalSpacing,
            ThemeStyle.spacingBottom,
            ThemeStyle.flexDirectionRowCenterSpaceBetween,
          ]}
        >
          <Text
            {...SECTION_TITLE_LIGHT}
          >{`${user?.first_name} ${user?.last_name?.[0]}`}</Text>

          <Button onPress={() => publicReviewRef.current.toggleTooltip()} plain>
            <View
              style={[
                ThemeStyle.flexDirectionRowCenter,
                ThemeStyle.buttonContentGraySmall,
              ]}
            >
              <Text {...SECTION_TITLE_LIGHT}>Review</Text>
              <Tooltip
                ref={(r) => (publicReviewRef.current = r)}
                actionType="press"
                backgroundColor={ThemeColor.green}
                width={225}
                height={60}
                withOverlay={false}
                containerStyle={styles.tooltipContainer}
                popover={
                  <Text category="p2" appearance="alternative">
                    Customer and merchants will be able to see your review.
                  </Text>
                }
              >
                <Icon
                  name="info-outline"
                  fill={ThemeColor.green}
                  style={[styles.iconStyle, ThemeStyle.spacingLeftSmall]}
                />
              </Tooltip>
            </View>
          </Button>
        </View>

        <View style={ThemeStyle.pageHorizontalSpacing}>
          <RatingItem
            items={state.details.items}
            reactions={state.itemsReaction}
            onItemReaction={onItemReaction}
          />
        </View>
      </View>
    </ScreenWrapper>
  );
};

export const styles = StyleSheet.create({
  wrapper: ThemeStyle.flex1,
  storeImage: {
    width: 50,
    height: 50,
  },
  iconStyle: {
    width: 15,
    height: 15,
  },
  tooltipContainer: {
    ...ThemeStyle.alignItemsStart,
    borderRadius: 5,
    padding: globalSpacing / 4,
  },
});

export default OrderRating;
