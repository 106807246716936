import PropTypes from 'prop-types';
import lodashPick from 'lodash/pick';

import constants from '../../Config/constants';

// NOTE - number string is because it's from api response

const itemLimitPerTimeSlotProps = PropTypes.arrayOf(
  PropTypes.shape({
    time_slot: PropTypes.string,
    limit: PropTypes.number,
  })
).isRequired;

const extraGroupProps = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    is_required: PropTypes.bool.isRequired,
    minimum_number: PropTypes.number.isRequired,
    maximum_number: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        position: PropTypes.number.isRequired,
        price: PropTypes.string.isRequired, // number string
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
  })
).isRequired;

const itemProps = {
  id: PropTypes.string.isRequired,
  active_discount: PropTypes.string.isRequired, // number string
  available: PropTypes.bool.isRequired,
  description: PropTypes.string,
  discount_type: PropTypes.string.isRequired, // number string
  discount_value: PropTypes.string.isRequired,
  discounted_price: PropTypes.string.isRequired, // number string
  extra_group: extraGroupProps,
  image: PropTypes.string.isRequired,
  is_bulk: PropTypes.bool.isRequired,
  isMealPlan: PropTypes.bool.isRequired, // to hide/show the item price
  item_limit_per_day: PropTypes.number.isRequired,
  item_limit_per_time_slot: itemLimitPerTimeSlotProps,
  name: PropTypes.string.isRequired,
  off_dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  price: PropTypes.string.isRequired, // number string
  regular_price: PropTypes.string.isRequired, // number string
  stock: PropTypes.number.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  onPress: PropTypes.func.isRequired,
};

export const itemSmallProps = lodashPick(itemProps, [
  'id',
  'active_discount',
  'description',
  'discount_type',
  'discount_value',
  'discounted_price',
  'image',
  'name',
  'regular_price',
  'onPress',
]);

export const itemMediumProps = {
  ...itemSmallProps,
  ...lodashPick(itemProps, [
    'available',
    'extra_group',
    'isMealPlan',
    'item_limit_per_day',
    'item_limit_per_time_slot',
    'off_dates',
    'price',
    'stock',
    'tags',
  ]),
  ...(constants.isWeb ? { onError: PropTypes.func.isRequired } : {}),
};

export const itemLargeProps = {
  ...itemMediumProps,
  ...lodashPick(itemProps, ['is_bulk']),
};

export default {};
