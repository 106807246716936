import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import constants from '../Config/constants';
import ReservationHelper from '../Helper/Reservation';
import { reservationTypeData } from '../Screens/Reservation/constant/SelectOptions';

const initialState = {
  searchFilter: {
    selectedAddress: {}, // use for reservation guest
    reservationType: reservationTypeData[0],
    selectedTimeSlot: ReservationHelper.getNowTimeslot(),
    selectedDate: dayjs().format(constants.DBDATE_FORMAT),
    guestNumber: 2,
    distance: constants.DEFAULT_DISTANCE,
  },
  selectedStore: null,
  selectedReservation: undefined,
  reservationHistory: [],
};

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    updateSearchFilterDistance: (state, action) => {
      state.searchFilter.distance = action.payload;
    },
    updateSearchFilterReservationType: (state, action) => {
      state.searchFilter.reservationType = action.payload;
    },
    updateSearchFilterSelectedTime: (state, action) => {
      state.searchFilter.selectedTimeSlot = action.payload;
    },
    updateGuestNumber: (state, action) => {
      state.searchFilter.guestNumber = action.payload;
    },
    updateDate: (state, action) => {
      state.searchFilter.selectedDate = action.payload;
    },
    updateSelectedAddress: (state, action) => {
      state.searchFilter.selectedAddress = action.payload;
    },
    setSelectedStore: (state, action) => {
      state.selectedStore = action.payload;
    },
    setSelectedReservation: (state, action) => {
      state.selectedReservation = action.payload;
    },
    setReservationHistory: (state, action) => {
      state.reservationHistory = [action.payload, ...state.reservationHistory];
    },
  },
});

export const {
  updateSearchFilterDistance,
  updateSearchFilterReservationType,
  updateSearchFilterSelectedTime,
  updateGuestNumber,
  updateDate,
  updateSelectedAddress,
  setSelectedStore,
  setSelectedReservation,
  setReservationHistory,
} = reservationSlice.actions;
export default reservationSlice.reducer;
