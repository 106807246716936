import { geocodeByPlaceId } from 'react-places-autocomplete';
import { GOOGLE_MAPS_API_KEY } from '@env';

import constants from '../../Config/constants';

import client from './client';

function getAddressDetails(placeId, cancelToken) {
  // temporary fix for cors issue on web
  if (constants.isWeb) {
    return new Promise((resolve) => {
      geocodeByPlaceId(placeId)
        .then((res) => res)
        .then((res) => resolve({ ok: true, data: res[0] }))
        .catch((err) => resolve({ ok: false, data: { message: err } }));
    });
  } else {
    const baseURL = 'https://maps.googleapis.com';
    const path = '/maps/api/place/details/json';
    return client.get(
      path,
      { key: GOOGLE_MAPS_API_KEY, place_id: placeId },
      { baseURL, cancelToken }
    );
  }
}

function googleMapReverseGeocode({ latitude, longitude }, cancelToken) {
  // temporary fix for cors issue on web
  if (constants.isWeb) {
    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve) => {
      geocoder
        .geocode({ location: { lat: latitude, lng: longitude } })
        .then((res) => resolve({ ok: true, data: res }))
        .catch((err) => resolve({ ok: false, data: { message: err } }));
    });
  } else {
    const baseURL = 'https://maps.googleapis.com';
    const path = '/maps/api/geocode/json';
    return client.get(
      path,
      {
        key: GOOGLE_MAPS_API_KEY,
        latlng: `${latitude},${longitude}`,
      },
      { baseURL, cancelToken }
    );
  }
}

export default {
  getAddressDetails,
  googleMapReverseGeocode,
};
