import { REMOTE_CONFIG_FETCH_INTERVAL } from '@env';
import constants from '../../Config/constants';
import { getRemoteValue, remoteConfig, remoteConfigActive } from '../Firebase';

import firebaseRemoteConfigDefaults from '../RemoteConfig/defaults.json';

const getCartLimit = () => getRemoteValue('CART_LIMIT').asString();
const getCartTerm = () => getRemoteValue('CART_TERM').asString();
const getCategories = () => JSON.parse(getRemoteValue('CATEGORIES').asString());
const getCheckoutContinueDelay = () =>
  getRemoteValue('CHECKOUT_CONTINUE_DELAY').asNumber();
const getCheckoutRedirectionDelay = () =>
  getRemoteValue('CHECKOUT_REDIRECTION_DELAY').asNumber();
const getIsEnabledFeed = () => getRemoteValue('IS_ENABLED_FEED').asBoolean();
const getIsEnabledReservation = () =>
  getRemoteValue('IS_ENABLED_RESERVATION').asBoolean();
const getLastUsedAddressCount = () =>
  getRemoteValue('LAST_USED_ADDRESS_COUNT').asNumber();
const getPromoDisplayLimit = () =>
  getRemoteValue('PROMO_DISPLAY_LIMIT').asNumber();
const getStoreLongDistanceIndicator = () =>
  getRemoteValue('STORE_LONG_DISTANCE_INDICATOR').asNumber();
const getWebActiveOrdersLimit = () =>
  getRemoteValue('WEB_ACTIVE_ORDERS_LIMIT').asNumber();

const initializeRemoteConfig = async () => {
  const defaultValues = {
    ...firebaseRemoteConfigDefaults,
    CATEGORIES: JSON.stringify(firebaseRemoteConfigDefaults.CATEGORIES),
  };
  if (constants.isWeb) {
    if (typeof window !== 'undefined') {
      remoteConfig['defaultConfig'] = defaultValues;
      if (REMOTE_CONFIG_FETCH_INTERVAL) {
        remoteConfig['settings']['minimumFetchIntervalMillis'] = Number(
          REMOTE_CONFIG_FETCH_INTERVAL
        );
      }
      remoteConfigActive();
    }
  } else {
    remoteConfig().setDefaults(defaultValues);
    if (REMOTE_CONFIG_FETCH_INTERVAL) {
      await remoteConfig().setConfigSettings({
        minimumFetchIntervalMillis: Number(REMOTE_CONFIG_FETCH_INTERVAL),
      });
    }
    remoteConfigActive();
  }
};

export {
  getCartLimit,
  getCartTerm,
  getCategories,
  getCheckoutContinueDelay, // for continue to checkout delay before actual checkout for user to be able to cancel the checkout
  getCheckoutRedirectionDelay, // for redirection on checkout payment if the payment is taking too long
  getIsEnabledFeed,
  getIsEnabledReservation,
  getLastUsedAddressCount,
  getPromoDisplayLimit,
  getStoreLongDistanceIndicator,
  getWebActiveOrdersLimit,
  initializeRemoteConfig,
};
