import { StyleSheet } from 'react-native';
import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';

export default StyleSheet.create({
  wrapper: [
    ThemeStyle.flex1,
    ThemeStyle.pageVerticalSpacingMedium,
    ThemeStyle.pageHorizontalSpacing,
    constants.isWeb && ThemeStyle.spacingTop,
  ],
  wrapperBig: [
    ThemeStyle.flex1,
    ThemeStyle.pageVerticalSpacing,
    ThemeStyle.pageHorizontalSpacing,
  ],
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  loaderWrapper: {
    ...ThemeStyle.alignItemsCenter,
    ...ThemeStyle.justifyContentCenter,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
});
