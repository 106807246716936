import React, { Fragment } from 'react';
import { View } from 'react-native';

import { RectangleLoader } from '../../../Components/Loader';
import constants from '../../../Config/constants';
import { numberToArray } from '../../../Helper';
import ThemeStyle from '../../../Theme/styles';

const titleHeight = constants.isWeb ? 26 : constants.isIOS ? 26 : 25;
const subTitleHeight = constants.isWeb ? 18 : constants.isIOS ? 19 : 21;
const loaderCount = constants.isWeb ? 4 : 2;

const PromotionLoader = () => {
  return (
    <Fragment>
      <View
        style={[
          ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.spacingBottomSmall,
        ]}
      >
        <View style={ThemeStyle.spacingBottomSmall}>
          <RectangleLoader width={120} height={titleHeight} />
        </View>
        <RectangleLoader width={180} height={subTitleHeight} />
      </View>
      <View style={[ThemeStyle.flexDirectionRowCenter, ThemeStyle.spacingLeft]}>
        {numberToArray(loaderCount).map((_, i) => (
          <View style={ThemeStyle.spacingRightMedium} key={i}>
            <RectangleLoader width={300} height={120} />
          </View>
        ))}
      </View>
    </Fragment>
  );
};

export default PromotionLoader;
