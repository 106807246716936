import lodashIsUndefined from 'lodash/isUndefined';
import { ModalManager } from '../../hooks/useModalManager';

const useModalPrompt = () => {
  const hideModalPrompt = (name: string, data?: any) => {
    return ModalManager.hide(
      name,
      !lodashIsUndefined(data) ? { payload: data } : { payload: null }
    );
  };

  function isOpenModalPrompt(name: string) {
    return ModalManager.get(name)?.current?.isOpen() || false;
  }

  async function showModalPrompt(name: string, data: any) {
    return ModalManager.show(
      name,
      !lodashIsUndefined(data) ? { payload: data } : { payload: null }
    );
  }

  return {
    hideModalPrompt,
    isOpenModalPrompt,
    showModalPrompt,
  };
};

export default useModalPrompt;
