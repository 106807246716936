import lodashPick from 'lodash/pick';

import config from '../../Config';
import messages from '../../Config/messages';
import UrlParameter from '../../Helper/UrlParameter';

import client from './client';
import api from './endpoints';

const endpoints = api();

function signIn(payload, cancelToken) {
  const path = endpoints.USER_SIGNIN;
  return client.post(path, payload, { cancelToken });
}

function signInAsGuest(payload, cancelToken) {
  const path = endpoints.USER_AS_GUEST_LOGIN;
  return client.post(path, payload, { cancelToken });
}
function verifyOtp(payload, cancelToken) {
  const path = endpoints.USER_SIGNIN_VERIFY;
  return client.post(path, payload, { cancelToken });
}

function signUp(payload, cancelToken) {
  const path = endpoints.USER;
  return client.post(path, payload, { cancelToken });
}

function getUser(cancelToken) {
  console.log('getting user details...');
  const path = endpoints.USER_DETAILS;
  return client.get(path, {}, { cancelToken });
}

function update(payload, cancelToken) {
  const path = endpoints.USER;
  return client.patch(path, payload, { cancelToken });
}

function getAddress(cancelToken) {
  console.log('getting user address...');
  const path = endpoints.USER_ADDRESS;
  return client.get(path, {}, { cancelToken });
}

function saveAddress(payload, cancelToken) {
  const path = endpoints.USER_ADDRESS;
  return client.post(path, payload, { cancelToken });
}

async function updateAddress(id, payload) {
  // this function is not put method
  // it will remove the address first add it again
  const deleteResult = await removeAddress([id]);
  if (deleteResult.ok) {
    return await saveAddress(payload);
  } else {
    return {
      ok: false,
      data: {
        message: deleteResult.data.message || messages.COMMON_ERROR_MESSAGE,
      },
    };
  }
}

function removeAddress(addressIds, cancelToken) {
  const path = endpoints.USER_ADDRESS;
  return client.delete(
    path,
    {},
    { data: { addresses: addressIds }, cancelToken }
  );
}

function setAddressAsActive(addressId, cancelToken) {
  const path = endpoints.USER_ADDRESS;
  return client.patch(path, { id: addressId }, { cancelToken });
}

function resetPassword(payload, token, cancelToken) {
  let path = endpoints.USER_RESET_PASSWORD;
  if (token) {
    return client.patch(`${path}/${token}`, payload, { cancelToken });
  } else {
    return client.post(path, payload, { cancelToken });
  }
}

function addToFavorite(storeId, cancelToken) {
  const path = endpoints.USER_FAVORITES;
  return client.post(path, { id: storeId }, { cancelToken });
}

function deleteUser(cancelToken) {
  const path = endpoints.USER;
  return client.delete(path, {}, { cancelToken });
}

function removeFromFavorite(storeId, cancelToken) {
  const path = endpoints.USER_FAVORITES;
  return client.put(path, { id: storeId }, { cancelToken });
}

function getCart(cancelToken) {
  const path = endpoints.USER_CART;
  return client.get(path, {}, { cancelToken });
}

function validateCart(payload, cancelToken) {
  const path = endpoints.CART_VALIDATE;
  return client.post(path, payload, { cancelToken });
}

function addToCart(payload, cancelToken) {
  const path = endpoints.CART;
  const serializePayload = lodashPick(payload, [
    'store_id',
    'item_id',
    'quantity',
    'instructions',
    'extras',
    'order_type',
    'order_date',
    'order_time',
  ]);
  return client.post(path, serializePayload, { cancelToken });
}

async function updateCart(payload, cancelToken) {
  const { is_extras_changed, ...rest } = payload;
  // if extras is changed
  if (is_extras_changed) {
    // remove cart item
    const { ok, data } = await removeCart(rest);
    if (ok) {
      // when successful removing, add it
      return addToCart({ ...data, ...payload });
    } else {
      return {
        ok: false,
        data: { message: data.message || messages.COMMON_ERROR_MESSAGE },
      };
    }
  } else {
    // if updating aside from extras, just do normal update api
    const path = endpoints.USER_CART;
    const serializePayload = lodashPick(rest, [
      'cart_details_id',
      'quantity',
      'instructions',
      'extras',
      'order_type',
      'order_date',
      'order_time',
    ]);
    return client.patch(path, serializePayload, { cancelToken });
  }
}

function removeCart(payload) {
  // remove cart is updating the cart item with 0 quantity
  const path = endpoints.USER_CART;
  const serializePayload = lodashPick(payload, ['cart_details_id']);
  return client.patch(path, { ...serializePayload, quantity: 0 });
}

function removeStoreCart(storeIds, cancelToken) {
  const path = endpoints.USER_CART;
  return client.delete(path, {}, { data: { stores: storeIds }, cancelToken });
}

function getOrder(orderId, cancelToken) {
  const path = endpoints.USER_ORDER(orderId);
  return client.get(path, {}, { cancelToken });
}

function getOrders(page, status, cancelToken) {
  const limitOffset = UrlParameter.getLimitAndOffset(page);
  const path = `${endpoints.USER_ORDERS}?${limitOffset}&status=${status}`;
  return client.get(path, {}, { cancelToken });
}

// IMPORTANT NOTE - apisauce it not good to use combined with useQuery, it messed up the default behavior of useQuery due to the modified response of apisauce
// e.g: when using apisauce + useQuery when network error encounter, the previous response is not properly cache therefore the data dissapear
// unlike using fetch same scenario, but the data from previous success response before encountering the error did not dissapear
async function getNewOrders() {
  const apiAndRoute = `${config.ROOT_API}/user/orders`;
  const parameters = UrlParameter.objectToString({
    offset: 0,
    limit: 50,
    status: 'new',
  });
  const res = await fetch(`${apiAndRoute}${parameters}`, {
    credentials: 'include',
    headers: { 'x-api-version': 1 },
  });
  return res.json();
}

function reorder(orderId, cancelToken) {
  const path = endpoints.REORDER(orderId);
  return client.post(path, {}, { cancelToken });
}

function requestEmailVerification(cancelToken) {
  const path = endpoints.REQUEST_EMAIL_VERIFICATION;
  return client.post(path, {}, { cancelToken });
}

function verifyEmail(token, cancelToken) {
  const path = endpoints.VERIFY_EMAIL(token);
  return client.post(path, {}, { cancelToken });
}

export default {
  addToCart,
  addToFavorite,
  deleteUser,
  getAddress,
  getCart,
  getNewOrders,
  getOrder,
  getOrders,
  getUser,
  removeAddress,
  removeCart,
  removeFromFavorite,
  removeStoreCart,
  reorder,
  requestEmailVerification,
  resetPassword,
  saveAddress,
  setAddressAsActive,
  signIn,
  signInAsGuest,
  signUp,
  update,
  updateAddress,
  updateCart,
  validateCart,
  verifyEmail,
  verifyOtp,
};
