import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import Badge from '../../Components/Badge';
import Button from '../../Components/Button';
import ScreenBanner from '../../Components/ScreenBanner';
import modals from '../../Components/Sheets/modals';
import AnalyticsHelper from '../../Helper/Analytics';
import MenuItemHelper from '../../Helper/MenuItem';
import { getCartTerm } from '../../Helper/RemoteConfig';
import TagIconList from '../../Service/TagIconList';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import { SECTION_SUB_TITLE, SECTION_TITLE } from '../../Theme/typographyProps';

const cartIconSize = 25;

class ProductHeading extends React.PureComponent {
  _onStoreNamePressed = () => {
    const { productData, setParams } = this.props;
    setParams({ id: productData.store.id });
  };

  _onEditPressed = async () => {
    const { setParams, thisProductOnCart } = this.props;
    const shouldOpenSlideUpModal = thisProductOnCart.length > 1;

    if (shouldOpenSlideUpModal) {
      await modals.hide(modals.PRODUCT); // hide product first
      await modals.show(modals.MULTIPLE_VARIANT, { items: thisProductOnCart }); // then show the multivariant modal
    } else {
      const cartItem = thisProductOnCart[0];
      AnalyticsHelper.itemSelection({ ...cartItem, fromSection: 'Edit Item' });
      setParams({ isUpdate: true, id: cartItem.item_id, ...cartItem });
    }
  };

  render() {
    const { productData, thisProductOnCart } = this.props;
    const isOnCart = !lodashIsEmpty(thisProductOnCart);
    const hasDiscount = MenuItemHelper.hasDiscount(productData);
    let totalCart = 0;

    if (isOnCart) {
      totalCart = thisProductOnCart.reduce((acc, a) => acc + a.quantity, 0);
    }

    return (
      <Fragment>
        <ScreenBanner banner={productData.image} isProductPage />

        <View
          style={[
            ThemeStyle.pageVerticalSpacingMedium,
            ThemeStyle.pageHorizontalSpacing,
          ]}
        >
          <Text style={ThemeStyle.spacingBottomSmall} {...SECTION_TITLE}>
            {productData.name}
          </Text>
          {!!productData.description && (
            <Text style={ThemeStyle.dimColor} {...SECTION_SUB_TITLE}>
              {productData.description}
            </Text>
          )}
          {MenuItemHelper.showDiscountDescription(productData) && (
            <Text
              style={[ThemeStyle.noticeText, ThemeStyle.spacingTopSmall]}
              {...SECTION_SUB_TITLE}
            >
              {productData.discount_description}
            </Text>
          )}
          {!!productData.tags.length && (
            <View style={[ThemeStyle.flexWrap, ThemeStyle.flexDirectionRow]}>
              {productData?.tags?.map((tag, index) => {
                const result = TagIconList.getTagIcon(tag);
                return (
                  <View
                    style={[
                      ThemeStyle.spacingTopSmall,
                      ThemeStyle.spacingRightSmall,
                    ]}
                    key={index}
                  >
                    <Badge type={result.type} category={result.category}>
                      {`${result.icon} ${result.name}`}
                    </Badge>
                  </View>
                );
              })}
            </View>
          )}
        </View>

        {isOnCart && (
          <Fragment>
            <View
              style={[
                ThemeStyle.flexDirectionRowCenterSpaceBetween,
                ThemeStyle.pageHorizontalSpacing,
                ThemeStyle.spacingBottomMedium,
              ]}
            >
              <View style={ThemeStyle.flexDirectionRowCenter}>
                <Icon
                  name="basket-outline"
                  fill={ThemeColor.green}
                  style={[
                    styles.productOnCartIcon,
                    ThemeStyle.spacingRightMedium,
                  ]}
                  pack="ion"
                />
                <Text {...SECTION_SUB_TITLE}>
                  {totalCart}&times; in your {getCartTerm()}
                </Text>
              </View>

              <Button
                testID="editButton"
                onPress={this._onEditPressed}
                style={styles.productOnCartEditButton}
                plain
              >
                <Text {...SECTION_SUB_TITLE}>Edit</Text>
              </Button>
            </View>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const styles = StyleSheet.create({
  productOnCartIcon: {
    width: cartIconSize,
    height: cartIconSize,
  },
  productOnCartEditButton: {
    ...ThemeStyle.pageVerticalSpacingSmall,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    backgroundColor: ThemeColor.gray,
    borderRadius: 50,
  },
});

ProductHeading.propTypes = {
  productData: PropTypes.object.isRequired,
  thisProductOnCart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ProductHeading;
