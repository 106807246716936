import * as React from 'react';
import Svg, { Defs, G, Circle, Path } from 'react-native-svg';

import ThemeColor from '../../Theme/colors';

const TabBarProfile = (props) => (
  <Svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 1080"
    stroke={props?.color || ThemeColor.darkGray}
    strokeWidth={props?.strokeWidth || 20}
    {...props}
  >
    <Defs></Defs>
    <G id="Group_1057" data-name="Group 1057">
      <G id="Ellipse_162" data-name="Ellipse 162">
        <Circle cx={540} cy={540} r={297.28} fill="none" />
        <Circle cx={540} cy={540} r={281.63} fill="none" />
      </G>
      <G id="Ellipse_163" data-name="Ellipse 163">
        <Circle cx={540} cy={477.41} r={109.52} fill={'none'} />
        <Circle cx={540} cy={477.41} r={93.88} fill={'none'} />
      </G>
      <Path
        id="Path_703"
        data-name="Path 703"
        d="m362.35,746c47.64-52,114.15-82.71,184.62-85.24,68.12,1.22,132.23,32.41,175.24,85.24"
        fill={'none'}
        strokeWidth={30}
      />
    </G>
  </Svg>
);
export default TabBarProfile;
