import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@ui-kitten/components';
import lodashFilter from 'lodash/filter';
import { useToast } from 'react-native-toast-notifications';
import { RAZZLE_BUILD_MODE } from '@env';

import Toast from '../Components/Toast';
import constants from '../Config/constants';
import { numberToWord } from '../Helper';
import AnalyticsHelper from '../Helper/Analytics';
import StoreHelper from '../Helper/Store';
import { checkout, mealPlan } from '../RTK/defaultValues';
import { addItem } from '../RTK/mealPlan';

const { ORDER_TYPES } = constants;

export const mealPlanInitialState = {
  isShow: false,
  testID: '',
  value: '',
  label: '',
  apiDataKey: '',
};

function useMealPlan() {
  const dispatch = useDispatch();
  const toast = useToast();
  const prevOrderType = useRef();
  const store_theme = useTheme();

  const checkoutData = useSelector((state) => state.checkout?.checkoutData);
  const shopData = useSelector((state) => state.shop?.data);
  const mealPlanData = useSelector((state) => state.mealPlan?.data);

  let paramMealPlanType = '';

  // Check if running in web
  if (constants.isWeb) {
    if (RAZZLE_BUILD_MODE === 'branded') {
      const params = new URLSearchParams(document?.location?.search); // check url params for order_type
      paramMealPlanType = params.get('meal_plan_type') || paramMealPlanType; // default order type as string
    }
  }

  const showMealPlanHint = useSelector(
    (state) => state.utility.showMealPlanHint
  );
  const [showModalMealPlanType, setShowModalMealPlanType] =
    useState(mealPlanInitialState);

  const isThreeDay =
    mealPlanData.type === mealPlan.type.threeDay ||
    paramMealPlanType === mealPlan.type.threeDay;
  const isFiveDay =
    mealPlanData.type === mealPlan.type.fiveDay ||
    paramMealPlanType === mealPlan.type.fiveDay;

  const storeCheckoutData = StoreHelper.getStoreCheckoutData(
    shopData?.id,
    checkoutData
  );
  const isMealPlan =
    storeCheckoutData[checkout.keys.ORDER_TYPE]?.value ===
    ORDER_TYPES.MEAL_PLAN;
  const itemRequired = Number(
    (mealPlanData?.type || paramMealPlanType)?.substring?.(0, 1)
  );
  const nonExclusiveItems = lodashFilter(mealPlanData?.items, {
    is_exclusive: false,
  });
  const exclusiveItems = lodashFilter(mealPlanData?.items, {
    is_exclusive: true,
  });
  const hasExclusive = exclusiveItems.length !== 0;
  // hint should only show if, showMealPlanHint and isMealPlan is true and user selected the meal plan type
  const showHint =
    showMealPlanHint &&
    isMealPlan &&
    Boolean(mealPlanData?.type || paramMealPlanType);
  const isNonExclusiveStep = showHint && mealPlanData?.items?.length === 0;
  const isExclusiveStep =
    showHint && !hasExclusive && nonExclusiveItems.length === itemRequired - 1;
  const isCompleteStep =
    showHint && mealPlanData?.items?.length === itemRequired;

  useEffect(() => {
    if (!isMealPlan) {
      // save order type if not meal plan
      prevOrderType.current = storeCheckoutData[checkout.keys.ORDER_TYPE];
    }
  }, [storeCheckoutData[checkout.keys.ORDER_TYPE]]);

  const _addMealPlan = async (data) => {
    const itemCount = mealPlanData?.items.length;
    const nextItemCount = (data?.quantity || 1) + itemCount;
    const isLast = nextItemCount == itemRequired;
    if (constants.isWeb) {
      toast.hideAll(); // move the hide to above so we don't repeat calling it each toast on web
    }
    if (isLast && !hasExclusive && !data.is_exclusive) {
      // Show error toast if adding last item and doesn't have exclusive to it
      // and the adding last item is not exclusive
      const msg = `You have to add ${numberToWord(
        1
      )} exclusive item to your meal plan.`;
      if (constants.isWeb) {
        toast.show(msg, { type: 'danger', placement: 'top', duration: 2000 });
      } else {
        Toast.show(msg, Toast.TYPE_ERROR);
      }
    } else if (hasExclusive && data.is_exclusive) {
      // show error if adding exclusive item but has already exclusive item in their meal plan
      const msg = `You can only add ${numberToWord(
        1
      )} exclusive item to your meal plan.`;
      if (constants.isWeb) {
        toast.show(msg, { type: 'danger', placement: 'top', duration: 2000 });
      } else {
        Toast.show(msg, Toast.TYPE_ERROR);
      }
    } else if (nextItemCount > itemRequired) {
      // Show error toast if item is exceeding the meal plan type
      const msg = `You cannot add more than ${numberToWord(
        itemRequired
      )} items to the current meal plan.`;
      if (constants.isWeb) {
        toast.show(msg, { type: 'danger', placement: 'top', duration: 2000 });
      } else {
        Toast.show(msg, Toast.TYPE_ERROR);
      }
    } else {
      const msg = `Item has been added to meal plan (${nextItemCount}/${itemRequired})`;
      // validation is success
      AnalyticsHelper.addToCart(
        `Meal Plan ${nextItemCount}/${itemRequired}`,
        data
      );
      // finally, add the item to meal plan order
      await dispatch(addItem(data));
      // show toast message
      if (constants.isWeb) {
        toast.show(msg, {
          type: 'success',
          placement: 'top',
          duration: 2000,
          style: {
            backgroundColor: store_theme['icon_color'],
          },
        });
      } else {
        Toast.show(msg);
      }
    }
  };

  return {
    hasExclusive,
    isMealPlan,
    itemRequired,
    items: mealPlanData?.items || [],
    item: {
      nonExclusives: nonExclusiveItems,
      exclusives: exclusiveItems,
      list: mealPlanData?.items,
    },
    progress: {
      isNonExclusiveStep,
      isExclusiveStep,
      isCompleteStep,
    },
    isThreeDay,
    isFiveDay,
    showModalMealPlanType,
    setShowModalMealPlanType,
    addMealPlan: _addMealPlan,
  };
}

export default useMealPlan;
