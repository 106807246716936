import React from 'react';
import { StyleSheet, View } from 'react-native';

import useCancellableRequest from '../Hooks/useCancellableRequest';
import withController from '../Hooks/withController';
import withNavigation from '../Hooks/withNavigation';
import routeList from '../Routes/list';
import storeApi from '../Service/api/store';
import ThemeColor from '../Theme/colors';
import ThemeStyle, { globalSpacing } from '../Theme/styles';

import Button from './Button';
import Image from './Image';
import List from './List';

class ShopAd extends React.Component {
  constructor(props) {
    super(props);
    this.shouldSetState = null;
    this.state = {
      ads: [],
    };
  }

  componentDidMount() {
    this.shouldSetState = true;
    this._getStoreAds();
  }

  componentWillUnmount() {
    this.shouldSetState = false;
  }

  _getStoreAds = async () => {
    const { api } = this.props;
    const { ok, data } = await api.createRequest(storeApi.getStoreAds);
    if (ok && this.shouldSetState) {
      this.setState({ ads: data });
    }
  };

  _onItemPressed = (data) => () => {
    this.props.navigation.navigate(routeList.STORE, {
      id: data.store_id,
      ad: true,
    });
  };

  _renderItem = ({ isFirstOfList, isLastOfList, item }) => {
    const marginStyle = {
      marginLeft: isFirstOfList ? globalSpacing : globalSpacing / 4,
      marginRight: isLastOfList ? globalSpacing : globalSpacing / 4,
    };

    return (
      <Button style={marginStyle} onPress={this._onItemPressed(item)} plain>
        <View style={ThemeStyle.pageVerticalSpacingMedium}>
          <Image source={{ uri: item.ad_image }} style={styles.image} />
        </View>
      </Button>
    );
  };

  render() {
    return (
      <List
        data={this.state.ads}
        renderItem={this._renderItem}
        style={styles.listItem}
        horizontal
        plain
      />
    );
  }
}

ShopAd = withNavigation(ShopAd);
ShopAd = withController(ShopAd, useCancellableRequest, 'api');

const styles = StyleSheet.create({
  listItem: {
    backgroundColor: ThemeColor.white,
  },
  image: {
    width: 350,
    height: 135,
    borderRadius: 10,
  },
});

export default ShopAd;
