import React, { useState, useRef } from 'react';
import {
  View,
  SafeAreaView,
  useWindowDimensions,
  StyleSheet,
} from 'react-native';
import { Text, Input } from '@ui-kitten/components';
import Image from '../../../Components/Image';
import ScreenWrapper from '../../../Components/ScreenWrapper';
import ThemeStyles from '../../../Theme/styles';
import ThemeColor from '../../../Theme/colors';
import CarouselSlide from '../../../Components/Carousel';
import Button from '../../../Components/Button';
import ScreenSize from '../../../Helper/ScreenSize';
import Footer from '../../../Components/Footer';
const data = [
  {
    id: '1',
    name: 'Congratulations',
    banner: require('../../../Assets/gift_cards/card-design-congratulations.png'),
  },
  {
    id: '2',
    name: 'Dinner',
    banner: require('../../../Assets/gift_cards/card-design-dinner-onme.png'),
  },
  {
    id: '3',
    name: 'FromMeToYou',
    banner: require('../../../Assets/gift_cards/card-design-from-metoyou.png'),
  },
  {
    id: '4',
    name: 'Graduation',
    banner: require('../../../Assets/gift_cards/card-design-graduation.png'),
  },
  {
    id: '5',
    name: 'Happy Anniversary',
    banner: require('../../../Assets/gift_cards/card-design-happy-anniversary.png'),
  },
  {
    id: '6',
    name: 'HappyBirthday',
    banner: require('../../../Assets/gift_cards/card-design-happy-birthday.png'),
  },
  {
    id: '7',
    name: 'HappyHoliday',
    banner: require('../../../Assets/gift_cards/card-design-happy-birthday.png'),
  },
  {
    id: '8',
    name: 'SpecialDelivery',
    banner: require('../../../Assets/gift_cards/card-design-special-delivery.png'),
  },
  {
    id: '9',
    name: 'ThankYou',
    banner: require('../../../Assets/gift_cards/card-design-thank-you.png'),
  },
  {
    id: '10',
    name: 'ThinkingOfYou',
    banner: require('../../../Assets/gift_cards/card-design-thinkingof-you.png'),
  },
];
const GiftCard = () => {
  const { isWeb } = ScreenSize();
  const carousel = useRef(null);
  const size = useWindowDimensions();
  const [activeSlide, setActiveSlide] = useState(0);
  const [giftCardPin, setGiftCardPin] = useState('');
  const PAGE_WIDTH =
    size.width > 768 ? (57 / 100) * size.width : (80 / 100) * size.width;
  const PAGE_HEIGHT =
    size.width > 768 ? (8 / 100) * size.height : (15 / 100) * size.width;

  const scrollTo = (index) => {
    setActiveSlide(index);
    carousel.current.scrollTo({ index: index, animated: true });
  };

  const paginationIndicator = (item) => (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }}
    >
      {item.map((item, index) => (
        <Button
          key={index}
          testID="paging_indicator"
          onPress={() => scrollTo(index)}
          style={[
            giftCardStyles.paging_indicator,
            {
              backgroundColor:
                activeSlide == index ? ThemeColor.green : ThemeColor.white,
            },
          ]}
          plain
        />
      ))}
    </View>
  );

  const renderItem = ({ item }) => {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
        }}
      >
        <Image
          source={{ uri: item.banner }}
          style={{ width: '100%', height: '100%', borderRadius: 10 }}
          resizeMode="contain"
        />
      </View>
    );
  };
  return (
    <ScreenWrapper title="Gift Cards" footer={<Footer />}>
      <View style={giftCardStyles.main_wrapper}>
        <View style={{ height: 50 }}>
          <Text category="h4">Gift Card</Text>
        </View>

        <View style={{ height: 40 }}>
          <Text style={{ color: ThemeColor.darkGray }}>
            Connect your favorite people with their favorites, from noodles to
            nachos.
          </Text>
        </View>

        <View style={{ height: 20 }}>
          <Text style={{ color: ThemeColor.darkGray }}>
            Customise and buy gift card.
          </Text>
        </View>
        <View style={{ height: '70%' }}>
          <CarouselSlide
            ref={carousel}
            data={data}
            loop={false}
            autoPlay={true}
            style={[
              //{ justifyContent: 'flex-start', alignItems: 'center' },
              { width: 500, height: 400 },
            ]}
            width={500}
            height={400}
            renderItem={renderItem}
            snapEnabled={false}
          >
            {paginationIndicator(data)}
          </CarouselSlide>
        </View>
        <View
          style={{
            width: isWeb ? '35%' : '50%',
            height: '40%',
            justifyContent: 'space-between',
          }}
        >
          <View>
            <Button testID="buy_gift_btn" onPress={() => {}} status={'success'}>
              <Text style={ThemeStyles.bold}>Buy Gift Card</Text>
            </Button>
          </View>

          <View>
            <Input
              placeholder="Enter Gift Card Pin"
              value={giftCardPin}
              label={'Have a Gift Card? Redeem it here:'}
              accessoryRight={() => (
                <Button
                  testID="buy_gift_btn"
                  onPress={() => setGiftCardPin('')}
                  plain
                >
                  <Text>Clear</Text>
                </Button>
              )}
              style={[giftCardStyles.search_input, { flex: isWeb ? 1 : null }]}
              onChangeText={(nextValue) => setGiftCardPin(nextValue)}
            />
          </View>

          <View>
            <Button testID="buy_gift_btn" onPress={() => {}} status={'success'}>
              <Text style={ThemeStyles.bold}>Redeem Gift Card</Text>
            </Button>
          </View>
          <View>
            <Text style={[ThemeStyles.bold, { color: ThemeColor.darkGray }]}>
              Gift card amount will be added to your DoorDash Credit which are
              automatically applied to your next order.
            </Text>
          </View>
        </View>
      </View>
      <View style={{ height: 200 }} />
    </ScreenWrapper>
  );
};
const giftCardStyles = StyleSheet.create({
  main_wrapper: {
    ...ThemeStyles.pageVerticalSpacingMedium,
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '2%',
  },
  paging_indicator: {
    width: 20,
    height: 8,
    marginRight: '3%',
    borderColor: ThemeColor.lightGray,
    borderWidth: 1,
    borderRadius: 60,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  search_input: {
    borderColor: ThemeColor.gray,
    borderRadius: 10,
    borderWidth: 1,
    marginRight: 10,
  },
});

export default GiftCard;
