import React from 'react';
import { StyleSheet, View } from 'react-native';

import BaseLoader from './indexLoader';
import styles, { ids } from './styles';

function ItemMediumLoader({ containerStyle }) {
  return (
    <View
      dataSet={{ media: ids.itemMediumContainer }}
      style={[styles.itemMediumContainer, StyleSheet.flatten(containerStyle)]}
    >
      <BaseLoader style={{ aspectRatio: 1 / 1 }} />
    </View>
  );
}

export default ItemMediumLoader;
